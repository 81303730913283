import { localeFormat } from '../../../format/luxonToDisplayString';
import { useState } from 'react';
import { Button, Popup, toDefaultPopupFooter } from '../../../components';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { toDisplayDateFromISOString } from '../../../g11n/displayDates';
import { HeaderLabel } from './HeaderLabel';
import { PatientInfo } from './PatientInfo';
import { SignedInfo } from './SignedInfo';
import './styles.css';
import { EMPTY_OBJECT } from '../../../constants';
import {
  MenuNodeLeafFeature,
  toMenuNode,
} from '../../../contexts/XeMenuNodeContext/components';
import { useEnterprise } from '../../../contexts/XeEnterpriseContext';

export const Header = (props) => {
  const labels = useXeLabels();
  const { userData: { XeAppMenus = [] } = {} } = useEnterprise();

  // We are making the assumption here that XePatientDemographics exists in the menu data.
  const patientDemographicsNode = toMenuNode(
    XeAppMenus,
    ({ HtmlComponentName } = {}) =>
      HtmlComponentName === 'XePatientDemographics'
  );
  const [showPatientDemographics, setShowPatientDemographics] = useState(false);

  if (!props.patient) return null;

  const { patient, activeDocument = EMPTY_OBJECT } = props;
  const { EnteredDate, SignedBy, SignedDate, IPID } = activeDocument;

  return (
    <div className="clindoc__header">
      {(patient || IPID) && <PatientInfo {...patient} IPID={IPID} />}
      {EnteredDate && (
        <>
          <HeaderLabel type="label">Created On</HeaderLabel>
          <HeaderLabel>
            {toDisplayDateFromISOString(EnteredDate, localeFormat.MEDIUM)}
          </HeaderLabel>
        </>
      )}
      <SignedInfo date={SignedDate} signee={SignedBy} />
      <div style={{ flex: 1 }} />
      {patientDemographicsNode && (
        <Button
          dataElementName="clinicalDocsHeader__details"
          onClick={() => setShowPatientDemographics(true)}
        >
          {labels.Details}
        </Button>
      )}
      {showPatientDemographics ? (
        <Popup
          FooterComponent={toDefaultPopupFooter({
            closeLabelKey: 'Close',
            onClose: () => setShowPatientDemographics(false),
          })}
          size="large"
        >
          <MenuNodeLeafFeature
            menuNodeId={patientDemographicsNode?.MenuNodeID}
            patient={{ IPID: patient?.IPID }}
          />
        </Popup>
      ) : null}
    </div>
  );
};
