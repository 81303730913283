import PropTypes from 'prop-types';
import { Label } from '../Label';

export const DefaultHeader = (props) => {
  const { title = '', Controller, HeaderButtons, dataElementName = '' } = props;
  return (
    <div className="flex-container align-items-center justify-content-space-between">
      <div className="flex-container align-items-center">
        {Controller}
        <Label
          dataElementName={
            !!dataElementName
              ? `${dataElementName}__headerLabel`
              : 'headerLabel'
          }
          className="margin-left-medium"
        >
          {title}
        </Label>
      </div>
      {!!HeaderButtons && HeaderButtons}
    </div>
  );
};

DefaultHeader.propTypes = {
  title: PropTypes.string,
  HeaderButtons: PropTypes.node,
  dataElementName: PropTypes.string,
};
