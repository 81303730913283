export const SHOULD_UPDATE_FILE = 'attachmentPopup/updateFile';
export const SHOULD_SPLIT_PDF = 'attachmentPopup/splitFile';
export const SHOULD_ADD_FILE = 'attachmentPopup/addFile';
export const RESPONSE_ADD_FILE = 'attachmentPopup/responseAddFile';
export const SET_ITEM_DATA = 'attachmentPopup/setItemData';
export const SHOULD_CLOSE = 'attachmentPopup/closeSplitter';
export const RESPONSE_FETCH_FILE = 'attachmentPopup/fetchFileResponse';
export const RESPONSE_SPLIT_FILE = 'attachmentPopup/splitFileResponse';
export const RESPONSE_UPDATE_FILE = 'attachmentPopup/updateFileResponse';
export const UPLOAD_ATTACHMENT = 'attachmentPopup/uploadAttachment';
export const PERFORM_FILE_ACTION = 'attachmentPopup/performFileAction';
export const RESPONSE_FETCH_SCAN_DOC = 'attachmentPopup/responseFetchScanDoc';
export const UPDATE_UPLOADED_ATTACHMENT =
  'attachmentPopup/updateUploadedAttachment';
export const REQUEST_DOWNLOAD_ITEM = 'attachmentPopup/requestDownloadItem';
export const REQUEST_FILE = 'attachmentPopup/requestFile';
export const REQUEST_REMOVE_FILE = 'attachmentPopup/requestRemoveFile';
export const RESPONSE_REMOVE_FILE = 'attachmentPopup/responseRemoveFile';
export const SHOULD_UPDATE_IPID_DATA = 'attachmentPopup/updateIPIDData';
