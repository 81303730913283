import {
  Flexbox,
  Grid,
  Popup,
  DropDownList,
  CalendarPatientBar,
  DatePicker,
  Icon,
  TextInput,
} from '..';

import { Footer, FooterButton } from '../Popup/components/Footer';

import { XeStaffSearchWidget } from '../../widgets';
import { REQUIRED } from '../../icons';

import { useState, useRef, useEffect, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';

import { getDetails } from 'services/enc-forms/xe-enc-forms-svc';
import { getPatient } from 'services/patients/xe-patients-svc';
import {
  update as updateEncounterForm,
  submit as submitEncounterForm,
} from 'services/enc-forms/xe-enc-forms-svc';

import updateRequestSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormWithPxDx.json';

import { useXeRefData } from '../../contexts/XeRefDataContext';
import { useXeQuery } from '../../data/useXeQuery';
import { useXeMutation } from '../../data/useXeMutation';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { StaffSearchWidgetTypes } from '../../widgets/XeStaffSearchWidget';
import { identity } from '../../fp/fp';
import { SchemaReducer, useSchemaDispatch } from '../../schema/SchemaReducer';
import { UPSERT } from '../../schema/JSONSchemaReducer';
import { withDefaultJSONSchemaReducer } from '../../schema/JSONSchemaReducer';
import {
  toDefaultValidator,
  defaultBusinessRuleReducer,
} from '../../validators/schemaValidators';
import { castSchema } from '../../schema/schemaCaster';
import { pluck } from '../../fp/object';
import { usePathNavigate } from '../../hooks/usePathNavigate';

const toXeEncFormWithPxDx = castSchema(updateRequestSchema);

const ENCOUNTER_FORM_RULES = [
  [pluck('EncounterDate'), defaultBusinessRuleReducer],
  [pluck('BillingProviderID'), defaultBusinessRuleReducer],
];

const validator = toDefaultValidator(ENCOUNTER_FORM_RULES);

const dxGridDefs = [
  {
    headerLabelKey: 'Primary',
    field: 'IsPrimary',
    cellRenderer: ({ data }) =>
      data?.IsPrimary ? (
        <Icon icon={REQUIRED} className="md-12" />
      ) : (
        <span></span>
      ),
  },
  {
    headerLabelKey: '#',
    field: 'Sequence',
  },
  {
    headerLabelKey: 'ICDCode',
    field: 'DiagnosisID.Code',
    cellRenderer: ({ data }) =>
      `${data?.DiagnosisID?.Code} ${data?.DiagnosisID?.CodeTypeID}`,
  },
  {
    headerLabelKey: 'Diagnosis',
    field: 'DiagnosisID.Name',
  },
];

const pxGridDefs = [
  {
    headerLabelKey: 'Status',
    field: 'StatusID.Name',
  },
  {
    headerLabelKey: 'CPTCode',
    field: 'CodeRecordID.Code',
  },
  {
    headerLabelKey: 'Modifier',
    field: 'XeEncFormPxMod',
    valueFormatter: ({ value }) =>
      value.map((mod) => mod?.CodeRecordID.Code).join(' '),
  },
  {
    headerLabelKey: 'Charge',
    field: 'CodeRecordID.Name',
  },
  {
    headerLabelKey: 'Units',
    field: 'Quantity',
  },
  {
    headerLabelKey: 'DollarAmount',
    field: 'Amount',
  },
  {
    headerLabelKey: 'Comments',
    field: 'Comments',
  },
  {
    headerLabelKey: 'Linked',
    field: 'XeEncFormPxDx',
    valueFormatter: ({ data } = EMPTY_OBJECT) => {
      const { XeEncFormPxDx = EMPTY_ARRAY } = data;
      return XeEncFormPxDx.map(({ Sequence }) => Sequence)
        .sort((a, b) => a - b)
        .join(',');
    },
  },
];

const EncounterForm = (props) => {
  const { patientDetails, encounterDetails = EMPTY_OBJECT } = props;

  const navigate = usePathNavigate();
  const labels = useXeLabels();
  const { SpecialBillingCode = EMPTY_ARRAY, XgPointOfService = EMPTY_ARRAY } =
    useXeRefData();
  const schemaDispatch = useSchemaDispatch();

  const {
    BillingProviderID,
    ReferringProviderID,
    PerformProviderID,
    XeEncFormDx = EMPTY_ARRAY,
    XeEncFormPx = EMPTY_ARRAY,
  } = encounterDetails;

  const onViewChart = useCallback(
    (ipid) => navigate(`~/DefaultSearchFeature/${ipid}`),
    [navigate]
  );

  return (
    <Flexbox direction="column" className="flex-1">
      <CalendarPatientBar patient={patientDetails} onViewChart={onViewChart} />
      <Flexbox>
        <Flexbox className="margin-all-small flex-1">
          <DatePicker
            dataPath="EncounterDate"
            descriptor={labels.EncounterDate}
            required
          />
        </Flexbox>
        <Flexbox className="margin-all-small flex-1">
          <DropDownList
            data={XgPointOfService}
            descriptor={labels.PlaceOfService}
            labelFn={pluck('text')}
            valueFn={pluck('id')}
            required
            dataPath="PointOfServiceID"
          />
        </Flexbox>
      </Flexbox>
      <Flexbox>
        <Flexbox className="margin-all-small flex-1">
          <XeStaffSearchWidget
            descriptor={labels.BillingProvider}
            required
            value={BillingProviderID}
            type={StaffSearchWidgetTypes.PROVIDER}
            valueFn={pluck('StaffID')}
            onChange={(BillingProviderID) => {
              schemaDispatch({
                type: UPSERT,
                path: 'BillingProviderID',
                value: BillingProviderID,
              });
            }}
          />
        </Flexbox>
        <Flexbox className="margin-all-small flex-1">
          <XeStaffSearchWidget
            descriptor={labels.ReferringProvider}
            value={ReferringProviderID}
            type={StaffSearchWidgetTypes.PROVIDER}
            valueFn={pluck('StaffID')}
            onChange={(ReferringProviderID) => {
              schemaDispatch({
                type: UPSERT,
                path: 'ReferringProviderID',
                value: ReferringProviderID,
              });
            }}
          />
        </Flexbox>
      </Flexbox>
      <Flexbox>
        <Flexbox className="margin-all-small">
          <XeStaffSearchWidget
            descriptor={labels.PerformingProvider}
            value={PerformProviderID}
            type={StaffSearchWidgetTypes.PROVIDER}
            valueFn={pluck('StaffID')}
            onChange={(PerformProviderID) => {
              schemaDispatch({
                type: UPSERT,
                path: 'PerformProviderID',
                value: PerformProviderID,
              });
            }}
          />
        </Flexbox>
      </Flexbox>
      <Flexbox direction="column" style={{ height: `375px` }}>
        <Grid columnDefs={dxGridDefs} data={XeEncFormDx} />
        <Grid columnDefs={pxGridDefs} data={XeEncFormPx} />
      </Flexbox>
      <Flexbox>
        <Flexbox className="flex-1">
          <DropDownList
            descriptor={labels.SpecialBilling}
            data={SpecialBillingCode}
            labelFn={pluck('text')}
            valueFn={({ id } = EMPTY_OBJECT) => parseInt(id)}
            dataPath="SBCodeID"
          />
        </Flexbox>
        <TextInput
          className="flex-1"
          descriptor={labels.Comments}
          dataPath="Comments"
        />
      </Flexbox>
    </Flexbox>
  );
};

export const EncounterFormPopupView = (props) => {
  const { onClose, onSaveClose, EncFormID } = props;

  const [validityState, setValidityState] = useState(EMPTY_OBJECT);
  const labels = useXeLabels();
  const queryClient = useQueryClient();

  const { valid, changed, instance } = validityState;

  const {
    data: [encounterDetails = EMPTY_OBJECT] = EMPTY_ARRAY,
    isFetching: isFetchingEncounter,
  } = useXeQuery(getDetails({ encFormId: EncFormID }, identity), {
    enabled: !!EncFormID,
  });

  const initialEncounterFormData = useRef();

  useEffect(() => {
    if (!isFetchingEncounter && !initialEncounterFormData.current) {
      initialEncounterFormData.current = toXeEncFormWithPxDx(encounterDetails);
    }
  }, [encounterDetails, initialEncounterFormData, isFetchingEncounter]);

  const { IPID, IsDraft } = encounterDetails;

  const { data: [patientDetails] = [], isFetching: isFetchingPatient } =
    useXeQuery(getPatient({ ipid: IPID }, identity), { enabled: !!IPID });

  const updateEncounterMutation = useXeMutation(
    ({ encounterDetails, EncFormID } = EMPTY_OBJECT) =>
      updateEncounterForm(
        toXeEncFormWithPxDx({
          ...encounterDetails,
          IsDraft: true,
        }),
        { encFormId: EncFormID },
        identity
      ),
    {
      onSuccess: () => {
        onSaveClose();
        queryClient.invalidateQueries(['xe-enc-forms-svc', 'getDetails']);
      },
    }
  );

  const submitEncounterMutation = useXeMutation(
    (data) => submitEncounterForm(data, identity),
    {
      onSuccess: () => {
        onSaveClose();
        queryClient.invalidateQueries(['xe-enc-forms-svc', 'getDetails']);
      },
    }
  );

  const updateAndSubmitEncounterMutation = useXeMutation(
    ({ encounterDetails, EncFormID } = EMPTY_OBJECT) =>
      updateEncounterForm(
        toXeEncFormWithPxDx({
          ...encounterDetails,
          IsDraft: false,
        }),
        { encFormId: EncFormID },
        identity
      ),
    {
      onSuccess: (data) => {
        const encFormId = data?.results?.EncFormID;
        submitEncounterMutation.mutate({ encFormId });
      },
    }
  );

  const onSave = () => {
    updateEncounterMutation.mutate({
      encounterDetails: instance,
      EncFormID,
    });
  };

  const onSubmit = () => {
    updateAndSubmitEncounterMutation.mutate({
      encounterDetails: instance,
      EncFormID,
    });
  };

  return !isFetchingEncounter && !isFetchingPatient ? (
    <Popup size="large" title="Encounter Form">
      <SchemaReducer
        schema={updateRequestSchema}
        initialValue={initialEncounterFormData.current}
        toJsonReducer={withDefaultJSONSchemaReducer(validator)}
        onChange={setValidityState}
      >
        <EncounterForm
          onClose={onClose}
          encounterDetails={instance}
          patientDetails={patientDetails}
          EncFormID={EncFormID}
        />
      </SchemaReducer>
      <Footer>
        <FooterButton onClick={onClose}>{labels.Close}</FooterButton>
        <FooterButton
          disabled={!IsDraft || !changed || !valid}
          onClick={onSave}
        >
          {labels.SaveAsDraft}
        </FooterButton>
        <FooterButton disabled={!valid} onClick={onSubmit}>
          {labels.Submit}
        </FooterButton>
      </Footer>
    </Popup>
  ) : null;
};

export default EncounterFormPopupView;
