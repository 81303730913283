import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import {
  toTransientKeyReducer,
  clearTransientKey,
} from '../../legacy/transient';
import {
  ON_VIEW_CHANGE,
  RESPONSE_ADD_CALL_LOG,
  RESPONSE_CALL_LOG_DETAIL,
  RESPONSE_CALL_LOG_TYPES,
  RESPONSE_PHONE_CONTACTS,
  RESPONSE_PATIENT_VISITS,
  RESPONSE_ADD_PERSONAL_CONTACT,
  RESPONSE_ADD_CLINICAL_CONTACT,
  SHOULD_ADD_PATIENT_CONTACT,
  SHOULD_CANCEL_ADD_PATIENT_CONTACT,
  SHOULD_CANCEL_CALL_LOG,
  SHOULD_SHOW_ADD_PATIENT_CONTACT,
  SHOULD_UPDATE_CALL_LOG,
  SHOULD_UPDATE_CLINICAL_CONTACT_TYPE,
  SHOULD_UPDATE_CONTACT,
  SET_PHONE_CONTACTS_AFTER_EDIT,
} from './actions';
import {
  ADD_PATIENT_CONTACT_POPUP_KEY,
  ASSIGN_CLINICAL_CONTACT_TRANSIENT_KEY,
  DEFAULT_STATE,
} from './constants';
import { isEmpty } from '../../fp/pred';
import { formatStaffName } from '../../utils';

const toAddPatientContactPopupActionValueReducer = toTransientKeyReducer(
  ADD_PATIENT_CONTACT_POPUP_KEY
);

const isGeneralCallType = (state, { value: { CallType } = {} } = {}) =>
  CallType === 'GENERAL';

const toFormatContact = (item) => {
  if (!item || isEmpty(item)) return '';
  const { RelationshipName } = item;
  return `${formatStaffName(item)} (${RelationshipName})`;
};

const updateStateAfterInlinePhoneEdit = (state, { value: callWithOptions }) => {
  const CallToPhone = callWithOptions.find((callToOption) => {
    return callToOption?.CallerID === state?.callLog?.CallerID;
  });
  return {
    ...state,
    callLog: {
      ...state?.callLog,
      CallToName: toFormatContact(CallToPhone),
    },
    callWithOptions,
    CallToPhone: undefined,
    callToPhoneOptions: CallToPhone?.XePhone,
  };
};

export default [
  when((state) => state === undefined)(() => DEFAULT_STATE),
  when(isType(RESPONSE_ADD_CALL_LOG, SHOULD_CANCEL_CALL_LOG))(
    () => DEFAULT_STATE
  ),
  when(isType(RESPONSE_PATIENT_VISITS))(toActionValueReducer('patientVisits')),
  when(
    isType(SHOULD_UPDATE_CALL_LOG),
    isGeneralCallType
  )(toActionValueReducer('patientVisits', () => [])),
  when(isType(SHOULD_UPDATE_CALL_LOG))(toActionValueReducer('callLog')),
  when(isType(RESPONSE_CALL_LOG_TYPES))(toActionValueReducer('callLogTypes')),
  when(isType(RESPONSE_CALL_LOG_DETAIL))(
    toActionValueReducer('initialCallLog')
  ),
  when(isType(SHOULD_SHOW_ADD_PATIENT_CONTACT))(
    toAddPatientContactPopupActionValueReducer('patientContact')
  ),
  when(isType(SHOULD_ADD_PATIENT_CONTACT, SHOULD_CANCEL_ADD_PATIENT_CONTACT))(
    clearTransientKey(ADD_PATIENT_CONTACT_POPUP_KEY),
    toActionValueReducer('selectedContact', () => undefined)
  ),
  when(isType(SHOULD_UPDATE_CONTACT))(
    toActionValueReducer('selectedContact'),
    toActionValueReducer(
      `${ASSIGN_CLINICAL_CONTACT_TRANSIENT_KEY}.selectedContact`
    )
  ),
  when(isType(SHOULD_UPDATE_CLINICAL_CONTACT_TYPE))(
    toActionValueReducer(
      `${ASSIGN_CLINICAL_CONTACT_TRANSIENT_KEY}.selectedContactType`
    )
  ),
  when(isType(ON_VIEW_CHANGE))(
    toActionValueReducer('selectedContact', () => undefined),
    toActionValueReducer(
      `${ASSIGN_CLINICAL_CONTACT_TRANSIENT_KEY}.selectedContact`,
      () => undefined
    ),
    toActionValueReducer(
      `${ASSIGN_CLINICAL_CONTACT_TRANSIENT_KEY}.selectedContactType`,
      () => undefined
    )
  ),
  when(isType(RESPONSE_ADD_PERSONAL_CONTACT, RESPONSE_ADD_CLINICAL_CONTACT))(
    toActionValueReducer('newContact')
  ),
  when(isType(RESPONSE_PHONE_CONTACTS))(
    toActionValueReducer('callWithOptions')
  ),
  when(isType(SET_PHONE_CONTACTS_AFTER_EDIT))(updateStateAfterInlinePhoneEdit),
];
