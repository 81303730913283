import GrabButton from '../../../../../GrabButton';

export const WorklistGrab = (props) => {
  const {
    actionKey,
    actionData,
    updateActionCellData,
    context,
    labels,
    menuNode,
  } = props;

  const { [actionKey]: xeWorkContext = {} } = context;

  const { WorkflowID, WorkState } = xeWorkContext;

  const { StateOwner, StateOwnerName, RecordID, ProcessingTypeID } = actionData;

  return (
    <GrabButton
      onGrab={(value) => {
        updateActionCellData({
          ...actionData,
          ...value,
        });
      }}
      StateOwner={StateOwner}
      StateOwnerName={StateOwnerName}
      ProcessingTypeID={ProcessingTypeID}
      RecordID={RecordID}
      WorkflowID={WorkflowID}
      WorkState={WorkState}
      labels={labels}
      menuNode={menuNode}
    />
  );
};

export default WorklistGrab;
