export const RECOVER_STALLED_DOCUMENT = 'clinicalDocumentButtons/recover';
export const DOWNLOAD_EXISTING_DOCUMENT = 'clinicalDocumentButtons/download';
export const POLL_FOR_DOCUMENT_STATE = 'clinicalDocumentButtons/poll';
export const RESPONSE_LATEST_POLL =
  'clinicalDocumentButtons/latestPollResponse';
export const STOP_POLLING_FOR_DOCUMENT_STATE =
  'clinicalDocumentButtons/stopPolling';

export const MARK_DOCUMENT_IN_ERROR = 'clinicalDocumentButtons/markInError';
export const DID_MARK_DOCUMENT_IN_ERROR =
  'clinicalDocumentButtons/didMarkInError';
