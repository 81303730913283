import { schemaPipe } from '../../../schema/schemaTypeBuilder';
import { SchemaReducer } from '../../../schema/SchemaReducer';
import { castSchema } from '../../../schema/schemaCaster';
import {
  withDefaultJSONSchemaReducer,
  UPSERT,
} from '../../../schema/JSONSchemaReducer';
import { localeFormat } from '../../../format/luxonToDisplayString';
import { isNil, isEmptyObject } from '../../../fp/pred';
import { pluck, put } from '../../../fp/object';
import { useScopedSelector } from '../../../hooks/scopedReducer';
import { useRef, useState } from 'react';
import CategoryIDSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.scandoctype.CategoryID.json';
import XeScanDocDocumentTypeIDSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$DocumentTypeID.json';
import XeScanDocSetItemSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$XeScanDocSet.json';
import {
  DropDownList,
  GridLayout,
  IconButton,
  Input,
  Label,
  Panel,
  Flexbox,
  MarkInErrorPopup,
} from '../../';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { useXeRights } from '../../../contexts/XeUserRightsContext';
import fileSizeFormatter from '../../../format/fileSizeFormatter';
import { toDisplayDateFromISOString } from '../../../g11n/displayDates';
import { CLOSE, EXPORT_DATA } from '../../../icons';
import { formatCommonName } from '../../../utils';
import { PrimaryCategoryIDKey } from '../constants';
import { toDocumentTypeID, toDocTypeID } from '../utils';
import { useXeRefData } from '../../../contexts/XeRefDataContext';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants';

// NOTE: (SYNUI-5190) This is a manual transform into a cast so that the primary key from the
// CategoryID schema can be properly set onChange (JDM)
const transformCategoryId = (o) => {
  const categoryID = pluck('CategoryID')(o);
  return schemaPipe(CategoryIDSchema)(put('DocTypeID')(categoryID))(o);
};

const toDefaultState = (file = {}, XeResultStatusCode = []) => {
  if (!file.StatusCode) {
    const { id } = XeResultStatusCode[0];
    return {
      ...file,
      StatusCode: id,
    };
  }
  return file;
};

const customReducer = (state, action = {}) => {
  const { type, path } = action;
  const { instance } = state;

  switch (path) {
    case 'CategoryID':
      {
        if (type === UPSERT) {
          return {
            ...state,
            instance: { ...instance, DocumentTypeID: undefined },
          };
        }
      }
      break;
  }

  return state;
};

export const FileDetailsPanel = (props) => {
  const {
    dataElementName,
    file = EMPTY_OBJECT,
    onChange,
    onDownload,
    onMarkInError,
    categories = EMPTY_ARRAY,
    documentTypeId,
  } = props;

  const labels = useXeLabels();
  const { XeResultStatusCode } = useXeRefData();
  const {
    CategoryID = EMPTY_OBJECT,
    FileName = '',
    FileSize,
    UploadDate,
    UploadUser = EMPTY_OBJECT,
  } = file;

  const rightsBase = pluck('RightsBase')(documentTypeId) || null;

  const userResourceID = useScopedSelector(
    pluck('contexts', 'enterprise', 'userData', 'ResourceID') || ''
  );

  const {
    [`MARKINERROR_${rightsBase}`]: markInErrorRightsBase,
    [`EDIT_${rightsBase}`]: editRightsBase,
    [`EXPORT_${rightsBase}`]: exportRightsBase,
  } = useXeRights();

  const canMarkInError = !rightsBase || markInErrorRightsBase;
  const canEdit =
    userResourceID === UploadUser.ResourceID || !rightsBase || editRightsBase;
  const canExport = !rightsBase || exportRightsBase;

  const filteredDocTypes = categories
    .filter(({ CategoryID: catID } = {}) => {
      return catID === CategoryID[PrimaryCategoryIDKey];
    })
    .reduce((acc, curr) => {
      if (!curr) return acc;
      const { XeDocumentCategory } = curr;
      return [...acc, ...XeDocumentCategory];
    }, []);
  const disableDocTypeDropDown =
    isEmptyObject(CategoryID) || !filteredDocTypes.length;

  const [showMarkInError, setShowMarkInError] = useState(false);

  const instanceRef = useRef(toDefaultState(file, XeResultStatusCode));

  return (
    <>
      <Panel
        dataElementName={dataElementName}
        className="file-details-panel flex-shrink-0"
        childrenClassName="flex-container-row justify-content-space-between"
      >
        <SchemaReducer
          initialValue={instanceRef.current}
          schema={XeScanDocSetItemSchema}
          onChange={({ instance }) => {
            onChange(instance);
          }}
          toJsonReducer={withDefaultJSONSchemaReducer(customReducer)}
        >
          <GridLayout templateColumns="auto 1fr">
            <Input
              data-element-name="fileDetails__description"
              dataPath="Description"
              type="text"
              className="file-details-panel__grid-content"
              descriptorClassName="file-details-panel__grid-label"
              descriptor={labels.Description}
            />
            <DropDownList
              dataElementName="fileDetails__category"
              dataPath="CategoryID"
              data={categories}
              descriptor={labels.Category}
              required={true}
              labelFn={pluck('Name')}
              valueFn={transformCategoryId}
              comparator={(item, value) => {
                const itemDocTypeId = toDocTypeID(item);
                const valueDocTypeId = toDocTypeID(value);
                return (
                  itemDocTypeId &&
                  valueDocTypeId &&
                  itemDocTypeId === valueDocTypeId
                );
              }}
              className="file-details-panel__grid-content"
              labelClassName="file-details-panel__grid-label"
              disabled={!categories.length || !canEdit}
            />
            <DropDownList
              dataElementName="fileDetails__documentType"
              dataPath="DocumentTypeID"
              data={filteredDocTypes}
              disabled={disableDocTypeDropDown || !canEdit}
              descriptor={labels.DocumentType}
              required={true}
              labelFn={pluck('XeDocumentType', 'Name')}
              valueFn={castSchema(XeScanDocDocumentTypeIDSchema)}
              comparator={(item, value) => {
                return toDocumentTypeID(item) === toDocumentTypeID(value);
              }}
              className="file-details-panel__grid-content"
              labelClassName="file-details-panel__grid-label"
            />
            <DropDownList
              dataElementName="fileDetails__statusCode"
              dataPath="StatusCode"
              data={XeResultStatusCode}
              descriptor={labels.Status}
              labelFn={pluck('text')}
              valueFn={pluck('id')}
              className="file-details-panel__grid-content"
              labelClassName="file-details-panel__grid-label"
              disabled={!canEdit}
            />
            {/* Get data mapping for the Recieved/From line item (JDM) */}
            {/* {isExistingScanDoc ? (
              <Label descriptor={labels.Received}>
              </Label>
            ) : null} */}
            <Label
              dataElementName="fileDetails__uploaded"
              descriptor={labels.Uploaded}
            >
              {UploadDate
                ? `${toDisplayDateFromISOString(
                    UploadDate,
                    localeFormat.LONG
                  )} ${labels.By} ${formatCommonName(UploadUser)}`
                : null}
            </Label>
            <Label
              dataElementName="fileDetails__fileName"
              descriptor={labels.Filename}
              descriptorClassName="file-details-panel__grid-label"
            >
              {!isNil(FileSize)
                ? `${FileName} (${fileSizeFormatter(FileSize)})`
                : null}
            </Label>
          </GridLayout>
          <Flexbox direction="column" justifyContent="space-between">
            {onMarkInError ? (
              <IconButton
                dataElementName="fileDetails__close"
                icon={CLOSE}
                look="flat"
                disabled={!canMarkInError}
                description={labels.MarkInError}
                onClick={() => setShowMarkInError(true)}
              />
            ) : (
              <div />
            )}
            <IconButton
              dataElementName="fileDetails__download"
              description={labels.Download}
              icon={EXPORT_DATA}
              disabled={!canExport}
              onClick={() => onDownload(file)}
            />
          </Flexbox>
        </SchemaReducer>
      </Panel>
      {showMarkInError && (
        <MarkInErrorPopup
          onClose={() => setShowMarkInError(false)}
          onConfirm={(errorDescription) => {
            setShowMarkInError(false);
            onMarkInError(errorDescription);
          }}
        />
      )}
    </>
  );
};
