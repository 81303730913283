import { useCallback, useContext, useState } from 'react';
import './styles.css';
import {
  REQUEST_OWNER_RELEASE,
  RESPONSE_OWNER_CHANGE,
  REQUEST_OWNER_CHANGE,
} from './actions';
import withClassNameModifiers from '../../utils/withClassNameModifiers';
import { ofType } from '../../frp/operators/ofType';
import { combineEpics } from 'redux-observable';
import { combinePredicatedReducers } from '../../connection/toConnectionDef';
import { useReducer$ } from '../../hooks/useReducer$';
import { useRef$ } from '../../hooks/useRef$';
import { useEffect$ } from '../../hooks/useEffect$';
import { useEnterprise } from '../../contexts/XeEnterpriseContext';
import epics from './epics';
import reducers from './reducers';
import { tap } from 'rxjs/operators';
import { CellRendererContext } from '../Grid/contexts/CellRendererContext';
import { GrabAssign } from './components/GrabAssign';
import { UnassignPopup } from './components/UnassignPopup';
import { AssignToSelfPopup } from './components/AssignToSelfPopup';
import './styles.css';
import { of } from 'rxjs';
import { useXeRights } from '../../contexts/XeUserRightsContext';
import { toGrabButtonOnClick } from './utils';
import { NOOP_FUNCTION } from '../../constants';
import { Button } from '../Button';

const epic = combineEpics(...epics);
const reducer = combinePredicatedReducers(...reducers);

export const GrabButton = (props) => {
  const {
    onGrab,
    StateOwner,
    StateOwnerName,
    RecordID,
    WorkflowID,
    WorkState,
    ProcessingTypeID,
    labels,
    menuNode,
  } = props;

  const { processingTypeID = ProcessingTypeID } =
    useContext(CellRendererContext);

  const {
    userData: { ResourceID },
  } = useEnterprise();

  const processingTypeID$ = useRef$(processingTypeID);
  const epicWithDeps = useCallback(
    (action$, state$) => {
      return epic(action$, state$, {
        menuNode$: of(menuNode),
        processingTypeID$,
      });
    },
    [menuNode, processingTypeID$]
  );

  const [state = {}, dispatch, action$] = useReducer$(reducer, epicWithDeps);

  const {
    WORKLIST_GRAB: canGrab,
    WORKLIST_GRAB_ASSIGN: canGrabAssign,
    WORKLIST_GRAB_OVERWRITE: canGrabOverwrite,
  } = useXeRights();

  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [showUnassignPopup, setShowUnassignPopup] = useState(false);
  const [showAssignToSelfPopup, setShowAssignToSelfPopup] = useState(false);

  const toGrabActionFn = useCallback(() => {
    const actionFn = toGrabButtonOnClick({
      canGrab,
      canGrabAssign,
      canGrabOverwrite,
      dispatch,
      ResourceID,
      setShowAssignPopup,
      setShowUnassignPopup,
      setShowAssignToSelfPopup,
      StateOwner,
      WorkflowID,
      WorkState,
    });

    if (actionFn === NOOP_FUNCTION) {
      return undefined;
    }

    return actionFn;
  }, [
    canGrab,
    canGrabAssign,
    canGrabOverwrite,
    dispatch,
    ResourceID,
    StateOwner,
    WorkflowID,
    WorkState,
  ]);

  const rowText =
    !StateOwner && toGrabActionFn() ? labels.Grab : StateOwnerName;

  useEffect$(() => {
    return action$.pipe(
      ofType(RESPONSE_OWNER_CHANGE),
      tap(({ value }) => {
        onGrab(value);
      })
    );
  });

  return (
    <>
      <Button
        onClick={toGrabActionFn && toGrabActionFn()}
        className={withClassNameModifiers('worklist__grab-button', {
          enabled: toGrabActionFn(),
        })}
      >
        {rowText}
      </Button>
      {showUnassignPopup && (
        <UnassignPopup
          onClose={() => setShowUnassignPopup(false)}
          onConfirm={() => {
            setShowUnassignPopup(false);
            dispatch({
              type: REQUEST_OWNER_RELEASE,
              value: {
                RecordID: RecordID,
              },
            });
          }}
        />
      )}
      {showAssignToSelfPopup && (
        <AssignToSelfPopup
          onClose={() => setShowAssignToSelfPopup(false)}
          onConfirm={() => {
            setShowAssignToSelfPopup(false);
            dispatch({
              type: REQUEST_OWNER_CHANGE,
              value: {
                RecordID: RecordID,
              },
            });
          }}
        />
      )}
      {showAssignPopup && (
        <GrabAssign
          onClose={() => setShowAssignPopup(false)}
          onConfirm={() => {
            setShowAssignPopup(false);
          }}
          RecordID={RecordID}
          StateOwner={StateOwner}
          currentOwnerName={StateOwnerName}
          ResourceID={ResourceID}
          assignmentOptions={state.assignmentOptions}
          canGrabAssign={canGrabAssign}
          dispatch={dispatch}
        />
      )}
    </>
  );
};

export default GrabButton;
