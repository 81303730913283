import { ofType } from '../../frp/operators/ofType';
import { filter, map, mergeMap, pluck, withLatestFrom } from 'rxjs/operators';
import { castSchema } from '../../schema/schemaCaster';
import { SHOULD_SAVE_TASK, RESPONSE_SAVE_TASK } from './actions';
import { addTask, update } from 'services/wait-lists/xe-wait-lists-svc';
import addTaskRequestSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.waitlist.PatientTaskAddRequest.json';

const toMenuNodeRequestFn = (menuNode$) =>
  menuNode$.pipe(
    pluck('requestFn'),
    filter((x) => x),
    map((fn) => fn())
  );

const toTaskRequestEpic$ = (action$, state$, { menuNode$ }) =>
  action$.pipe(
    ofType(SHOULD_SAVE_TASK),
    pluck('value'),
    withLatestFrom(toMenuNodeRequestFn(menuNode$)),
    mergeMap(([value = {}, toRequest$]) => {
      return value.WaitListID
        ? update(value, {}, toRequest$({ fullRequest: true }))
        : addTask(
            castSchema(addTaskRequestSchema)(value),
            {},
            toRequest$({ fullRequest: true })
          );
    }),
    pluck('results'),
    map((value) => ({
      type: RESPONSE_SAVE_TASK,
      value,
    }))
  );

export default [toTaskRequestEpic$];
