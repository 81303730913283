import {
  Flexbox,
  Grid,
  Popup,
  IconButton,
  Label,
  Panel,
} from '../../components';

import { Footer, FooterButton } from '../Popup/components/Footer';

import { useState } from 'react';

import { formatCommonName } from '../../utils';
import { EDIT } from '../../icons';
import { EncounterFormPopupView } from '../../components/EncounterFormPopup';

import { useXeLabels } from '../../contexts/XeLabelContext';

import { getDetails } from 'services/claim-batchs/xe-claim-batchs-svc.js';
import { identity } from '../../fp/fp';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';
import { useXeQuery } from '../../data/useXeQuery';

import { toDisplayDateFromISOString } from '../../g11n/displayDates';
import { localeFormat } from '../../format/luxonToDisplayString';

const BatchClaimPopup = (props) => {
  const { batchClaimDetails, onEncounterSave } = props;
  const {
    CreateTStamp,
    SubmissionTimestamp,
    XeClaimBatchEntry = EMPTY_ARRAY,
    ClearingHouseID: { HealthPlanPayerID = EMPTY_OBJECT } = EMPTY_OBJECT,
  } = batchClaimDetails;
  const { Name: payerName, HealthPlanPayerID: PayerID } = HealthPlanPayerID;

  const labels = useXeLabels();

  const [shouldShowEncounterForm, setShouldShowEncounterForm] = useState(false);
  const [encounterFormToEdit, setEncounterFormToEdit] = useState();

  const createdData = toDisplayDateFromISOString(
    CreateTStamp,
    localeFormat.MEDIUM
  );
  const submittedDate = toDisplayDateFromISOString(
    SubmissionTimestamp,
    localeFormat.MEDIUM
  );

  // TODO: build this and column defs out via higher-order? (GCH)
  const BackgroundActionButtons = (props) => {
    const { data: { EncFormID: { EncFormID } = EMPTY_OBJECT } = EMPTY_OBJECT } =
      props;

    return (
      <Flexbox direction="row" justifyContent="space-around">
        <Flexbox>
          <IconButton
            className="flex-1"
            icon={EDIT}
            onClick={() => {
              setEncounterFormToEdit(EncFormID);
              setShouldShowEncounterForm(true);
            }}
          />
        </Flexbox>
      </Flexbox>
    );
  };

  const BATCH_CLAIM_COLUMN_DEFS = [
    {
      headerLabelKey: 'ID',
      field: 'EncFormID.EncFormID',
    },
    {
      headerLabelKey: 'Type',
      field: 'TransactionType',
    },
    {
      headerLabelKey: 'Consumer',
      valueGetter: (value) => {
        const IPID = value?.data?.EncFormID?.IPID;
        return formatCommonName(IPID, 'F, G');
      },
    },
    {
      headerLabelKey: 'Encounter',
      field: 'EncFormID.EncounterDate',
    },
    {
      headerName: '',
      resizable: false,
      sortable: false,
      enableRowGroup: false,
      pinned: 'right',
      suppressMovable: true,
      cellRenderer: BackgroundActionButtons,
    },
  ];

  return (
    <Flexbox direction="column" className="flex-1" title="Batch Details">
      <Panel>
        <Flexbox>
          <Label className="flex-1 margin-all-small" descriptor={labels.ID}>
            {PayerID}
          </Label>
          <Label className="flex-1 margin-all-small" descriptor={labels.Payer}>
            {payerName}
          </Label>
          <Label
            className="flex-1 margin-all-small"
            descriptor={labels.Created}
          >
            {createdData}
          </Label>
          <Label
            className="flex-1 margin-all-small"
            descriptor={labels.Submitted}
          >
            {submittedDate}
          </Label>
          <Label className="flex-1 margin-all-small" descriptor={labels.Count}>
            {XeClaimBatchEntry.length}
          </Label>
        </Flexbox>
      </Panel>
      <Grid
        columnDefs={BATCH_CLAIM_COLUMN_DEFS}
        data={XeClaimBatchEntry}
        rowHeight={50}
      />
      {shouldShowEncounterForm ? (
        <EncounterFormPopupView
          onClose={() => {
            setShouldShowEncounterForm(false);
          }}
          onSaveClose={() => {
            setShouldShowEncounterForm(false);
            onEncounterSave();
          }}
          EncFormID={encounterFormToEdit}
        />
      ) : null}
    </Flexbox>
  );
};

export const BatchClaimPopupView = (props) => {
  const { onClose, ClaimBatchID } = props;
  const labels = useXeLabels();

  const {
    data: batchClaimDetails = EMPTY_OBJECT,
    refetch: refetchBatchClaimDetails,
  } = useXeQuery(getDetails({ claimBatchId: ClaimBatchID }, identity), {
    enabled: !!ClaimBatchID,
  });

  return (
    <Popup
      dataElementName="Batch Claim Details"
      size="large"
      title="Batch Claim Details"
    >
      <BatchClaimPopup
        batchClaimDetails={batchClaimDetails}
        onEncounterSave={refetchBatchClaimDetails}
      />
      <Footer>
        <FooterButton onClick={onClose}>{labels.Close}</FooterButton>
      </Footer>
    </Popup>
  );
};

export default BatchClaimPopupView;
