import {
  useScopedDispatch,
  useScopedSelector,
} from '../../../hooks/scopedReducer';
import { pluck } from '../../../fp/object';
import { TreeViewList } from '../../TreeViewList';
import { SHOULD_OPEN_DOCUMENT, SHOULD_OPEN_MILLIMAN } from '../actions';
import { MILLIMAN_TYPE } from '../constants';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { useSystemPaths } from '../../../hooks/useSystemPaths';
import { Flexbox } from '../..';

const AvailableGuidelines = () => {
  const { pathRoot } = useSystemPaths();

  const dispatch = useScopedDispatch();
  const labels = useXeLabels();
  const guidelineOptions = useScopedSelector(pluck('availableGuidelines'));

  const onGuidelinesChanged = (guideline = {}) => {
    const { URL } = guideline;

    if (URL === MILLIMAN_TYPE) {
      dispatch({ type: SHOULD_OPEN_MILLIMAN, value: { pathRoot, guideline } });
    } else {
      dispatch({ type: SHOULD_OPEN_DOCUMENT, value: { pathRoot, guideline } });
    }
  };

  return (
    <Flexbox
      className="margin-left-auto margin-right-small margin-vertical-small"
      wrap={true}
    >
      <TreeViewList
        descriptor={labels.AvailableGuidelines}
        data={guidelineOptions}
        labelFn={pluck('Name')}
        branchFn={pluck('XeClinicalLinks')}
        branchLabelFn={pluck('Name')}
        value={null}
        onChange={(item) => onGuidelinesChanged(item)}
        forceExpand={true}
      />
    </Flexbox>
  );
};

export default AvailableGuidelines;
