import PropTypes from 'prop-types';
import { withClassNameModifiers } from '../../utils/withClassNameModifiers';
import './styles.css';

/**
 * @typedef GridLayoutProps
 * @property {string=} templateColumns
 * @property {string=} templateRows
 * @property {string=} alignContent
 * @property {string=} alignItems
 * @property {string=} rowGap
 * @property {string=} columnGap
 * @property {'column'|'row'=} autoFlow
 */

/**
 * @param {GridLayoutProps & React.HTMLAttributes<HTMLDivElement>} props
 */
export const GridLayout = (props) => {
  const {
    className,
    templateColumns,
    gridAutoRows,
    templateRows,
    children,
    style,
    alignContent,
    rowGap,
    columnGap,
    autoFlow,
    alignItems,
    dataElementName = '',
    normalizeItemDimensions = true,
    itemRef,
  } = props;

  const baseClassName = withClassNameModifiers('xjs-grid-layout', {
    normalized: normalizeItemDimensions,
  });
  const gridStyle = (() => {
    const styleObject = { ...(style || {}), display: 'grid' };
    if (templateColumns) {
      styleObject.gridTemplateColumns = templateColumns;
    }
    if (templateRows) {
      styleObject.gridTemplateRows = templateRows;
    }
    if (gridAutoRows) {
      styleObject.gridAutoRows = gridAutoRows;
    }
    if (alignContent) {
      styleObject.alignContent = alignContent;
    }
    if (rowGap) {
      styleObject.rowGap = rowGap;
    }
    if (columnGap) {
      styleObject.columnGap = columnGap;
    }
    if (alignItems) {
      styleObject.alignItems = alignItems;
    }
    if (autoFlow) {
      styleObject.gridAutoFlow = autoFlow;
    }
    return styleObject;
  })();

  return (
    <div
      ref={itemRef}
      style={gridStyle}
      className={className ? `${className} ${baseClassName}` : baseClassName}
      data-element-name={dataElementName}
    >
      {children}
    </div>
  );
};

GridLayout.propTypes = {
  templateColumns: PropTypes.string,
  templateRows: PropTypes.string,
  alignContent: PropTypes.oneOf(['center', 'start', 'end']),
  rowGap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  columnGap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  autoFlow: PropTypes.oneOf(['column', 'row']),
  dataElementName: PropTypes.string,
  normalizeItemDimensions: PropTypes.bool,
};
