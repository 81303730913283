import { combineEpics } from 'redux-observable';
import { useScopedSelector } from '../../../../../hooks/scopedReducer';
import { pluck } from '../../../../../fp/object';
import { isNil } from '../../../../../fp/pred';
import { identity } from '../../../../../fp/fp';
import { localeFormat } from '../../../../../format/luxonToDisplayString';
import { schemaGet } from '../../../../../schema/schemaTypeBuilder';
import AuthDetailsSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';
import XeHistoryViewSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.historyview.XeHistoryView.json';
import XeScanDocSetDetailsSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails.json';
import { Flexbox, IconButton, Label } from '../../../../';
import { useXeLabels } from '../../../../../contexts/XeLabelContext';
import { useXeRights } from '../../../../../contexts/XeUserRightsContext';
import { phoneNumberFormatter } from '../../../../../format/phoneNumberFormatter';
import { toDisplayDateFromISOString } from '../../../../../g11n/displayDates';
import { useRequestDownloadFile } from '../../../../../hooks/useRequestDownloadFile';
import {
  DOWNLOAD_DOCUMENT as DISPLAY_PDF,
  DOWNLOAD_FILE,
  REMOVE,
} from '../../../../../icons';
import { formatStaffName } from '../../../../../utils';
import { castFunction } from '../../../../../fp/fp';
import { GridLayout } from '../../../../GridLayout';
import './styles.css';
import { EMPTY_OBJECT } from '../../../../../constants';
import { ofType } from '../../../../../frp/operators/ofType';
import {
  withLatestFrom,
  filter,
  map,
  mergeMap,
  pluck as rxjsPluck,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { useReducer$ } from '../../../../../hooks/useReducer$';
import { useEffect$ } from '../../../../../hooks/useEffect$';
import { useState, useCallback } from 'react';
import { useMenuNode } from '../../../../../contexts/XeMenuNodeContext';
import { MarkInErrorPopup } from '../../../../../components';
import { updateSetItem } from 'services/scan-doc-sets/xe-scan-doc-sets-svc';

const SHOULD_MARK_ATTACHMENT_IN_ERROR = 'action/shouldMarkAttachmentInError';
const SHOULD_REFRESH = 'action/shouldRefresh';

const epics = [
  (action$, state$, { menuNode$ }) =>
    action$.pipe(
      ofType(SHOULD_MARK_ATTACHMENT_IN_ERROR),
      rxjsPluck('value'),
      withLatestFrom(
        menuNode$.pipe(
          rxjsPluck('requestFn'),
          filter((x) => x),
          map((fn) => fn())
        )
      ),
      mergeMap(([value, toRequest$]) =>
        updateSetItem(
          value,
          { itemId: value.ItemID },
          toRequest$({ fullRequest: true })
        )
      ),
      rxjsPluck('results'),
      map((value) => ({
        type: SHOULD_REFRESH,
        value,
      }))
    ),
];

const epic = combineEpics(...epics);

const AttachmentDetailsRender = (props) => {
  /**
   * @type {{ data: import('services/generated/types').XeScanDocSetDetails }}
   */
  const {
    data = EMPTY_OBJECT,
    onPDFClick,
    historyItem = EMPTY_OBJECT,
    worklistDetails,
    onRefresh,
  } = props;

  const menuNode = useMenuNode();
  const epicWithDeps = useCallback(
    (action$, state$) => epic(action$, state$, { menuNode$: of(menuNode) }),
    [menuNode]
  );
  const [, dispatch, action$] = useReducer$(identity, epicWithDeps);

  const FileName = schemaGet(XeScanDocSetDetailsSchema, 'FileName', data);
  const AuthorDate = schemaGet(XeScanDocSetDetailsSchema, 'AuthorDate', data);
  const Author = schemaGet(XeScanDocSetDetailsSchema, 'Author', data);
  const UploadUser = schemaGet(XeScanDocSetDetailsSchema, 'UploadUser', data);
  const DocumentTypeIDRightID = schemaGet(
    XeScanDocSetDetailsSchema,
    'DocumentTypeID.RightsBase',
    data
  );
  const Description = schemaGet(XeScanDocSetDetailsSchema, 'Description', data);

  const { SourcePhone, IncomingPhone } = data;

  const StatusName = schemaGet(XeHistoryViewSchema, 'StatusName', historyItem);

  const labels = useXeLabels();
  const RightID =
    useScopedSelector(pluck('contexts', 'enterprise', 'userData', 'RightID')) ||
    '';
  const { MARKINERROR_SCAN_DOC } = useXeRights();

  const linkToValue = ((worklistDetails) => {
    return (
      schemaGet(AuthDetailsSchema, 'AuthNum', worklistDetails) ||
      // (SYNUI-5304) TODO: We'll need to figure out the schemas for these other 4 types in order to use the schemaGet (JDM)
      pluck('IssueNum')(worklistDetails) ||
      pluck('XePatientVisit', 'VisitID')(worklistDetails) ||
      pluck('XePatientVisit', 'VisitID')(worklistDetails) ||
      pluck('XeAssessment', 'Name')(worklistDetails) ||
      labels.None
    );
  })(worklistDetails);

  const requestDownloadFile = useRequestDownloadFile();

  const canMarkInError = MARKINERROR_SCAN_DOC;

  const [showMarkInError, setShowMarkInError] = useState(false);

  useEffect$(
    () =>
      action$.pipe(
        ofType(SHOULD_REFRESH),
        tap(() => onRefresh(data)),
        tap(() => setShowMarkInError(false))
      ),
    [action$, data, onRefresh, setShowMarkInError]
  );

  return (
    <Flexbox alignItems="center">
      <GridLayout
        templateColumns="max-content auto"
        className="stretch-x padding-all-medium"
        style={{ justifyItems: 'start' }}
      >
        <Label
          dataElementName="attachmentDetails__date"
          descriptor={labels.AuthorDate}
        >
          {toDisplayDateFromISOString(AuthorDate, localeFormat.LONG)}
        </Label>
        <Label
          dataElementName="attachmentDetails__status"
          descriptor={labels.Status}
        >
          {StatusName}
        </Label>
        {!isNil(SourcePhone) ? (
          <Label
            dataElementName="attachmentDetails__source"
            descriptor={labels.Source}
          >
            {phoneNumberFormatter(SourcePhone)}
          </Label>
        ) : null}
        {!isNil(IncomingPhone) ? (
          <Label
            dataElementName="attachmentDetails__fax"
            descriptor={labels.Fax}
          >
            {phoneNumberFormatter(IncomingPhone)}
          </Label>
        ) : null}
        <Label
          dataElementName="attachmentDetails__fileName"
          descriptor={labels.Filename}
        >
          {FileName}
        </Label>
        <Label
          dataElementName="attachmentDetails__description"
          descriptor={labels.Description}
        >
          {Description}
        </Label>
        <Label
          dataElementName="attachmentDetails__author"
          descriptor={labels.Author}
        >
          {Author}
        </Label>
        <Label
          dataElementName="attachmentDetails__uploadUser"
          descriptor={labels.UploadUser}
        >
          {formatStaffName(UploadUser)}
        </Label>
        <Label
          dataElementName="attachmentDetails__linkTo"
          descriptor={labels.LinkTo}
          className="attachment-details-render__display"
        >
          {linkToValue}
        </Label>
        <Flexbox
          style={{ gridColumn: '1 / -1' }}
          justifyContent="space-between"
        >
          <div>
            {!!FileName &&
              FileName.toLowerCase().endsWith('.pdf') &&
              onPDFClick && (
                <IconButton
                  dataElementName="attachmentDetails__pdfView"
                  look="default"
                  className="attachment-details-render__pdf-button"
                  icon={DISPLAY_PDF}
                  description={labels.View}
                  onClick={() =>
                    castFunction(onPDFClick)(pluck('FileID')(data))
                  }
                />
              )}
            {!DocumentTypeIDRightID ||
            RightID.includes(DocumentTypeIDRightID) ? (
              <IconButton
                dataElementName="attachmentDetails__download"
                look="default"
                icon={DOWNLOAD_FILE}
                description={labels.Download}
                onClick={() => {
                  const fileId = schemaGet(
                    XeScanDocSetDetailsSchema,
                    'FileID',
                    data
                  );
                  const fileName = schemaGet(
                    XeScanDocSetDetailsSchema,
                    'FileName',
                    data
                  );

                  requestDownloadFile(fileId, fileName);
                }}
              />
            ) : null}
          </div>
          {canMarkInError && (
            <IconButton
              dataElementName="attachmentDetails__markInError"
              look="default"
              onClick={() => setShowMarkInError(true)}
              description={labels.MarkInError}
              icon={REMOVE}
            />
          )}
        </Flexbox>
      </GridLayout>
      {showMarkInError && (
        <MarkInErrorPopup
          onConfirm={(reason) => {
            dispatch({
              type: SHOULD_MARK_ATTACHMENT_IN_ERROR,
              value: {
                ItemID: data.ItemID,
                IsInError: true,
                ErrorDescription: reason,
                ScanDocID: data.ScanDocID.ScanDocID,
              },
            });
          }}
          onClose={() => setShowMarkInError(false)}
        />
      )}
    </Flexbox>
  );
};

export default AttachmentDetailsRender;
