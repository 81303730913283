import { localeFormat } from '../../format/luxonToDisplayString';
import { toDisplayDateFromISOString } from '../../g11n/displayDates';
import { isoStringToAge } from '../../utils/dates';
import { Icon } from '../';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { ACCOUNT } from '../../icons';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';

const baseClassName =
  'calendar-patient-bar flex-container align-items-center justify-content-space-between';

export const CalendarPatientBar = (props) => {
  const {
    patient: {
      FamilyName = '',
      GivenName = '',
      SexID = '',
      DoB = '',
      DateOfDeath,
      IPID,
      XePatientAddress: {
        AddressLine1 = '',
        City = '',
        StateProvince = '',
        ZipPositalCode = '',
      } = EMPTY_OBJECT,
    } = EMPTY_OBJECT,
    onViewChart = NOOP_FUNCTION,
    className = '',
    disabled = false,
    ...rest
  } = props;

  const labels = useXeLabels();

  const sexId = typeof SexID === 'object' ? SexID.SexID : SexID;

  return (
    <div
      {...rest}
      data-component-name="CalendarPatientBar"
      className={className ? `${baseClassName} ${className}` : baseClassName}
    >
      <div className="flex-container align-items-center">
        <Icon icon={ACCOUNT} className="padding-all-x-small" />
        <div className="patient-info">
          <div>
            <span
              data-element-name="calendarPatientBar__fullName"
              className="bold"
            >
              {FamilyName}, {GivenName}
            </span>
            <span data-element-name="calendarPatientBar__gender">
              {' '}
              ({sexId}){DoB ? ', ' : ''}
            </span>
            {DoB && (
              <>
                <span data-element-name="calendarPatientBar__age">
                  {labels.Age}
                  <span className="bold">
                    {' '}
                    {isoStringToAge(DoB, DateOfDeath)}{' '}
                  </span>
                </span>
                <span data-element-name="calendarPatientBar__born">
                  {labels.Born}
                  <span className="bold">
                    {' '}
                    {toDisplayDateFromISOString(DoB, localeFormat.MEDIUM)}{' '}
                  </span>
                </span>
              </>
            )}
          </div>
          {AddressLine1 || (City && StateProvince) ? (
            <div className="address-info-container">
              {labels.Address}
              {AddressLine1 ? (
                <span className="bold"> {AddressLine1}</span>
              ) : null}
              {City && StateProvince
                ? ` ${City}, ${StateProvince} ${ZipPositalCode}`
                : null}
            </div>
          ) : null}
        </div>
      </div>
      <span
        data-element-name="calendarPatientBar__chartLink"
        className={`chart-link${disabled ? ' disabled' : ''}`}
        onClick={() => !disabled && onViewChart(IPID)}
      >
        {labels.Chart}
      </span>
    </div>
  );
};

export default CalendarPatientBar;
