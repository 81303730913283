import { useContext } from 'react';
import { ClinicalDocumentButtons } from '../../../../../ClinicalDocumentButtons';
import { CellRendererContext } from '../../../../contexts/CellRendererContext';

export const VisitAssessment = (props) => {
  const { actionData, labels, menuNode /* updateActionCellData */ } = props;

  const { onSearch } = useContext(CellRendererContext);

  if (!actionData) return null;

  const { IPID, IVID } = actionData;

  return (
    <ClinicalDocumentButtons
      labels={labels}
      menuNode={menuNode}
      data={actionData}
      ipid={IPID}
      ivid={IVID}
      onSave={
        (/* result */) => {
          // This has to refetch the entire dashboard right now which isn't ideal
          onSearch();
          // const { VisitAssessmentID, ModifiedTStamp } = result;
          // updateActionCellData({
          //   ...actionData,
          //   VisitAssessmentID,
          //   ModifiedTStamp
          // });
          // return result;
        }
      }
    />
  );
};

export default VisitAssessment;
