import { useCallback, useContext } from 'react';
import { EMPTY_OBJECT } from '../../../../constants';
import {
  XE_AUTH_DETAILS_ACTION_KEY,
  XE_AUTH_PROVIDER_DETAILS_ACTION_KEY,
  XE_CALL_LOG_ACTION_KEY,
  XE_INVOICE_ACTION_KEY,
  XE_PATIENT_ISSUE_DETAILS_ACTION_KEY,
  XE_PATIENT_PROVIDER_ACTION_KEY,
  XE_PROVIDER_ASSIGNMENT_ACTION_KEY,
  XE_SCAN_DOC_SET_ACTION_KEY,
  XE_SERVICE_BOOKING_ACTION_KEY,
  XE_VISIT_ASSESSMENT_ACTION_KEY,
  XE_VISIT_CASE_MANAGEMENT_ACTION_KEY,
  XE_WORK_GRAB_ACTION_KEY,
  XE_ENCOUNTER_FORM_ACTION_KEY,
  XE_CLEARING_HOUSE,
  XE_CLAIM_BATCH,
} from '../../../Dashboard/constants';
import {
  AUTH,
  ISSUE,
  PROVIDER_PORTAL,
  PHM,
} from '../../../WorklistUITemplateWrapper/constants';
import { CellRendererContext } from '../../contexts/CellRendererContext';
import { EditAppointmentAction } from './actionRenderers/Appointment';
import { AssignProvider } from './actionRenderers/AssignProvider';
import { CallLogAction } from './actionRenderers/CallLogAction';
import { DownloadFileAction } from './actionRenderers/DownloadFileAction';
import { InvoiceAction } from './actionRenderers/InvoiceAction';
import { PatientProvider } from './actionRenderers/PatientProvider';
import { VisitAssessment } from './actionRenderers/VisitAssessment';
import { WorklistAction } from './actionRenderers/WorklistDetails';
import { EditEncounterFormAction } from './actionRenderers/EditEncounterFormAction';
import { EditClearingHouseRecordAction } from './actionRenderers/EditClearingHouseRecordAction';
import { EditBatchClaimAction } from './actionRenderers/EditBatchClaimAction';
import { WorklistGrab as GrabButton } from './actionRenderers/WorklistGrab';

const ACTION_RENDERER_DICTIONARY = {
  [XE_PATIENT_PROVIDER_ACTION_KEY]: PatientProvider,
  [XE_VISIT_ASSESSMENT_ACTION_KEY]: VisitAssessment,
  [XE_CALL_LOG_ACTION_KEY]: CallLogAction,
  [XE_INVOICE_ACTION_KEY]: InvoiceAction,
  [XE_WORK_GRAB_ACTION_KEY]: GrabButton,
  [XE_SCAN_DOC_SET_ACTION_KEY]: DownloadFileAction,
  [XE_AUTH_DETAILS_ACTION_KEY]: (props) => {
    return (
      <WorklistAction {...props} type={AUTH} titleLabelKey="AuthDetails" />
    );
  },
  [XE_VISIT_CASE_MANAGEMENT_ACTION_KEY]: (props) => {
    return <WorklistAction {...props} type={PHM} titleLabelKey="Details" />;
  },
  [XE_AUTH_PROVIDER_DETAILS_ACTION_KEY]: (props) => {
    return (
      <WorklistAction
        {...props}
        type={PROVIDER_PORTAL}
        titleLabelKey="AuthDetails"
      />
    );
  },
  [XE_PATIENT_ISSUE_DETAILS_ACTION_KEY]: (props) => {
    return (
      <WorklistAction {...props} type={ISSUE} titleLabelKey="IssueDetails" />
    );
  },
  [XE_SERVICE_BOOKING_ACTION_KEY]: EditAppointmentAction,
  [XE_PROVIDER_ASSIGNMENT_ACTION_KEY]: AssignProvider,
  [XE_ENCOUNTER_FORM_ACTION_KEY]: EditEncounterFormAction,
  [XE_CLEARING_HOUSE]: EditClearingHouseRecordAction,
  [XE_CLAIM_BATCH]: EditBatchClaimAction,
  DEFAULT: () => null,
};

export const ActionCell = (props) => {
  const { data, actionKey = '', node, value, context = EMPTY_OBJECT } = props;

  const { labels, menuNode, refData, rights } = useContext(CellRendererContext);

  const updateActionCellData = useCallback(
    (nextCellData) => {
      const { row, ActionList } = data;

      const nextActionList = {
        ...ActionList,
        [actionKey]: nextCellData,
      };

      const nextRowData = {
        ...data,
        ActionList: nextActionList,
        row: {
          ...row,
          ActionList: nextActionList,
        },
      };

      return node.setData(nextRowData);
    },
    [actionKey, data, node]
  );

  if (!value) return null;

  const Renderer =
    ACTION_RENDERER_DICTIONARY[actionKey] || ACTION_RENDERER_DICTIONARY.DEFAULT;

  return (
    <Renderer
      actionKey={actionKey}
      actionData={value}
      rowData={node?.data}
      key={actionKey}
      labels={labels}
      refData={refData}
      rights={rights}
      menuNode={menuNode}
      updateActionCellData={updateActionCellData}
      context={context}
    />
  );
};
