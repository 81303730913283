import { isType } from '../../../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../../../reducers';
import { when } from '../../../../predication/when';
import { RESPONSE_CALL_LOG_HISTORY } from './actions';

export default [
  when(isType(RESPONSE_CALL_LOG_HISTORY))(
    toActionValueReducer('callLogHistory')
  ),
];
