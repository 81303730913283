import PropTypes from 'prop-types';
import { useSchema, useSchemaDispatch } from '../../schema/SchemaReducer';
import { localeFormat } from '../../format/luxonToDisplayString';
import { MERGE_PATCH } from '../../schema/JSONSchemaReducer';
import { CUSTOM_DATE_RANGE } from '../../icons';
import { useRef, useState, useMemo } from 'react';
import TextInput from '../TextInput';
import IconButton from '../IconButton';
import CustomDateRangePickerPopup from '../CustomDateRangePickerPopup';
import { toDateSchemaNodeFormat } from '../../utils/schema';
import { toFormatterAndParserByType } from '../../g11n/ISODates';
import { UIControlLabel } from '../Label';
import { toDisplayDateFromISOString } from '../../g11n/displayDates';

const toRangeLabel = (dateRange) => {
  if (!dateRange) return '';
  const { start = '', end = '' } = dateRange;
  if (!start || !end) return '';
  return `${toDisplayDateFromISOString(
    start,
    localeFormat.MEDIUM
  )} - ${toDisplayDateFromISOString(end, localeFormat.MEDIUM)}`;
};

export const DateRangeInput = (props) => {
  const {
    dataElementName,
    descriptor,
    descriptorClassName,
    format: propsFormat,
    startDataPath,
    endDataPath,
    required: propsRequired = false,
    validityStyles: propsValidityStyles = false,
    min,
    max,
  } = props;

  const {
    value: startValue = '',
    schemaNode: startSchemaNode,
    touched: startTouched = false,
    required: startRequired = false,
  } = useSchema(startDataPath);

  const {
    value: endValue = '',
    schemaNode: endSchemaNode,
    required: endRequired = false,
  } = useSchema(endDataPath);

  const dispatch = useSchemaDispatch();
  const startDateFormat = startSchemaNode
    ? toDateSchemaNodeFormat(startSchemaNode)
    : propsFormat;

  const endDataFormat = endSchemaNode
    ? toDateSchemaNodeFormat(endSchemaNode)
    : propsFormat;

  if (startDateFormat !== endDataFormat) {
    console.warn(
      `start and end dates have a different format, the picker requires them to be the same, defaulting to startDateFormat: ${startDateFormat}`
    );
  }

  const { parser: startParser, formatter: startFormatter } = useMemo(
    () => toFormatterAndParserByType(startDateFormat),
    [startDateFormat]
  );

  const { parser: endParser, formatter: endFormatter } = useMemo(
    () => toFormatterAndParserByType(endDataFormat),
    [endDataFormat]
  );

  const onChange = ({ range: { from = () => '', to = () => '' } } = {}) => {
    updateShowPicker(false);
    const valueFormatted = {
      //The CustomDateRangePickerPopup already formats back to string based on format argument
      //the format argument uses the startFormatter so we need to account for type mismatch
      [startDataPath]: startFormatter(startParser(from())),
      [endDataPath]: endFormatter(startParser(to())),
    };
    dispatch({
      type: MERGE_PATCH,
      value: valueFormatted,
    });
  };

  const rangeValue =
    startValue && endValue
      ? {
          //For the picker to work they both need the same format
          start: startValue,
          end: startFormatter(endParser(endValue)),
        }
      : undefined;

  //This presumes that we will won't ever only touch half of the picker, which shouldn't happen
  const validityStyles = startSchemaNode ? startTouched : propsValidityStyles;

  const dateButtonRef = useRef();
  const [showPicker, updateShowPicker] = useState(false);

  return (
    <>
      {/* This is not in the text input so we can keep the grid spacing consistent across components
       * We want the text input and the button to be one div for grids but keep the descriptor separate
       */}
      {descriptor && (
        <UIControlLabel
          dataElementName={!!dataElementName ? `${dataElementName}__label` : ''}
          className={descriptorClassName}
          required={startRequired || endRequired || propsRequired}
        >
          {descriptor}
        </UIControlLabel>
      )}
      <div
        data-component-name="DateRangeInput"
        data-element-name={dataElementName}
      >
        <TextInput
          dataElementName="dateRangeTextInput"
          disabled={true}
          validityStyles={validityStyles}
          value={toRangeLabel(rangeValue)}
        />
        <IconButton
          icon={CUSTOM_DATE_RANGE}
          dataElementName="customDateRange"
          itemRef={(ref) => (dateButtonRef.current = ref)}
          onClick={() => updateShowPicker(!showPicker)}
          className="date-range-calendar-svg"
        />
        <CustomDateRangePickerPopup
          value={rangeValue}
          anchor={dateButtonRef.current}
          showPopup={showPicker}
          onChange={onChange}
          onClose={() => updateShowPicker(false)}
          format={startDateFormat}
          min={min}
          max={max}
        />
      </div>
    </>
  );
};

DateRangeInput.propTypes = {
  descriptor: PropTypes.string,
  descriptorClassName: PropTypes.string,
  format: PropTypes.string,
  startDataPath: PropTypes.string.isRequired,
  endDataPath: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validityStyles: PropTypes.bool,
};

export default DateRangeInput;
