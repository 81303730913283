import { castSchema } from '../../schema/schemaCaster';
import { combineWithLatestFrom } from '../../frp/operators/combineWithLatestFrom';
import { ofChangedPropWhenExists } from '../../frp/operators/ofChangedPropWhenExists';
import { ofType } from '../../frp/operators/ofType';
import { isNil } from '../../fp/pred';
import { combineLatest, forkJoin } from 'rxjs';
import {
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  pluck,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {
  createOrFind,
  getDetails,
  getFromSpec,
  getVersion,
  quickSave,
  quickSaveInsert,
  recoverQuick,
} from 'services/visit-clindocs/xe-visit-clindocs-svc';
import {
  downloadFile,
  getFileData,
  getReportData,
} from '../../files/operators';
import { neverCache } from '../../service/serviceCache';
import { backgroundReducer } from '../../service/toRequestFns';
import {
  getEnterpriseID,
  getVisitAssessmentID,
  getIpid,
  getIvid,
} from '../../utils/pluckers';
import { toClinicalDocumentReportParams } from '../ClinicalDocumentButtons/utils';
import {
  CREATE_OR_FIND_NEW_DOCUMENT,
  DID_FINISH_PROCESSING_DOCUMENT_SET,
  INITIALIZE_UNOPENED_SET_ITEMS,
  OPEN_EXISTING_DOCUMENT,
  PROCESS_DOCUMENT_SET,
  RESPONSE_CREATE_DOCUMENT,
  RESPONSE_OPEN_EXISTING_DOCUMENT,
  SAVE_DOCUMENT_SET,
  SHOULD_PRINT,
  SWITCH_ACTIVE_DOCUMENT,
} from './actions';
import FullXeVisitAssessmentSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.FullXeVisitAssessment.json';
import { IS_QUICK_SAVE_DRAFT } from './constants';
import { HEADER_XE_ENTERPRISE_ID } from '../../service/constants';
import { toFormatterAndParserByType } from '../../g11n/ISODates';
import { DateTime } from 'luxon';

const toGetDocumentRequestConfig = (enterpriseId) => {
  if (!isNil(enterpriseId)) {
    return { headers: { [HEADER_XE_ENTERPRISE_ID]: enterpriseId } };
  }
  return {};
};

export const toInitialDocumentFetchActionsEpic$ = (
  _action$,
  _state$,
  { data$ }
) => {
  return data$.pipe(
    take(1),
    map((data = []) => {
      const [item0] = data;
      if (isNil(getVisitAssessmentID(item0))) {
        return {
          type: CREATE_OR_FIND_NEW_DOCUMENT,
          value: item0,
        };
      }
      return {
        type: OPEN_EXISTING_DOCUMENT,
        value: item0,
      };
    })
  );
};

export const toInitializeUnopenedDocumentsEpic$ = (
  action$,
  _state$,
  { data$ }
) => {
  return action$.pipe(
    ofType(RESPONSE_CREATE_DOCUMENT, RESPONSE_OPEN_EXISTING_DOCUMENT),
    take(1),
    mergeMap(() => {
      return data$.pipe(
        map((data = []) => {
          return {
            type: INITIALIZE_UNOPENED_SET_ITEMS,
            value: data.slice(1),
          };
        })
      );
    })
  );
};

export const toFetchAdditionalDocumentEpic$ = (action$, state$) => {
  return action$.pipe(
    ofType(SWITCH_ACTIVE_DOCUMENT),
    pluck('value'),
    withLatestFrom(state$.pipe(ofChangedPropWhenExists('documentSet'))),
    filter(([nextActiveDocumentIndex, documentSet = []]) => {
      const { fetched } = documentSet[nextActiveDocumentIndex] || {};
      return !fetched;
    }),
    map(([nextActiveDocumentIndex, documentSet = []]) => {
      const { data } = documentSet[nextActiveDocumentIndex] || {};
      if (isNil(getVisitAssessmentID(data))) {
        return {
          type: CREATE_OR_FIND_NEW_DOCUMENT,
          value: data,
        };
      }
      return {
        type: OPEN_EXISTING_DOCUMENT,
        value: data,
      };
    })
  );
};

export const toCreateOrFindDocumentEpic$ = (
  action$,
  _state$,
  { menuNode$, ipid$, ivid$, enterpriseId$, assessmentId$ }
) => {
  return action$.pipe(
    ofType(CREATE_OR_FIND_NEW_DOCUMENT),
    pluck('value'),
    combineWithLatestFrom(
      ipid$,
      ivid$,
      enterpriseId$,
      assessmentId$,
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn(neverCache)())
      )
    ),
    mergeMap(
      ([data, ipid, ivid, enterpriseId, propsAssessmentId, toRequest$]) => {
        const ipidNumber = getIpid(ipid);
        const ividNumber = getIvid(ivid, ipid);

        const documentEnterpriseId = getEnterpriseID(data);
        const getDocumentRequestConfig = toGetDocumentRequestConfig(
          enterpriseId || documentEnterpriseId
        );
        if (!isNil(propsAssessmentId)) {
          return createOrFind(
            {
              assessmentId: propsAssessmentId,
              ipid: ipidNumber,
              ivid: ividNumber,
            },
            toRequest$({ fullRequest: true, ...getDocumentRequestConfig })
          );
        }

        const { AssessmentID } = data;

        return getFromSpec(
          { assessmentId: AssessmentID, ipid: ipidNumber, ivid: ividNumber },
          toRequest$({ fullRequest: true, ...getDocumentRequestConfig })
        );
      }
    ),
    map(({ results: [result0], request: { headers } }) => ({
      type: RESPONSE_CREATE_DOCUMENT,
      value: result0,
      enterpriseIdHeader: headers[HEADER_XE_ENTERPRISE_ID],
    }))
  );
};

export const toOpenExistingDocumentEpic$ = (
  action$,
  _state$,
  { menuNode$, enterpriseId$ }
) => {
  return action$.pipe(
    ofType(OPEN_EXISTING_DOCUMENT),
    pluck('value'),
    combineWithLatestFrom(
      enterpriseId$,
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn(neverCache)())
      )
    ),
    mergeMap(([data = {}, enterpriseId, toRequest$]) => {
      const visitAssessmentId = getVisitAssessmentID(data);
      const documentEnterpriseId = getEnterpriseID(data);
      const getDocumentRequestConfig = toGetDocumentRequestConfig(
        enterpriseId || documentEnterpriseId
      );

      const makeRequestValue = toRequest$({
        fullRequest: true,
        ...getDocumentRequestConfig,
      });

      if ('Version' in data && 'VersionID' in data) {
        const { Version: version, VersionID: versionId } = data;
        return getVersion(
          { versionId, visitAssessmentId, version },
          makeRequestValue
        );
      }
      return getDetails({ visitAssessmentId }, makeRequestValue);
    }),
    map(({ results: [result0], request: { headers } }) => ({
      type: RESPONSE_OPEN_EXISTING_DOCUMENT,
      value: result0,
      enterpriseIdHeader: headers[HEADER_XE_ENTERPRISE_ID],
    }))
  );
};

export const toPrintDocumentEpic$ = (
  action$,
  _state$,
  { menuNode$, data$ }
) => {
  return action$.pipe(
    ofType(SHOULD_PRINT),
    pluck('value'),
    withLatestFrom(data$),
    map(([documentData, dataArr]) => {
      //The actual document doesn't have the VersionID still attached to it, so we need to use the initial data
      //match it to the document we plan to print and pluck the VersionID out of that document
      const matchedData =
        dataArr.find(
          (data) => data.VisitAssessmentID === documentData.VisitAssessmentID
        ) || {};
      return {
        ...documentData,
        VersionID: matchedData.VersionID,
      };
    }),
    getReportData(toClinicalDocumentReportParams, menuNode$),
    pluck('results'),
    getFileData(([response = {}]) => {
      const { Report: [report0 = {}] = [] } = response;
      const { FileID, Name } = report0;
      return { fileId: FileID, Name: Name };
    }, menuNode$),
    downloadFile(({ response, fileData }) => {
      const { Name } = fileData;
      return {
        blob: response.blob,
        fileName: Name,
      };
    }),
    ignoreElements()
  );
};

/**
 * Quick saves each document in a set
 */
export const toQuickSaveDocumentSetEpic$ = (
  action$,
  _state$,
  { menuNode$, enterpriseId$, userData$ }
) => {
  return action$.pipe(
    ofType(SAVE_DOCUMENT_SET),
    combineWithLatestFrom(
      enterpriseId$,
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      ),
      userData$
    ),
    mergeMap(
      ([
        { value: documentSet, isSign },
        enterpriseId,
        toRequest$,
        userData,
      ]) => {
        const { formatter } = toFormatterAndParserByType('date-time');

        const statusData = isSign
          ? {
              Status: 'SIGNED',
              SignedBy: userData?.ResourceID,
              SignedDate: formatter(DateTime.now()),
            }
          : {
              Status: 'UNSIGNED',
              SignedDate: null,
              SignedBy: null,
            };

        const getDocumentRequestConfig =
          toGetDocumentRequestConfig(enterpriseId);
        const arrayOfSaveRequests$ = documentSet.map((setItem) => {
          const visitAssessmentId = getVisitAssessmentID(setItem);
          const quickSaveRequest$ = (() => {
            const documentDataWithStatus = Object.assign(
              {},
              setItem,
              statusData
            );

            const fullXeVisitAssessment = castSchema(
              FullXeVisitAssessmentSchema
            )(documentDataWithStatus);

            if (isNil(visitAssessmentId)) {
              return quickSaveInsert(
                fullXeVisitAssessment,
                {},
                toRequest$({ fullRequest: true, ...getDocumentRequestConfig })
              );
            }

            return quickSave(
              fullXeVisitAssessment,
              { visitAssessmentId },
              toRequest$({ fullRequest: true, ...getDocumentRequestConfig })
            );
          })();

          return quickSaveRequest$.pipe(
            pluck('results'),
            map(([response]) => {
              const { SignedDate } = setItem;
              const { ModifiedTStamp } = response;
              const responseWithUpdatedSignedDate = {
                ...response,
                SignedDate: isSign && SignedDate ? SignedDate : ModifiedTStamp,
              };
              return {
                ...setItem,
                ...responseWithUpdatedSignedDate,
                IsQuickSave: IS_QUICK_SAVE_DRAFT,
              };
            })
          );
        });

        return combineLatest(...arrayOfSaveRequests$).pipe(
          map((documentSet) => ({
            type: PROCESS_DOCUMENT_SET,
            value: documentSet,
          }))
        );
      }
    )
  );
};

/**
 * Processes a document set
 */
export const toProcessDocumentSetEpic$ = (
  action$,
  _state$,
  { enterpriseId$, menuNode$ }
) => {
  return action$.pipe(
    ofType(PROCESS_DOCUMENT_SET),
    pluck('value'),
    combineWithLatestFrom(
      enterpriseId$,
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn(backgroundReducer)())
      )
    ),
    mergeMap(([documentSet, enterpriseId, toRequest$]) => {
      const getDocumentRequestConfig = toGetDocumentRequestConfig(enterpriseId);
      const arrayOfProcessRequests$ = documentSet.map(
        (documentSetItem = {}) => {
          const { VisitAssessmentID } = documentSetItem;
          return recoverQuick(
            { VisitAssessmentID },
            { visitAssessmentId: VisitAssessmentID },
            toRequest$({ fullRequest: true, ...getDocumentRequestConfig })
          );
        }
      );

      return forkJoin(arrayOfProcessRequests$).pipe(
        mapTo({
          type: DID_FINISH_PROCESSING_DOCUMENT_SET,
        })
      );
    })
  );
};

export default [
  toCreateOrFindDocumentEpic$,
  toOpenExistingDocumentEpic$,
  toFetchAdditionalDocumentEpic$,
  toInitializeUnopenedDocumentsEpic$,
  toInitialDocumentFetchActionsEpic$,
  toPrintDocumentEpic$,
  toProcessDocumentSetEpic$,
  toQuickSaveDocumentSetEpic$,
];
