import { isFunction } from '../../fp/pred';
import { identity } from '../../fp/fp';
import { pluck } from '../../fp/object';
import { useSchema } from '../../schema/SchemaReducer';
import { ComboBox as ComboBoxKendo } from '@progress/kendo-react-dropdowns';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef, useState, cloneElement } from 'react';
import { castFunction } from '../../fp/fp';
import { UIControlLabel } from '../Label';
import { Flexbox } from '../Flexbox';
import useSetAutofocus from '../../hooks/useSetAutofocus';
import { toIsKendoComponentValid } from '../utils';
import { isEnterPress } from './utils';
import { EMPTY_ARRAY, NOOP_FUNCTION } from '../../constants';

const KEYCODE_UP = 38;
const KEYCODE_DOWN = 40;
const KEY_UP = 'ArrowUp';
const KEY_DOWN = 'ArrowDown';

/**
 * @param {React.SyntheticEvent<HTMLElement, Event>} ev
 */
const isArrowNavigationEvent = (ev) => {
  if (!ev) return false;

  const { key, keyCode } = ev;
  if (keyCode) {
    return keyCode === KEYCODE_UP || keyCode === KEYCODE_DOWN;
  }
  if (key) {
    return key === KEY_UP || key === KEY_DOWN;
  }
  return false;
};

const ComboBox = forwardRef((props, refFn) => {
  const {
    dataElementName = '',
    dataPath,
    data = EMPTY_ARRAY,
    onEnter,
    labelFn = identity,
    valueFn = identity,
    clearOnSelect = false,
    filter,
    value: propsValue,
    required: propsRequired,
    // opened,
    descriptor,
    descriptorClassName,
    onFilterChange: onFilterChangeProp,
    onChange = NOOP_FUNCTION,
    onFocus = NOOP_FUNCTION,
    onBlur = NOOP_FUNCTION,
    itemRender,
    autofocus,
    dataItemKey,
    children,
    className,
    onValidation = NOOP_FUNCTION,
    valid,
    ...passThroughProps
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  /**
   * @type {React.MutableRefObject<ComboBoxKendo>}
   */
  const comboBoxRef = useRef();
  // const [focused, setFocused] = useState(false);

  const setAutoFocus = useSetAutofocus(autofocus);

  const [internalTouched, setInternalTouched] = useState(false);

  const {
    onValueChange = onChange,
    value = propsValue,
    touched = false,
    required: schemaRequired = false,
    validityMessage = '',
    onTouched = () => setInternalTouched(true),
    onValidationChange = onValidation,
    schemaNode,
  } = useSchema(dataPath);

  /**
   * @type {{ item: any, label: string }}
   */
  const kendoComboBoxData = data.map((item) => {
    return {
      item,
      label: labelFn(item),
    };
  });
  /**
   * @type {{ item: any, label?: string } | void}
   */
  const findResult = kendoComboBoxData.find(({ item }) => {
    if (dataItemKey) {
      return pluck(dataItemKey)(item) === pluck(dataItemKey)(value);
    }
    return valueFn(item) === value;
  });

  // formRequired is used to have the ComboBox be required without showing
  // the required star, used in cases like Widgets where the the Label shows
  // the required stars instead. SNET-910 (JCh)
  const { formRequired = false } = props;

  let kendoComboBoxValue;
  if (!clearOnSelect) {
    kendoComboBoxValue = findResult
      ? findResult
      : value
      ? { item: value }
      : null;
  }

  const validityStyles = schemaNode ? touched : internalTouched;

  useEffect(() => {
    /*
     * Callback to invoke `onEnter` only if there is no item in the data matching the current `filter`
     */
    comboBoxRef.current.element.onkeydown = (ev) => {
      const focusedIndex = comboBoxRef.current.getFocusedIndex();
      if (isEnterPress(ev) && focusedIndex === -1) {
        castFunction(onEnter)(filter);
      }
    };
  }, [onEnter, filter]);

  const onFilterChange = castFunction(onFilterChangeProp);

  const required = schemaRequired || propsRequired;

  return (
    <>
      <UIControlLabel
        dataElementName={
          dataElementName !== '' ? `${dataElementName}__label` : ''
        }
        className={descriptorClassName}
        required={!formRequired && required}
      >
        {descriptor}
      </UIControlLabel>
      <Flexbox
        dataElementName={
          dataElementName !== '' ? `${dataElementName}__flex` : ''
        }
        alignItems="center"
        // TODO: Determine why flex-1 is always being applied (JDM)
        className={className ? `${className} flex-1` : 'flex-1'}
      >
        <ComboBoxKendo
          required={required}
          validityStyles={validityStyles}
          valid={valid && !validityMessage}
          ref={(ref) => {
            if (ref && comboBoxRef.current !== ref) {
              const { element, _input } = ref;
              setAutoFocus(_input);
              element.setAttribute('data-component-name', 'ComboBox');
              element.setAttribute('data-element-name', dataElementName);
              const dataAttributes = Object.entries(props).filter(([key]) =>
                key.includes('data-')
              );
              dataAttributes.forEach(([attr, value]) => {
                element.setAttribute(attr, value);
              });
            }
            comboBoxRef.current = ref;
            castFunction(refFn)(ref);
          }}
          filterable
          filter={filter || ''}
          onFocus={(ev) => {
            castFunction(onFocus)(ev);
            // setFocused(true);
          }}
          onBlur={(ev) => {
            castFunction(onBlur)(ev);
            // setFocused(false);
            onTouched();
          }}
          data={kendoComboBoxData}
          textField="label"
          value={kendoComboBoxValue}
          onFilterChange={({
            target: { value: comboBoxValue, index },
            filter: { value },
            syntheticEvent,
          }) => {
            const {
              target: { classList },
            } = syntheticEvent;
            const wasClearAction = classList.contains('k-clear-value');
            onFilterChange(value);
            if (wasClearAction && index === -1) {
              onValueChange(comboBoxValue);
              onValidationChange(toIsKendoComponentValid(comboBoxRef.current));
              comboBoxRef.current._input.focus();
            }
          }}
          onChange={(params) => {
            const {
              target: { index, value },
              nativeEvent,
            } = params;
            const { item, label } = value || {};
            if (index >= 0) {
              /*
               * Invoke the change handler only if onChange wasn't called via an arrow navigation event.
               * The user should be able to navigate through options without making an immediate commitment.
               */
              if (!isArrowNavigationEvent(nativeEvent)) {
                onValueChange(valueFn(item));
                onValidationChange(
                  toIsKendoComponentValid(comboBoxRef.current)
                );
                return onFilterChange(clearOnSelect ? '' : label);
              }
              onFilterChange(label);
            }
          }}
          itemRender={(element, listItem) => {
            const { props: elementProps } = element;
            const { dataItem: { item } = {} } = listItem;
            if (isFunction(itemRender)) {
              return itemRender(element, {
                ...listItem,
                dataItem: item,
              });
            }
            return cloneElement(element, elementProps, labelFn(item));
          }}
          className="flex-1"
          {...passThroughProps}
          focusedItemIndex={(data, inputText, textField) => {
            if (inputText === '') return -1;
            return data.findIndex((item = {}) => item[textField] === inputText);
          }}
        />
        {children}
      </Flexbox>
    </>
  );
});

export { ComboBox };
export default ComboBox;

ComboBox.propTypes = {
  filter: PropTypes.string.isRequired,
  allowCustom: PropTypes.bool,
  clearOnSelect: PropTypes.bool,
  data: PropTypes.array,
  labelFn: PropTypes.func,
  valueFn: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  onFilterChange: PropTypes.func,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
};
