import { pluck as objPluck } from '../../../../fp/object';
import { downloadFile } from '../../../../download/downloadFile';
import { ofType } from '../../../../frp/operators/ofType';
import { combineWithLatestFrom } from '../../../../frp/operators/combineWithLatestFrom';
import { map, pluck, mergeMap, tap, ignoreElements } from 'rxjs/operators';
import { browseScanDocSets } from '../../../../files/operators';
import { getFile } from 'services/file-storages/xe-file-storages-svc';
import {
  REQUEST_SCAN_DOCS,
  RESPONSE_SCAN_DOCS,
  REQUEST_DOWNLOAD_ITEM,
} from './actions';
import { toXsDateTimeString } from '../../../../g11n/ISODates';
import { toDateRangeFromKey } from '../../../../dates/dateOptions';

/**
 * @type {import('redux-observable').Epic}
 */
const toFetchAttachmentsEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(REQUEST_SCAN_DOCS),
    pluck('value'),
    browseScanDocSets((filters = {}) => {
      const { from: uploadDateMin, to: uploadDateMax } = toDateRangeFromKey(
        objPluck('selectedDateRange')(filters),
        toXsDateTimeString
      );

      return {
        ...filters,
        uploadDateMax,
        uploadDateMin,
      };
    }, menuNode$),
    pluck('results'),
    map((value) => ({
      type: RESPONSE_SCAN_DOCS,
      value,
    }))
  );
};

const toDownloadFileEpic$ = (action$, state$, { menuNode$ }) =>
  action$.pipe(
    ofType(REQUEST_DOWNLOAD_ITEM),
    pluck('value'),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([{ FileName, FileID } = {}, toRequest$]) => {
      return getFile(
        { fileId: FileID },
        toRequest$({ fullRequest: true })
      ).pipe(
        tap(({ blob }) => {
          return downloadFile(blob, FileName);
        }),
        ignoreElements()
      );
    })
  );

export default [toFetchAttachmentsEpic$, toDownloadFileEpic$];
