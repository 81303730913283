import { localeFormat } from '../../../format/luxonToDisplayString';
import { identity } from '../../../fp/fp';
import {
  SchemaReducer,
  useSchemaDispatch,
} from '../../../schema/SchemaReducer';
import { pluck } from '../../../fp/object';
import {
  UPSERT,
  withDefaultJSONSchemaReducer,
} from '../../../schema/JSONSchemaReducer';
import { castSchema } from '../../../schema/schemaCaster';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import {
  TextArea,
  DropDownList,
  ButtonBar,
  DatePicker,
  GridLayout,
  Label,
  CalendarPatientBar,
  CalendarVisitBar,
  Button,
  Flexbox,
  ButtonGroup,
  TaskChangeLog,
  UIControlLabel,
  Checkbox,
} from '../../';
import { XePatientSearchWidget, XeStaffSearchWidget } from '../../../widgets';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { useXeQuery } from '../../../data/useXeQuery';
import { useXePatient } from '../../../contexts/XePatientContext';
import { getPatient } from 'services/patients/xe-patients-svc';
import { formatStaffName } from '../../../utils';
import nameFormatter from '../../../format/nameFormatter';
import NewTaskRequest from 'services/schemas/com.thrasys.xnet.erp.assets.AssetXeWaitList.json';
import UpdateTaskRequest from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListRequest.json';
import { toXsDateTimeString } from '../../../g11n/ISODates';
import { toDisplayDateFromISOString } from '../../../g11n/displayDates';
import { toDefaultValidator } from '../../../validators/schemaValidators';
import PatientTaskAddRequest$PriorityID from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.waitlist.PatientTaskAddRequest$PriorityID.json';
import { EMPTY_ARRAY, EMPTY_OBJECT, NOOP_FUNCTION } from '../../../constants';
import { DateTime } from 'luxon';
import { useActiveList } from 'xnetjs/hooks/useActiveList';

const TASK_RULES = [
  [
    ({ IPID } = {}) => IPID,
    () => ({
      '': {
        message: 'Failed IPID',
      },
    }),
  ],
  [
    ({ ProviderID } = {}) => ProviderID,
    () => ({
      '': {
        message: 'Failed ProviderID',
      },
    }),
  ],
  [
    ({ PreferredDateTime } = {}) => PreferredDateTime,
    () => ({
      '': {
        message: 'Failed PreferredDateTime',
      },
    }),
  ],
  [
    ({ Description = '' } = {}) => !!Description.trim(),
    () => ({
      '': {
        message: 'Failed Description',
      },
    }),
  ],
  [
    ({ StatusID } = {}) => StatusID,
    () => ({
      '': {
        message: 'Failed StatusID',
      },
    }),
  ],
  [
    ({ WaitListSubCategoryID } = {}) => WaitListSubCategoryID,
    () => ({
      '': {
        message: 'Failed WaitlistSubCategoryID',
      },
    }),
  ],
  [
    ({ StatusID, ClosedComment = '' } = {}) => {
      if (StatusID === 'CANCELLED') return !!ClosedComment.trim();
      return true;
    },
    () => ({
      '': {
        message: 'Failed ClosedComment',
      },
    }),
  ],
];

const validator = toDefaultValidator(TASK_RULES);

const toDefaultPriorityID = (options) => {
  if (!options || !options[1]) return null;
  return options[1];
};

const toDefaultStatusID = (statusOptions) => {
  if (!statusOptions || statusOptions.length == 0) return null;
  return statusOptions[0].StatusID;
};

const toDefaultWaitListSubCategoryID = (taskCategories, initialValue) => {
  if (!taskCategories || taskCategories.length == 0) return null;

  // Checks if task is set with category and returns task category id
  if (initialValue?.WaitListSubCategoryID) {
    return initialValue?.WaitListSubCategoryID;
  }

  // if task value is not set then default value is returned
  return taskCategories[0].WaitListSubCategoryID;
};

const toStatusID = pluck('StatusID');
const toName = pluck('Name');

const PriorityButtonGroup = (props) => {
  const schemaDispatch = useSchemaDispatch();
  return (
    <ButtonGroup
      {...props}
      onChange={(value) => {
        return schemaDispatch({
          type: UPSERT,
          path: 'PriorityID',
          value: castSchema(PatientTaskAddRequest$PriorityID)(value),
        });
      }}
    />
  );
};

const Provider = (props) => {
  const dispatch = useSchemaDispatch();
  return (
    <XeStaffSearchWidget
      {...props}
      onChange={(value) =>
        dispatch({ type: UPSERT, path: 'ProviderID', value })
      }
    />
  );
};

export const DefaultView = (props) => {
  const {
    dataElementName,
    task = EMPTY_OBJECT,
    category,
    onChange = NOOP_FUNCTION,
    onViewChart,
    onSetViewAllCategories,
    initialValue,
    refData = EMPTY_OBJECT,
    taskCategories = EMPTY_ARRAY,
    viewAllCategories,
  } = props;

  const labels = useXeLabels();
  const { ipid: contextIpid } = useXePatient();

  const { data: [contextPatient] = EMPTY_ARRAY } = useXeQuery(
    getPatient({ ipid: contextIpid }, identity),
    {
      enabled: !!contextIpid,
    }
  );

  const { XeAppUserNotificationPriority = [], WaitListStatus = [] } = refData;
  const { StatusID, PriorityID, PreferredDateTime } = task;
  const {
    WaitListID,
    IPID,
    IVID,
    ProviderID,
    IsInError,
    ErrorDescription,
    CreationUser,
    CreateTStamp,
    CategoryID,
  } = initialValue;
  const taskSchema = WaitListID ? UpdateTaskRequest : NewTaskRequest;
  const ipid = IPID || contextPatient;
  const activeTaskCategory = useActiveList(taskCategories);
  const initialValueWithDefaults = useMemo(
    () =>
      castSchema(taskSchema)({
        PreferredDateTime: toXsDateTimeString(DateTime.now().startOf('day')),
        PriorityID: toDefaultPriorityID(XeAppUserNotificationPriority),
        StatusID: toDefaultStatusID(WaitListStatus),
        IPID: ipid,
        CategoryID: pluck('CategoryID')(CategoryID) || category,
        ...initialValue,
        WaitListSubCategoryID: toDefaultWaitListSubCategoryID(
          activeTaskCategory,
          initialValue
        ),
        ProviderID: pluck('ResourceID')(ProviderID),
      }),
    [
      category,
      CategoryID,
      initialValue,
      ipid,
      XeAppUserNotificationPriority,
      WaitListStatus,
      taskSchema,
      ProviderID,
      activeTaskCategory,
    ]
  );
  const [viewChangeLog, setViewChangeLog] = useState(undefined);
  return (
    <>
      <SchemaReducer
        schema={taskSchema}
        onChange={onChange}
        initialValue={initialValueWithDefaults}
        toJsonReducer={withDefaultJSONSchemaReducer(validator)}
        dangerouslyRetainCompletelyOutdatedState={true}
      >
        {WaitListID && IPID && (
          <Flexbox direction="column" className="stretch-x">
            <CalendarPatientBar patient={IPID} onViewChart={onViewChart} />
            <CalendarVisitBar visit={IVID} />
          </Flexbox>
        )}
        <GridLayout
          dataElementName={dataElementName}
          templateColumns="auto auto"
          className="flex-1 padding-top-medium"
          alignItems="center"
        >
          {WaitListID ? (
            <Label
              dataElementName="addTask__providerLabel"
              descriptor={labels.Provider}
              descriptorClassName="task-popup-view__control-label"
            >
              {formatStaffName(ProviderID)}
            </Label>
          ) : (
            <>
              <UIControlLabel
                dataElementName="addTask__providerLabel"
                required={true}
              >
                {labels.Provider}
              </UIControlLabel>
              <Provider
                dataElementName="addTask__provider"
                value={ProviderID}
                valueFn={pluck('StaffID')}
                canAdd={false}
              />
            </>
          )}
          {!WaitListID && (
            <XePatientSearchWidget
              dataPath="IPID"
              valueFn={pluck('IPID')}
              dataElementName="addTask__member"
              descriptor={labels.Patient}
              required={true}
            />
          )}
          <DatePicker
            dataPath="PreferredDateTime"
            dataElementName="addTask__taskDueDate"
            descriptor={labels.TaskDueDate}
            disabled={IsInError}
            required={!IsInError}
          />
          <DatePicker
            dataPath="CompletedDateTime"
            name="addTask__taskDate"
            dataElementName="addTask__taskCompletedDate"
            min={PreferredDateTime}
            descriptor={labels.TaskCompletedDate}
            disabled={IsInError}
          />
          <ButtonBar
            dataElementName="addTask__statusBar"
            data={WaitListStatus}
            dataPath="StatusID"
            valueFn={toStatusID}
            labelFn={toName}
            descriptor={labels.Status}
            disabledIndices={IsInError ? WaitListStatus.map((o, i) => i) : []}
            required={!IsInError}
          />
          <PriorityButtonGroup
            dataElementName="addTask__priority"
            data={XeAppUserNotificationPriority}
            value={PriorityID}
            dataItemKey="PriorityID"
            labelFn={nameFormatter}
            disabled={IsInError}
            descriptor={labels.Priority}
            required={!IsInError}
          />
          <DropDownList
            dataElementName="addTask__consumerCategory"
            data={taskCategories}
            labelFn={nameFormatter}
            dataPath="WaitListSubCategoryID"
            valueFn={pluck('WaitListSubCategoryID')}
            descriptor={labels.Category}
            disabled={IsInError}
            required={!IsInError}
          >
            <Checkbox
              dataElementName="addTask__viewAll"
              label={labels.ViewAll}
              checked={viewAllCategories}
              onChange={onSetViewAllCategories}
              wrapperClassName="padding-left-small"
            />
          </DropDownList>
          <TextArea
            dataPath="Description"
            className="description__text-area"
            dataElementName="addTask__description"
            descriptor={labels.Description}
            disabled={IsInError}
            required={!IsInError}
          />
          {StatusID === 'CANCELLED' && (
            <TextArea
              dataPath="ClosedComment"
              className="description__text-area"
              dataElementName="addTask__cancelReason"
              descriptor={labels.CancelReason}
              disabled={IsInError}
              required={!IsInError}
            />
          )}
          {IsInError && (
            <TextArea
              dataElementName="addTask__description"
              value={ErrorDescription}
              descriptor={labels.MarkedInErrorReason}
              disabled={true}
            />
          )}
        </GridLayout>
        {WaitListID && (
          <GridLayout
            templateColumns="max-content auto"
            alignItems="center"
            className="padding-left-medium"
          >
            <div>
              <Label dataElementName="addTask__createdBy__label">
                {`${labels.CreatedBy} `}
                <Label className="bold">{CreationUser}</Label>
                {` ${labels.On} `}
                <Label className="bold">
                  {toDisplayDateFromISOString(CreateTStamp, localeFormat.LONG)}
                </Label>
              </Label>
            </div>
            <Button
              dataElementName="addTask__changeLog"
              className="underline task-popup-view__change-log"
              onClick={() => setViewChangeLog(initialValue)}
            >
              {labels.ChangeLog}
            </Button>
          </GridLayout>
        )}
      </SchemaReducer>
      {viewChangeLog && (
        <TaskChangeLog
          task={viewChangeLog}
          onClose={() => setViewChangeLog(undefined)}
        />
      )}
    </>
  );
};

DefaultView.propTypes = {
  task: PropTypes.object,
  onChange: PropTypes.func,
  refData: PropTypes.object,
  taskCategories: PropTypes.array,
};

export default DefaultView;
