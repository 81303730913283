import { castSchema } from '../../../schema/schemaCaster';
import { schemaPluck } from '../../../schema/schemaTypeBuilder';
import { pluck } from '../../../fp/object';
import { isNil, isEmpty, isString } from '../../../fp/pred';
import {
  SchemaReducer,
  useSchemaDispatch,
} from '../../../schema/SchemaReducer';
import {
  UPSERT,
  DELETE,
  MERGE_PATCH,
  withDefaultJSONSchemaReducer,
} from '../../../schema/JSONSchemaReducer';
import { transformSchema } from '../../../schema/schemaTransformer';
import PropTypes from 'prop-types';
import {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useLayoutEffect,
} from 'react';
import XeOutreachItemSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogRequest$XeOutreachItems.json';
import AddCallLogRequestSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogRequest.json';
import FactXeCallLogSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog.json';
import {
  Button,
  ButtonBar,
  DateTimePicker,
  DropDownList,
  Flexbox,
  GridLayout,
  IconButton,
  TelephoneInput,
  TextArea,
  TextInput,
  UIControlLabel,
  AddTask,
  Label,
  Checkbox,
} from '../../';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { useXeRefData } from '../../../contexts/XeRefDataContext';
import {
  DATE_NOW_TOKEN,
  toFormatterAndParserObject,
  XS_DATE_TIME_FORMAT,
} from '../../../g11n/ISODates';
import { ADD_FILLED, HELP, PHONE, PHONE_CALL } from '../../../icons';
import { formatStaffName } from '../../../utils';
import {
  defaultBusinessRuleReducer,
  toDefaultValidator,
} from '../../../validators/schemaValidators';
import { XePatientSearchWidget } from '../../../widgets';
import { CalendarPatientBar } from '../../CalendarPatientBar';
import { CalendarVisitBar } from '../../CalendarVisitBar';
import ClinicalDocumentList from '../../ClinicalDocumentList';
import { HeaderLabel } from '../../Label';
import { OverflowContainer } from '../../OverflowContainer';
import {
  SHOULD_ADD_CALL_LOG,
  SHOULD_ADD_CLINICAL_CONTACT,
  SHOULD_ADD_PATIENT_CONTACT,
  SHOULD_ADD_PERSONAL_CONTACT,
  SHOULD_CANCEL_ADD_PATIENT_CONTACT,
  SHOULD_CANCEL_CALL_LOG,
  SHOULD_SHOW_ADD_PATIENT_CONTACT,
  SHOULD_REFRESH_PHONE_CONTACTS,
  SHOULD_UPDATE_CALL_LOG,
  RESPONSE_ADD_CALL_LOG,
  SHOULD_UPDATE_CLINICAL_CONTACT_TYPE,
  SHOULD_UPDATE_CONTACT,
  ON_VIEW_CHANGE,
} from '../actions';
import { CallLogHistory } from '../components/CallLogHistory';
import { GENERAL_CALL_TYPE, callTypeToTaskCategoryMap } from '../constants';
import { useCallDirectionOptions } from '../hooks';
import '../styles.css';
import AddPatientContactPopup from './AddPatientContactPopup';
import { isDateAfter } from '../../../utils/dates';
import { EMPTY_OBJECT, EMPTY_ARRAY, NOOP_FUNCTION } from '../../../constants';
import { toFilteredByProgramBits } from '../../../utils/bitFiltering';
import { useXeAppProperties } from '../../../contexts/XeAppPropertyContext';
import { Window } from '../../Popup/Window';
import { Footer, FooterButton } from '../../Popup/components/Footer';
import { useReducer$ } from '../../../hooks/useReducer$';
import { useRef$ } from '../../../hooks/useRef$';
import { combineEpics } from 'redux-observable';
import { combinePredicatedReducers } from '../../../connection/toConnectionDef';
import { useEffect$ } from '../../../hooks/useEffect$';
import { ofType } from '../../../frp/operators/ofType';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { useMenuNode } from '../../../contexts/XeMenuNodeContext';
import { useEnterprise } from '../../../contexts/XeEnterpriseContext';
import { ChartDetailsPopup } from './ChartDetailsPopup';
import { getDetails } from 'services/visit-clindocs/xe-visit-clindocs-svc';
import { useXeQuery } from '../../../data/useXeQuery';
import { identity } from '../../../fp/fp';
import epics from '../epics';
import reducers from '../reducers';
import CallToPhoneWrapper from '../components/CallToPhoneWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { MAXIMIZE_WINDOW, MINIMIZE_WINDOW } from 'features/XeAbout/actions';

const epic = combineEpics(...epics);
const reducer = combinePredicatedReducers(...reducers);

const formatData = (o) => {
  const WaitListID = schemaPluck(
    FactXeCallLogSchema,
    'XeWaitList',
    'WaitListID'
  )(o);
  return {
    //If we don't have a defined direction default to outbound
    IsInbound: false,
    ...transformSchema(FactXeCallLogSchema, AddCallLogRequestSchema)(o),
    WaitListID,
  };
};

const isValidPurpose = ({ IVID, IPID, CallType } = {}) =>
  IPID && CallType !== GENERAL_CALL_TYPE ? !!IVID : true;

const { formatter } = toFormatterAndParserObject(XS_DATE_TIME_FORMAT);

/**
 * @typedef { import("services/generated/types").AddCallLogRequest } AddCallLogRequest
 */
/**
 *
 * @param {AddCallLogRequest} callLogRequest
 * @returns {boolean}
 */
const callStartBeforeEnd = (callLogRequest) => {
  const { StartDateTime = '' } = callLogRequest;
  const { EndDateTime = '' } = callLogRequest;
  const isAfterStartDateTime = isDateAfter(StartDateTime);
  return isAfterStartDateTime(EndDateTime) >= 0;
};

const CALL_LOG_RULES = [
  [isValidPurpose, defaultBusinessRuleReducer],
  [pluck('IPID'), defaultBusinessRuleReducer],
  [({ IsInbound } = {}) => !isNil(IsInbound), defaultBusinessRuleReducer],
  [pluck('CallType'), defaultBusinessRuleReducer],
  [pluck('CallToName'), defaultBusinessRuleReducer],
  [pluck('CallToPhone'), defaultBusinessRuleReducer],
  [pluck('StartDateTime'), defaultBusinessRuleReducer],
  [pluck('EndDateTime'), defaultBusinessRuleReducer],
  [pluck('OutcomeID'), defaultBusinessRuleReducer],
  [pluck('CatalogID'), defaultBusinessRuleReducer],
  [callStartBeforeEnd, defaultBusinessRuleReducer],
];

const validator = toDefaultValidator(CALL_LOG_RULES);

const setTimeToNow = (path) => {
  return {
    type: UPSERT,
    path,
    value: formatter(new Date()),
  };
};

const clearCallToPhone = {
  type: DELETE,
  path: 'CallToPhone',
};

const setCallToData = (value) => ({
  type: MERGE_PATCH,
  value: {
    ...value,
    CallToPhone: undefined,
  },
});

const clearCallLogOnlyFields = {
  type: MERGE_PATCH,
  value: {
    CallToName: undefined,
    CallToPhone: undefined,
    IsCallToPatient: undefined,
    IsCallToContact: undefined,
  },
};

// TODO: CallToName and CallToPhone aren't actually cleared by the MERGE_PATCH action here.  If we
// have to clear these fields we should dispatch a DELETE action and provide the paths.
// We should also figure out what the requirements of Call Log Only are.
// Also we should check the rest of the MERGE_PATCH actions (like the one above) in this file to
// see if they're working properly. (RA)
const callLogDefaults = {
  type: MERGE_PATCH,
  value: {
    IsInbound: true,
    IsCallToPatient: false,
    IsCallToContact: false,
    CallToName: undefined,
    CallToPhone: undefined,
  },
};

const setPatientContact = (contact) => {
  return {
    type: MERGE_PATCH,
    value: contact,
  };
};

const toFormatContact = (item) => {
  if (!item || isEmpty(item)) return '';
  const { RelationshipName } = item;
  return `${formatStaffName(item)} (${RelationshipName})`;
};

const toValue = ({ value } = {}) => value;
const toLabel = ({ label } = {}) => label;
const toVisitID = ({ VisitID } = {}) => VisitID;
const toIVID = ({ IVID } = {}) => IVID;
const toName = ({ Name } = {}) => Name;
const toCatalogID = ({ CatalogID } = {}) => CatalogID;

const toCallerID = pluck('CallerID');
const toPatientContactID = pluck('PatientContactID');

const TimeIconButton = ({ path, ...rest }) => {
  const dispatch = useSchemaDispatch();
  return <IconButton {...rest} onClick={() => dispatch(setTimeToNow(path))} />;
};

const ExistingContactButton = ({ disabled, label, setCallLogOnly }) => {
  const dispatch = useSchemaDispatch();
  return (
    <Button
      dataElementName="addCallLogPopup__selectExistingContact"
      onClick={() => setCallLogOnly(false) || dispatch(clearCallLogOnlyFields)}
      disabled={disabled}
      style={{ gridColumnStart: '2' }}
      look="outline"
    >
      {label}
    </Button>
  );
};

const ExistingNumberButton = ({ label, setCallLogPhoneOnly }) => {
  const dispatch = useSchemaDispatch();
  return (
    <Button
      onClick={() => setCallLogPhoneOnly(false) || dispatch(clearCallToPhone)}
    >
      {label}
    </Button>
  );
};

const CallLogOnlyButton = ({ label, setCallLogOnly, disabled }) => {
  const dispatch = useSchemaDispatch();
  return (
    <Button
      onClick={() =>
        setCallLogOnly(true) ||
        dispatch(callLogDefaults) ||
        dispatch({ type: DELETE, path: 'CallerID' })
      }
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

const CallWithDropDown = ({
  setCallWith,
  setHipaaCleared,
  setCallToPhoneOptions,
  ...rest
}) => {
  const dispatch = useSchemaDispatch();
  return (
    <DropDownList
      {...rest}
      onChange={(callWith = {}) => {
        const {
          IsHIPAAClear,
          XePhone,
          IsCallToContact,
          IsCallToFacility,
          IsCallToPatient,
          CallerID,
          PatientContactID,
        } = callWith;

        setCallWith(callWith);
        setHipaaCleared(IsHIPAAClear);
        setCallToPhoneOptions(XePhone);
        dispatch(
          setCallToData(
            castSchema(AddCallLogRequestSchema)({
              CallToName: toFormatContact(callWith),
              IsCallToContact,
              IsCallToFacility,
              IsCallToPatient,
              CallerID,
              PatientContactID,
            })
          )
        );
      }}
    />
  );
};

// TODO: The AddPatientContactPopup should really become it's own component with internal requests
// as right now Add Call Log popup is overloaded and we're having to do too many workarounds with the current structure (JCM)
const AddPatientContactWrapper = ({
  addPatientContact,
  onShowAddPatientContact,
  onAddPatientContact,
  setCallLogOnly,
  readOnly,
  callWithOptions = EMPTY_ARRAY,
  setHipaaCleared,
  setCallWith,
  setCallToPhoneOptions,
  newContact,
  ...rest
}) => {
  const dispatch = useSchemaDispatch();
  const fullNewContact = callWithOptions.find((c) =>
    isSameCallWithOption(c, newContact)
  );

  useEffect(() => {
    if (fullNewContact) {
      const {
        IsHIPAAClear,
        XePhone,
        IsCallToContact,
        IsCallToFacility,
        IsCallToPatient,
        CallerID,
        PatientContactID,
      } = fullNewContact;

      setCallWith(fullNewContact);
      setHipaaCleared(IsHIPAAClear);
      setCallToPhoneOptions(XePhone);
      dispatch(
        setCallToData(
          castSchema(AddCallLogRequestSchema)({
            CallToName: toFormatContact(fullNewContact),
            IsCallToContact,
            IsCallToFacility,
            IsCallToPatient,
            CallerID,
            PatientContactID,
          })
        )
      );
    }
  }, [
    fullNewContact,
    setCallWith,
    setHipaaCleared,
    setCallToPhoneOptions,
    dispatch,
  ]);

  return (
    <>
      <IconButton
        icon={ADD_FILLED}
        iconClassName="add-call-log-popup__add-icon"
        dataElementName="callLog__addPatientContact"
        onClick={onShowAddPatientContact}
        disabled={readOnly}
      />
      {/* NOTE: Popup is being displayed here, in order to hook back into the SchemaReducer logic. With future schemareducer updates, this can likely be refactored (JCM) */}
      {addPatientContact && (
        <AddPatientContactPopup
          {...rest}
          setCallLogOnly={setCallLogOnly}
          onAddPatientContact={(contact) => {
            dispatch(setPatientContact(contact));
            onAddPatientContact(contact);
          }}
        />
      )}
    </>
  );
};

const AddTaskPopup = (props) => {
  const { onSave, ...rest } = props;
  const dispatch = useSchemaDispatch();

  return (
    <AddTask
      {...rest}
      onSave={(task = {}) => {
        const { WaitListID } = task;

        dispatch({
          type: UPSERT,
          path: 'WaitListID',
          value: WaitListID,
        });
        onSave(task);
      }}
    />
  );
};

const OutreachItemsList = (props) => {
  const schemaDispatch = useSchemaDispatch();
  return (
    <ClinicalDocumentList
      {...props}
      onChange={(nextSavedDocuments) => {
        schemaDispatch({
          type: UPSERT,
          path: 'XeOutreachItems',
          value: nextSavedDocuments.map(castSchema(XeOutreachItemSchema)),
        });
      }}
    />
  );
};

const PurposeDropDownList = (props) => {
  const { setClinicalDocumentListData, ...rest } = props;
  const schemaDispatch = useSchemaDispatch();

  const onChange = useCallback(
    (CallType) => {
      setClinicalDocumentListData(EMPTY_ARRAY);
      schemaDispatch({
        type: MERGE_PATCH,
        value: {
          CallType,
        },
      });
      schemaDispatch({
        type: DELETE,
        path: 'CatalogID',
      });
      schemaDispatch({
        type: DELETE,
        path: 'IVID',
      });
    },
    [schemaDispatch, setClinicalDocumentListData]
  );

  return <DropDownList {...rest} onChange={onChange} />;
};

const toInitialCallTypeFn = (data = []) => {
  return data.length === 1 ? data[0] : undefined;
};

const CallTypeDropDownList = (props) => {
  const { onChange, data, children, readOnly, ...rest } = props;

  const schemaDispatch = useSchemaDispatch();

  const _onChange = useCallback(
    (callLogType) => {
      schemaDispatch({
        type: UPSERT,
        path: 'CatalogID',
        value: toCatalogID(callLogType),
      });
      onChange(callLogType);
    },
    [onChange, schemaDispatch]
  );

  // We use initialValueFn to default the type dropdown to "GENERAL" when
  // adding a new call log outside of an auth context. This becomes
  // problematic when viewing call logs in readOnly mode as the setting
  // of the initial value causes an additional re-render that makes
  // us lose the correct CatalogID. To solve this, using readOnly as a pivot
  // point for setting the initialValueFn.
  const initialValueFn = !readOnly ? toInitialCallTypeFn : NOOP_FUNCTION;

  return (
    <DropDownList
      {...rest}
      data={data}
      onChange={_onChange}
      initialValueFn={initialValueFn}
    >
      {children}
    </DropDownList>
  );
};

const isSameCallWithOption = (item = {}, value = {}) => {
  if (isString(value)) {
    return value.includes(formatStaffName(item));
  }
  const CallerID = toCallerID(value);
  if (CallerID) return CallerID === toCallerID(item);

  const PatientContactID = toPatientContactID(value);
  if (PatientContactID) return PatientContactID === toPatientContactID(item);

  const StaffID = pluck('StaffID')(value);
  if (StaffID) return StaffID === toCallerID(item);

  return false;
};

const SchemaCalenderPatientBar = (props) => {
  const { patient, callLog, onCloseChart, disabled } = props;
  const schemaDispatch = useSchemaDispatch();
  const [showChart, setShowChart] = useState(false);

  return (
    <>
      <CalendarPatientBar
        patient={patient}
        onViewChart={() => {
          //We need to clear the value selected in CallToPhone when we open the details as that value might change in the popup
          schemaDispatch({
            type: DELETE,
            path: 'CallToPhone',
          });
          schemaDispatch({
            type: DELETE,
            path: 'CallToName',
          });
          setShowChart(true);
        }}
        disabled={disabled}
      />
      {showChart && (
        <ChartDetailsPopup
          callLog={callLog}
          onClose={() => {
            setShowChart(false);
            onCloseChart();
          }}
        />
      )}
    </>
  );
};

export const AddCallLogPopup = (props) => {
  const {
    callLogId,
    initialCallLog: providedInitialCallLog,
    readOnly,
    showHistory,
    patient,
    ivid,
    filterBits,
    onSave,
    onClose,
  } = props;

  const menuNode = useMenuNode();
  const initialCallLog$ = useRef$(providedInitialCallLog);
  const ivid$ = useRef$(ivid);
  const callLogId$ = useRef$(callLogId);

  const epicWithDeps = useCallback(
    (action$, state$) =>
      epic(action$, state$, {
        menuNode$: of(menuNode),
        initialCallLog$,
        ivid$,
        callLogId$,
      }),
    [menuNode, initialCallLog$, ivid$, callLogId$]
  );

  const [state = EMPTY_OBJECT, dispatch, action$] = useReducer$(
    reducer,
    epicWithDeps
  );

  const {
    callLog = EMPTY_OBJECT,
    initialCallLog = providedInitialCallLog,
    callWithOptions = EMPTY_ARRAY,
    callLogTypes = EMPTY_ARRAY,
    patientVisits = EMPTY_ARRAY,
    newContact,
    selectedContact,
    assignClinicalContact,
    addPatientContact,
  } = state;

  useEffect$(() => {
    return action$.pipe(
      ofType(RESPONSE_ADD_CALL_LOG),
      tap(({ value }) => {
        onSave(value);
      })
    );
  }, [action$, onSave]);

  useEffect$(() => {
    return action$.pipe(
      ofType(SHOULD_CANCEL_CALL_LOG),
      tap(() => {
        onClose();
      })
    );
  }, [action$, onClose]);

  const { CallOutcome, CallType: callTypeOptions = EMPTY_ARRAY } =
    useXeRefData();

  const {
    CallLogID,
    IPID: initialIpid,
    IVID: initialIvid,
    CallToName: initialCallToName,
    XeWaitList: {
      WaitListSubCategoryID: { Name: existingTaskName } = EMPTY_OBJECT,
    } = EMPTY_OBJECT,
  } = initialCallLog || { IPID: patient?.IPID };

  const {
    IPID = initialIpid,
    IVID,
    StartDateTime,
    EndDateTime,
    CallToName,
    CallType,
    WaitListID,
    CatalogID,
    XeOutreachItems = EMPTY_ARRAY,
  } = callLog;

  const dispatchAction = useDispatch();
  const [canSave, setCanSave] = useState(false);
  const [callWith, setCallWith] = useState(CallToName);
  const [callLogOnly, setCallLogOnly] = useState(false);
  const [callLogPhoneOnly, setCallLogPhoneOnly] = useState(false);
  const [_callToPhoneOptions, setCallToPhoneOptions] = useState(EMPTY_ARRAY);
  const [isEditingPhoneInline, setIsEditingPhoneInline] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  useLayoutEffect(() => {
    return () => {
      dispatch({ type: SHOULD_CANCEL_CALL_LOG });
      handleRestore();
    };
  }, []);
  const handleMinimize = () => {
    dispatchAction({
      type: MINIMIZE_WINDOW,
    });
    setIsMinimized(true);
  };

  const handleRestore = () => {
    dispatchAction({
      type: MAXIMIZE_WINDOW,
    });
    setIsMinimized(false);
  };

  // SYNUI-7518: Depending on where AddCallLog is opened from, there is a timing issue where we don't always have the
  // initialCallToName value at first, so we can't default callLogOnly above directly to this value (JCM).
  useEffect(() => {
    setCallLogOnly(!!initialCallToName);
  }, [initialCallToName]);

  //When we have new callWithOptions our previous selections are void
  const numberOfCallWithOptions = callWithOptions.length;
  useEffect(() => {
    // We only clear when adding a new contact and, therefore
    // when a size change occurs for numberOfCallWithOptions
    setCallToPhoneOptions([]);
    setCallWith(undefined);
  }, [numberOfCallWithOptions]);

  const [hipaaCleared, setHipaaCleared] = useState();
  const [followUp, setFollowup] = useState({});
  const { WaitListSubCategoryID: { Name: newTaskName } = {} } = followUp;
  const callToPhoneOptions = useMemo(() => {
    const caller = callWith || CallToName;

    return (
      pluck('XePhone')(
        callWithOptions.find((call) => {
          // Not all contacts have CallerID. Therefore, using
          // name string compare as a generic check
          const hasMatchingCallerName =
            isString(caller) && caller.includes(formatStaffName(call));
          // Using provider caller id to check for updated
          // callToPhoneOptions after an inline phone number edit
          const hasMatchingCallerID = call?.CallerID === caller?.CallerID;

          return hasMatchingCallerName || hasMatchingCallerID;
        })
      ) || _callToPhoneOptions
    );
  }, [callWithOptions, callWith, CallToName, _callToPhoneOptions]);

  const updateCallLog = useCallback(
    ({ instance, valid }) => {
      if (canSave != valid) {
        setCanSave(valid);
      }
      dispatch({
        type: SHOULD_UPDATE_CALL_LOG,
        value: instance,
      });
    },
    [dispatch, canSave]
  );

  const labels = useXeLabels();
  const properties = useXeAppProperties();
  const callDirectionOptions = useCallDirectionOptions(labels);

  const [clinicalDocumentListData, setClinicalDocumentListData] =
    useState(EMPTY_ARRAY);

  const initialSeed = useMemo(() => {
    if (!initialCallLog)
      return {
        IPID: patient?.IPID,
        //SYNUI-7380 default call type to outbound
        IsInbound: false,
      };
    return formatData(initialCallLog);
  }, [initialCallLog, patient]);

  const [viewAddTask, setViewAddTask] = useState(false);

  const { userData } = useEnterprise();
  const ProviderID = pluck('XeResource', 'ResourceID')(userData);

  const initialTaskData = {
    ProviderID,
    IPID,
    IVID,
  };

  const [viewAllCallLogTypes, onSetViewAllCallLogTypes] = useState(false);
  const bitFilteredCallLogTypes =
    filterBits !== undefined && !viewAllCallLogTypes
      ? toFilteredByProgramBits(callLogTypes, filterBits)
      : callLogTypes;
  const [firstXeOutreachItems] = XeOutreachItems;
  const visitAssessmentID = firstXeOutreachItems?.VisitAssessmentID;

  useEffect(() => {
    if (
      !!CatalogID &&
      !isEmpty(bitFilteredCallLogTypes) &&
      isEmpty(clinicalDocumentListData)
    ) {
      setClinicalDocumentListData([
        bitFilteredCallLogTypes.find(
          (callLogType) => callLogType?.CatalogID === CatalogID
        ),
      ]);
    }
  }, [
    CatalogID,
    bitFilteredCallLogTypes,
    clinicalDocumentListData,
    setClinicalDocumentListData,
  ]);

  const { data: signedOutreachItems } = useXeQuery(
    getDetails({ visitAssessmentId: visitAssessmentID }, identity),
    { enabled: !!visitAssessmentID && !!callLogId }
  );

  // If callLogId was provided, we need to wait for Add Call Log to retrieve the details for that ID.
  if (callLogId && !initialCallLog) return null;

  return (
    <>
      <Window
        size="large"
        dataElementName="addCallLog__popup"
        className={
          isMinimized ? 'minimized edit-call-log-popup' : 'edit-call-log-popup'
        }
        title={CallLogID ? labels.EditCallLog : labels.AddCallLog}
        contentDisplayType="block"
        draggable={true}
        accessBackground={isMinimized}
      >
        <GridLayout templateRows="4fr auto 1fr" className="stretch-y">
          <Flexbox direction="column" className="flex-1">
            {isMinimized && (
              <div
                className="minimized-window"
                style={{ visibility: 'visible' }}
              >
                <div className="minimized-header">
                  {CallLogID ? labels.EditCallLog : labels.AddCallLog}
                </div>
                <button
                  className="maximize-btn"
                  onClick={handleRestore}
                  dataElementName="Maximize"
                >
                  {labels.Maximize}
                </button>
              </div>
            )}
            <SchemaReducer
              schema={AddCallLogRequestSchema}
              onChange={updateCallLog}
              toJsonReducer={withDefaultJSONSchemaReducer(validator)}
              initialValue={initialSeed}
            >
              {initialIpid && (
                <SchemaCalenderPatientBar
                  patient={patient ?? initialIpid}
                  callLog={callLog}
                  onCloseChart={() => {
                    setCallWith();
                    dispatch({
                      type: SHOULD_REFRESH_PHONE_CONTACTS,
                      value: callLog,
                    });
                  }}
                  disabled={isEditingPhoneInline}
                />
              )}
              {initialCallLog && initialIvid && (
                <CalendarVisitBar visit={initialIvid} />
              )}
              <Flexbox className="flex-1">
                <GridLayout
                  templateColumns="repeat(4, max-content)"
                  style={{ justifyContent: 'left', alignItems: 'center' }}
                  className="flex-1"
                >
                  {!initialIpid ? (
                    <XePatientSearchWidget
                      dataElementName="patient"
                      descriptor={labels.Patient}
                      dataPath="IPID"
                      disabled={!!initialSeed?.IPID}
                      valueFn={pluck('IPID')}
                    />
                  ) : null}
                  {!(
                    initialCallLog &&
                    initialCallLog.CallType &&
                    initialIvid
                  ) && (
                    <PurposeDropDownList
                      dataElementName="purpose"
                      value={CallType}
                      descriptor={labels.Purpose}
                      data={callTypeOptions}
                      labelFn={pluck('text')}
                      valueFn={pluck('id')}
                      required={true}
                      setClinicalDocumentListData={setClinicalDocumentListData}
                    />
                  )}
                  {/* TODO: if there is only one patientVisit possible, should select that by default */}
                  {/* This will most likely require a change to DropDownList itself */}
                  {!initialIvid && (
                    <DropDownList
                      dataElementName="visit"
                      descriptor={labels.Link}
                      data={patientVisits}
                      dataPath="IVID"
                      labelFn={toVisitID}
                      valueFn={toIVID}
                      disabled={!patientVisits.length}
                      required={CallType !== GENERAL_CALL_TYPE}
                    />
                  )}
                  <ButtonBar
                    dataElementName="addCallLogPopup__direction"
                    descriptor={labels.Direction}
                    required={true}
                    data={callDirectionOptions}
                    dataPath="IsInbound"
                    labelFn={toLabel}
                    valueFn={toValue}
                    disabledIndices={
                      readOnly ? callDirectionOptions.map((v, i) => i) : []
                    }
                  />
                  <CallTypeDropDownList
                    dataElementName="callType"
                    value={CatalogID}
                    descriptor={labels.Type}
                    data={bitFilteredCallLogTypes}
                    labelFn={toName}
                    disabled={readOnly || CallLogID}
                    required={true}
                    comparator={(item, value) => {
                      return toCatalogID(item) === value;
                    }}
                    onChange={(callLogType) => {
                      if (callLogType)
                        setClinicalDocumentListData([callLogType]);
                    }}
                    readOnly={readOnly}
                  >
                    <Checkbox
                      dataElementName="addCallLogPopup__viewAll"
                      label={labels.ViewAll}
                      checked={viewAllCallLogTypes}
                      onChange={onSetViewAllCallLogTypes}
                      wrapperClassName="padding-left-small"
                    />
                  </CallTypeDropDownList>
                  <UIControlLabel
                    dataElementName="addCallPopup__callWith__label"
                    style={{ gridColumnStart: 1 }}
                    required={true}
                  >
                    {labels.CallWith}
                  </UIControlLabel>
                  <>
                    {callLogOnly ? (
                      <>
                        <TextInput
                          dataElementName="addCallPopup__callWith"
                          dataPath="CallToName"
                          disabled={readOnly}
                        />
                        <TelephoneInput
                          dataElementName="addCallPopup__phone"
                          descriptor={labels.Phone}
                          descriptorClassName="margin-left-small"
                          dataPath="CallToPhone"
                          disabled={readOnly}
                          required={true}
                        />
                        <ExistingContactButton
                          disabled={readOnly}
                          label={labels.SelectExistingContact}
                          setCallLogOnly={setCallLogOnly}
                        />
                      </>
                    ) : (
                      <>
                        <div style={{ gridColumn: '2 / -1', display: 'flex' }}>
                          <CallWithDropDown
                            dataElementName="callWith"
                            comparator={isSameCallWithOption}
                            value={callWith}
                            data={callWithOptions}
                            labelFn={toFormatContact}
                            setCallWith={setCallWith}
                            setHipaaCleared={setHipaaCleared}
                            setCallToPhoneOptions={setCallToPhoneOptions}
                            disabled={readOnly || isEditingPhoneInline}
                          />
                          <AddPatientContactWrapper
                            patientIPID={IPID}
                            readOnly={readOnly || isEditingPhoneInline}
                            newContact={newContact}
                            selectedContact={selectedContact}
                            assignClinicalContact={assignClinicalContact}
                            callWithOptions={callWithOptions}
                            addPatientContact={addPatientContact}
                            setHipaaCleared={setHipaaCleared}
                            setCallToPhoneOptions={setCallToPhoneOptions}
                            setCallWith={setCallWith}
                            onShowAddPatientContact={(value) =>
                              dispatch({
                                type: SHOULD_SHOW_ADD_PATIENT_CONTACT,
                                value,
                              })
                            }
                            onAddPatientContact={(value) =>
                              dispatch({
                                type: SHOULD_ADD_PATIENT_CONTACT,
                                value,
                              })
                            }
                            onCancelAddPatientContact={() =>
                              dispatch({
                                type: SHOULD_CANCEL_ADD_PATIENT_CONTACT,
                              })
                            }
                            onAddPersonalContact={(value) =>
                              dispatch({
                                type: SHOULD_ADD_PERSONAL_CONTACT,
                                value,
                              })
                            }
                            onAddClinicalContact={(value) =>
                              dispatch({
                                type: SHOULD_ADD_CLINICAL_CONTACT,
                                value,
                              })
                            }
                            onUpdateContact={(value) =>
                              dispatch({
                                type: SHOULD_UPDATE_CONTACT,
                                value,
                              })
                            }
                            onViewChange={() =>
                              dispatch({ type: ON_VIEW_CHANGE, value: {} })
                            }
                            onUpdateClinicalContactType={(value) =>
                              dispatch({
                                type: SHOULD_UPDATE_CLINICAL_CONTACT_TYPE,
                                value,
                              })
                            }
                            setCallLogOnly={setCallLogOnly}
                          />
                          {CallToName && hipaaCleared === true && (
                            <span className="add-call-log-popup__hipaa-label--blue">
                              {labels.HIPAACleared}
                            </span>
                          )}
                          {CallToName && hipaaCleared === false && (
                            <span className="add-call-log-popup__hipaa-label--red">
                              {labels.NotHIPAACleared}
                            </span>
                          )}
                          {/* TODO: The user can also add a new phone number to a contact inline. Need more details + relevant endpoints to complete (JCM) */}
                          <CallToPhoneWrapper
                            isVisible={CallToName && !callLogPhoneOnly}
                            callToPhoneOptions={callToPhoneOptions}
                            readOnly={readOnly}
                            shouldEnableCallLinkRef={
                              properties.EnableCallLinkHRef
                            }
                            setCallWith={setCallWith}
                            callWith={callWith}
                            ipid={IPID}
                            ivid={IVID}
                            dispatch={dispatch}
                            onSave={() => {
                              setIsEditingPhoneInline(false);
                            }}
                            onEdit={() => {
                              setIsEditingPhoneInline(true);
                            }}
                            onCancel={() => {
                              setIsEditingPhoneInline(false);
                            }}
                          />
                        </div>
                        {CallToName && callLogPhoneOnly && (
                          <>
                            <TelephoneInput
                              descriptor={labels.Phone}
                              dataPath="CallToPhone"
                            />
                            <ExistingNumberButton
                              label={labels.SelectExistingNumber}
                              setCallLogPhoneOnly={setCallLogPhoneOnly}
                            />
                          </>
                        )}
                        <div style={{ gridColumnStart: '2' }}>
                          <CallLogOnlyButton
                            dataElementName="callLogOnly"
                            label={labels.CallLogOnly}
                            setCallLogOnly={setCallLogOnly}
                            disabled={readOnly}
                          />
                          <IconButton
                            dataElementName="help"
                            icon={HELP}
                            iconClassName="flex-0"
                            description={labels.CallLogOnlyMessage}
                            tooltip
                          />
                        </div>
                      </>
                    )}
                  </>
                  <DateTimePicker
                    dataElementName="startCallTime"
                    descriptor={labels.StartTime}
                    descriptorClassName="add-call-log-popup__first-column"
                    dataPath="StartDateTime"
                    max={EndDateTime || DATE_NOW_TOKEN}
                    disabled={readOnly}
                    required={true}
                  >
                    <TimeIconButton
                      iconClassName="add-call-log-popup__icon--green"
                      dataElementName="callLog__phoneInTime"
                      look="default"
                      icon={PHONE_CALL}
                      disabled={readOnly}
                      path="StartDateTime"
                    />
                  </DateTimePicker>
                  <DateTimePicker
                    dataElementName="endCallTime"
                    descriptor={labels.EndTime}
                    dataPath="EndDateTime"
                    min={StartDateTime}
                    max={DATE_NOW_TOKEN}
                    disabled={readOnly}
                    required={true}
                  >
                    <TimeIconButton
                      iconClassName="add-call-log-popup__icon--red"
                      dataElementName="callLog__phoneOutTime"
                      look="default"
                      icon={PHONE}
                      disabled={readOnly}
                      path="EndDateTime"
                    />
                  </DateTimePicker>
                  <DropDownList
                    dataElementName="outcome"
                    descriptor={labels.Outcome}
                    data={CallOutcome}
                    dataPath="OutcomeID"
                    labelFn={pluck('text')}
                    valueFn={pluck('id')}
                    disabled={readOnly}
                    required={true}
                  />
                  <UIControlLabel dataElementName="addCallPopup_followUp__label">
                    {labels.FollowUp}
                  </UIControlLabel>
                  {WaitListID ? (
                    <Label>{existingTaskName || newTaskName}</Label>
                  ) : (
                    <div>
                      <Button
                        dataElementName="addCallPopup_followUp"
                        onClick={() => setViewAddTask(true)}
                        disabled={!IPID}
                        look="outline"
                      >
                        {labels.AddFollowUp}
                      </Button>
                    </div>
                  )}
                  {viewAddTask && (
                    <AddTaskPopup
                      dataElementName="newTask"
                      category={callTypeToTaskCategoryMap[CallType]}
                      value={initialTaskData}
                      onSave={(task) => {
                        setFollowup(task);
                        setViewAddTask(false);
                      }}
                      onClose={() => setViewAddTask(false)}
                    />
                  )}
                  <TextArea
                    dataElementName="comments"
                    descriptor={labels.Comments}
                    descriptorClassName="add-call-log-popup__grid-label-item--align-top"
                    rows={3}
                    dataPath="Comments"
                    disabled={readOnly}
                    style={{ gridColumnEnd: -1 }}
                    className="add-call-log-popup__comment-box"
                  />
                </GridLayout>
                {clinicalDocumentListData.length ? (
                  <>
                    <div className="vertical-separator" />
                    <OutreachItemsList
                      data={clinicalDocumentListData}
                      ipid={IPID}
                      ivid={IVID}
                      disabled={!IPID}
                      disabledDescription={labels.MemberRequired}
                      patient={patient}
                      signedOutreachItems={signedOutreachItems}
                    />
                  </>
                ) : null}
              </Flexbox>
            </SchemaReducer>
          </Flexbox>
          {showHistory ? (
            <>
              <div className="horizontal-separator" />
              <Flexbox direction="column" className="stretch-y">
                <HeaderLabel>{labels.CallHistory}</HeaderLabel>
                <OverflowContainer className="flex-1">
                  <CallLogHistory
                    AssetXeCallLog={initialCallLog}
                    AddCallLogRequest={callLog}
                  />
                </OverflowContainer>
              </Flexbox>
            </>
          ) : null}
        </GridLayout>

        <Footer>
          <FooterButton
            dataElementName="close"
            onClick={() => dispatch({ type: SHOULD_CANCEL_CALL_LOG })}
          >
            {labels.Close}
          </FooterButton>
          <FooterButton
            dataElementName="confirm"
            onClick={() =>
              dispatch({ type: SHOULD_ADD_CALL_LOG, value: callLog })
            }
            disabled={!canSave || readOnly || isEditingPhoneInline}
          >
            {labels.Save}
          </FooterButton>
          <FooterButton dataElementName="Minimize" onClick={handleMinimize}>
            {labels.Minimize}
          </FooterButton>
        </Footer>
      </Window>
    </>
  );
};

AddCallLogPopup.propTypes = {
  readOnly: PropTypes.bool,
  initialCallLog: PropTypes.object,
  patient: PropTypes.object,
};

export default AddCallLogPopup;
