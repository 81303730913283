import { Label, Flexbox, Icon, ToolTip } from '../../../components';
import { HELP } from '../../../icons';

export const DashboardHeader = (props) => {
  const { Name, Description, children, showHeader } = props;
  return (
    <Flexbox
      className={!showHeader ? '' : 'padding-all-small'}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flexbox alignItems="center">
        {Name && showHeader && (
          <Label className="padding-right-small">{Name}</Label>
        )}
        {Description && (
          <ToolTip value={Description}>
            <Icon icon={HELP} dataElementName="dashboardHeaderHelp" />
          </ToolTip>
        )}
      </Flexbox>
      <Flexbox justifyContent="flex-end" className="flex-1 padding-all-small">
        {children}
      </Flexbox>
    </Flexbox>
  );
};

export default DashboardHeader;
