import { useState } from 'react';
import {
  getDetails,
  queryScanDoc,
} from 'services/dashboards/xe-dashboards-svc';
import { EMPTY_OBJECT } from '../../../../../constants';
import { useXeMutation } from '../../../../../data/useXeMutation';
import { useXeQuery } from '../../../../../data/useXeQuery';
import { identity } from '../../../../../fp/fp';
import { isNil } from '../../../../../fp/pred';
import { OPEN_IN_NEW } from '../../../../../icons';
import { IconButton } from '../../../../IconButton';
import { Label } from '../../../../Label';
import { Popup } from '../../../../Popup';
import { Footer, FooterButton } from '../../../../Popup/components/Footer';
import { SmartBook } from '../../../../SmartBook';
import DashboardDetailSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardDetail.json';
import { useXeLabels } from '../../../../../contexts/XeLabelContext';

export const GlobalActionGenerateScandocFile = (props) => {
  const { dashboardDetails = EMPTY_OBJECT } = props;

  const labels = useXeLabels();

  const { ParentID } = dashboardDetails;

  const { data: globalActionDashboardDetails } = useXeQuery(
    getDetails({ dashboardId: ParentID }, identity),
    {
      enabled: !isNil(ParentID),
    }
  );

  const [showGenerateFileMessage, setShowGenerateFileMessage] = useState();
  const [smartbookData, setSmartbookData] = useState();

  const { mutate: queryScanDocMutation } = useXeMutation((data) =>
    queryScanDoc(data, { xxsid: undefined }, identity)
  );

  return (
    <>
      <IconButton
        icon={OPEN_IN_NEW}
        onClick={() => {
          if (isNil(globalActionDashboardDetails?.BookID)) {
            setShowGenerateFileMessage(true);
          } else {
            setSmartbookData(globalActionDashboardDetails);
          }
        }}
        description={labels.GenerateFile}
      />
      {smartbookData ? (
        <Popup>
          <SmartBook
            instance={smartbookData}
            onChange={({ instance }) => {
              setSmartbookData(instance);
            }}
            SmartBookSchema={DashboardDetailSchema}
          />
          <Footer>
            <FooterButton onClick={() => setSmartbookData(null)}>
              {labels.Close}
            </FooterButton>
            <FooterButton
              onClick={() => {
                queryScanDocMutation(smartbookData, {
                  onSuccess: () => {
                    setSmartbookData(null);
                  },
                });
              }}
            >
              {labels.Confirm}
            </FooterButton>
          </Footer>
        </Popup>
      ) : null}
      {showGenerateFileMessage ? (
        <Popup title={labels.GenerateFile} size="small">
          <Label wrapText={true}>{labels.PromptGenerateFile}</Label>
          <Footer>
            <FooterButton onClick={() => setShowGenerateFileMessage(false)}>
              {labels.No}
            </FooterButton>
            <FooterButton
              onClick={() => {
                queryScanDocMutation(globalActionDashboardDetails, {
                  onSuccess: () => {
                    setShowGenerateFileMessage(false);
                  },
                });
              }}
            >
              {labels.Yes}
            </FooterButton>
          </Footer>
        </Popup>
      ) : null}
    </>
  );
};
