import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { REQUEST_ASSIGN_CANDIDATES } from './actions';

// These "roles" are not the same as user roles in the larger application.
// They are just convenient ways to conceptualize the rights combinations in shorter names. (MM)
const userRoles = {
  CAN_GRAB_ONLY: 'CAN_GRAB_ONLY',
  CAN_ASSIGN_ONLY: 'CAN_ASSIGN_ONLY',
  CAN_OVERWRITE_ONLY: 'CAN_OVERWRITE_ONLY',
  CAN_GRAB_ASSIGN: 'CAN_GRAB_ASSIGN',
  CAN_GRAB_OVERWRITE: 'CAN_GRAB_OVERWRITE',
  CAN_ASSIGN_OVERWRITE: 'CAN_ASSIGN_OVERWRITE',
  CAN_GRAB_ASSIGN_OVERWRITE: 'CAN_GRAB_ASSIGN_OVERWRITE',
  NO_RIGHTS: 'NO_RIGHTS',
};

const ticketStatuses = {
  UNASSIGNED: 'UNASSIGNED',
  ASSIGNED_TO_SELF: 'ASSIGNED_TO_SELF',
  ASSIGNED_TO_OTHER: 'ASSIGNED_TO_OTHER',
};

const callbacks = {
  SHOW_ASSIGN_POPUP: 'SHOW_ASSIGN_POPUP',
  SHOW_ASSIGN_POPUP_WITH_CANDIDATES: 'SHOW_ASSIGN_POPUP_WITH_CANDIDATES',
  SHOW_RELEASE_POPUP: 'SHOW_RELEASE_POPUP',
  SHOW_TAKEOVER_POPUP: 'SHOW_TAKEOVER_POPUP',
  NOOP: 'NOOP',
};

const toCallback = (userRole, ticketStatus) => {
  if (userRole === userRoles.CAN_GRAB_ONLY) {
    if (ticketStatus === ticketStatuses.UNASSIGNED)
      return callbacks.SHOW_TAKEOVER_POPUP;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_SELF)
      return callbacks.SHOW_RELEASE_POPUP;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_OTHER)
      return callbacks.NOOP;
  }
  if (userRole === userRoles.CAN_ASSIGN_ONLY) {
    if (ticketStatus === ticketStatuses.UNASSIGNED)
      return callbacks.SHOW_ASSIGN_POPUP_WITH_CANDIDATES;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_SELF) return callbacks.NOOP;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_OTHER)
      return callbacks.NOOP;
  }
  if (userRole === userRoles.CAN_OVERWRITE_ONLY) {
    if (ticketStatus === ticketStatuses.UNASSIGNED) return callbacks.NOOP;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_SELF)
      return callbacks.SHOW_RELEASE_POPUP;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_OTHER)
      return callbacks.SHOW_RELEASE_POPUP;
  }
  if (userRole === userRoles.CAN_GRAB_ASSIGN) {
    if (ticketStatus === ticketStatuses.UNASSIGNED)
      return callbacks.SHOW_ASSIGN_POPUP_WITH_CANDIDATES;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_SELF)
      return callbacks.SHOW_ASSIGN_POPUP_WITH_CANDIDATES;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_OTHER)
      return callbacks.NOOP;
  }
  if (userRole === userRoles.CAN_GRAB_OVERWRITE) {
    if (ticketStatus === ticketStatuses.UNASSIGNED)
      return callbacks.SHOW_TAKEOVER_POPUP;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_SELF)
      return callbacks.SHOW_RELEASE_POPUP;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_OTHER)
      return callbacks.SHOW_ASSIGN_POPUP;
  }
  if (userRole === userRoles.CAN_ASSIGN_OVERWRITE) {
    if (ticketStatus === ticketStatuses.UNASSIGNED)
      return callbacks.SHOW_ASSIGN_POPUP_WITH_CANDIDATES;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_SELF)
      return callbacks.SHOW_ASSIGN_POPUP_WITH_CANDIDATES;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_OTHER)
      return callbacks.SHOW_ASSIGN_POPUP_WITH_CANDIDATES;
  }
  if (userRole === userRoles.CAN_GRAB_ASSIGN_OVERWRITE) {
    if (ticketStatus === ticketStatuses.UNASSIGNED)
      return callbacks.SHOW_ASSIGN_POPUP_WITH_CANDIDATES;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_SELF)
      return callbacks.SHOW_ASSIGN_POPUP_WITH_CANDIDATES;
    if (ticketStatus === ticketStatuses.ASSIGNED_TO_OTHER)
      return callbacks.SHOW_ASSIGN_POPUP_WITH_CANDIDATES;
  }
  if (userRole === userRoles.NO_RIGHTS) return callbacks.NOOP;
};

const toTicketStatus = (stateOwner, resourceID) => {
  if (!stateOwner) return ticketStatuses.UNASSIGNED;
  if (resourceID === stateOwner) return ticketStatuses.ASSIGNED_TO_SELF;
  if (resourceID !== stateOwner && stateOwner)
    return ticketStatuses.ASSIGNED_TO_OTHER;

  return undefined;
};

const toUserRole = (canGrab, canGrabAssign, canGrabOverwrite) => {
  if (canGrab && !canGrabAssign && !canGrabOverwrite)
    return userRoles.CAN_GRAB_ONLY;
  if (!canGrab && canGrabAssign && !canGrabOverwrite)
    return userRoles.CAN_ASSIGN_ONLY;
  if (!canGrab && !canGrabAssign && canGrabOverwrite)
    return userRoles.CAN_GRAB_OVERWRITE;
  if (canGrab && canGrabAssign && !canGrabOverwrite)
    return userRoles.CAN_GRAB_ASSIGN;
  if (canGrab && !canGrabAssign && canGrabOverwrite)
    return userRoles.CAN_GRAB_OVERWRITE;
  if (!canGrab && canGrabAssign && canGrabOverwrite)
    return userRoles.CAN_ASSIGN_OVERWRITE;
  if (canGrab && canGrabAssign && canGrabOverwrite)
    return userRoles.CAN_GRAB_ASSIGN_OVERWRITE;
  return userRoles.NO_RIGHTS;
};

/*
This implementation was decided on after consulting with the backend team as to whether this logic should live on the server.
If this lived on the server, the logic of the below function would either need to be run on the server for each button in the list ahead of time, or we would need an additional query to the server to get the correct action when the button is clicked.
Both of these options were decided to be worse than having the logic live on the client. 
As of writing this, we have 3 rights and 3 possible item states. Each combination of those could have a different action allowed.
We opted for if statements for simplicity. 
An alternative was tried with a more involved dynamic key:value pair approach, but that was deemed too convoluted in the end.
(MM)
*/

export const toGrabButtonOnClick = (config = EMPTY_OBJECT) => {
  const {
    canGrab,
    canGrabAssign,
    canGrabOverwrite,
    dispatch,
    ResourceID,
    setShowAssignPopup,
    setShowUnassignPopup,
    setShowAssignToSelfPopup,
    StateOwner,
    WorkflowID,
    WorkState,
  } = config;

  const ticketStatus = toTicketStatus(StateOwner, ResourceID);
  const userRole = toUserRole(canGrab, canGrabAssign, canGrabOverwrite);
  const callback = toCallback(userRole, ticketStatus);

  const showAssignPopup = () => setShowAssignPopup(true);
  const showReleasePopup = () => setShowUnassignPopup(true);
  const showAssignToSelfPopup = () => setShowAssignToSelfPopup(true);
  const showAssignPopupWithCandidates = () => {
    dispatch({
      type: REQUEST_ASSIGN_CANDIDATES,
      value: { WorkflowID, WorkState },
    });
    setShowAssignPopup(true);
  };

  if (callback === 'NOOP') return NOOP_FUNCTION;
  if (callback === 'SHOW_ASSIGN_POPUP') return showAssignPopup;
  if (callback === 'SHOW_ASSIGN_POPUP_WITH_CANDIDATES')
    return showAssignPopupWithCandidates;
  if (callback === 'SHOW_RELEASE_POPUP') return showReleasePopup;
  if (callback === 'SHOW_TAKEOVER_POPUP') return showAssignToSelfPopup;

  return undefined;
};
