import { useSchema } from '../../schema/SchemaReducer';
import {
  toUIValidForDate,
  toUIValid,
} from '../../schema/UIValidationProjection';
import PropTypes from 'prop-types';
import { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import { DatePicker as KendoDatePicker } from '@progress/kendo-react-dateinputs';
import {
  toFormatterAndParserByType,
  toParseWithToken,
  DateTimeFormatNamePropType,
} from '../../g11n/ISODates';
import { UIControlLabel } from '../Label';
import { toDateSchemaNodeFormat } from '../../utils/schema';
import { Flexbox } from '../Flexbox';
import { NOOP_FUNCTION } from '../../constants';

const defaultFormatPlaceholder = {
  year: 'yyyy',
  month: 'MM',
  day: 'dd',
};

const disableInputFn = (ev) => {
  ev.preventDefault();
  ev.stopPropagation();
  return null;
};

export const DatePicker = (props) => {
  const {
    dataElementName = '',
    dataPath,
    value: propValue,
    onChange = NOOP_FUNCTION,
    min,
    max,
    format: propsFormat = 'date',
    onBlur = NOOP_FUNCTION,
    descriptor,
    descriptorClassName,
    required: propsRequired = false,
    children,
    onValidation = NOOP_FUNCTION,
    inputDisabled = false,
    ...passthroughProps
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const [internalTouched, setInternalTouched] = useState(false);
  const [uiValid, setUiValid] = useState(true);
  const {
    onValueChange = onChange,
    value = propValue,
    schemaNode,
    touched = false,
    required: schemaRequired = false,
    validityMessage = '',
    onTouched = () => setInternalTouched(true),
    onValidationChange = onValidation,
  } = useSchema(dataPath);

  const dateFormat = schemaNode
    ? toDateSchemaNodeFormat(schemaNode)
    : propsFormat;

  //we are required if either the schema or the props say we are
  const required = schemaRequired || propsRequired;

  const validityStyles = schemaNode ? touched : internalTouched;

  const datePickerRef = useRef();

  // Commenting this out due to UI not wanting unintended side effects so close to SYN-23357 release date
  // Will address this again once the migrations toward the different customers are done. SNET-1026 (JCh)
  /* useEffect(() => {
    if (!touched && required && !value) {
      // SYNUI-8887; on initial mount if the picker is required and has no value, validity isn't set
      // properly until the picker is touched (AZ)

      //If the validity is changing from true to false
      if (uiValid) {
        setUiValid(false);
        onValidationChange(false);
      }
    } else {
      //Getting the element's validity according to Telerik recomendation. SNETEXT-220(MF)
      const element = datePickerRef.current._dateInput.current._element;
      element.checkValidity();
      const { validity, validationMessage } = element;
      const nextUiValid = toUIValidForDate(validity);

      //If the validity is changing
      if (uiValid !== nextUiValid) {
        setUiValid(nextUiValid);
        // If used from an AttrType/Smartbook context, add validity information. SNETEXT-220(MF)
        // This execution is not focused on changing the value, but on reacting to the change in the value to provide extra validity information.
        if (!dataPath) {
          onValueChange(value, nextUiValid);
        }
        onValidationChange(nextUiValid, validationMessage);
      }
    }
  }, [touched, required, value, onValidationChange, onValueChange]); */

  //TODO: SYNUI-6445 - This can possibly be handled better in the future. Currently, due to how the html date input works
  // it will fire on any valid date. In some instances, we want to disable that input and this was a quick and dirty way
  // to make a patch. (JAC)
  useEffect(() => {
    if (inputDisabled) {
      datePickerRef.current._element.addEventListener(
        'keydown',
        disableInputFn
      );
    } else {
      datePickerRef.current._element.removeEventListener(
        'keydown',
        disableInputFn
      );
    }
  }, [inputDisabled]);

  const { parser, formatter } = useMemo(
    () => toFormatterAndParserByType(dateFormat),
    [dateFormat]
  );

  const valueAsDate = useMemo(() => {
    if (!value) return null;
    return parser(value);
  }, [value, parser]);

  const memoizedMin = useMemo(
    () => toParseWithToken(parser)(min),
    [min, parser]
  );

  const memoizedMax = useMemo(
    () => toParseWithToken(parser)(max),
    [max, parser]
  );

  const memoizedOnChange = useCallback(
    ({ target: { value, validity } = {} }) => {
      //Due to how moment works, passing it a null will result in a date at timestamp 0.
      //We need the actual value of null to be passed through to update dates in the database. This ensures
      //null is always passed back when an incomplete or fully deleted date is entered.
      const realizedValue = value !== null ? formatter(value) : value;
      const { valueMissing, valid, ...rest } = validity;
      //FIXME: in Kendo, valueMissing does not follow the html specification when the constraint
      // should apply.  Remove when Kendo fixes this for their datepickers. (AZ)

      //This is a strange usecase, where if we are using this outside of a schema context,
      //we are not receiving any validation data within the onChange passed into the component.
      //This only exists within Smartbooks for now, since the SmartBook AttrType Date and DateTime are wrapped around this as a basic component. (JAC)
      if (dataPath) {
        onValueChange(realizedValue);
      } else {
        onValueChange(
          realizedValue,
          toUIValid({
            ...rest,
            valid,
            valueMissing: required ? valueMissing : false,
          })
        );
      }
      onValidationChange(
        toUIValid({
          ...rest,
          valid,
          valueMissing: required ? valueMissing : false,
        })
      );
    },
    [onValueChange, onValidationChange, formatter, required, dataPath]
  );

  const memoizedValidity = useMemo(() => {
    if (!validityMessage) return undefined;
    return false;
  }, [validityMessage]);

  // SYNUI-8887; on initial mount if the picker is required and has no value, validity isn't set
  // properly until the picker is touched (AZ)
  useEffect(() => {
    if (!touched && required && !value) {
      onValidationChange(
        toUIValid({
          valid: false,
          valueMissing: true,
        })
      );
    }
  });

  return (
    <>
      <UIControlLabel
        dataElementName={
          dataElementName !== '' ? `${dataElementName}__label` : 'label'
        }
        className={descriptorClassName}
        required={required || propsRequired}
      >
        {descriptor}
      </UIControlLabel>
      <Flexbox alignItems="center">
        <KendoDatePicker
          ref={(ref) => {
            if (ref && datePickerRef.current !== ref) {
              ref.element.setAttribute('data-component-name', 'DatePicker');
              ref.element.setAttribute('data-element-name', dataElementName);
            }
            datePickerRef.current = ref;
          }}
          value={valueAsDate}
          formatPlaceholder={defaultFormatPlaceholder}
          min={memoizedMin}
          max={memoizedMax}
          onChange={memoizedOnChange}
          onBlur={(event) => {
            onBlur(event);
            onTouched();
          }}
          valid={memoizedValidity}
          required={required || propsRequired}
          validityStyles={validityStyles}
          {...passthroughProps}
        />
        {children}
      </Flexbox>
    </>
  );
};

DatePicker.propTypes = {
  dataElementName: PropTypes.string,
  dataPath: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  min: PropTypes.string,
  max: PropTypes.string,
  format: DateTimeFormatNamePropType,
  onBlur: PropTypes.func,
  autoComplete: PropTypes.string,
  onValidation: PropTypes.func,
  inputDisabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default DatePicker;
