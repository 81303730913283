import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  ButtonBar,
  Flexbox,
  Label,
  TextInput,
  TelephoneInput,
  Table,
} from '../../';
import { EMPTY_OBJECT } from '../../../constants';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { useCallDirectionOptions, usePhoneTypeOptions } from '../hooks';

const toLabel = ({ label } = {}) => label;
const toValue = ({ value } = {}) => value;

export const CallLogOnly = (props) => {
  const { contact = EMPTY_OBJECT, updateContactKey } = props;
  const { name, relationship, CallToPhone, IsInbound } = contact;

  const labels = useXeLabels();

  const phoneTypeOptions = usePhoneTypeOptions(labels);
  const callDirectionOptions = useCallDirectionOptions(labels);
  const [phoneType, setPhoneType] = useState(phoneTypeOptions[0]);

  return (
    <Flexbox direction="column" justifyContent="center">
      <Label
        dataElementName="callLogOnly__phoneContactCallLogOnly_label"
        className="padding-bottom-large"
      >
        {labels.PhoneContactCallLogOnly}
      </Label>
      <Table.Layout>
        <Table.Row>
          <Table.Cell>
            <Label
              dataElementName="callLogOnly__callWith__label"
              required={true}
            >
              {labels.CallWith}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <TextInput
              dataElementName="callLogOnly__firstAndLastName"
              value={name}
              placeholder={labels.FirstAndLastName}
              onChange={updateContactKey('name')}
            />
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>
            <Label
              dataElementName="callLogOnly__relationship__label"
              required={true}
            >
              {labels.Relationship}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <TextInput
              dataElementName="callLogOnly__relationship"
              value={relationship}
              placeholder={labels.ContactRelationship}
              onChange={updateContactKey('relationship')}
            />
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>
            <Label dataElementName="callLogOnly__number_label">
              {labels.Number}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <TelephoneInput
              dataElementName="callLogOnly__number"
              value={CallToPhone}
              onChange={updateContactKey('CallToPhone')}
            />
            <ButtonBar
              data={phoneTypeOptions}
              labelFn={toLabel}
              keyFn={toValue}
              selectedItem={phoneType}
              onSelect={setPhoneType}
            />
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>
            <Label dataElementName="callLogOnly__direction__label">
              {labels.Direction}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <ButtonBar
              dataElementName="callLogOnly__direction"
              data={callDirectionOptions}
              labelFn={toLabel}
              keyFn={toValue}
              selectedItem={IsInbound}
              onSelect={updateContactKey('IsInbound')}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Layout>
    </Flexbox>
  );
};

CallLogOnly.propTypes = {
  contact: PropTypes.object,
  updateContactKey: PropTypes.func.isRequired,
};

export default CallLogOnly;
