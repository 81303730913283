import PropTypes from 'prop-types';
import { isEmpty } from '../../../../fp/pred';
import { localeFormat } from '../../../../format/luxonToDisplayString';
import { toDisplayDateFromISOString } from '../../../../g11n/displayDates';

import { HeaderLabel } from '../HeaderLabel';
import { formatCommonName } from '../../../../utils';

export const SignedInfo = (props) => {
  const { date, signee } = props;

  return (
    !!date &&
    !!signee && (
      <>
        <HeaderLabel type="label">Signed</HeaderLabel>
        {!!date && (
          <>
            <HeaderLabel type="label"> on </HeaderLabel>
            <HeaderLabel type="value">
              {toDisplayDateFromISOString(date, localeFormat.MEDIUM)}
            </HeaderLabel>
          </>
        )}
        {!!signee && !isEmpty(signee) && (
          <>
            <HeaderLabel type="label"> by </HeaderLabel>
            <HeaderLabel type="value">
              {`${formatCommonName(signee, 'F, G')}`}
            </HeaderLabel>
          </>
        )}
      </>
    )
  );
};

SignedInfo.propTypes = {
  date: PropTypes.string,
  signee: PropTypes.shape({
    FamilyName: PropTypes.string,
    GivenName: PropTypes.string,
  }),
};
