import { useMemo } from 'react';
import { EMPTY_OBJECT } from '../../constants';

export const useAddContactOptions = (labels, rights = EMPTY_OBJECT) => {
  return useMemo(() => {
    const {
      CREATE_CLINICAL_PATIENT_DATA_DEMOGRAPHIC,
      CREATE_CLINICAL_PATIENT_DATA_ANY,
      CREATE_CLINICAL_PATIENT_DATA_CAREPROVIDER,
    } = rights;

    return [
      {
        label: labels.SocialContacts,
        key: 'SocialContacts',
        hasRight:
          CREATE_CLINICAL_PATIENT_DATA_DEMOGRAPHIC ||
          CREATE_CLINICAL_PATIENT_DATA_ANY,
      },
      {
        label: labels.CareTeam,
        key: 'CareTeam',
        hasRight:
          CREATE_CLINICAL_PATIENT_DATA_CAREPROVIDER ||
          CREATE_CLINICAL_PATIENT_DATA_ANY,
      },
      { label: labels.CallLogOnly, key: 'CallLogOnly', hasRight: true },
    ].filter(({ hasRight }) => hasRight);
  }, [rights, labels.SocialContacts, labels.CareTeam, labels.CallLogOnly]);
};

export const useCallDirectionOptions = (labels) => {
  return useMemo(
    () => [
      { label: labels.Incoming, value: true },
      { label: labels.Outgoing, value: false },
    ],
    [labels.Incoming, labels.Outgoing]
  );
};

export const usePhoneTypeOptions = (labels) => {
  return useMemo(
    () => [
      { label: labels.Phone, value: 'PHONE' },
      { label: labels.Mobile, value: 'MOBILE' },
    ],
    [labels.Phone, labels.Mobile]
  );
};
