import { forwardRef } from 'react';
import { MultiViewCalendar } from '@progress/kendo-react-dateinputs';
import { Button } from '../Button';
import { Flexbox } from '../Flexbox';
import PropTypes from 'prop-types';
import { NOOP_FUNCTION } from '../../constants';

export const CustomCalendar = forwardRef((props, ref) => {
  const { okLabel = '', okDisabled = true, onSubmit = NOOP_FUNCTION } = props;

  return (
    <Flexbox direction="column" ref={ref} data-component-name="CustomCalendar">
      <MultiViewCalendar {...props} />
      <Button
        dataElementName="okButton"
        look="default"
        disabled={okDisabled}
        onClick={onSubmit}
      >
        {okLabel}
      </Button>
    </Flexbox>
  );
});

export default CustomCalendar;

CustomCalendar.propTypes = {
  okLabel: PropTypes.string,
  okDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};
