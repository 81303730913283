import { localeFormat } from '../../../../format/luxonToDisplayString';
import { toDisplayDateFromISOString } from '../../../../g11n/displayDates';
import { isoStringToAge } from '../../../../utils/dates';
import { Icon } from '../../../../components';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { ACCOUNT } from '../../../../icons';

import { HeaderLabel } from '../HeaderLabel';
import './styles.css';

export const PatientInfo = (props) => {
  const { DoB, DateOfDeath, FamilyName, GivenName, SexID = '', IPID } = props;
  const labels = useXeLabels();
  const {
    DoB: activeDocumentPatientDoB,
    DateOfDeath: activeDocumentPatientDateOfDeath,
    FamilyName: activeDocumentPatientFamilyName,
    GivenName: activeDocumentPatientGivenName,
    SexID: activeDocumentPatientSexID,
  } = IPID;

  return (
    <div className="patient-info__header">
      <Icon icon={ACCOUNT} />
      <HeaderLabel dataElementName="fullName" type="value">
        {FamilyName || activeDocumentPatientFamilyName},{' '}
        {GivenName || activeDocumentPatientGivenName}
      </HeaderLabel>
      <HeaderLabel dataElementName="sex" type="label">
        ({SexID?.SexID || SexID || activeDocumentPatientSexID})
      </HeaderLabel>
      {(DoB || activeDocumentPatientDoB) && (
        <>
          ,
          <HeaderLabel dataElementName="patientInfo__ageLabel" type="label">
            {labels.Age}
          </HeaderLabel>
          <HeaderLabel dataElementName="age" type="value">
            {isoStringToAge(
              DoB || activeDocumentPatientDoB,
              DateOfDeath || activeDocumentPatientDateOfDeath
            )}
          </HeaderLabel>
        </>
      )}
      {(DoB || activeDocumentPatientDoB) && (
        <>
          ,
          <HeaderLabel dataElementName="patientInfo__bornLabel" type="label">
            {labels.Born}
          </HeaderLabel>
          <HeaderLabel dataElementName="dob" type="value">
            {toDisplayDateFromISOString(
              DoB || activeDocumentPatientDoB,
              localeFormat.MEDIUM
            )}
          </HeaderLabel>
        </>
      )}
    </div>
  );
};
