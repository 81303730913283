import { useEffect, useRef, useState } from 'react';
import {
  Checkbox,
  ExpandablePanel,
  Flexbox,
  Panel,
  Popup,
  TextInput,
  TextArea,
} from '../../components';
import { Footer, FooterButton } from '../Popup/components/Footer';
import { XeHealthPlanPayerWidget } from '../../widgets';
import { useXeLabels } from '../../contexts/XeLabelContext';
import {
  getDetails,
  update,
  add,
} from 'services/clearing-houses/xe-clearing-houses-svc.js';
import clearingHouseRequestSchema from 'services/schemas/com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';
import { castSchema } from '../../schema/schemaCaster';
import { SchemaReducer } from '../../schema/SchemaReducer';
import { withDefaultJSONSchemaReducer } from '../../schema/JSONSchemaReducer';
import {
  defaultBusinessRuleReducer,
  toDefaultValidator,
} from '../../validators/schemaValidators';
import { EMPTY_OBJECT, EMPTY_ARRAY } from '../../constants';
import { identity } from '../../fp/fp';
import { pluck } from '../../fp/object';
import { useXeQuery } from '../../data/useXeQuery';
import { useXeMutation } from '../../data/useXeMutation';
import { useQueryClient } from 'react-query';
import { useXeRefData } from '../../contexts/XeRefDataContext';
import { DropDownList } from '../DropDownList';

const toClearingHouseRequestSchema = castSchema(clearingHouseRequestSchema);

const ClearingHouseRecord = () => {
  const labels = useXeLabels();

  const { ChargeServiceCodeRefCatalog } = useXeRefData();

  return (
    <Flexbox direction="column" className="flex-1">
      <Panel shouldOverflow={false}>
        <Flexbox alignItems="center">
          <TextInput
            className="flex-1 margin-all-small"
            descriptor={labels.Name}
            dataPath="Name"
            required={true}
          />
          <TextInput
            className="flex-1 margin-all-small"
            descriptor={labels.ID}
            dataPath="ClearingHouseID"
            required={true}
          />
          <Checkbox
            className="margin-all-small"
            label={labels.IsDefault}
            dataPath="IsDefault"
          />
          <Checkbox
            className="margin-all-small"
            label={labels.Active}
            dataPath="Active"
          />
        </Flexbox>
      </Panel>
      <ExpandablePanel title={labels.Details}>
        <Flexbox>
          <TextInput
            className="flex-1 margin-all-small"
            descriptor={labels.AuthInfo}
            dataPath="AuthorizationInformation"
            required={true}
          />
          <TextInput
            className="flex-1 margin-all-small"
            descriptor={labels.SecurityInfo}
            dataPath="SecurityInformation"
            required={true}
          />
        </Flexbox>
        <Flexbox className="flex-1" alignItems="center">
          <TextArea
            className="flex-1 margin-all-small"
            descriptor={labels.Description}
            dataPath="Description"
            required={true}
          />
          <DropDownList
            data={ChargeServiceCodeRefCatalog}
            labelFn={pluck('text')}
            valueFn={pluck('id')}
            className="flex-1 margin-all-small"
            descriptor={labels.Catalog}
            dataPath="CatalogID"
            required={true}
          />
        </Flexbox>
      </ExpandablePanel>
      <ExpandablePanel title={labels.Payer}>
        <Flexbox>
          <TextInput
            className="flex-1 margin-all-small"
            descriptor={labels.ID}
            dataPath="ClearingHousePayerID"
            required={true}
          />
          <XeHealthPlanPayerWidget
            className="flex-1 margin-all-small"
            descriptor={labels.HealthPlanPayer}
            dataPath="HealthPlanPayerID"
            required={true}
          />
        </Flexbox>
      </ExpandablePanel>
      <ExpandablePanel title={labels.Submitter}>
        <Flexbox className="flex-1 margin-all-small">
          <Flexbox className="flex-1">
            <TextInput
              className="flex-1 margin-all-small"
              descriptor={labels.SubmitterID}
              dataPath="SubmitterID"
              required={true}
            />
          </Flexbox>
          <Flexbox className="flex-1">
            <TextInput
              className="flex-1 margin-all-small"
              descriptor={labels.SubmitterName}
              dataPath="SubmitterName"
              required={true}
            />
          </Flexbox>
        </Flexbox>
        <Flexbox className="flex-1 margin-all-small">
          <Flexbox className="flex-1">
            <TextInput
              dataPath="SecurityInformationQualifier"
              descriptor={labels.SecurityInformationQualifier}
              required={true}
            />
          </Flexbox>
          <Flexbox className="flex-1">
            <TextInput
              dataPath="InterchangeIDQualifier"
              descriptor={labels.InterchangeQualifier}
              required={true}
            />
          </Flexbox>
        </Flexbox>
      </ExpandablePanel>
      <ExpandablePanel title={labels.Receiver}>
        <Flexbox className="flex-1 margin-all-small">
          <Flexbox className="flex-1">
            <TextInput
              className="flex-1 margin-all-small"
              descriptor={labels.ReceiverID}
              dataPath="ReceiverID"
              required={true}
            />
          </Flexbox>
          <Flexbox className="flex-1">
            <TextInput
              className="flex-1 margin-all-small"
              descriptor={labels.ReceiverName}
              dataPath="ReceiverName"
              required={true}
            />
          </Flexbox>
        </Flexbox>
        <Flexbox className="flex-1 margin-all-small">
          <TextInput
            className="flex-1"
            descriptor={labels.ReceiverIdentificationCode}
            dataPath="ReceiverIdentificationCode"
            required={true}
          />
        </Flexbox>
      </ExpandablePanel>
      <ExpandablePanel title={labels.SFTP}>
        <Flexbox className="flex-1 margin-all-small">
          <Flexbox className="flex-1">
            <TextInput
              descriptor={labels.URL}
              dataPath="SftpServerURL"
              type="text"
              required={true}
            />
          </Flexbox>
          <Flexbox className="flex-1">
            <TextInput
              descriptor={labels.Directory}
              dataPath="SftpServerDirectory"
              type="text"
              required={true}
            />
          </Flexbox>
        </Flexbox>
      </ExpandablePanel>
    </Flexbox>
  );
};

const CLEARING_HOUSE_RECORD_FORM_RULES = [
  [pluck('Name'), defaultBusinessRuleReducer],
];

const validator = toDefaultValidator(CLEARING_HOUSE_RECORD_FORM_RULES);

export const ClearingHouseRecordPopupView = (props) => {
  const { onClose, onSaveClose, ClearingHouseID, title } = props;

  const [validityState, setValidityState] = useState(EMPTY_OBJECT);
  const labels = useXeLabels();

  const { valid, changed, instance } = validityState;

  const {
    data: [clearingHouseRecordData = EMPTY_OBJECT] = EMPTY_ARRAY,
    isFetching: isFetchingClearingHouseRecord,
  } = useXeQuery(getDetails({ clearingHouseId: ClearingHouseID }, identity), {
    enabled: !!ClearingHouseID,
  });

  const queryClient = useQueryClient();

  const initialClearingHouseRecordFormData = useRef();

  useEffect(() => {
    if (!ClearingHouseID) {
      initialClearingHouseRecordFormData.current = EMPTY_OBJECT;
      return;
    }

    if (
      !isFetchingClearingHouseRecord &&
      !initialClearingHouseRecordFormData.current
    ) {
      initialClearingHouseRecordFormData.current = {
        ...toClearingHouseRequestSchema(clearingHouseRecordData),
        HealthPlanPayerID: pluck(
          'HealthPlanPayerID',
          'HealthPlanPayerID'
        )(clearingHouseRecordData),
      };
    }
  }, [
    ClearingHouseID,
    clearingHouseRecordData,
    initialClearingHouseRecordFormData,
    isFetchingClearingHouseRecord,
  ]);

  const updateDetailsMutation = useXeMutation(
    ({ clearingHouseRecordData, ClearingHouseID } = EMPTY_OBJECT) =>
      update(
        toClearingHouseRequestSchema(clearingHouseRecordData),
        { clearingHouseId: ClearingHouseID },
        identity
      ),
    {
      onSuccess: () => {
        onSaveClose();
        queryClient.invalidateQueries(['xe-clearing-houses-svc', 'getDetails']);
      },
    }
  );

  const addNewMutation = useXeMutation(
    (clearingHouseRecordData) =>
      add(
        toClearingHouseRequestSchema(clearingHouseRecordData),
        EMPTY_OBJECT,
        identity
      ),
    {
      onSuccess: () => {
        onSaveClose();
      },
    }
  );

  const onSubmit = () => {
    if (ClearingHouseID) {
      updateDetailsMutation.mutate({
        clearingHouseRecordData: instance,
        ClearingHouseID,
      });
    } else {
      addNewMutation.mutate(instance);
    }
  };

  return !isFetchingClearingHouseRecord ? (
    <Popup size="large" title={title}>
      <SchemaReducer
        schema={clearingHouseRequestSchema}
        initialValue={initialClearingHouseRecordFormData.current}
        toJsonReducer={withDefaultJSONSchemaReducer(validator)}
        onChange={setValidityState}
      >
        <ClearingHouseRecord clearingHouseRecordData={instance} />
      </SchemaReducer>
      <Footer>
        <FooterButton onClick={onClose}>{labels.Close}</FooterButton>
        <FooterButton disabled={!changed || !valid} onClick={onSubmit}>
          {labels.Submit}
        </FooterButton>
      </Footer>
    </Popup>
  ) : null;
};

export default ClearingHouseRecordPopupView;
