import { combineEpics } from 'redux-observable';
import { useReducer$ } from '../../hooks/useReducer$';
import { useMenuNode } from '../../contexts/XeMenuNodeContext';
import { useEffect$ } from '../../hooks/useEffect$';
import { useRef$ } from '../../hooks/useRef$';
import { schemaGet } from '../../schema/schemaTypeBuilder';
import { SchemaReducer, useSchemaDispatch } from '../../schema/SchemaReducer';
import {
  withDefaultJSONSchemaReducer,
  UPSERT,
} from '../../schema/JSONSchemaReducer';
import { transformSchema } from '../../schema/schemaTransformer';
import { isNil, isEmptyObject } from '../../fp/pred';
import { pluck as objPluck } from '../../fp/object';
import { useCallback, useState, useEffect, useMemo } from 'react';
import { ofType } from 'redux-observable';
import { combineLatest, of } from 'rxjs';
import { map, pluck, startWith, tap } from 'rxjs/operators';
import XeScanDocSetItemSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$XeScanDocSet.json';
import XeScanDocSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json';
import XeScanDocUpdateSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocUpdate.json';
import {
  Popup,
  Panel,
  toDefaultPopupFooter,
  GridLayout,
  Label,
  Flexbox,
} from '../';
import { useEnterprise } from '../../contexts/XeEnterpriseContext';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { useXePatient } from '../../contexts/XePatientContext';
import { castFunction } from '../../fp/fp';
import {
  defaultBusinessRuleReducer,
  toDefaultValidator,
} from '../../validators/schemaValidators';
import {
  PERFORM_FILE_ACTION,
  RESPONSE_ADD_FILE,
  RESPONSE_FETCH_FILE,
  RESPONSE_FETCH_SCAN_DOC,
  RESPONSE_SPLIT_FILE,
  RESPONSE_UPDATE_FILE,
  SHOULD_CLOSE,
  UPDATE_UPLOADED_ATTACHMENT,
  UPLOAD_ATTACHMENT,
  REQUEST_DOWNLOAD_ITEM,
  SHOULD_SPLIT_PDF,
  REQUEST_FILE,
  REQUEST_REMOVE_FILE,
  SHOULD_UPDATE_FILE,
  SHOULD_UPDATE_IPID_DATA,
} from './actions';
import { UpdateSet } from './components/UpdateSet';
import { AttachmentGrid } from './components/AttachmentGrid';
import { FileDetailsPanel } from './components/FileDetailsPanel';
import { FileSummaryHeader } from './components/FileSummaryHeader';
import epics from './epics';
import './styles.css';
import { useXeRefData } from '../../contexts/XeRefDataContext';
import { EMPTY_OBJECT } from '../../constants';

/**
 * @typedef AttachmentPopupState
 * @property {any} pdfFileData
 */

/**
 * @typedef SplitItem
 * @property {number} StartPage
 * @property {number} EndPage
 * @property {string} DocumentTypeID
 * @property {string} CategoryID
 * @property {number} StatusCode
 * @property {string} localId
 * @property {boolean} valid
 */

/**
 * @type {import('react').Reducer<AttachmentPopupState, { type: string, value?: any }>}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case RESPONSE_FETCH_FILE: {
      const { value } = action;
      return {
        ...state,
        pdfFileData: value,
      };
    }
    case RESPONSE_FETCH_SCAN_DOC: {
      const { value = {} } = action;
      return {
        ...state,
        initialScanDoc: value,
      };
    }
    case UPDATE_UPLOADED_ATTACHMENT: {
      const { value } = action;
      return { ...state, uploadedAttachment: value };
    }
    case SHOULD_UPDATE_IPID_DATA: {
      const { value } = action;
      return { ...state, ipidData: value };
    }
    default:
      return state;
  }
};

const epic = combineEpics(...epics);

const RULES = [
  [
    ({ DocTypeID } = {}) => DocTypeID && !isEmptyObject(DocTypeID),
    defaultBusinessRuleReducer,
  ],
  [
    ({ ScanDocID, XeScanDocSet = [] } = {}) =>
      ScanDocID || (!ScanDocID && !!XeScanDocSet.length),
    () => ({
      ScanDocID: {
        message: 'ScanDocID Bad',
      },
    }),
  ],
  [
    ({ XeScanDocSet = [] } = {}) =>
      XeScanDocSet.every(
        ({ Active, CategoryID, DocumentTypeID } = {}) =>
          Active &&
          CategoryID &&
          DocumentTypeID &&
          !isEmptyObject(CategoryID) &&
          !isEmptyObject(DocumentTypeID)
      ),
    () => ({
      XeScanDocSet: {
        message: 'Set Bad',
      },
    }),
  ],
];
const validator = toDefaultValidator(RULES);

export const AttachmentPopup = (props) => {
  const {
    docTypeId,
    scanDocId,
    itemId,
    fileId,
    onClose,
    onSave,
    comments,
    ipid,
    enterpriseId,
  } = props;

  const labels = useXeLabels();

  const itemId$ = useRef$(itemId);
  const scanDocId$ = useRef$(scanDocId);
  const fileId$ = useRef$(fileId);
  const comments$ = useRef$(comments || '');
  const docTypeId$ = useRef$(docTypeId || '');
  const enterpriseId$ = useRef$(enterpriseId);
  const ipid$ = useRef$(ipid);

  const menuNode = useMenuNode();
  const enterpriseContext = useEnterprise();
  const patientContext = useXePatient();

  const customReducer = (state, action = {}) => {
    const { type, path } = action;
    const { instance } = state;

    switch (path) {
      case 'DocTypeID':
        {
          if (type === UPSERT) {
            const { XeScanDocSet = [] } = instance;
            const updatedScanDocSet = XeScanDocSet.map((item) => ({
              ...item,
              CategoryID: undefined,
              DocumentTypeID: undefined,
            }));
            return {
              ...state,
              instance: { ...instance, XeScanDocSet: updatedScanDocSet },
            };
          }
        }
        break;
    }

    return state;
  };
  const memoEpic = useCallback(
    (action$, state$) =>
      epic(action$, state$, {
        menuNode$: of(menuNode),
        itemId$,
        fileId$,
        enterpriseId$,
        enterpriseContext$: of(enterpriseContext),
        docTypeId$,
        comments$,
        scanDocId$,
        ipid$: combineLatest(
          ipid$.pipe(startWith(undefined)),
          of(patientContext).pipe(pluck('ipid'), startWith(undefined))
        ).pipe(map(([propIpid, contextIpid]) => propIpid || contextIpid)),
      }),
    [
      menuNode,
      itemId$,
      fileId$,
      enterpriseId$,
      enterpriseContext,
      docTypeId$,
      comments$,
      ipid$,
      scanDocId$,
      patientContext,
    ]
  );

  const [state = {}, dispatch, action$] = useReducer$(reducer, memoEpic);

  useEffect$(() =>
    action$.pipe(ofType(SHOULD_CLOSE), tap(castFunction(onClose)))
  );
  useEffect$(() =>
    action$.pipe(
      ofType(RESPONSE_ADD_FILE, RESPONSE_UPDATE_FILE, RESPONSE_SPLIT_FILE),
      pluck('value'),
      tap(castFunction(onSave))
    )
  );

  const [attachment, updateAttachment] = useState();
  const [canSave, updateCanSave] = useState(false);

  const { initialScanDoc } = state;

  const confirmLabelKey = (() => {
    if (!itemId) return 'Save';
    return 'Update';
  })();

  if (!initialScanDoc) return null;

  return (
    <Popup
      dataElementName="attachmentGrid__addAttachmentPopup"
      title={labels.AddAttachment}
      size="large"
      className="attachment-popup"
      FooterComponent={toDefaultPopupFooter({
        onClose: () =>
          dispatch({
            type: SHOULD_CLOSE,
          }),
        onConfirm: () => {
          return dispatch({
            type: PERFORM_FILE_ACTION,
            value: attachment,
          });
        },
        confirmLabelKey,
        disableConfirm: !canSave,
      })}
    >
      <SchemaReducer
        initialValue={initialScanDoc}
        schema={XeScanDocSchema}
        onChange={(params) => {
          const { instance, valid } = params;
          updateAttachment(instance);
          updateCanSave(valid);
        }}
        toJsonReducer={withDefaultJSONSchemaReducer(customReducer, validator)}
      >
        <Attachment
          {...props}
          {...state}
          dispatch={dispatch}
          attachment={attachment}
        />
      </SchemaReducer>
    </Popup>
  );
};

const Attachment = (props) => {
  const {
    dispatch,
    attachment = EMPTY_OBJECT,

    // state
    pdfFileData,
    uploadedAttachment,
    initialScanDoc,
    ipidData,

    // props
    docTypeId,
    scanDocId,
    itemId,
    fileId,
    ipid,
  } = props;

  const {
    ScanDocID = scanDocId,
    XeScanDocSet = [],
    DocTypeID = docTypeId,
  } = attachment || {};
  const { StatusCode } = initialScanDoc || {};

  const schemaDispatch = useSchemaDispatch();
  const labels = useXeLabels();
  const { XeScanDocTypeCategory } = useXeRefData();

  // TODO: (SYNUI-5750) These 3 `useEffect`s are code smell.
  // Needs discussion on how we can achieve the same net result without needing to do this (JDM)
  useEffect(() => {
    if (uploadedAttachment) {
      schemaDispatch({
        type: UPSERT,
        path: 'XeScanDocSet',
        value: [...XeScanDocSet, uploadedAttachment],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedAttachment, schemaDispatch]);

  useEffect(() => {
    if (ipid) {
      schemaDispatch({
        type: UPSERT,
        path: 'IPID',
        value: ipid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipid, schemaDispatch]);

  const [viewBrowse, setViewBrowse] = useState(false);
  const [viewUpdate, setViewUpdate] = useState(false);

  const [splitObject, setSplitObject] = useState();
  const { splitData = [], fileData = {} } = splitObject || {};
  const canConfirm = fileData.CategoryID && fileData.DocumentTypeID;

  const docTypeIdString =
    typeof DocTypeID === 'string'
      ? DocTypeID
      : objPluck('DocTypeID')(DocTypeID);

  const selectedType = useMemo(() => {
    return XeScanDocTypeCategory.find(({ id } = {}) => {
      return id === docTypeIdString;
    });
  }, [XeScanDocTypeCategory, docTypeIdString]);

  const categories = objPluck('item', 'XeUploadCategory')(selectedType) || [];

  return (
    <>
      <GridLayout templateRows="max-content 1fr" className="flex-1">
        <FileSummaryHeader
          hideAdd={!isNil(itemId) || !isNil(fileId)}
          onAddFile={(files) =>
            dispatch({
              type: UPLOAD_ATTACHMENT,
              value: files,
            })
          }
          onBrowse={() => setViewBrowse(true)}
          onShowUpdate={() => {
            dispatch({ type: REQUEST_FILE });
            setViewUpdate(true);
          }}
          data={attachment}
          docTypeId={docTypeId}
          scanDocId={scanDocId}
          statusCode={StatusCode}
          ipid={ipid}
          ipidData={ipidData}
        />
        <Panel
          dataElementName="attachmentPopup__currentSet"
          Header={<div className="padding-all-medium">{labels.CurrentSet}</div>}
          className="attachment-popup__current-set"
          childrenClassName="attachment-popup__current-set-content"
        >
          {!!XeScanDocSet.length ? (
            <GridLayout templateColumns="1fr 1fr">
              {XeScanDocSet.map((item, index) => {
                const active = schemaGet(
                  XeScanDocSetItemSchema,
                  'Active',
                  item
                );
                const isInError = schemaGet(
                  XeScanDocSetItemSchema,
                  'IsInError',
                  item
                );
                const documentTypeId = schemaGet(
                  XeScanDocSetItemSchema,
                  'DocumentTypeID',
                  item
                );
                const thisItemId = objPluck('ItemID')(item);
                if (!active || isInError) return null;

                return (
                  <FileDetailsPanel
                    dataElementName={`attachmentPopup__fileDetailsPanel${index}`}
                    categories={categories}
                    onChange={(data) => {
                      if (data) {
                        schemaDispatch({
                          type: UPSERT,
                          path: 'XeScanDocSet',
                          value: [
                            ...XeScanDocSet.slice(0, index),
                            data,
                            ...XeScanDocSet.slice(index + 1),
                          ],
                        });
                      }
                    }}
                    onDownload={(value) =>
                      dispatch({ type: REQUEST_DOWNLOAD_ITEM, value })
                    }
                    onMarkInError={(ErrorDescription) => {
                      schemaDispatch({
                        type: UPSERT,
                        path: 'XeScanDocSet',
                        value: XeScanDocSet.filter((v, i) => i !== index),
                      });

                      if (
                        ScanDocID &&
                        XeScanDocSet.filter(({ Active }) => Active).length === 1
                      ) {
                        schemaDispatch({
                          type: UPSERT,
                          path: 'Active',
                          value: false,
                        });
                      }

                      if (thisItemId) {
                        dispatch({
                          type: REQUEST_REMOVE_FILE,
                          value: {
                            ...transformSchema(
                              XeScanDocSetItemSchema,
                              XeScanDocUpdateSchema
                            )(item),
                            Active: false,
                            IsInError: true,
                            ErrorDescription,
                          },
                        });
                      }
                    }}
                    key={`${docTypeIdString}_${thisItemId || index}`}
                    file={item}
                    documentTypeId={documentTypeId}
                  />
                );
              })}
            </GridLayout>
          ) : (
            <Flexbox
              alignItems="center"
              justifyContent="center"
              className="flex-1"
            >
              <Label className="bold">
                {scanDocId
                  ? labels.BrowseExistingorUploadFileMessage
                  : labels.UploadFileMessage}
              </Label>
            </Flexbox>
          )}
        </Panel>
      </GridLayout>
      {viewBrowse && (
        <Popup
          dataElementName="attachmentGrid__browserExistingFilesPopup"
          title={labels.BrowseExistingFiles}
          size="large"
          className="attachment-popup"
          FooterComponent={toDefaultPopupFooter({
            onClose: () => setViewBrowse(false),
          })}
        >
          <AttachmentGrid
            ipid={ipid}
            categories={categories}
            scanDocSet={XeScanDocSet}
            onAdd={() => {
              setViewBrowse(false);
            }}
          />
        </Popup>
      )}
      {viewUpdate && (
        <Popup
          title={labels.UpdateInSet}
          size="large"
          FooterComponent={toDefaultPopupFooter({
            onClose: () => setViewUpdate(false),
            onConfirm: () => {
              setViewUpdate(false);

              if (splitData.length) {
                return dispatch({
                  type: SHOULD_SPLIT_PDF,
                  value: { ...splitObject, attachment },
                });
              }

              return dispatch({
                type: SHOULD_UPDATE_FILE,
                value: {
                  ...attachment,
                  XeScanDocSet: XeScanDocSet.map((item = {}) =>
                    item.ItemID === fileData.ItemID ? fileData : item
                  ),
                },
              });
            },
            disableConfirm: !canConfirm,
          })}
        >
          <UpdateSet
            attachment={attachment}
            itemId={itemId}
            pdfFileData={pdfFileData}
            categories={categories}
            dispatch={dispatch}
            onChange={setSplitObject}
          />
        </Popup>
      )}
    </>
  );
};

export default AttachmentPopup;
