import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import uniqid from 'uniqid';
import { Flexbox } from '../../components/Flexbox';
import { castFunction } from '../../fp/fp';
import { isNumber } from '../../fp/pred';
import GridFooter from './GridFooter';
import { autoGroupColumnDef, useColumnDefsSet } from './utils';
import { CellRendererContext } from './contexts/CellRendererContext';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';
import { withEllipsis } from '../../utils';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { AgGridReact } from 'ag-grid-react';
/*eslint-disable no-unused-vars*/
import { AgGridEvent, AllModules, GridApi } from 'ag-grid-enterprise';
/*eslint-enable no-unused-vars*/

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-balham.css';
import './styles.css';

// With the upgrade to AgGrid v26 and turning on their React UI option, they do not currently support
// React components for the loading and no rows overlay, hence the below code.
class GridOverlay {
  init({ message }) {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `<span class="xjs-label overlay-label">${message}</span>`;
  }

  getGui() {
    return this.eGui;
  }
}

const toIcon = (name, className) =>
  `<i class="mdi mdi-${name} md-12 ${className}" />`;

/**
 *
 * @param {Object} The horizontal pixel range of the current scroll of the grid
 * @param {Array} columnState Array of AG-Grid Column Definitions
 * @returns {number} Returns the amount of excess space
 */
const getExcessSpace = (scrollRange, columnState) => {
  const { left, right } = scrollRange;

  const gridWidth = right - left;
  const columnTotalWidth = columnState
    .filter(({ hide, pinned } = { hide: false }) => hide == false && !pinned)
    .reduce((total, { width } = { width: 0 }) => width + total, 0);

  return Math.max(gridWidth - columnTotalWidth, 0);
};

/**
 * @typedef {import('react').ReactNode} ReactNode
 */

/**
 * @typedef GridProps
 * @property {Array<any>} data
 * @property {import('./utils').XnetColDef[]} columnDefs
 * @property {CSSStyleDeclaration=} style
 * @property {ReactNode=} GridHeader
 * @property {ReactNode=} FooterComponent
 * @property {Boolean=} alwaysShowFooter
 * @property {Number=} timestamp
 * @property {Boolean=} condensed
 * @property {Array<String>=} omitColumns
 * @property {Array<String>=} onlyColumns
 * @property {Object} cellRendererContext
 */
/**
 * @param {Omit<import('@ag-grid-community/react').AgGridReactProps, "columnDefs"> & GridProps & import('./AgGrid').CustomAgGridReactProps} props
 */
export const Grid = (props) => {
  const {
    dataElementName = '',
    data,
    columnDefs,
    GridHeader = null,
    className = '',
    style = EMPTY_OBJECT,
    timestamp,
    FooterComponent = null,
    onlyColumns,
    omitColumns,
    condensed = false,
    cellRendererContext,
    detailCellRendererParams,
    onGridReady,
    onGridSizeChanged,
    onRowDataChanged,
    onRowDataUpdated,
    onColumnRowGroupChanged,
    isLoading,
    // TODO: given that this is only being passed from
    // Dashboard's usage of Grid, we will need to leave this
    // defaulted to true for the cases where we are using Grid
    // outside of Dashboard to keep functionality as is.
    // Otherwise, the "No Results" message
    // will never show. Eventually this will change
    // so that all components using Grids will define this prop
    // but in the meantime, defaulting to prevent regressions
    // (GCH) (JCh) SYNUI-6384
    hasSearched = true,
    suppressDragLeaveHidesColumns = true,
    gridModel = EMPTY_OBJECT,
    pagedRowCount,
    ...gridProps
  } = props;

  const labels = useXeLabels();

  const gridIdRef = useRef(uniqid());
  const gridRef = useRef(null);

  const {
    rowModelType = 'clientSide',
    decoratedColumnDefs,
    ...gridModelProps
  } = gridModel;

  const dataCount =
    rowModelType == 'clientSide' ? (data || EMPTY_ARRAY).length : pagedRowCount;

  const loadingLabel = labels.Loading;
  const noResultsLabel = labels.NoResults;
  const clickSearchToFindResultsLabel = labels.ClickSearchToFindResults;
  const isLoadingLabel = labels.Loading;

  useEffect(() => {
    if (gridRef.current) {
      /**
       * @type {import('ag-grid-enterprise').GridApi}
       */
      const gridApi = gridRef.current.api;
      if (isLoading && loadingLabel) {
        gridApi.showLoadingOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [isLoading, loadingLabel, dataCount, condensed]);

  // When upgrading to AgGrid v26 and turning on their React UI option, I had to refactor how the overlays
  // were done, since for the moment, we can't use React components for the overlays.
  // This resulted in a timing issue, where we'd request the necessary labels, but weren't retrying to display
  // the overlays after getting back that information, hence the resulting code below (JCM)
  useEffect(() => {
    if (gridRef.current) {
      /**
       * @type {import('ag-grid-enterprise').GridApi}
       */
      const gridApi = gridRef.current.api;
      if (data && !dataCount && noResultsLabel) gridApi.showNoRowsOverlay();
      // TODO: we need to figure out how the No Results overlay should actually intersect with paging
      // but for now, this is so it's not covering up the results
      if (rowModelType === 'serverSide') gridApi.hideOverlay();
    }
  }, [noResultsLabel, data, dataCount, rowModelType]);

  const hasFetchedAllColumnLabels = columnDefs.every(({ headerLabelKey }) => {
    return headerLabelKey ? !!labels[headerLabelKey] : true;
  });

  const columnDefsSet = useColumnDefsSet(
    decoratedColumnDefs || columnDefs,
    labels,
    {
      only: onlyColumns,
      omit: omitColumns,
      condensed,
    }
  );

  const detailGridColumnDefs = useColumnDefsSet(
    detailCellRendererParams?.detailGridOptions?.columnDefs,
    labels
  );
  const detailCellRendererParamsWithLabels = detailGridColumnDefs.length
    ? {
        ...detailCellRendererParams,
        detailGridOptions: {
          ...detailCellRendererParams.detailGridOptions,
          columnDefs: detailGridColumnDefs,
        },
      }
    : detailCellRendererParams;

  /**
   * @param {AgGridEvent} ev
   */
  const resizeGridColumns = (ev) => {
    const { api, columnApi } = ev;

    if (!api || rowModelType === 'serverSide') return;

    columnApi.autoSizeAllColumns();

    const excessSpace = getExcessSpace(
      api.getHorizontalPixelRange(),
      columnApi.getColumnState()
    );
    if (excessSpace) {
      // If we have excess space in the grid, we are going to extend the last column (that isn't pinned)
      // to fill out the rest of the space
      const columns = columnApi
        .getColumns()
        .filter(({ pinned, visible }) => !pinned && visible);
      const lastColumn = columns[columns.length - 1];

      if (!lastColumn) return;

      const { colId, actualWidth } = lastColumn;
      columnApi.setColumnWidth(colId, actualWidth + excessSpace);
    }
  };

  const noRowsMessage = isLoading
    ? isLoadingLabel
    : hasSearched
    ? noResultsLabel
    : clickSearchToFindResultsLabel;

  return (
    <CellRendererContext.Provider value={cellRendererContext}>
      <div
        style={style}
        className={`xjs-grid display-grid flex-1 ${className}`}
      >
        {GridHeader}
        <div
          data-element-name={dataElementName}
          data-component-name="AgGrid"
          className={`ag-custom ag-theme-balham ag-grid-wrapper ag-row-hover flex-1`}
          key={`${gridIdRef.current}_${condensed}_${rowModelType}`}
        >
          <AgGridReact
            rowHeight={30}
            suppressCellFocus
            suppressRowClickSelection
            suppressRowHoverClass
            suppressLoadingOverlay={false}
            suppressDragLeaveHidesColumns={suppressDragLeaveHidesColumns}
            rowGroupPanelShow={condensed ? 'onlyWhenGrouping' : 'always'}
            columnDefs={
              (dataCount || rowModelType === 'serverSide') &&
              hasFetchedAllColumnLabels
                ? columnDefsSet
                : EMPTY_ARRAY
            }
            rowData={data}
            autoGroupColumnDef={autoGroupColumnDef}
            detailCellRendererParams={detailCellRendererParamsWithLabels}
            icons={{
              sortAscending: toIcon('arrow-up', 'ascending blue-color'),
              sortDescending: toIcon('arrow-down', 'descending blue-color'),
              groupExpanded: toIcon(
                'triangle',
                'rotate-flip triangle-down black-color'
              ),
              groupContracted: toIcon(
                'triangle',
                'rotate-right triangle-right black-color'
              ),
              columnGroupClosed: toIcon(
                'triangle',
                'rotate-left triangle-left black-color'
              ),
              columnGroupOpened: toIcon(
                'triangle',
                'rotate-right triangle-right black-color'
              ),
            }}
            /* Previous way of defining components was not working, 
            turned to this implementation to avoid console warnings - NDS */
            loadingOverlayComponent={GridOverlay}
            noRowsOverlayComponent={GridOverlay}
            loadingOverlayComponentParams={{
              message: isLoading ? withEllipsis(labels.Loading) : 'No Results',
            }}
            noRowsOverlayComponentParams={{
              message: noRowsMessage,
            }}
            onGridSizeChanged={(ev) => {
              resizeGridColumns(ev);
              castFunction(onGridSizeChanged)(ev);
            }}
            onRowDataChanged={(ev) => {
              resizeGridColumns(ev);
              castFunction(onRowDataChanged)(ev);
            }}
            onRowDataUpdated={(ev) => {
              resizeGridColumns(ev);
              castFunction(onRowDataUpdated)(ev);
            }}
            onRowGroupOpened={(ev) => {
              resizeGridColumns(ev);
              castFunction(props.onRowGroupOpened)(ev);
            }}
            onColumnRowGroupChanged={(ev) => {
              resizeGridColumns(ev);
              castFunction(onColumnRowGroupChanged)(ev);
            }}
            onGridReady={(ev) => {
              gridRef.current = ev;
              castFunction(onGridReady)(ev);
            }}
            modules={AllModules}
            defaultColDef={{
              resizable: true,
            }}
            rowModelType={rowModelType}
            {...gridModelProps}
            {...gridProps}
          />
        </div>
        {(isNumber(timestamp) || FooterComponent) && (
          <Flexbox
            justifyContent="space-between"
            alignItems="center"
            className="flex-0"
          >
            {isNumber(timestamp) && (
              <GridFooter
                dataElementName={
                  dataElementName !== ''
                    ? `${dataElementName}__footer`
                    : 'grid__footer'
                }
                count={dataCount}
                timestamp={timestamp}
              />
            )}
            {FooterComponent}
          </Flexbox>
        )}
      </div>
    </CellRendererContext.Provider>
  );
};

Grid.propTypes = {
  dataElementName: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  columnDefs: PropTypes.array.isRequired,
  GridHeader: PropTypes.node,
  FooterComponent: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  timestamp: PropTypes.number,
  onGridReady: PropTypes.func,
  onlyColumns: PropTypes.array,
  omitColumns: PropTypes.array,
  condensed: PropTypes.bool,
};

export default Grid;
