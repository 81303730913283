import { ofType } from '../../frp/operators/ofType';
import { combineWithLatestFrom } from '../../frp/operators/combineWithLatestFrom';
import { castSchema } from '../../schema/schemaCaster';
import { map, mapTo, mergeMap, pluck, withLatestFrom } from 'rxjs/operators';
import {
  RESPONSE_ASSIGN_CANDIDATES,
  REQUEST_OWNER_CHANGE,
  RESPONSE_OWNER_CHANGE,
  REQUEST_OWNER_RELEASE,
  RESPONSE_OWNER_RELEASE,
  REQUEST_ASSIGN_CANDIDATES,
} from './actions';

import WorkStateOwnerSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.work.WorkStateOwner.json';
import {
  clearStateOwner,
  setStateOwner,
  getPotentialOwners,
} from 'services/work-states/xe-work-states-svc';

const toWorkStateOwner = castSchema(WorkStateOwnerSchema);

const changeOwners$ = (action$, state$, { menuNode$ }) =>
  action$.pipe(
    ofType(REQUEST_OWNER_CHANGE),
    pluck('value'),
    withLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([requestBody, toRequest]) => {
      return setStateOwner(
        toWorkStateOwner(requestBody),
        {},
        toRequest({ fullRequest: true })
      );
    }),
    pluck('results'),
    map(([response]) => ({
      type: RESPONSE_OWNER_CHANGE,
      value: response,
    }))
  );

const releaseOwner$ = (action$, state$, { menuNode$ }) =>
  action$.pipe(
    ofType(REQUEST_OWNER_RELEASE),
    pluck('value'),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([requestBody, toRequest]) => {
      return clearStateOwner(
        toWorkStateOwner(requestBody),
        {},
        toRequest({ fullRequest: true })
      );
    }),
    pluck('results'),
    map((response) => ({
      type: RESPONSE_OWNER_RELEASE,
      value: response,
    }))
  );

const getAssignmentCandidates$ = (
  action$,
  state$,
  { menuNode$, processingTypeID$ }
) =>
  action$.pipe(
    ofType(REQUEST_ASSIGN_CANDIDATES),
    pluck('value'),
    withLatestFrom(
      processingTypeID$,
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([{ WorkflowID, WorkState }, processingTypeID, toRequest$]) => {
      return getPotentialOwners(
        {
          workflowId: WorkflowID,
          workState: WorkState,
          processingTypeId: processingTypeID,
        },
        toRequest$()
      );
    }),
    map((options) => ({
      type: RESPONSE_ASSIGN_CANDIDATES,
      value: options,
    }))
  );

const mapOwnerReleaseToEmptyValue$ = (action$) => {
  return action$.pipe(
    ofType(RESPONSE_OWNER_RELEASE),
    mapTo({
      type: RESPONSE_OWNER_CHANGE,
      value: {
        StateOwner: undefined,
        StateOwnerExpire: undefined,
        StateOwnerName: undefined,
      },
    })
  );
};

export default [
  getAssignmentCandidates$,
  mapOwnerReleaseToEmptyValue$,
  releaseOwner$,
  changeOwners$,
];
