import { ofType } from '../../frp/operators/ofType';
import { exists } from '../../frp/operators/exists';
import { combineWithLatestFrom } from '../../frp/operators/combineWithLatestFrom';
import { ofValue } from '../../frp/operators/ofValue';
import { pluck as objPluck, put } from '../../fp/object';
import { schemaPipe, schemaGet } from '../../schema/schemaTypeBuilder';
import { combineLatest, merge } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pluck,
  withLatestFrom,
} from 'rxjs/operators';
import { browse as getTypes } from 'services/assessment-catalogs/xe-assessment-catalogs-svc';
import {
  enter as updateCallLog,
  getDetails,
  insert as addCallLog,
} from 'services/call-logs/xe-call-logs-svc';
import { insert as addClinicalContact } from 'services/patient-providers/xe-patient-providers-svc';
import { insert as addSocialContact } from 'services/patient-contacts/xe-patient-contacts-svc';
import { browse } from 'services/patient-visits/xe-patient-visits-svc';
import { getPhoneContacts } from 'services/patients/xe-patients-svc';
import AddCallLogRequestSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogRequest.json';
import PatientProviderAddRequestSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddRequest.json';
import {
  RESPONSE_ADD_CALL_LOG,
  RESPONSE_CALL_LOG_DETAIL,
  RESPONSE_CALL_LOG_TYPES,
  RESPONSE_PATIENT_VISITS,
  RESPONSE_ADD_PERSONAL_CONTACT,
  RESPONSE_ADD_CLINICAL_CONTACT,
  RESPONSE_PHONE_CONTACTS,
  SHOULD_ADD_CALL_LOG,
  SHOULD_ADD_CLINICAL_CONTACT,
  SHOULD_ADD_PERSONAL_CONTACT,
  SHOULD_UPDATE_CALL_LOG,
  SHOULD_REFRESH_PHONE_CONTACTS,
} from './actions';

const purposeToContentTypeID = {
  AUTHORIZATION: 'AUTH_OUTREACH',
  CASE_MGMT: 'CM_OUTREACH',
  ISSUE: 'ISSUE_OUTREACH',
  GENERAL: 'TEL_OUTREACH',
  PROGRAM: 'PROGRAM_OUTREACH',
};

const purposeToVisitTypeID = {
  AUTHORIZATION: 'AUTH',
  CASE_MGMT: 'CMGMT',
  ISSUE: 'ISSUE',
  PROGRAM: 'PROGRAM',
};

const getCallLogTypes$ = (action$, state$, { menuNode$ }) =>
  action$.pipe(
    ofType(SHOULD_UPDATE_CALL_LOG),
    pluck('value', 'CallType'),
    exists(),
    distinctUntilChanged(),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([purpose, toRequest$]) =>
      getTypes(
        {
          catalogTypeId: 'TELEPHONIC|ANY',
          catContentTypeId: purposeToContentTypeID[purpose],
        },
        toRequest$({ fullRequest: true })
      )
    ),
    pluck('results'),
    map((value) => ({
      type: RESPONSE_CALL_LOG_TYPES,
      value,
    }))
  );

const getPatientVisits$ = (action$, state$, { menuNode$, initialCallLog$ }) =>
  combineLatest(
    action$.pipe(
      ofType(SHOULD_UPDATE_CALL_LOG),
      pluck('value', 'CallType'),
      distinctUntilChanged()
    ),
    action$.pipe(
      ofType(SHOULD_UPDATE_CALL_LOG),
      pluck('value', 'IPID'),
      distinctUntilChanged()
    )
  ).pipe(
    withLatestFrom(initialCallLog$),
    filter((params) => {
      const [[visitTypeId, ipid], callLog] = params;
      return (
        ipid &&
        visitTypeId &&
        visitTypeId !== 'GENERAL' &&
        !(callLog && callLog.IVID)
      );
    }),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap((params) => {
      const [[[purpose, ipid]], toRequest$] = params;
      return browse(
        { ipid, visitTypeId: purposeToVisitTypeID[purpose] },
        toRequest$({ fullRequest: true })
      );
    }),
    pluck('results'),
    map((value) => ({
      type: RESPONSE_PATIENT_VISITS,
      value,
    }))
  );

const toGetPhoneContactsEpic$ = (action$, state$, { ivid$, menuNode$ }) =>
  merge(
    action$.pipe(
      ofType(
        RESPONSE_ADD_PERSONAL_CONTACT,
        RESPONSE_ADD_CLINICAL_CONTACT,
        SHOULD_REFRESH_PHONE_CONTACTS
      ),
      pluck('value')
    ),
    state$.pipe(
      pluck('callLog'),
      distinctUntilChanged(({ IPID } = {}) => IPID)
    )
  ).pipe(
    filter(({ IPID } = {}) => IPID),
    combineWithLatestFrom(
      ivid$,
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([{ IPID: ipid, CurrentCaseIVID } = {}, IVID, toRequest$]) => {
      // When provided an IVID from the component we want to override the default
      const ivid = IVID || CurrentCaseIVID;

      return getPhoneContacts(
        { ipid, ivid },
        toRequest$({ fullRequest: true })
      );
    }),
    pluck('results'),
    map((value) => ({
      type: RESPONSE_PHONE_CONTACTS,
      value,
    }))
  );

const addCallLog$ = (action$, state$, { menuNode$ }) =>
  action$.pipe(
    ofType(SHOULD_ADD_CALL_LOG),
    pluck('value'),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([log, toRequest$]) => {
      return log.CallLogID
        ? updateCallLog(log, {}, toRequest$({ fullRequest: true }))
        : addCallLog(log, {}, toRequest$({ fullRequest: true }));
    }),
    map(({ request }) => {
      const CallLogID = objPluck('body', 'CallLogID')(request);
      return CallLogID;
    }),
    map((value) => {
      return {
        type: RESPONSE_ADD_CALL_LOG,
        value,
      };
    })
  );

export const shouldAddPersonalContact$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SHOULD_ADD_PERSONAL_CONTACT),
    pluck('value'),
    withLatestFrom(state$.pipe(ofValue('callLog'))),
    map(([contact, { IPID } = {}]) => ({
      IPID,
      ...contact,
    })),
    filter(({ IPID } = {}) => IPID),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([contact, toRequest$]) =>
      addSocialContact(contact, {}, toRequest$({ fullRequest: true }))
    ),
    pluck('results'),
    map((value = []) => ({
      type: RESPONSE_ADD_PERSONAL_CONTACT,
      value: value[0],
    }))
  );
};

export const addClinicalContact$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SHOULD_ADD_CLINICAL_CONTACT),
    pluck('value'),
    withLatestFrom(state$.pipe(ofValue('callLog'))),
    map((params) => {
      const [clinicalContact, callLog] = params;
      const IPID = schemaGet(AddCallLogRequestSchema, 'IPID', callLog);
      return schemaPipe(PatientProviderAddRequestSchema)(put('IPID')(IPID))(
        clinicalContact
      );
    }),
    filter(({ IPID } = {}) => IPID),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([contact, toRequest$]) =>
      addClinicalContact(contact, {}, toRequest$({ fullRequest: true }))
    ),
    // eslint-disable-next-line no-unused-vars
    map(({ request, ...response }) => response),
    map((value) => ({
      type: RESPONSE_ADD_CLINICAL_CONTACT,
      value,
    }))
  );
};

const getCallLogDetailsEpic$ = (action$, state$, { callLogId$, menuNode$ }) =>
  callLogId$.pipe(
    exists(),
    withLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([callLogId, toRequest$]) =>
      getDetails({ callLogId }, toRequest$({ fullRequest: true }))
    ),
    pluck('results'),
    map((value) => ({
      type: RESPONSE_CALL_LOG_DETAIL,
      value,
    }))
  );

export default [
  getPatientVisits$,
  toGetPhoneContactsEpic$,
  addCallLog$,
  getCallLogTypes$,
  shouldAddPersonalContact$,
  addClinicalContact$,
  getCallLogDetailsEpic$,
];
