import { combineEpics } from 'redux-observable';
import { combinePredicatedReducers } from '../../connection/toConnectionDef';
import { useReducer$ } from '../../hooks/useReducer$';
import { useMenuNode } from '../../contexts/XeMenuNodeContext';
import { useEffect$ } from '../../hooks/useEffect$';
import { useRef$ } from '../../hooks/useRef$';
import { localeFormat } from '../../format/luxonToDisplayString';
import { SchemaReducer } from '../../schema/SchemaReducer';
import { pluck as pluckFp } from '../../fp/object';
import { isNil, not } from '../../fp/pred';
import { ofType } from '../../frp/operators/ofType';
import { pluck, tap, filter, withLatestFrom } from 'rxjs/operators';
import { useCallback, useState } from 'react';
import UpdateAppointmentRequest from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
import {
  Button,
  Flexbox,
  Input,
  Label,
  NumberInput,
  Popup,
  TextArea,
  CalendarPatientBar,
  CalendarUserBar,
  GridLayout,
  List,
  DropDownList,
} from '../';
import { ClinicalDocumentButtons } from '../ClinicalDocumentButtons';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { useXeRefData } from '../../contexts/XeRefDataContext';
import { toDisplayDateFromISOString } from '../../g11n/displayDates';
import epics from './epics';
import reducers from './reducers';
import { SHOULD_SAVE_APPOINTMENT, RESPONSE_SAVE_APPOINTMENT } from './actions';
import { useDispatch } from 'react-redux';
import { formatStaffName } from '../../utils';
import { castFunction } from '../../fp/fp';
import './styles.css';
import { UIControlLabel } from '../Label';
import Tag from '../Tag';
import { useXeRights } from '../../contexts/XeUserRightsContext';
import { of } from 'rxjs';
import { usePathNavigate } from '../../hooks/usePathNavigate';
import { EMPTY_OBJECT } from '../../constants';

const epic = combineEpics(...epics);
const reducer = combinePredicatedReducers(...reducers);

// TODO: This only handles the case of editing appointments. In the future, we will most likely
// want it to work for Add as well, but currently the only place we can add appointments is through
// Calendar, which uses a more complicated popup that handles both Appointments and Other Events
export const AppointmentPopup = (props) => {
  const { CREATE_BOOKING, EDIT_BOOKING } = useXeRights();

  const {
    data: initialAppointment,
    resourceId,
    canSave = CREATE_BOOKING || EDIT_BOOKING,
    onClose,
    onSave,
  } = props;

  const { ServiceBookingID } = initialAppointment;

  const labels = useXeLabels();
  const { XeServiceBookingStatusReasonTree } = useXeRefData();
  const globalDispatch = useDispatch();
  const navigate = usePathNavigate();
  const onClose$ = useRef$(onClose);
  const menuNode = useMenuNode();
  const serviceBookingId$ = useRef$(ServiceBookingID);
  const epicWithDeps = useCallback(
    (action$, state$) =>
      epic(action$, state$, { menuNode$: of(menuNode), serviceBookingId$ }),
    [menuNode, serviceBookingId$]
  );

  const [state = {}, dispatch, action$] = useReducer$(reducer, epicWithDeps);
  const [appointmentDetails, setAppointmentDetails] = useState({});

  const { appointment } = state;

  useEffect$(
    () =>
      action$.pipe(
        ofType(RESPONSE_SAVE_APPOINTMENT),
        filter(not(pluckFp('navigate'))),
        pluck('value'),
        tap(onSave)
      ),
    [action$, onSave]
  );

  useEffect$(() => {
    return action$.pipe(
      ofType(RESPONSE_SAVE_APPOINTMENT),
      filter(pluckFp('navigate')),
      pluck('value', 'IPID'),
      withLatestFrom(onClose$),
      tap(([ipid, onClose]) => {
        castFunction(onClose)();
        return navigate(`~/DefaultSearchFeature/${ipid}`);
      })
    );
  }, [action$, onClose$, globalDispatch]);

  if (!appointment) return null;

  const {
    IPID,
    Duration,
    StartTime,
    CreationUser,
    CreateTStamp,
    ProviderID,
    ServiceID: { Name } = EMPTY_OBJECT,
    SchedCapacityLaneID: { Name: CapacityLaneName } = EMPTY_OBJECT,
  } = appointment;

  const filteredOptions = XeServiceBookingStatusReasonTree.filter(
    ({ item: { IsSystem, IsEndStatus } = {} } = {}) => !IsSystem && IsEndStatus
  ).sort(({ item: itemA = {} } = {}, { item: itemB = {} } = {}) => {
    return itemA.SequenceNo > itemB.SequenceNo;
  });
  const { instance = {}, changed, valid } = appointmentDetails;
  const { IsProviderAccepted, StatusID } = instance;
  const isCancelled = StatusID === 'CANCELLED';
  const { item: { XeServiceBookingStatus: statusReasonOptions = [] } = {} } =
    filteredOptions.find(({ id } = {}) => id === StatusID) || {};

  const filteredResources = appointment.XeServiceBookingResource.map(
    ({ ResourceID }) => ResourceID
  ).filter(({ ResourceID }) => ProviderID.ResourceID != ResourceID);

  const fullServiceAssessmentId = pluckFp(
    'ServiceID',
    'AssessmentID'
  )(appointment);
  const ipid = pluckFp('IPID', 'IPID')(appointment);

  const contactTypeLabel = CapacityLaneName
    ? `${Name}  ${labels.From}: ${CapacityLaneName}`
    : Name;

  return (
    <Popup
      dataElementName="appointment__popup"
      title={labels.EditAppointment}
      className="edit-appointment-popup edit-schedule-item-popup"
      FooterComponent={() => (
        <div>
          <Button
            dataElementName="close"
            className="default-popup-footer-button"
            onClick={onClose}
            look="outline"
          >
            {labels.Cancel}
          </Button>
          <Button
            dataElementName="confirm"
            primary
            look="outline"
            className="default-popup-footer-button"
            disabled={!canSave || !(valid && changed)}
            onClick={() => {
              dispatch({
                type: SHOULD_SAVE_APPOINTMENT,
                value: instance,
                navigate: false,
              });
            }}
          >
            {labels.Save}
          </Button>
          <Button
            dataElementName="saveAndChart"
            primary
            look="outline"
            className="default-popup-footer-button"
            disabled={!canSave || !(valid && changed)}
            onClick={() => {
              dispatch({
                type: SHOULD_SAVE_APPOINTMENT,
                value: instance,
                navigate: true,
              });
            }}
          >
            {labels.SaveAndChart}
          </Button>
        </div>
      )}
      contentStyle={{ padding: 0 }}
    >
      <SchemaReducer
        schema={UpdateAppointmentRequest}
        onChange={setAppointmentDetails}
        initialValue={appointment}
      >
        <Flexbox direction="column" className="flex-1">
          <CalendarUserBar
            user={resourceId || ProviderID}
            appointment={appointment}
            shouldAccept={IsProviderAccepted}
          />
          <CalendarPatientBar
            patient={IPID}
            onViewChart={(value) => {
              navigate(`~/DefaultSearchFeature/${ipid}`);
              onClose();
            }}
          />
          <Flexbox
            dataElementName="appointment__appointmentDetails"
            justifyContent="center"
            className="flex-1 overflow-auto"
          >
            <GridLayout
              alignItems="center"
              className="appointment-details"
              templateColumns="auto 400px"
              templateRows="repeat(10, min-content)"
            >
              {CapacityLaneName ? (
                <>
                  <UIControlLabel dataElementName="appointment__contactTypes__label">
                    {labels.ContactType}
                  </UIControlLabel>
                  <Flexbox alignItems="center">
                    <Label>{Name}</Label>
                    <Label
                      descriptor={labels.On}
                      descriptorClassName="margin-left-small"
                    >
                      {CapacityLaneName}
                    </Label>
                  </Flexbox>
                </>
              ) : (
                <Label
                  dataElementName="appointment__contactTypes"
                  descriptor={labels.ContactType}
                >
                  {Name}
                </Label>
              )}
              <Label
                dataElementName="appointment__date"
                descriptor={labels.Date}
              >
                {toDisplayDateFromISOString(StartTime, localeFormat.LONG)}
              </Label>
              <Label
                dataElementName="appointment__duration"
                descriptor={labels.Duration}
              >
                {Duration} {labels.Minutes}
              </Label>
              <UIControlLabel dataElementName="appointment__outcome__label">
                {labels.Outcome}
              </UIControlLabel>
              <Flexbox alignItems="center">
                <DropDownList
                  dataElementName="appointment__outcome"
                  data={filteredOptions}
                  dataPath="StatusID"
                  labelFn={pluckFp('text')}
                  valueFn={pluckFp('id')}
                />
                {statusReasonOptions.length > 0 ? (
                  <DropDownList
                    dataElementName="appointment__reason"
                    required={true}
                    data={statusReasonOptions}
                    dataPath="StatusReasonID"
                    labelFn={pluckFp('Name')}
                    valueFn={pluckFp('StatusID')}
                    descriptor={labels.Reason}
                    descriptorClassName="margin-left-small"
                  />
                ) : null}
              </Flexbox>
              <TextArea
                dataPath="Description"
                dataElementName="appointment__description"
                descriptor={labels.Description}
              />
              <Input
                descriptor={labels.Location}
                dataElementName="appointment__location"
                dataPath="PerformLocation"
                type="text"
              />
              <UIControlLabel dataElementName="appointment__travelTime__label">
                {labels.TravelTime}
              </UIControlLabel>
              <Flexbox alignItems="center">
                <NumberInput
                  dataElementName="appointment__travelTime"
                  exclusiveMinimum={0}
                  dataPath="TravelTime"
                  min={0}
                />
                <NumberInput
                  exclusiveMinimum={0}
                  descriptor={labels.Miles}
                  dataElementName="appointment__travelMileage"
                  descriptorClassName="margin-left-small"
                  dataPath="TravelMileage"
                  min={0}
                />
              </Flexbox>
              {isCancelled && (
                <TextArea
                  required={!(statusReasonOptions.length > 0)}
                  dataPath="CancelReason"
                  descriptor={labels.CancelReason}
                />
              )}
              {filteredResources && filteredResources.length > 0 ? (
                <>
                  <UIControlLabel dataElementName="appointment__providers__label">
                    {labels.Providers}
                  </UIControlLabel>
                  <List
                    dataElementName="appointment__providers"
                    style={{ gridColumn: '2 / -1' }}
                    data={filteredResources}
                    renderItem={({ item: { ResourceID }, item } = {}) => {
                      return (
                        <Tag
                          item={item}
                          // SYNUI-6107 -- Forward looking we want to be able to delete/add items here.
                          // But there needs to be additional RIGHTS added first.  When that time comes we'll also need
                          // A XeDelegationSearchWidget to allow for adding items to the list.
                          // onRemove={() => {
                          // }}
                          labelFn={formatStaffName}
                          key={ResourceID}
                        />
                      );
                    }}
                  />
                </>
              ) : null}
              {fullServiceAssessmentId && !isNil(IPID) ? (
                <div style={{ gridColumn: '2 / -1' }}>
                  <ClinicalDocumentButtons
                    viewButtonComponent={Button}
                    viewButtonComponentProps={{
                      children: <Label>{fullServiceAssessmentId.Name}</Label>,
                      look: 'outline',
                    }}
                    hideDownload={true}
                    ipid={ipid}
                    assessmentId={fullServiceAssessmentId.AssessmentID}
                    data={fullServiceAssessmentId}
                    menuNode={menuNode}
                  />
                </div>
              ) : null}
            </GridLayout>
          </Flexbox>
          <div className="padding-all-small">
            <span data-element-name="appointment__createdBy__label">
              {labels.CreatedBy}
            </span>
            <span className="bold"> {CreationUser} </span>
            {labels.On}
            <span className="bold">
              {' '}
              {toDisplayDateFromISOString(CreateTStamp, localeFormat.LONG)}{' '}
            </span>
          </div>
        </Flexbox>
      </SchemaReducer>
    </Popup>
  );
};

export default AppointmentPopup;
