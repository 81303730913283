import { useMemo, useState } from 'react';
import { IconButton } from '../../../../IconButton';
import { EMPTY_OBJECT } from '../../../../../constants';
import { ADD } from '../../../../../icons';
import { useXePatient } from '../../../../../contexts/XePatientContext';

export const GlobalActionAddWorklist = (props) => {
  const { labels, type, titleLabelKey, components } = props;

  const { WorklistWrapper = () => null } = components;

  const [showWorklist, setShowWorklist] = useState();

  const { ipid: contextIpid } = useXePatient();

  const worklistData = useMemo(() => {
    return contextIpid ? { IPID: contextIpid } : EMPTY_OBJECT;
  }, [contextIpid]);

  return (
    <>
      <IconButton
        dataElementName="add"
        icon={ADD}
        onClick={() => {
          setShowWorklist(true);
        }}
        description={labels.Add}
      />
      {showWorklist ? (
        <WorklistWrapper
          title={labels[titleLabelKey]}
          data={worklistData}
          type={type}
          onClose={() => {
            setShowWorklist(false);
          }}
          onTransition={() => {
            setShowWorklist(false);
          }}
          onSave={() => {
            setShowWorklist(false);
          }}
        />
      ) : null}
    </>
  );
};
