import { get, pluck } from '../../fp/object';
import { isNil, isString } from '../../fp/pred';
import objOmit from 'lodash/omit';
import { DateTime } from 'luxon';
import { isoStrAsLuxon } from '../../g11n/ISODates';
import { useRef } from 'react';

/**
 * @typedef XnetColDefOptions
 * @property {string} [headerLabelKey]
 * @property {string} [sortField]
 *
 * @typedef {import('ag-grid-enterprise').ColDef & XnetColDefOptions} XnetColDef
 */

const toPrunedColumnDefs = (colDefs = [], { only, omit }) => {
  if (Array.isArray(only)) {
    return only.reduce((reducedColumnDefs, id) => {
      const match = colDefs.find((cd = {}) => cd.colId === id);
      if (!match) return colDefs;
      return [...reducedColumnDefs, match];
    }, []);
  }
  if (Array.isArray(omit)) {
    return colDefs.filter((item) => !omit.includes(item.colId));
  }
  return colDefs;
};

const toSafeCellRendererFn = (columnDefCellRendererFn) => {
  if (!columnDefCellRendererFn) return undefined;
  if (typeof columnDefCellRendererFn !== 'function')
    return columnDefCellRendererFn;
  return (params) => {
    const { data } = params;
    if (!data) return '';
    return columnDefCellRendererFn(params);
  };
};

const defaultCellValue = undefined;
export const defaultFormattedCellValue = '—';
const defaultKeyCreatorValue = 'No Value';

/**
 * @callback FallbackValueValueGetter
 * @param {import('ag-grid-enterprise').ValueGetterParams} params
 */

/**
 * @param {Function} columnDefValueGetter
 * @returns {FallbackValueValueGetter}
 */
const toFallbackValueValueGetter = (columnDefValueGetter) => {
  return (params) => {
    const { data } = params;
    if (isNil(data)) return defaultCellValue;

    if (!columnDefValueGetter) {
      const {
        colDef: { field },
      } = params;
      if (!field) return defaultCellValue;
      const value = get(field, data);
      return isNil(value) ? defaultCellValue : value;
    }

    const columnDefValueGetterResult = columnDefValueGetter(params);
    return columnDefValueGetterResult ?? defaultCellValue;
  };
};

/**
 * @callback toFallbackValueKeyCreator
 * @param {Object} data
 */

/**
 * @param {Function} columnDefKeyCreator
 * @returns {FallbackValueKeyCreator}
 */
const toFallbackValueKeyCreator = (columnDefKeyCreator) => {
  return (data) => {
    if (!!columnDefKeyCreator) {
      return columnDefKeyCreator(data);
    }
    if (isNil(data)) {
      return defaultKeyCreatorValue;
    }

    const { value } = data;
    //No value for this cell, group these items on a common default value.
    if (isNil(value)) {
      return defaultKeyCreatorValue;
    }

    return value;
  };
};
/**
 * @callback FallbackValueValueFormatter
 * @param {import('ag-grid-enterprise').ValueFormatterParams} params
 */

/**
 * @param {Function} columnDefValueFormatter
 * @returns {FallbackValueValueFormatter}
 */
const toFallbackValueValueFormatter = (columnDefValueFormatter) => {
  return (params) => {
    const { colDef = {}, data, value } = params;

    const { checkboxSelection } = colDef;

    const isCheckboxColumn =
      checkboxSelection === true || typeof checkboxSelection === 'function';
    const getDefaultValue = () => {
      return isCheckboxColumn ? defaultCellValue : defaultFormattedCellValue;
    };

    if (isNil(data) || isNil(value)) {
      return getDefaultValue();
    }

    if (!columnDefValueFormatter) {
      return value;
    }

    const formatterResult = columnDefValueFormatter(params);
    if (isNil(formatterResult) || formatterResult === '') {
      return getDefaultValue();
    }

    return formatterResult;
  };
};

const toColumnIdentifier = (colId, headerName, field, index) => {
  if (!!colId && isNil(headerName) && isNil(field)) return colId;

  // Generate as unique of an identifier as possible to avoid issues where a new set of column defs with
  // similar fields results in the previous column definition cache being returned.
  let id = '';
  if (headerName) {
    id += `_hn_${headerName}`;
  }
  if (field) {
    id += `_f_${field}`;
  }
  return id || `_generated_colid_${index}`;
};
/**
 * Returns a set of column defintions for ag-grid.
 *
 * @param {Array<Omit<XnetColDef, 'headerLabelKey'>>} columnDefs
 * @param {{ only?: Array<String>, omit?: Array<String> }} param1
 */
export const useColumnDefsSet = (columnDefs, labels, { only, omit } = {}) => {
  if (only && omit) {
    console.warn(
      "A <Grid> was given a set of items to 'only' as well as 'omit'. Only the keys provided to 'only' will be used."
    );
  }

  const prevColumnSetIdentifierRef = useRef('');
  const prevColumnSetRef = useRef([]);

  const prunedColumnDefs = toPrunedColumnDefs(columnDefs, { only, omit });

  const columnDefsWithLabels = prunedColumnDefs.map(
    ({ headerLabelKey, headerName, ...rest }) => {
      return {
        ...rest,
        headerName: !!headerLabelKey ? labels[headerLabelKey] : headerName,
      };
    }
  );

  /**
   * Result containing the next column def projection and the identifier for the column def set.
   *
   * @type {{
   *  colDefs: Array,
   *  setId: string
   * }}
   */
  const reducerResult = columnDefsWithLabels.reduce(
    ({ colDefs, setId }, currentColDef, index) => {
      const {
        headerName,
        colId,
        field,
        valueGetter,
        cellRenderer,
        valueFormatter,
        keyCreator,
      } = currentColDef;

      const columnIdentifier = toColumnIdentifier(
        colId,
        headerName,
        field,
        index
      );
      const canGroupColumn = !!(field || valueGetter);

      const shouldApplyDefaultGroupingAndSorting =
        canGroupColumn && !!headerName;

      /**
       * @type {import('ag-grid-enterprise').ColDef[]}
       */
      const returnValue = [
        ...colDefs,
        {
          enableRowGroup: shouldApplyDefaultGroupingAndSorting,
          sortable: shouldApplyDefaultGroupingAndSorting,
          ...objOmit(currentColDef, 'sortField'),
          colId: columnIdentifier,
          headerName,
          cellRenderer: cellRenderer
            ? toSafeCellRendererFn(cellRenderer)
            : undefined,
          valueGetter: toFallbackValueValueGetter(valueGetter),
          valueFormatter: toFallbackValueValueFormatter(valueFormatter),
          keyCreator: toFallbackValueKeyCreator(keyCreator),
        },
      ];

      return {
        colDefs: returnValue,
        setId: setId.concat(`|${columnIdentifier}`),
      };
    },
    {
      colDefs: [],
      setId: '',
    }
  );

  const { colDefs, setId } = reducerResult;

  if (prevColumnSetIdentifierRef.current === setId) {
    return prevColumnSetRef.current;
  }

  prevColumnSetIdentifierRef.current = setId;
  prevColumnSetRef.current = colDefs;

  return colDefs;
};

const toFirstGroupedRelevantChild = (node = {}) => {
  if ('childrenAfterGroup' in node) {
    const firstGroupedChild = pluck('childrenAfterGroup', '0')(node);
    return toFirstGroupedRelevantChild(firstGroupedChild);
  }
  return node;
};

/**
 * @typedef {import('ag-grid-enterprise').ICellRendererParams} ICellRendererParams
 */

/**
 * @param {ICellRendererParams['api']} gridApi
 * @param {ICellRendererParams['columnApi']} columnApi
 * @param {ICellRendererParams['value']} value
 * @param {ICellRendererParams['node']} node
 * @param {ICellRendererParams['context']} context
 *
 * @returns {{ value: any, valueFormatted: any }}
 */
const toGroupNodeValues = (gridApi, columnApi, value, node = {}, context) => {
  const firstGroupedChild = toFirstGroupedRelevantChild(node);

  /**
   * @type {import('ag-grid-enterprise').Column}
   */
  const rowGroupColumn = node.rowGroupColumn || { getColDef: () => {} };

  const colDef = rowGroupColumn.getColDef() || {};
  const defaultGetValue = () => value;
  const { valueFormatter = defaultGetValue, valueGetter = defaultGetValue } =
    colDef;

  const { data } = firstGroupedChild;

  /**
   * @type {import('ag-grid-enterprise').ValueGetterParams}
   */
  const valueGetterParams = {
    api: gridApi,
    node,
    data,
    colDef,
    column: rowGroupColumn,
    columnApi,
    context,
  };

  /**
   * @type {import('ag-grid-enterprise').ValueFormatterParams}
   */
  const valueFormatterParams = {
    api: gridApi,
    colDef,
    column: rowGroupColumn,
    columnApi,
    context,
    data,
    node,
    value: valueGetter(valueGetterParams),
  };

  return {
    valueFormatted: valueFormatter(valueFormatterParams),
    value: valueFormatterParams.value,
  };
};

export const basicComparator = (lhs = '', rhs = '') => {
  //if lhs is a string, use proper collation rules
  if (isString(lhs)) {
    return lhs.localeCompare(rhs);
  }

  //if its not a string, defer to the > operator.... good luck everybody
  if (lhs === rhs) return 0;
  return lhs > rhs ? 1 : -1;
};

/**
 * @type {import('ag-grid-enterprise').ColDef}
 */
export const autoGroupColumnDef = {
  cellRendererParams: {
    /**
     * @param {ICellRendererParams} params
     */
    innerRenderer: ({ api, node, columnApi, value, context } = {}) => {
      const { valueFormatted } = toGroupNodeValues(
        api,
        columnApi,
        value,
        node,
        context
      );

      return valueFormatted;
    },
  },
  comparator: (valueA, valueB, nodeA, nodeB) => {
    if (!nodeA.childrenAfterGroup && !nodeB.childrenAfterGroup) {
      return basicComparator(valueA, valueB);
    }

    const { gridApi, context, columnApi } = nodeA;

    const { value: lhsValue } = toGroupNodeValues(
      gridApi,
      columnApi,
      valueA,
      nodeA,
      context
    );
    const { value: rhsValue } = toGroupNodeValues(
      gridApi,
      columnApi,
      valueB,
      nodeB,
      context
    );

    return basicComparator(lhsValue, rhsValue);
  },
  pinned: 'left',
  sortable: true,
};

const valuePlucker = ({ value } = {}) => value;
export const gridExportParams = {
  processCellCallback: ({ column, node, value, context }) => {
    const { colDef: { valueFormatter = valuePlucker } = {} } = column;
    const { data } = node;

    const formattedValue = valueFormatter({ data, value, context });
    return formattedValue === defaultFormattedCellValue ? '' : formattedValue;
  },
};

const formattedAs =
  (projection) =>
  ({ value } = {}) => {
    // We seem to sometime get items defined as a date that are undefined....
    // and sometimes get items defined as a date that are an empty string in dashboards.
    // This is problematic but working around this assumption
    if (isNil(value) || value === '') return value;
    const luxonDateTime = isoStrAsLuxon(value);
    if (isNil(luxonDateTime)) return value;

    return projection(luxonDateTime);
  };

//In all cases, excel cannot handle timezone information so do not put it in the CSV
const dashboardExcelOverrideFormatters = {
  DateFormat: formattedAs((d) => d.toLocaleString(DateTime.DATE_SHORT)),
  DateLocalFormat: formattedAs((d) => d.toLocaleString(DateTime.DATE_SHORT)),
  DateTimeFormat: formattedAs(
    (d) =>
      `${d.toLocaleString(DateTime.DATE_SHORT)} ${d.toLocaleString(
        DateTime.TIME_24_WITH_SECONDS
      )}`
  ),
  DateTimeLocalFormat: formattedAs(
    (d) =>
      `${d.toLocaleString(DateTime.DATE_SHORT)} ${d.toLocaleString(
        DateTime.TIME_24_WITH_SECONDS
      )}`
  ),
  TimeFormat: formattedAs((d) =>
    d.toLocaleString(DateTime.TIME_24_WITH_SECONDS)
  ),
  TimeLocalFormat: formattedAs((d) =>
    d.toLocaleString(DateTime.TIME_24_WITH_SECONDS)
  ),
};

/***
 * We currently call this to do CSV exports. However, when importing CSVs, programs like excel cannot
 * interpret dates with timezone information correctly, causing a mess.
 *
 * Here we are defining an export format that is viable for excel for all dates, however, there is a caveat
 * Since excel won't understand timezone information, we are presenting date times in the USER's timezone
 *
 * This is consistent with the way they would see the date on the screen but would cause a problem if they,
 * for example, emailed this file to someone in another timezone as we have no ability to adjust accordingly.
 *
 * This assumption is documented in https://jira.thrasys.com/browse/SYNUI-6431
 *
 * In essence, all dates will become floating
 *
 * As far as code location, I am leaving this here for now as we may very well want to make all grid exports
 * use this same procedure but as we do not necessarily have the time information for all columns at this same
 * point it is likley necessary to do some more surgery
 */
export const dashboardExportParams = {
  processCellCallback: ({ column, node, value, context }) => {
    const { colId = '', colDef: { valueFormatter = valuePlucker } = {} } =
      column;
    const { data = {} } = node;
    const { [colId]: { DisplayType = '' } = {} } = data;

    const exportFormatter =
      dashboardExcelOverrideFormatters[DisplayType] || valueFormatter;
    const formattedValue = exportFormatter({ data, value, context });
    return formattedValue === defaultFormattedCellValue ? '' : formattedValue;
  },
};
