import { useEnterprise } from '../../../contexts/XeEnterpriseContext';
import {
  MenuNodeLeafFeature,
  toMenuNode,
} from '../../../contexts/XeMenuNodeContext/components';
import { Popup, toDefaultPopupFooter } from '../../Popup';

export const ChartDetailsPopup = ({ onClose, callLog }) => {
  const { userData: { XeAppMenus = [] } = {} } = useEnterprise();

  // TODO: We are making a big assumption that XePatientDemographics exists
  const patientDemographicsNode = toMenuNode(
    XeAppMenus,
    ({ HtmlComponentName } = {}) =>
      HtmlComponentName === 'XePatientDemographics'
  );

  if (!patientDemographicsNode) return null;

  return (
    <Popup
      title={patientDemographicsNode?.Name}
      size="large"
      FooterComponent={toDefaultPopupFooter({ onClose })}
    >
      <MenuNodeLeafFeature
        menuNodeId={patientDemographicsNode?.MenuNodeID}
        patient={{ IPID: callLog?.IPID }}
      />
    </Popup>
  );
};

export default ChartDetailsPopup;
