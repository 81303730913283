import { identity } from '../../fp/fp';
import { isNil } from '../../fp/pred';
import { forwardRef, createElement } from 'react';
import './styles.css';
import { EMPTY_OBJECT } from '../../constants';

const VALID_DIRECTIONS = ['row', 'column', 'row-reverse', 'column-reverse'];

const toDirectionClass = (direction, inline) => {
  let baseClass;
  if (!VALID_DIRECTIONS.find((d) => d === direction)) {
    baseClass = 'flex-container-row';
  } else {
    baseClass = `flex-container-${direction}`;
  }

  return inline ? `inline-${baseClass}` : baseClass;
};

const flexboxStyle = (style = {}, gap) => {
  const styleObject = { ...style };
  if (gap) {
    styleObject.gap = gap;
  }
  return styleObject;
};

const toAlignmentClass = (value, prefix) => (value ? `${prefix}${value}` : '');

const toGapClass = (value, prefix) => (value ? `${prefix}${value}` : '');

/**
 * @typedef {import('react').CSSProperties} CSSProperties
 */

/**
 * @typedef {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
 *  direction?: CSSProperties['flexDirection'],
 *  alignItems?: CSSProperties['alignItems'],
 *  justifyContent?: CSSProperties['justifyContent'],
 *  wrap?: boolean,
 *  inline?: boolean
 * }} FlexboxProps
 *
 * @type {React.ForwardRefExoticComponent<React.RefAttributes<FlexboxProps>>}
 */

/**
 * @type {(render: React.ForwardRefRenderFunction<any, FlexboxProps>) => React.ForwardRefExoticComponent<React.PropsWithoutRef<FlexboxProps> & React.RefAttributes<T>>}
 */
const forwardFlexboxRef = forwardRef;

export const Flexbox = forwardFlexboxRef((props, ref) => {
  const {
    direction = 'row',
    justifyContent,
    alignItems,
    className = '',
    children,
    wrap,
    gap,
    inline,
    style = EMPTY_OBJECT,
    dataElementName = '',
    ...viewProps
  } = props;

  const wrapClass = wrap ? 'flex-wrap' : '';

  const classNames = [
    toDirectionClass(direction, inline),
    toAlignmentClass(justifyContent, 'justify-content-'),
    toAlignmentClass(alignItems, 'align-items-'),
    toGapClass(gap, 'gap-'),
    wrapClass,
    className,
  ];

  return (
    <div
      {...viewProps}
      data-element-name={dataElementName}
      ref={ref}
      style={flexboxStyle(style, gap)}
      className={classNames.filter(identity).join(' ')}
    >
      {children}
    </div>
  );
});

Flexbox.displayName = 'Flexbox';
export default Flexbox;

/**
 * @typedef FlexItemProperties
 * @property {CSSProperties['flex']} flex
 * @property {CSSProperties['flexGrow']} flexGrow
 * @property {CSSProperties['flexShrink']} flexShrink
 * @property {CSSProperties['flexBasis']} flexBasis
 * @property {CSSProperties['alignSelf']} alignSelf
 * @property {CSSProperties['justifySelf']} justifySelf
 */

/**
 * @param {CSSProperties} target
 * @param {Partial<FlexItemProperties>} flexItemProperties
 */
const setOptionalFlexStyleProps = (target, flexItemProperties) => {
  const targetCopy = { ...target };
  return Object.entries(flexItemProperties).reduce(
    (updatedTarget, [flexProperty, flexValue]) => {
      if (!isNil(flexValue)) {
        return {
          ...updatedTarget,
          [flexProperty]: flexValue,
        };
      }
      return updatedTarget;
    },
    targetCopy
  );
};

/**
 * @typedef {Partial<FlexItemProperties> &
 *  { style: CSSProperties,
 *    component: keyof import('react').ReactHTML,
 *    componentProps: any }} FlexItemProps
 */

/**
 * @param {FlexItemProps} props
 */
export const FlexItem = (props) => {
  const {
    flex,
    flexGrow,
    flexShrink,
    flexBasis,
    alignSelf,
    justifySelf,
    style = EMPTY_OBJECT,
    component = 'div',
    ...componentProps
  } = props;

  /**
   * @type {import('react').CSSProperties}
   */
  const withFlexStyle = setOptionalFlexStyleProps(style, {
    flex,
    flexGrow,
    flexShrink,
    flexBasis,
    alignSelf,
    justifySelf,
  });

  return createElement(component, {
    ...componentProps,
    style: withFlexStyle,
  });
};
