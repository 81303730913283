import { useXeLabels } from '../../../contexts/XeLabelContext';
import { Popup, toDefaultPopupFooter } from '../..';

export const UnassignPopup = (props) => {
  const { onClose, onConfirm } = props;
  const labels = useXeLabels();
  return (
    <Popup
      dataElementName="grabReleaseConfirm__popup"
      size="small"
      FooterComponent={toDefaultPopupFooter({
        closeLabelKey: 'No',
        confirmLabelKey: 'Yes',
        onClose: onClose,
        onConfirm: onConfirm,
      })}
      title={labels.Confirm}
    >
      <div data-element-name="grabReleaseConfirm__label">
        {labels.GrabReleaseConfirm}
      </div>
    </Popup>
  );
};
