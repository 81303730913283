import { isEmpty } from '../../../fp/pred';
import { put } from '../../../fp/object';
import { schemaPipe, schemaGet } from '../../../schema/schemaTypeBuilder';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import StaffSearchByTypeRequestSchema from 'services/schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSearchByTypeRequest.json';
import PatientProviderAddRequestSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddRequest.json';
import { ButtonBar, Flexbox, Popup, toDefaultPopupFooter } from '../../';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { AssignClinicalContact } from '../../AssignClinicalContact';
import { PersonalContactForm } from '../../PersonalContactForm';
import CallLogOnly from '../components/CallLogOnly';
import {
  useAddContactOptions,
  useCallDirectionOptions,
  usePhoneTypeOptions,
} from '../hooks';
import { formatStaffName } from '../../../utils';
import { useXeRights } from '../../../contexts/XeUserRightsContext';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants';
import { useXeQuery } from '../../../data/useXeQuery';
import { getPatient } from 'services/patients/xe-patients-svc';
import { identity } from '../../../fp/fp';

const toLabel = ({ label } = {}) => label;
const toKey = ({ key } = {}) => key;

const contactLogicMap = {
  SocialContacts: (contact, { onAddPersonalContact }) => {
    onAddPersonalContact(contact);

    const { FamilyName, GivenName, RelationTypeID: Name } = contact;

    return {
      CallToName: `${FamilyName}, ${GivenName} (${Name})`,
    };
  },
  CareTeam: (
    provider,
    {
      onAddClinicalContact,
      assignClinicalContact: { selectedContactType: role },
    }
  ) => {
    const EnterpriseID = schemaGet(
      StaffSearchByTypeRequestSchema,
      'EnterpriseID.EnterpriseID',
      provider
    );
    const patientProviderAddRequestObject = schemaPipe(
      PatientProviderAddRequestSchema
    )(
      put('ProviderTypeID')(role),
      put('StaffID_EnterpriseID')(EnterpriseID)
    )(provider);
    onAddClinicalContact(patientProviderAddRequestObject);

    const { Name } = role;

    return {
      CallToName: `${formatStaffName(provider)} (${Name})`,
    };
  },
  CallLogOnly: (
    {
      name,
      relationship,
      CallToPhone,
      IsInbound: { value: IsInbound } = {},
    } = {},
    { setCallLogOnly } = {}
  ) => {
    setCallLogOnly(true);
    return {
      CallToName: `${name} (${relationship})`,
      CallToPhone,
      IsInbound,
    };
  },
};

const isValidCallLogContact = ({ name, relationship } = {}) =>
  name && relationship;
const isValidPersonalContact = ({
  FamilyName,
  GivenName,
  RelationTypeID,
} = {}) => FamilyName && GivenName && RelationTypeID;
const isValidClinicalContact = (provider, role) =>
  !isEmpty(provider) && !!role && !isEmpty(role);
const isInvalidContact = (...args) =>
  !isValidCallLogContact(...args) &&
  !isValidPersonalContact(...args) &&
  !isValidClinicalContact(...args);

export const AddPatientContactPopup = (props) => {
  const {
    patientIPID,
    selectedContact: contact,
    assignClinicalContact = EMPTY_OBJECT,
    onAddPatientContact,
    onCancelAddPatientContact,
    onUpdateContact,
    onViewChange,
    onUpdateClinicalContactType,
  } = props;
  const contactType = assignClinicalContact.selectedContactType;

  const rights = useXeRights();
  const labels = useXeLabels();

  const { data: [selectedPatient = EMPTY_OBJECT] = EMPTY_ARRAY } = useXeQuery(
    getPatient({ ipid: patientIPID }, identity),
    {
      enabled: !!patientIPID,
    }
  );
  const { XePatientAddress } = selectedPatient;

  const phoneTypeOptions = usePhoneTypeOptions(labels);
  const callDirectionOptions = useCallDirectionOptions(labels);
  const addContactOptions = useAddContactOptions(labels, rights);

  const [currentView, setCurrentView] = useState(addContactOptions[0]);
  const { key: currentKey } = currentView || {};

  const updateContactKey = useCallback(
    (key) => (value) => onUpdateContact({ ...contact, [key]: value }),
    [contact, onUpdateContact]
  );

  return (
    <Popup
      size="large"
      dataElementName="addPatientContact__popup"
      title={labels.AddPatientContact}
      FooterComponent={toDefaultPopupFooter({
        disableConfirm: isInvalidContact(contact, contactType),
        onConfirm: () => {
          return onAddPatientContact(
            contactLogicMap[currentKey](contact, props)
          );
        },
        onClose: onCancelAddPatientContact,
      })}
    >
      <Flexbox direction="column" alignItems="center" className="flex-1">
        <ButtonBar
          data={addContactOptions}
          labelFn={toLabel}
          keyFn={toKey}
          selectedItem={currentView}
          onSelect={(view) => {
            onViewChange();
            setCurrentView(view);
          }}
        />
        <Flexbox className="flex-1 padding-top-large">
          {currentKey === 'SocialContacts' && (
            <PersonalContactForm
              isNewPatientContact
              value={contact}
              consumerAddress={XePatientAddress}
              onContactUpdate={({ instance } = {}) => onUpdateContact(instance)}
            />
          )}
          {currentKey === 'CareTeam' && (
            <div className="align-self-center">
              <AssignClinicalContact
                onChange={onUpdateContact}
                onRoleChange={onUpdateClinicalContactType}
              />
            </div>
          )}
          {currentKey === 'CallLogOnly' && (
            <CallLogOnly
              callDirectionOptions={callDirectionOptions}
              phoneTypeOptions={phoneTypeOptions}
              contact={contact}
              updateContactKey={updateContactKey}
            />
          )}
        </Flexbox>
      </Flexbox>
    </Popup>
  );
};

AddPatientContactPopup.propTypes = {
  onAddPatientContact: PropTypes.func.isRequired,
  onCancelAddPatientContact: PropTypes.func.isRequired,
};

export default AddPatientContactPopup;
