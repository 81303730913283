import { ofType } from '../../../../../../frp/operators/ofType';
import { map, mergeMap, pluck, withLatestFrom } from 'rxjs/operators';
import {
  insert,
  update,
} from 'services/patient-providers/xe-patient-providers-svc';
import {
  DID_ADD_PATIENT_PROVIDER,
  DID_UPDATE_PATIENT_PROVIDER,
  SHOULD_ADD_PATIENT_PROVIDER,
  SHOULD_UPDATE_PATIENT_PROVIDER,
} from './actions';

const toMenuNodeRequestFn = (...reducers) => (source$) => {
  return source$.pipe(
    pluck('requestFn'),
    map((fn) => (reducers.length ? fn(...reducers)() : fn()))
  );
};

const toAddPatientProviderEpic$ = (action$, _state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SHOULD_ADD_PATIENT_PROVIDER),
    pluck('value'),
    withLatestFrom(menuNode$.pipe(toMenuNodeRequestFn())),
    mergeMap(([value, toRequest$]) => {
      return insert(
        value,
        { xxsid: undefined },
        toRequest$({ fullRequest: true })
      );
    }),
    pluck('results'),
    map((response) => {
      return {
        type: DID_ADD_PATIENT_PROVIDER,
        value: response,
      };
    })
  );
};

const toUpdatePatientProviderEpic$ = (action$, _state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SHOULD_UPDATE_PATIENT_PROVIDER),
    pluck('value'),
    withLatestFrom(menuNode$.pipe(toMenuNodeRequestFn())),
    mergeMap(([value, toRequest$]) => {
      return update(
        value,
        { xxsid: undefined },
        toRequest$({ fullRequest: true })
      );
    }),
    pluck('results'),
    map((response) => {
      return {
        type: DID_UPDATE_PATIENT_PROVIDER,
        value: response,
      };
    })
  );
};

export default [toAddPatientProviderEpic$, toUpdatePatientProviderEpic$];
