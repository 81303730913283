import { combineEpics } from 'redux-observable';
import { combinePredicatedReducers } from '../../connection/toConnectionDef';
import { useReducer$ } from '../../hooks/useReducer$';
import { useRef$ } from '../../hooks/useRef$';
import { useEffect$ } from '../../hooks/useEffect$';
import {
  isFunction,
  isEmpty,
  isObjectLike,
  isArray,
  isNumber,
  isNil,
} from '../../fp/pred';
import { ofType } from '../../frp/operators/ofType';
import { castSchema } from '../../schema/schemaCaster';
import { useCallback, useState } from 'react';
import { pluck as pluckFrp, tap, withLatestFrom } from 'rxjs/operators';
import FactXeVisitAssessmentSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment.json';
import XeSmartBookInstanceSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json';
import { getEnterpriseID, getIpid } from '../../utils/pluckers';
import { Flexbox, IconButton } from '..';
import { useEnterprise } from '../../contexts/XeEnterpriseContext';
import { useXeLabels } from '../../contexts/XeLabelContext';
import {
  ADD,
  DOWNLOAD_DOCUMENT,
  OPEN_DOCUMENT,
  REMOVE,
  WARNING,
} from '../../icons';
import { castFunction, identity } from '../../fp/fp';
import { toAvailableActionsForDocument } from '../ClinicalDocumentsGrid/utils';
import { ClinicalDocumentViewer } from '../ClinicalDocumentViewer';
import { MarkInErrorPopup } from '../MarkInErrorPopup';
import {
  DID_MARK_DOCUMENT_IN_ERROR,
  DOWNLOAD_EXISTING_DOCUMENT,
  MARK_DOCUMENT_IN_ERROR,
  POLL_FOR_DOCUMENT_STATE,
  RECOVER_STALLED_DOCUMENT,
  STOP_POLLING_FOR_DOCUMENT_STATE,
} from './actions';
import {
  PROCESSING_STATE_PENDING,
  PROCESSING_STATE_STALLED,
} from './constants';
import epics from './epics';
import reducers from './reducers';
import { of } from 'rxjs';
import { Header } from '../ClinDocs/Header';
import { useXeQuery } from '../../data/useXeQuery';
import { getPatient } from 'services/patients/xe-patients-svc';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { useXeMutation } from '../../data/useXeMutation';
import { markVersionInError } from 'services/visit-clindocs/xe-visit-clindocs-svc';

const CAN_CREATE = 'CREATE_VISITDOC';
const CAN_VIEW = 'VIEW_VISITDOC';
const CAN_EDIT = 'EDIT_VISITDOC';
const CAN_SIGN = 'SIGN_VISITDOC';
const CAN_MARK_IN_ERROR = 'MARKINERROR';
const CAN_MARK_IN_ERROR_VERSION = 'MARKINERROR_VERSION_VISITDOC';

const CLINICAL_DOCUMENT_RIGHTS = [
  CAN_CREATE,
  CAN_VIEW,
  CAN_EDIT,
  CAN_SIGN,
  CAN_MARK_IN_ERROR,
  CAN_MARK_IN_ERROR_VERSION,
];

const getVisitAssessmentID = (document) => {
  if (!document) return null;
  const { VisitAssessmentID: _VisitAssessmentID } = document;
  if (isNumber(_VisitAssessmentID)) {
    return _VisitAssessmentID;
  }
  if (isArray(_VisitAssessmentID)) {
    const { VisitAssessmentID } = _VisitAssessmentID[0] || {};
    return VisitAssessmentID;
  }
  return null;
};

/**
 * Returns an object containing the user's rights for the provided document
 * @param {Object} data
 * @param {Object} userData
 */
const toDocumentRights = (data = {}, userData = {}, enterpriseId) => {
  const { RightID, EnterpriseID: userEnterpriseId } = userData;

  const { RightsBase } = data;

  const relevantEnterpriseId = enterpriseId || userEnterpriseId;

  const documentEnterpriseId = getEnterpriseID(data);

  const isDocumentEnterpriseMatch = documentEnterpriseId
    ? documentEnterpriseId === relevantEnterpriseId
    : true;
  // From SYNUI-5133:
  // > The expected behavior is no or null Rightsbase means everyone can access it fully
  // > Other access to consider is that IsLocked is false or null (allow access) or IsLocked=true ( view-only access)
  if (!RightsBase) {
    return {
      canCreateNew: true,
      canDownload: true,
      canEditExisting: isDocumentEnterpriseMatch,
      canMarkInError: RightID.includes(CAN_MARK_IN_ERROR),
    };
  }

  const availableActions = toAvailableActionsForDocument(
    data,
    RightID,
    CLINICAL_DOCUMENT_RIGHTS
  );

  const hasViewRight = availableActions.includes(CAN_VIEW);
  const hasEditRight = availableActions.includes(CAN_EDIT);
  const hasSignRight = availableActions.includes(CAN_SIGN);
  const hasCreateRight = availableActions.includes(CAN_CREATE);
  const hasMarkInErrorRight = availableActions.includes(CAN_MARK_IN_ERROR);
  const hasMarkVersionInErrorRight = availableActions.includes(
    CAN_MARK_IN_ERROR_VERSION
  );

  return {
    canCreateNew: hasCreateRight || hasSignRight,
    canDownload: hasViewRight,
    canEditExisting:
      (hasEditRight || hasSignRight || hasViewRight) &&
      isDocumentEnterpriseMatch,
    canMarkInError: hasMarkInErrorRight,
    canMarkVersionInError: hasMarkVersionInErrorRight,
  };
};

const epic = combineEpics(...epics);
const reducer = combinePredicatedReducers(...reducers);

const DefaultAddButtonComponentWrapper = (props) => {
  const labels = useXeLabels();
  return (
    <IconButton
      dataElementName="add"
      {...props}
      icon={ADD}
      description={labels.Add}
    />
  );
};

const DefaultOpenButtonComponentWrapper = (props) => {
  const labels = useXeLabels();
  return (
    <IconButton
      dataElementName="openDocument"
      {...props}
      icon={OPEN_DOCUMENT}
      description={labels.Open}
    />
  );
};

/**
 * @typedef ClinicalDocumentButtonsProps
 * @property {number} [assessmentId] Associated assessment ID for the buttons. Note that this will always result in a createOrFind being invoked instead of getFromSpec
 * @property {function} setPreventDropDownClose  In at least one case, VersionControlCellRenderer, ClinicalDocumentButtons appear in a grid that live inside of a
 * popup.  VersionControlCellRenderer has logic built in to close the popup containing the grid if the user clicks outside of the PopUp.  This causes an issue where the
 * popup and inturn MarkInErrorPopup disappear from the the screen when the user tries to click on the MarkInErrorPopup to enter text.   This function allows us to
 * tell the Parent of the grid, again in this case VersionControlCellRenderer, to disable / enable the the close popup functionality.
 * @property {function} maxVersionNumber This is the maximum version number in the set of versions that are being representing in the grid.  This is only used in
 * VersionControlCellRenderer
 */

/**
 * @param {ClinicalDocumentButtonsProps} props
 */
export const ClinicalDocumentButtons = (props) => {
  const {
    data: initialData,
    assessmentId,
    ipid,
    ivid,
    userData,
    enterpriseId,
    showOpen = true,
    showDownload = true,
    showMarkInError = false,
    readOnly = false,
    showSocialPanelOnLoad = false,
    SocialPanelElement,
    PatientHeaderElement,
    viewButtonComponent: ViewButtonComponentWrapper,
    viewButtonComponentProps = EMPTY_OBJECT,
    onOpen,
    onSave,
    onProcessingStart,
    onMarkInError,
    labels = EMPTY_OBJECT,
    menuNode,
    onMarkVersionInError = NOOP_FUNCTION,
    setPreventDropDownClose = NOOP_FUNCTION,
    maxDocumentVersionNumber,
  } = props;

  const onSave$ = useRef$(onSave);
  const onProcessingStart$ = useRef$(onProcessingStart);
  const onMarkInError$ = useRef$(onMarkInError);

  const toFactXeVisitAssessment = useCallback(
    (data, VisitAssessmentPollResponse) => {
      return castSchema(FactXeVisitAssessmentSchema)({
        ...data,
        ...VisitAssessmentPollResponse,
      });
    },
    []
  );

  const enterprise = useEnterprise();
  const data$ = useRef$(initialData);
  const enterpriseId$ = useRef$(enterpriseId);
  const epicWithDeps = useCallback(
    (action$, state$) => {
      return epic(action$, state$, {
        data$,
        enterpriseId$,
        menuNode$: of(menuNode),
      });
    },
    [data$, enterpriseId$, menuNode]
  );

  const [state = {}, dispatch, action$] = useReducer$(reducer, epicWithDeps);
  const [documentData, setDocumentData] = useState(null);
  const [markInErrorData, setMarkInErrorData] = useState(null);

  const ipidNumber = getIpid(ipid);

  const { data = [] } = useXeQuery(getPatient({ ipid: ipidNumber }, identity), {
    enabled: !!ipidNumber,
  });
  const [associatedPatient = EMPTY_OBJECT] = data;
  /**
   * Store information about the document version we are potentially marking in error. This
   * also serves as a flag to display the MarkInError component used for marking versions in
   * error.
   */
  const [versionData, setVersionData] = useState(null);

  const { currentDocument = initialData, processingState } = state;

  const visitAssessmentId = getVisitAssessmentID(currentDocument);

  const { userData: fallbackUserData = EMPTY_OBJECT } = enterprise;

  const documentRights = toDocumentRights(
    initialData,
    userData || fallbackUserData,
    enterpriseId
  );
  const {
    canCreateNew,
    canEditExisting,
    canDownload,
    canMarkInError,
    canMarkVersionInError,
  } = documentRights;
  const isANewDocument = !visitAssessmentId;

  /**
   * Versions can only be marked in error if they are not the most current version
   * and the user has the rights to mark a version in error.  If the Version property of the
   * document we are rendering matches the maximum version in the data grid, we need to hide
   * the MarkVersionInError button
   *
   * Note: currentDocument is only provided by VersionControlCellRenderer
   */
  const showMarkVersionInError =
    !isNil(maxDocumentVersionNumber) &&
    currentDocument?.Version !== maxDocumentVersionNumber &&
    canMarkVersionInError;

  // function used to mark a version in error
  const { mutate: markVersionInErrorMutation } = useXeMutation(
    (data) => {
      return markVersionInError(
        data,
        { visitAssessmentId: versionData?.VisitAssessmentID },
        identity
      );
    },
    {
      onSuccess: () => {
        /**
         * When we have marked a version in error, clear the relevant state and
         * call onMarkVersionInError to let the parent know we have just changed
         * the data in this grid so take some action to refresh with some fresh data.
         */
        setVersionData(null);
        setPreventDropDownClose(false);
        onMarkVersionInError();
      },
    }
  );

  useEffect$(() => {
    return action$.pipe(
      ofType(STOP_POLLING_FOR_DOCUMENT_STATE),
      pluckFrp('value', '0'),
      withLatestFrom(onSave$),
      tap(([VisitAssessmentPollResponse, onSave]) => {
        return castFunction(onSave)(
          toFactXeVisitAssessment(currentDocument, VisitAssessmentPollResponse)
        );
      })
    );
  }, [currentDocument, onSave$]);

  useEffect$(() => {
    return action$.pipe(
      ofType(RECOVER_STALLED_DOCUMENT, POLL_FOR_DOCUMENT_STATE),
      pluckFrp('value'),
      withLatestFrom(onProcessingStart$),
      tap(([FactXeVisitAssessment, onProcessingStart]) => {
        return castFunction(onProcessingStart)(FactXeVisitAssessment);
      })
    );
  }, [onProcessingStart$]);

  useEffect$(() => {
    return action$.pipe(
      ofType(DID_MARK_DOCUMENT_IN_ERROR),
      pluckFrp('value'),
      withLatestFrom(onMarkInError$),
      tap(([AssetXeVisitAssessment, onMarkInError]) => {
        return castFunction(onMarkInError)(AssetXeVisitAssessment);
      })
    );
  }, [onMarkInError$]);

  if (processingState === PROCESSING_STATE_STALLED) {
    return (
      <IconButton
        icon={WARNING}
        description={labels.Recover}
        onClick={() => {
          dispatch({
            type: RECOVER_STALLED_DOCUMENT,
            value: currentDocument,
          });
        }}
      />
    );
  }

  const OpenDocComponent = () => {
    if (isANewDocument) {
      if (!canCreateNew) return null;
      const ButtonWrapper =
        ViewButtonComponentWrapper || DefaultAddButtonComponentWrapper;
      return (
        <ButtonWrapper
          onClick={() => {
            setDocumentData(currentDocument);
          }}
          {...viewButtonComponentProps}
        />
      );
    }
    if (!showOpen || !canEditExisting) return null;
    const ButtonWrapper =
      ViewButtonComponentWrapper || DefaultOpenButtonComponentWrapper;
    return (
      <ButtonWrapper
        onClick={() => {
          const openHandler = () => setDocumentData(currentDocument);
          if (isFunction(onOpen)) {
            return onOpen(currentDocument, () =>
              setDocumentData(currentDocument)
            );
          }
          return openHandler();
        }}
        {...viewButtonComponentProps}
      />
    );
  };

  const DownloadDocComponent = () => {
    if (!showDownload || isANewDocument || !canDownload) return null;
    return (
      <IconButton
        dataElementName="downloadDocument"
        icon={DOWNLOAD_DOCUMENT}
        description={labels.Download}
        onClick={() => {
          dispatch({
            type: DOWNLOAD_EXISTING_DOCUMENT,
            value: currentDocument,
          });
        }}
      />
    );
  };

  const MarkInErrorComponent = () => {
    if (!showMarkInError || isANewDocument || !canMarkInError) return null;
    return (
      <IconButton
        dataElementName="clinicalDocumentsButton__remove"
        icon={REMOVE}
        description={labels.MarkInError}
        onClick={() => {
          setMarkInErrorData(currentDocument);
        }}
      />
    );
  };

  const currentDocumentIsValid =
    initialData && isObjectLike(initialData) && !isEmpty(currentDocument);

  return currentDocumentIsValid ? (
    <Flexbox
      inline={true}
      alignItems="center"
      data-busy={processingState === PROCESSING_STATE_PENDING}
    >
      <OpenDocComponent />
      <DownloadDocComponent />
      <MarkInErrorComponent />
      {showMarkVersionInError && (
        <IconButton
          dataElementName="clinicalDocumentsButton__removeVersion"
          icon={REMOVE}
          description={labels.MarkInError}
          onClick={() => {
            /**
             * Tell the parent (VersionControlCellRenderer) to prevent closing when the user clicks away
             * so we dont close this renderer when we try to enter text in the MarkInErrorPopup.
             *
             * Note: this button is only relevant when dealing with the VersionControlCellRenderer.
             */
            setPreventDropDownClose(true);
            // set the version data to open the MarkInErrorPopup.
            setVersionData(currentDocument);
          }}
        />
      )}

      {documentData ? (
        <ClinicalDocumentViewer
          readOnly={readOnly}
          SmartBookSchema={FactXeVisitAssessmentSchema}
          SmartBookInstanceSchema={XeSmartBookInstanceSchema}
          showSocialPanelOnLoad={showSocialPanelOnLoad}
          SocialPanelElement={SocialPanelElement}
          PatientHeaderElement={
            PatientHeaderElement || <Header patient={associatedPatient} />
          }
          data={[documentData]}
          ipid={ipid}
          ivid={ivid}
          enterpriseId={enterpriseId}
          assessmentId={assessmentId}
          onSave={([FactXeVisitAssessment] = []) => {
            // (SYNUI-5083) We don't currently have the requirement around adding `n` documents
            // to an instance of the viewer. When that time comes, this dispatch will need to be updated
            // to only poll for the document that was opened from this specific set of buttons (JDM)
            dispatch({
              type: POLL_FOR_DOCUMENT_STATE,
              value: toFactXeVisitAssessment(
                currentDocument,
                FactXeVisitAssessment
              ),
            });
          }}
          onClose={() => setDocumentData(null)}
        />
      ) : null}
      {markInErrorData ? (
        <MarkInErrorPopup
          minLength={25}
          onClose={() => setMarkInErrorData(null)}
          onConfirm={(reason) => {
            dispatch({
              type: MARK_DOCUMENT_IN_ERROR,
              value: currentDocument,
              reason,
            });
            setMarkInErrorData(null);
          }}
        />
      ) : null}
      {versionData && (
        <MarkInErrorPopup
          minLength={25}
          onClose={() => {
            /**
             * If the user closes the MarkInErrorPopup.  Reset our flags
             * which will close this MarkInErrorPopup and reset the parents (VersionControlCellRenderer)
             * automatic popup closing logic.
             */
            setVersionData(null);
            setPreventDropDownClose(false);
          }}
          onConfirm={(reason) => {
            // Make the mutation call
            markVersionInErrorMutation({
              VersionID: versionData?.VersionID,
              VisitAssessmentID: versionData?.VisitAssessmentID,
              ErrorDescription: reason,
            });
          }}
        />
      )}
    </Flexbox>
  ) : null;
};
