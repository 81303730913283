import PropTypes from 'prop-types';
import { useState } from 'react';
import { EMPTY_ARRAY } from '../../constants';
import fileSizeFormatter from '../../format/fileSizeFormatter';
import { FILE_UPLOAD } from '../../icons';
import { Icon } from '../Icon';
import { Label } from '../Label';
import './styles.css';
import { NOOP_FUNCTION } from '../../constants';

/**
 *
 * @param {FileList} fileList
 * @returns {File[]}
 */
const toFilesArray = (fileList) => Array.prototype.slice.call(fileList, 0);

/**
 * @param {{
 *  file: File
 * }} props
 */
export const Attachment = ({ file }) => (
  <span>
    {file.name}
    <span className="grey"> {fileSizeFormatter(file.size)}</span>
  </span>
);

/**
 * @typedef {Omit<'value', React.InputHTMLAttributes<HTMLInputElement>>} FileInputProps
 * @property {string} icon
 * @property {(x: File[]) => void} onChange
 * @property {string} className
 *
 * @param {Omit<'value', React.InputHTMLAttributes<HTMLInputElement>> & FileInputProps} props
 */
export const FileInput = (props) => {
  const {
    className = '',
    onChange = NOOP_FUNCTION,
    descriptor,
    icon = FILE_UPLOAD,
    dataElementName = '',
    ...passThroughProps
  } = props;

  /**
   * @type {[File[], import('react').Dispatch<import('react').SetStateAction<File[]>>]}
   */
  const [files, setFiles] = useState(EMPTY_ARRAY);

  return (
    <label className={`file-input ${className}`}>
      <Label descriptor={descriptor} dataElementName="attachment">
        <Icon icon={icon} />
        {files.map((file) => (
          <Attachment key={file.name} file={file} />
        ))}
      </Label>
      <input
        {...passThroughProps}
        data-element-name={dataElementName}
        onChange={(ev) => {
          const files = toFilesArray(ev?.target?.files);

          const returnValue = {
            nativeEvent: ev,
            value: files,
          };

          setFiles(files);
          onChange(returnValue);
        }}
        className="file-input__input-element"
        type="file"
      />
    </label>
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
