import { useState } from 'react';
import { isEmpty } from '../../../fp/pred';
import { Button, DropDownList, Label, Flexbox, Panel } from '../..';
import { formatStaffName } from '../../../utils';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { Popup, toDefaultPopupFooter } from '../..';
import { EMPTY_ARRAY, NOOP_FUNCTION } from '../../../constants';
import { REQUEST_OWNER_CHANGE, REQUEST_OWNER_RELEASE } from '../actions';
import { pluck } from '../../../fp/object';

/**
 * @typedef GrabResource
 * @property {string} ExternalID
 * @property {string} FamilyName
 * @property {string} GivenName
 * @property {number} AccountID
 */
/**
 *
 * @param {GrabResource} resource
 * @returns string
 */
const patientFormatter = (resource) => {
  if (!resource || isEmpty(resource)) return '';

  //Currently the resource dosn't actually conform to the schema, this is noticed
  const { ExternalID } = resource;

  const additionalDisplay = !!ExternalID ? ` (${ExternalID})` : '';

  return `${formatStaffName(resource)} ${additionalDisplay}`;
};

export const GrabAssign = (props) => {
  const {
    onConfirm,
    onClose,
    assignmentOptions = EMPTY_ARRAY,
    canGrabAssign,
    RecordID,
    ResourceID,
    StateOwner,
    currentOwnerName,
    dispatch = NOOP_FUNCTION,
  } = props;

  const OwnerResourceID = parseInt(StateOwner);

  const labels = useXeLabels();
  const [assignmentSelection, setAssignmentSelection] =
    useState(OwnerResourceID);
  const stateOwnerIsCurrentSelection =
    OwnerResourceID === assignmentSelection || isNaN(assignmentSelection);
  const isAlreadyAssignedToSelf = OwnerResourceID === parseInt(ResourceID);

  const onConfirmFn = () => {
    dispatch({
      type: REQUEST_OWNER_CHANGE,
      value: {
        RecordID: RecordID,
        StateOwner: assignmentSelection,
      },
    });
    onConfirm();
  };

  return (
    <Popup
      size="medium"
      FooterComponent={toDefaultPopupFooter({
        closeLabelKey: 'Cancel',
        confirmLabelKey: 'Save',
        disableConfirm: stateOwnerIsCurrentSelection,
        onClose: onClose,
        onConfirm: canGrabAssign ? onConfirmFn : undefined,
      })}
      title={labels.OwnerAssignment}
    >
      <Flexbox
        direction="column"
        className="fill-parent"
        justifyContent="center"
      >
        <Flexbox
          alignItems="center"
          justifyContent="center"
          className="margin-bottom-large"
        >
          <Label>
            {canGrabAssign
              ? labels.OwnerAssignmentMessageAnother
              : labels.OwnerAssignmentMessageSelf}
          </Label>
        </Flexbox>
        <Panel>
          <Flexbox justifyContent="flex-start" alignItems="center">
            <Label descriptor={labels.CurrentOwner}>
              {currentOwnerName ? currentOwnerName : `${labels.Unassigned}`}
            </Label>
          </Flexbox>
        </Panel>
        <Panel>
          <Flexbox alignItems="center" justifyContent="space-around">
            {canGrabAssign && (
              <Flexbox alignItems="center" justifyContent="center">
                <DropDownList
                  descriptor={labels.AssignToSelected}
                  value={assignmentSelection}
                  data={assignmentOptions}
                  labelFn={patientFormatter}
                  valueFn={pluck('ResourceID')}
                  defaultValue={labels.SearchStaff}
                  onChange={setAssignmentSelection}
                />
              </Flexbox>
            )}
            <Button
              className="margin-all-small"
              look="outline"
              disabled={isAlreadyAssignedToSelf}
              onClick={() => {
                dispatch({
                  type: REQUEST_OWNER_CHANGE,
                  value: {
                    RecordID: RecordID,
                  },
                });
                onClose();
              }}
            >
              {labels.AssignToSelf}
            </Button>
            <Button
              className="margin-all-small"
              look="outline"
              disabled={!StateOwner}
              onClick={() => {
                dispatch({
                  type: REQUEST_OWNER_RELEASE,
                  value: {
                    RecordID: RecordID,
                  },
                });
                onClose();
              }}
            >
              {labels.Unassign}
            </Button>
          </Flexbox>
        </Panel>
      </Flexbox>
    </Popup>
  );
};

export default GrabAssign;
