import { useContext, useState } from 'react';
import { CellRendererContext } from '../../../../../../components/Grid/contexts/CellRendererContext';
import { IconButton, MarkInErrorPopup } from '../../../../../../components';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../../../../../constants';
import { useXeRights } from '../../../../../../contexts/XeUserRightsContext';
import { useEnterprise } from '../../../../../../contexts/XeEnterpriseContext';
import { EDIT, VIEW, REMOVE } from '../../../../../../icons';
import {
  AUTH,
  ISSUE,
} from '../../../../../WorklistUITemplateWrapper/constants';
import { identity } from '../../../../../../fp/fp';
import { castSchema } from '../../../../../../schema/schemaCaster';
import { markInError as authMarkInError } from 'services/auths/xe-auths-svc';
import { markInError as issueMarkInError } from 'services/patient-issues/xe-patient-issues-svc';
import AuthSimpleRequest from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';
import PatientIssueDetails from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';
import { useXeMutation } from '../../../../../../data/useXeMutation';

const MARK_IN_ERROR_SERVICE = {
  [AUTH]: authMarkInError,
  [ISSUE]: issueMarkInError,
  //TODO: Implement this service once they are created. (JAC)
  // [PROGRAM]: x => x,
};

const MARK_IN_ERROR_SCHEMA = {
  [AUTH]: AuthSimpleRequest,
  [ISSUE]: PatientIssueDetails,
  //TODO: Implement this if it is different. (JAC)
  // [PROGRAM]: schema,
};

export const WorklistAction = (props) => {
  const {
    actionData,
    context,
    titleLabelKey,
    type,
    labels,
    rowData: {
      ActionList: { XeWork: { StateOwner } = EMPTY_OBJECT } = EMPTY_OBJECT,
    },
  } = props;
  const {
    EDIT_AUTH,
    CREATE_AUTH,
    VIEW_AUTH,
    MARKINERROR_AUTH,
    MARKINERROR_PATIENT_ISSUE,
  } = useXeRights();
  const {
    components: { WorklistWrapper },
  } = context;

  const { onSearch = NOOP_FUNCTION } = useContext(CellRendererContext);

  const [showWorklist, setShowWorklist] = useState(false);
  const [showMarkInError, setShowMarkInError] = useState(false);

  const markInErrorService = MARK_IN_ERROR_SERVICE[type] || identity;
  const markInErrorSchema = MARK_IN_ERROR_SCHEMA[type] || EMPTY_OBJECT;

  const { IsEdit, IVID, IsMarkInError, IssueIVID } = actionData;
  const { userData } = useEnterprise();

  const { mutate: markInErrorMutation } = useXeMutation(
    (data) =>
      markInErrorService(data, { ivid: IVID, issueIvid: IssueIVID }, identity),
    {
      onSuccess: () => {
        setShowMarkInError(false);
        onSearch();
      },
    }
  );

  const canEdit = EDIT_AUTH || CREATE_AUTH;
  const canMarkInErrorAuth =
    IsMarkInError && (MARKINERROR_AUTH || StateOwner === userData.ResourceID);
  const canMarkInErrorIssue = IsMarkInError && MARKINERROR_PATIENT_ISSUE;

  const { disabled, iconLabel, descriptionLabel, canView } =
    type === AUTH
      ? {
          disabled: !canEdit,
          iconLabel: canEdit ? EDIT : VIEW,
          descriptionLabel: canEdit ? labels.Edit : labels.View,
          canView: VIEW_AUTH || canEdit,
        }
      : {
          disabled: false,
          iconLabel: EDIT,
          descriptionLabel: labels.Edit,
          canView: true,
        };

  if (!IsEdit || !canView) {
    return <div />;
  }

  return (
    <>
      <IconButton
        dataElementName="worklistDetails__edit"
        icon={iconLabel}
        description={descriptionLabel}
        onClick={() => setShowWorklist(true)}
      />
      {canMarkInErrorAuth || canMarkInErrorIssue ? (
        <IconButton
          dataElementName="worklistDetails__edit"
          icon={REMOVE}
          description={labels.MarkInError}
          onClick={() => setShowMarkInError(true)}
        />
      ) : null}
      {showWorklist ? (
        <WorklistWrapper
          title={labels[titleLabelKey]}
          data={actionData}
          disabled={disabled}
          type={type}
          onClose={() => {
            setShowWorklist(false);
            onSearch();
          }}
          onSave={() => {
            setShowWorklist(false);
          }}
          onTransition={() => {
            setShowWorklist(false);
            onSearch();
          }}
        />
      ) : null}
      {showMarkInError ? (
        <MarkInErrorPopup
          data={actionData}
          onConfirm={(errorMsg) => {
            const markInErrorRequestBody = castSchema(markInErrorSchema)({
              ErrorDescription: errorMsg,
            });
            markInErrorMutation(markInErrorRequestBody);
          }}
          onClose={() => setShowMarkInError(false)}
        />
      ) : null}
    </>
  );
};
