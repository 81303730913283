import { cloneElement, useCallback } from 'react';
import { castFunction, identity } from '../../fp/fp';
import { isNil, isFunction } from '../../fp/pred';
import { pluck } from '../../fp/object';
import { useSchema } from '../../schema/SchemaReducer';
import { toUIValid } from '../../schema/UIValidationProjection';
import { DropDownList as KendoDropDownList } from '@progress/kendo-react-dropdowns';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useActiveList } from '../../hooks/useActiveList';
import { UIControlLabel } from '../Label';
import { toIsKendoComponentValid } from '../utils';
import Flexbox from '../Flexbox';
import { NOOP_FUNCTION } from '../../constants';

/** @TODO Pull out as reusable hook (CJP) */
const useCleanup = (cb) => {
  useEffect(() => {
    return () => cb && cb();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

const DEFAULT_SEARCH_TIMEOUT = 1000;

/**
 * @typedef {import('@progress/kendo-react-dropdowns').DropDownListProps} KendoDropdown
 */

const ValueRender = (props) => {
  const {
    data,
    element,
    valueRender,
    labelFn,
    onValidationChange,
    getLastValidRef,
    getDropDownListRef,
  } = props;

  const lastValidRef = getLastValidRef();
  const dropDownListRef = getDropDownListRef();
  const uiValid = toIsKendoComponentValid(dropDownListRef.current);

  useEffect(() => {
    // Initial note:
    //There is a timing bug with the dropdown component getting its value and us checking if the value is valid
    //At initial render the component doesn't have a value yet so we can't check the validity of default or existing values
    //To work around this we are checking validity when we render the value since then we have the value to compare
    //This is expensive and dirty, but there isn't really a work around (SG SYNUI-5541)

    // Moving this into a component to invoke `onValidationChange` after a render cycle if the uiValid has changed. The previous implementation where
    // we invoked this function in the middle of rendering threw a "bad setState" react warning (JDM)
    if (lastValidRef.current !== uiValid) {
      lastValidRef.current = uiValid;
      onValidationChange(uiValid);
    }
  }, [onValidationChange, lastValidRef, uiValid]);

  const { props: elementProps } = element;

  if (isFunction(valueRender)) {
    return valueRender(element, data);
  }
  return cloneElement(element, elementProps, labelFn(data));
};

const defaultComparator = (a, b) => a === b;

/**
 * @typedef CustomDropDownProps
 * @property {string} [name]
 * @property {string} [descriptor]
 * @property {string} [dataPath]
 * @property {(x:any) => string} [valueFn]
 * @property {(x:any) => string} [labelFn]
 * @property {string} [descriptorClassName]
 * @property {(listItemValue: any, dropDownListValue: any) => boolean} [comparator]
 * @property {(x: array) => any} [initialValueFn]
 * @property {(x: any, y?: boolean) => void} [onChange]
 * @property {(x: boolean) => void} [onValidation]
 */

/**
 *
 * @param {KendoDropdown & CustomDropDownProps} props
 */
export const DropDownList = (props) => {
  const {
    dataElementName = '',
    dataPath,
    valueFn = identity,
    labelFn = identity,
    data,
    onChange = NOOP_FUNCTION,
    onBlur = NOOP_FUNCTION,
    value: propsValue,
    descriptor,
    descriptorClassName = '',
    comparator = defaultComparator,
    required: propsRequired = false,
    dataItemKey,
    valueRender,
    itemRender,
    initialValueFn,
    onValidation = NOOP_FUNCTION,
    className,
    children,
    useActiveFilter = true,
    ...passThroughProps
  } = props;

  // This shouldn't be how this works. You should be able to observe the change handler even with a dataPath
  // if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
  //   console.warn(
  //     '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
  //   );
  // }
  // What we _should_ check is whether there exists both a dataPath and a value prop. Those conflict.
  if (!isNil(propsValue) && dataPath) {
    console.error(
      `Value conflict: A \`value\` prop was provided where \`dataPath={${dataPath}\` was also provided. \`value\` will be ignored`
    );
  }

  const [internalTouched, setInternalTouched] = useState(false);

  const {
    onValueChange,
    value = propsValue,
    touched = false,
    required: schemaRequired = false,
    validityMessage = '',
    onTouched = () => setInternalTouched(true),
    onValidationChange = onValidation,
    onCleanup = NOOP_FUNCTION,
    schemaNode,
  } = useSchema(dataPath);

  const _onChange = useCallback(
    (...args) => {
      castFunction(onValueChange)(...args);
      castFunction(onChange)(...args);
    },
    [onValueChange, onChange]
  );

  const isFullEquivalentToValue = (dropDownListItem, dropDownValue) => {
    if (dataItemKey) {
      return (
        pluck(dataItemKey)(dropDownListItem) ===
        pluck(dataItemKey)(dropDownValue)
      );
    }
    return comparator(valueFn(dropDownListItem), dropDownValue);
  };

  // We are showing either Active=true/false data with backend configuration
  // through the AttrDetail property SNET-914 (JCh)
  const onlyActiveData = useActiveList(data, value, isFullEquivalentToValue);
  const filteredData = useActiveFilter ? onlyActiveData : data;
  const _dropDownListRef = useRef();
  const lastValidRef = useRef(true);

  const required = schemaRequired || propsRequired;

  useCleanup(onCleanup);

  /**
   * @type {Array.<{ item: any, label: string }>}
   */
  const kendoDropDownListData = filteredData.map((x) => {
    const itemLabel = labelFn(x);
    return {
      item: x,
      label: isNil(itemLabel) ? '' : itemLabel,
    };
  });

  const kendoDropDownListValue =
    kendoDropDownListData.find(({ item }) =>
      isFullEquivalentToValue(item, value)
    ) || null;

  // (SYNUI-5551) How we do default selections should be expanded upon, but this should be a good start to get us going.
  // If you'd like to reuse this code, let's talk about why and how we can improve this (JDM)
  useEffect(() => {
    if (isNil(kendoDropDownListValue) && Array.isArray(data) && data.length) {
      // Always default required dropdowns w/ only 1 value (AZ)
      if (data.length === 1 && required) {
        lastValidRef.current = null;
        const defaultValue = valueFn(data[0]);
        if (!isNil(defaultValue)) {
          _onChange(defaultValue);
        }
        // Otherwise use the initialValueFn if provided (AZ)
      } else if (isFunction(initialValueFn)) {
        // (SYNUI-5505) This is a bit of a hack. There's not enough information here to know whether the component is valid or not
        // since we are calling the onChange callback outside of the change handler flow.
        // This should prompt the the `valueRender` callback to invoke `onValidated` (JDM)
        lastValidRef.current = null;
        const fallbackDefault = valueFn(initialValueFn(data));
        if (!isNil(fallbackDefault)) {
          _onChange(fallbackDefault);
        }
      }
    }
    // Intentional. The conditions under which we'd like to derive an initial value are specifically when the data source
    // or component value has been updated and nothing else.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kendoDropDownListValue, data]);

  return (
    <>
      <UIControlLabel
        dataElementName={
          dataElementName !== '' ? dataElementName + '__label' : 'label'
        }
        className={descriptorClassName}
        required={required}
      >
        {descriptor}
      </UIControlLabel>
      <Flexbox alignItems="center" className={className} inline={true} gap={4}>
        <KendoDropDownList
          ref={(ref) => {
            if (ref && _dropDownListRef.current !== ref) {
              ref.element.setAttribute('data-component-name', 'DropDownList');
              ref.element.setAttribute('data-element-name', dataElementName);
            }
            _dropDownListRef.current = ref;
          }}
          data={kendoDropDownListData}
          value={kendoDropDownListValue}
          textField="label"
          onChange={(ev) => {
            const {
              target: { validity, value },
            } = ev;

            if (!value) {
              return undefined;
            }

            const { item } = value;

            const itemValue = valueFn(item);

            _onChange(itemValue);
            lastValidRef.current = toUIValid(validity);
            onValidationChange(lastValidRef.current);
          }}
          itemRender={(element, listItem) => {
            const { props: elementProps } = element;
            const { dataItem: { item } = { item: undefined } } = listItem;
            if (isFunction(itemRender)) {
              return itemRender(element, {
                ...listItem,
                dataItem: item,
              });
            }
            return cloneElement(element, elementProps, labelFn(item));
          }}
          valueRender={(element, selectedItem) => {
            const { item } = selectedItem || {};
            return (
              <ValueRender
                data={item}
                element={element}
                valueRender={valueRender}
                labelFn={labelFn}
                onValidationChange={onValidationChange}
                getLastValidRef={() => lastValidRef}
                getDropDownListRef={() => _dropDownListRef}
              />
            );
          }}
          required={required}
          validityStyles={schemaNode ? touched : internalTouched}
          valid={!validityMessage ? undefined : false}
          validationMessage={validityMessage}
          onBlur={(event) => {
            onBlur(event);
            onTouched();
          }}
          delay={DEFAULT_SEARCH_TIMEOUT}
          {...passThroughProps}
        />
        {children}
      </Flexbox>
    </>
  );
};

DropDownList.propTypes = {
  data: PropTypes.array.isRequired,
  dataPath: PropTypes.string,
  value: PropTypes.any,
  valueFn: PropTypes.func,
  labelFn: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  descriptor: PropTypes.string,
  descriptorClassName: PropTypes.string,
  comparator: PropTypes.func,
  required: PropTypes.bool,
  initialValueFn: PropTypes.func,
  dataElementName: PropTypes.string,
};
