import { useState } from 'react';
import { Button, Checkbox, Flexbox, Label, Popup } from '../../../components';
import { EMPTY_OBJECT } from '../../../constants';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { isTrue } from '../../../utils';

const SIGNED_STATUS = 'SIGNED';

export const SaveSelectionPopup = (props) => {
  const { onClose, onSave, canSignInvalidDoc, documentSet, validate } = props;

  const modifiedDocuments = documentSet.filter(
    ({
      changed,
      data: { VisitAssessmentID, Status } = {},
      fileSetChanged,
    } = {}) =>
      changed ||
      fileSetChanged ||
      (VisitAssessmentID && Status !== SIGNED_STATUS)
  );

  const [selectedIndicesMap, updateSelectedIndicesMap] = useState(
    documentSet.reduce((idsMap, params = {}, index) => {
      if (!validate) {
        return {
          ...idsMap,
          [index]: true,
        };
      }
      const {
        changed,
        fileSetChanged,
        valid,
        data: { VisitAssessmentID, Status } = {},
      } = params;

      return {
        ...idsMap,
        [index]:
          ((changed || fileSetChanged) && valid) ||
          (VisitAssessmentID && Status !== SIGNED_STATUS && valid),
      };
    }, {})
  );

  const canSave = Object.values(selectedIndicesMap).some(isTrue);

  const labels = useXeLabels();

  return (
    <Popup
      dataElementName="clinicalDocumentViewer__saveSelection__popup"
      size="small"
      FooterComponent={() => (
        <div>
          <Button onClick={onClose} className="default-popup-footer-button">
            {labels.Cancel}
          </Button>
          <Button
            disabled={!canSave}
            onClick={() => {
              const setToSave = (() => {
                if (!!modifiedDocuments) {
                  return Object.values(modifiedDocuments).map(
                    ({ data } = {}) => data
                  );
                }
                return documentSet;
              })();
              return onSave({
                documentSet: setToSave.filter((x, index) => {
                  return selectedIndicesMap[index];
                }),
                isSign: validate,
              });
            }}
            className="default-popup-footer-button"
          >
            {validate ? labels.Sign : labels.SaveAndClose}
          </Button>
        </div>
      )}
    >
      <Flexbox direction="column">
        {documentSet.map((params = EMPTY_OBJECT, index) => {
          const { data = {}, changed, valid, canEdit } = params;
          const { Name, VisitAssessmentID, Status } = data;
          const disabled = (() => {
            if (!validate) {
              return !changed || !canEdit;
            }
            //SYNUI-6657: Need to short circuit if these specific rules are passed,
            // as the ClinDoc can be signed without change. (JAC)
            if (VisitAssessmentID && Status !== SIGNED_STATUS) return !valid;
            return !changed || !canEdit || (!valid && !canSignInvalidDoc);
          })();
          return (
            <Flexbox direction="column" key={index}>
              <Checkbox
                disabled={disabled}
                label={Name}
                checked={selectedIndicesMap[index]}
                onChange={(checked) => {
                  updateSelectedIndicesMap({
                    ...selectedIndicesMap,
                    [index]: checked,
                  });
                }}
              />
              {validate && !valid ? (
                <Label wrapText className="text--warning">
                  {canSignInvalidDoc
                    ? labels.SignIncompleteDocument
                    : labels.InvalidDocumentMissingFields}
                </Label>
              ) : null}
            </Flexbox>
          );
        })}
      </Flexbox>
    </Popup>
  );
};
