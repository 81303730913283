import { combineEpics } from 'redux-observable';
import { useReducer$ } from '../../../../hooks/useReducer$';
import { useMenuNode } from '../../../../contexts/XeMenuNodeContext';
import { useEffect$ } from '../../../../hooks/useEffect$';
import { identity } from '../../../../fp/fp';
import { ofType } from '../../../../frp/operators/ofType';
import { localeFormat } from '../../../../format/luxonToDisplayString';
import { useXeRights } from '../../../../contexts/XeUserRightsContext';
import {
  pluck,
  withLatestFrom,
  filter,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { useState, useCallback } from 'react';
import {
  Flexbox,
  GridLayout,
  IconButton,
  Label,
  AddTask,
  TaskChangeLog,
  MarkInErrorPopup,
} from '../../../../components';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { toDisplayDateFromISOString } from '../../../../g11n/displayDates';
import { EDIT, HISTORY, REMOVE } from '../../../../icons';
import { markInError } from 'services/wait-lists/xe-wait-lists-svc';
import { of } from 'rxjs';

const SHOULD_MARK_TASK_IN_ERROR = 'action/shouldMarkTaskInError';
const SHOULD_REFRESH = 'action/shouldRefresh';

const epics = [
  (action$, state$, { menuNode$ }) =>
    action$.pipe(
      ofType(SHOULD_MARK_TASK_IN_ERROR),
      pluck('value'),
      withLatestFrom(
        menuNode$.pipe(
          pluck('requestFn'),
          filter((x) => x),
          map((fn) => fn())
        )
      ),
      mergeMap(([value, toRequest$]) =>
        markInError(value, {}, toRequest$({ fullRequest: true }))
      ),
      pluck('results'),
      map((value) => ({
        type: SHOULD_REFRESH,
        value,
      }))
    ),
];

const epic = combineEpics(...epics);

export const TaskRender = (props) => {
  const {
    data,
    onRefresh,
    canEdit: canEditProp = true,
    canMarkInError = true,
  } = props;
  const {
    PreferredDateTime,
    CompletedDateTime,
    WaitListSubCategoryID: { Name: Category } = {},
    PriorityID: { Name: Priority } = {},
    StatusID: { Name: Status } = {},
    Description,
    CancelReason,
  } = data;

  const menuNode = useMenuNode();
  const epicWithDeps = useCallback(
    (action$, state$) => epic(action$, state$, { menuNode$: of(menuNode) }),
    [menuNode]
  );
  const [, dispatch, action$] = useReducer$(identity, epicWithDeps);

  const {
    CREATE_WAITLIST,
    EDIT_WAITLIST,
    MARKINERROR_WAITLIST,
    VIEW_WAITLIST,
  } = useXeRights();

  const canView = VIEW_WAITLIST || CREATE_WAITLIST || EDIT_WAITLIST;
  const canEdit = canEditProp && (CREATE_WAITLIST || EDIT_WAITLIST);
  const markInError = canMarkInError && MARKINERROR_WAITLIST;

  const labels = useXeLabels();
  const [showTask, setShowTask] = useState(false);
  const [showChangeLog, setShowChangeLog] = useState(false);
  const [showMarkInError, setShowMarkInError] = useState(false);

  useEffect$(
    () =>
      action$.pipe(
        ofType(SHOULD_REFRESH),
        tap(() => onRefresh(data)),
        tap(() => setShowMarkInError(false))
      ),
    [action$, data, onRefresh, setShowMarkInError]
  );

  return (
    <>
      {canView && (
        <Flexbox direction="column">
          <GridLayout
            dataElementName="taskDetails"
            templateColumns="max-content auto auto"
            className="padding-all-medium"
          >
            <Label
              dataElementName="taskDetails__date"
              descriptor={labels.TaskDueDate}
              className="task-details-render__label--column2-span2"
            >
              {toDisplayDateFromISOString(
                PreferredDateTime,
                localeFormat.MEDIUM
              )}
            </Label>
            {CompletedDateTime && (
              <Label
                dataElementName="taskDetails__date"
                descriptor={labels.TaskCompletedDate}
                className="task-details-render__label--column2-span2"
              >
                {toDisplayDateFromISOString(
                  CompletedDateTime,
                  localeFormat.MEDIUM
                )}
              </Label>
            )}
            <Label
              dataElementName="taskDetails__category"
              descriptor={labels.Category}
              className="task-details-render__label--column2-span2"
            >
              {Category}
            </Label>
            <Label
              dataElementName="taskDetails__priority"
              descriptor={labels.Priority}
              className="task-details-render__label--column2-span2"
            >
              {Priority}
            </Label>
            <Label
              dataElementName="taskDetails__status"
              descriptor={labels.Status}
              className="task-details-render__label--column2-span2"
            >
              {Status}
            </Label>
            <Label
              dataElementName="taskDetails__description"
              descriptor={labels.Description}
              className="task-details-render__label--column2-span2"
            >
              {Description}
            </Label>
            {CancelReason && (
              <Label
                dataElementName="taskDetails__cancelReason"
                descriptor={labels.CancelReason}
                className="task-details-render__label--column2-span2"
              >
                {CancelReason}
              </Label>
            )}
            <Flexbox
              style={{ gridColumn: '1 / -1' }}
              justifyContent="space-between"
            >
              <div>
                {canEdit && (
                  <IconButton
                    dataElementName="taskDetails__edit"
                    look="default"
                    icon={EDIT}
                    description={labels.Edit}
                    onClick={() => setShowTask(true)}
                  />
                )}
                <IconButton
                  dataElementName="taskDetails__changeLog"
                  look="default"
                  description={labels.ChangeLog}
                  icon={HISTORY}
                  onClick={() => setShowChangeLog(true)}
                />
              </div>
              {markInError && (
                <IconButton
                  dataElementName="taskDetails__markInError"
                  look="default"
                  onClick={() => setShowMarkInError(true)}
                  description={labels.MarkInError}
                  icon={REMOVE}
                />
              )}
            </Flexbox>
          </GridLayout>
        </Flexbox>
      )}
      {showTask && (
        <AddTask
          dataElementName="newTask"
          // TODO: This category value will need to pivot for grievances vs issues vs auth, etc (JCM / SYNUI-5514)
          category="TASKAUTH"
          value={data}
          onSave={(value) => {
            onRefresh(value);
            setShowTask(false);
          }}
          onClose={() => setShowTask(false)}
        />
      )}
      {showChangeLog && (
        <TaskChangeLog task={data} onClose={() => setShowChangeLog(false)} />
      )}
      {showMarkInError && (
        <MarkInErrorPopup
          onConfirm={(reason) => {
            dispatch({
              type: SHOULD_MARK_TASK_IN_ERROR,
              value: {
                ErrorDescription: reason,
                WaitListID: data.WaitListID,
              },
            });
          }}
          onClose={() => setShowMarkInError(false)}
        />
      )}
    </>
  );
};

export default TaskRender;
