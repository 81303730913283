import { EMPTY_OBJECT } from '../../../../constants';
import {
  AUTH,
  ISSUE,
  PHM,
  PROVIDER_PORTAL,
} from '../../../WorklistUITemplateWrapper/constants';
import { GlobalActionAddWorklist } from './GlobalActionAddWorklist';
import GlobalActionAvailableGuidelines from './GlobalActionAvailableGuidelines';
import { GlobalActionGenerateScandocFile } from './GlobalActionGenerateScandocFile';
import { GlobalActionPatientProvider } from './GlobalActionPatientProvider';
import { GlobalActionVisitProvider } from './GlobalActionVisitProvider';
import { GlobalActionXeClearingHouse } from './GlobalActionXeClearingHouse';

const GlobalActionXeAuth = (props) => {
  return (
    <GlobalActionAddWorklist
      {...props}
      type={AUTH}
      titleLabelKey="AuthDetails"
    />
  );
};

const GlobalActionXePatientIssue = (props) => {
  return (
    <GlobalActionAddWorklist
      {...props}
      type={ISSUE}
      titleLabelKey="IssueDetails"
    />
  );
};

const GlobalActionXeAuthProvider = (props) => {
  return (
    <GlobalActionAddWorklist
      {...props}
      type={PROVIDER_PORTAL}
      titleLabelKey="AuthDetails"
    />
  );
};

const GlobalActionXeVisitCaseManagementProgram = (props) => {
  return (
    <GlobalActionAddWorklist {...props} type={PHM} titleLabelKey="Details" />
  );
};

const GlobalActionComponentMap = {
  ADD_AUTH: GlobalActionXeAuth,
  ADD_AUTH_PROVIDER: GlobalActionXeAuthProvider,
  ADD_ISSUE: GlobalActionXePatientIssue,
  PATIENT_PROV: GlobalActionPatientProvider,
  ADD_PROGRAM: GlobalActionXeVisitCaseManagementProgram,
  GENERATE_SCANDOC_FILE: GlobalActionGenerateScandocFile,
  VISIT_PROV: GlobalActionVisitProvider,
  ADD_CLEARING_HOUSE: GlobalActionXeClearingHouse,
  AVAILABLE_GUIDELINES: GlobalActionAvailableGuidelines,
};

export const GlobalActions = (props) => {
  const {
    data = '',
    gridApi,
    dashboardDetails = EMPTY_OBJECT,
    components = EMPTY_OBJECT,
    onSearch,
    labels,
    refData,
    rights,
  } = props;

  const globalActionArray = data ? data.split('|') : [];

  return globalActionArray.map((actionName) => {
    const ActionElement = GlobalActionComponentMap[actionName] || (() => null);

    return (
      <ActionElement
        key={actionName}
        gridApi={gridApi}
        components={components}
        dashboardDetails={dashboardDetails}
        labels={labels}
        refData={refData}
        rights={rights}
        onSearch={onSearch}
      />
    );
  });
};
