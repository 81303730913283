export const ADD_PATIENT_CONTACT_POPUP_KEY = 'addPatientContact';

export const DEFAULT_STATE = {
  callLog: {},
};

export const GENERAL_CALL_TYPE = 'GENERAL';

export const callTypeToTaskCategoryMap = {
  AUTHORIZATION: 'TASKAUTH',
  CASE_MGMT: 'TASKCM',
  ISSUE: 'TASKISSUE',
  PROGRAM: 'TASKPROGRAM',
  GENERAL: 'TASK',
};

export const ASSIGN_CLINICAL_CONTACT_TRANSIENT_KEY = 'assignClinicalContact';
