import { localeFormat } from '../../../../format/luxonToDisplayString';
import { combineEpics } from 'redux-observable';
import { pluck } from '../../../../fp/object';
import { identity } from '../../../../fp/fp';
import { ofType } from '../../../../frp/operators/ofType';
import {
  withLatestFrom,
  filter,
  map,
  mergeMap,
  tap,
  pluck as rxjsPluck,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { useReducer$ } from '../../../../hooks/useReducer$';
import { useXeRights } from '../../../../contexts/XeUserRightsContext';
import { useMenuNode } from '../../../../contexts/XeMenuNodeContext';
import { useEffect$ } from '../../../../hooks/useEffect$';
import { isOneOf, exists, isNil } from '../../../../fp/pred';
import { schemaPluck } from '../../../../schema/schemaTypeBuilder';
import { useState, useCallback } from 'react';
import VisitCorrespondenceRecipDetailSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail.json';
import {
  IconButton,
  Label,
  LetterWizard,
  MarkInErrorPopup,
  Flexbox,
  GridLayout,
} from '../../../../components';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { toDisplayDateFromISOString } from '../../../../g11n/displayDates';
import {
  DOWNLOAD_DOCUMENT,
  DOWNLOAD_FILE,
  DOWNLOAD_PREVIEW,
  EDIT,
  REMOVE,
} from '../../../../icons';
import { formatAddress } from '../../../../utils';
import { castFunction } from '../../../../fp/fp';
import { useRequestDownloadFile } from '../../../../hooks/useRequestDownloadFile';
import { EMPTY_OBJECT } from '../../../../constants';
import useWorklistTemplateWrapperContext from '../../../WorklistUITemplateWrapper/hooks/useWorklistTemplateWrapperContext';
import { markInError } from 'services/correspondence-recipients/xe-correspondence-recipients-svc';
import '../styles.css';

const SHOULD_MARK_CORRESPONDENCE_IN_ERROR =
  'action/shouldCorrespondenceInError';
const SHOULD_REFRESH = 'action/shouldRefresh';

const epics = [
  (action$, state$, { menuNode$ }) =>
    action$.pipe(
      ofType(SHOULD_MARK_CORRESPONDENCE_IN_ERROR),
      rxjsPluck('value'),
      withLatestFrom(
        menuNode$.pipe(
          rxjsPluck('requestFn'),
          filter((x) => x),
          map((fn) => fn())
        )
      ),
      mergeMap(([value, toRequest$]) =>
        markInError(value, {}, toRequest$({ fullRequest: true }))
      ),
      rxjsPluck('results'),
      map((value) => ({
        type: SHOULD_REFRESH,
        value,
      }))
    ),
];

const epic = combineEpics(...epics);

const toName = pluck('Name');
const toStatusID = pluck('StatusID');
const toFileID = pluck('FileID');
const toCode = pluck('Code');
const toCheckCode = (code) => (value) => toCode(value) === code;
const isMail = toCheckCode('MAIL');
export const CorrespondenceDetailsRender = (props) => {
  const {
    data,
    worklistDetails = EMPTY_OBJECT,
    onPDFClick,
    onPreviewClick,
    historyItem = EMPTY_OBJECT,
    onRefresh,
    dataElementName,
  } = props;

  const labels = useXeLabels();
  const menuNode = useMenuNode();
  const epicWithDeps = useCallback(
    (action$, state$) => epic(action$, state$, { menuNode$: of(menuNode) }),
    [menuNode]
  );
  const [, dispatch, action$] = useReducer$(identity, epicWithDeps);

  const { worklistVariationData } = useWorklistTemplateWrapperContext();

  const {
    AddressLine1,
    DeliveryStatus,
    Fax,
    GeneratedDateTime,
    IsInError,
    ErrorData,
    IsPatient,
    IsProvider,
    Name,
    SendMethod,
    SendMethodDetail,
    SentDateTime,
    StatusID,
    CreateTStamp,
    FileID,
  } = data;

  const { ParentKey } = historyItem;

  const isGeneratedOrResent = isOneOf(
    'GENERATED',
    'RESENT'
  )(toStatusID(StatusID));

  const isFinal = isOneOf('FINAL')(toStatusID(StatusID));

  const recipientLabel = (() => {
    if (!(IsPatient || IsProvider)) return '';
    return IsPatient ? labels.Patient : labels.Provider;
  })();

  const isWorkFlow = schemaPluck(
    VisitCorrespondenceRecipDetailSchema,
    'VisitCorrespondenceID',
    'CorrespondenceInfoID',
    'SubType',
    'IsWorkflow'
  )(data);

  const [showEditLetter, setShowEditLetter] = useState(false);
  const [showMarkInError, setShowMarkInError] = useState(false);
  const { ipid, ivid } = worklistVariationData;
  const { MARKINERROR_CORRESPONDENCE } = useXeRights();
  const canMarkInError = MARKINERROR_CORRESPONDENCE;

  const ViewButtonElement = onPDFClick ? (
    <IconButton
      look="default"
      dataElementName="correspondenceDetails__renderPDF"
      className="correspondence-details-render__pdf-button"
      icon={DOWNLOAD_DOCUMENT}
      description={labels.View}
      onClick={() => castFunction(onPDFClick)(toFileID(FileID))}
    />
  ) : null;

  const PreviewButtonElement = onPreviewClick ? (
    <IconButton
      look="default"
      className="correspondence-details-render__pdf-button"
      icon={DOWNLOAD_PREVIEW}
      description={labels.Preview}
      onClick={() => castFunction(onPreviewClick)(data)}
    />
  ) : null;

  const requestDownloadFile = useRequestDownloadFile();

  useEffect$(
    () =>
      action$.pipe(
        ofType(SHOULD_REFRESH),
        tap(() => onRefresh(data)),
        tap(() => setShowMarkInError(false))
      ),
    [action$, data, onRefresh, setShowMarkInError]
  );

  return (
    <>
      <Flexbox
        dataElementName={
          !!dataElementName
            ? `${dataElementName}__activityAssetRender`
            : 'activityAssetRender'
        }
        direction="column"
        data-component-name="Correspondence-Detail-Render"
      >
        <GridLayout
          templateColumns="max-content auto"
          className="padding-all-medium"
          style={{ justifyItems: 'start' }}
        >
          <Label
            dataElementName={labels.Created}
            descriptor={labels.Created}
            className="correspondence-details-render__display"
          >
            {toDisplayDateFromISOString(CreateTStamp, localeFormat.MEDIUM)}
          </Label>
          {isGeneratedOrResent && (
            <Label
              dataElementName={labels.Generated}
              descriptor={labels.Generated}
              className="correspondence-details-render__display"
            >
              {toDisplayDateFromISOString(
                GeneratedDateTime,
                localeFormat.MEDIUM
              )}
            </Label>
          )}
          <Label
            dataElementName={labels.Sent}
            descriptor={labels.Sent}
            className="correspondence-details-render__display"
          >
            {toDisplayDateFromISOString(SentDateTime, localeFormat.LONG)}
          </Label>
          <Label dataElementName={labels.Status} descriptor={labels.Status}>
            {toName(StatusID)}
          </Label>
          <Label dataElementName={labels.Delivery} descriptor={labels.Delivery}>
            {toName(DeliveryStatus)}
          </Label>
          <Label dataElementName={labels.Error} descriptor={labels.Error}>
            {ErrorData}
          </Label>
          <Label
            dataElementName={labels.Method}
            descriptor={labels.Method}
            className="correspondence-details-render__display"
          >
            {toName(SendMethod)}
          </Label>
          {isMail(SendMethod) && (
            <Label
              dataElementName={labels.Detail}
              descriptor={labels.Detail}
              className="correspondence-details-render__display"
            >
              {toName(SendMethodDetail)}
            </Label>
          )}
          <Label
            dataElementName={labels.To}
            descriptor={labels.To}
            className="correspondence-details-render__display"
          >
            {Name} {recipientLabel && `(${recipientLabel})`}
          </Label>
          {AddressLine1 && (
            <Label
              dataElementName={labels.Address}
              descriptor={labels.Address}
              className="correspondence-details-render__display"
            >
              {formatAddress(data)}
            </Label>
          )}
          {Fax && (
            <Label
              dataElementName={labels.Fax}
              descriptor={labels.Fax}
              className="correspondence-details-render__display"
            >
              {Fax}
            </Label>
          )}
          <Flexbox
            style={{ gridColumn: '1 / -1' }}
            justifyContent="space-between"
          >
            <div>
              {exists(toFileID(FileID))
                ? ViewButtonElement
                : PreviewButtonElement}
              {!isNil(FileID?.FileID) ? (
                <IconButton
                  dataElementName="correspondenceDetails__downloadPDF"
                  look="default"
                  icon={DOWNLOAD_FILE}
                  description={labels.Download}
                  onClick={() => {
                    const { FileID: fileId, FileName: fileName } = FileID;
                    requestDownloadFile(fileId, fileName);
                  }}
                />
              ) : null}
              {exists(ParentKey) &&
              pluck('IsEditable')(StatusID) &&
              !isFinal &&
              !isWorkFlow ? (
                <IconButton
                  look="default"
                  icon={EDIT}
                  description={labels.Edit}
                  onClick={() => setShowEditLetter(true)}
                />
              ) : null}
            </div>
            {canMarkInError && (
              <IconButton
                dataElementName="correspondenceDetails__markInError"
                look="default"
                onClick={() => setShowMarkInError(true)}
                description={labels.MarkInError}
                icon={REMOVE}
              />
            )}
          </Flexbox>
        </GridLayout>
      </Flexbox>
      {showEditLetter && (
        <LetterWizard
          params={{
            IPID: ipid,
            IVID: ivid,
            LetterType: ivid ? 'AUTH' : 'ISSUE',
            SubType: 'ADHOC',
            VisitStatus:
              pluck('XePatientVisit', 'Status')(worklistDetails) ||
              pluck('Status')(worklistDetails),
            VisitCorrespondenceID: ParentKey,
            WorkList: worklistDetails,
          }}
          onSave={() => {
            onRefresh();
            setShowEditLetter(false);
          }}
          onClose={() => setShowEditLetter(false)}
        />
      )}
      {showMarkInError && (
        <MarkInErrorPopup
          onConfirm={(reason) => {
            dispatch({
              type: SHOULD_MARK_CORRESPONDENCE_IN_ERROR,
              value: {
                VisitCorrespRecipID: [data.VisitCorrespRecipID],
                IsInError: true,
                ErrorDescription: reason,
                VisitCorrespondenceID:
                  data.VisitCorrespondenceID.VisitCorrespondenceID,
              },
            });
            setShowMarkInError(false);
          }}
          onClose={() => setShowMarkInError(false)}
        />
      )}
    </>
  );
};
