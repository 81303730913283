import { useState } from 'react';
import { IconButton } from '../../../../..';
import { EDIT } from '../../../../../../icons';
import { ClearingHouseRecordPopupView } from '../../../../../ClearingHouseRecordPopup';
import { NOOP_FUNCTION } from '../../../../../../constants';
import { useContext } from 'react';
import { CellRendererContext } from '../../../../contexts/CellRendererContext';

export const EditClearingHouseRecordAction = (props) => {
  const { actionData, labels } = props;
  const { IsEdit, ClearingHouseID } = actionData;

  const [showEditClearingHousePopup, setShowEditClearingHousePopup] =
    useState(false);

  const { onSearch = NOOP_FUNCTION } = useContext(CellRendererContext);

  return (
    <>
      {IsEdit ? (
        <IconButton
          icon={EDIT}
          description={labels.Edit}
          onClick={() => setShowEditClearingHousePopup(true)}
        />
      ) : null}
      {showEditClearingHousePopup ? (
        <ClearingHouseRecordPopupView
          onClose={() => {
            setShowEditClearingHousePopup(false);
          }}
          onSaveClose={() => {
            setShowEditClearingHousePopup(false);
            onSearch();
          }}
          ClearingHouseID={ClearingHouseID}
        />
      ) : null}
    </>
  );
};

export default EditClearingHouseRecordAction;
