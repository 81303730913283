import PropTypes from 'prop-types';
import { useCallback, isValidElement } from 'react';
import './styles.css';
import { EMPTY_OBJECT, NOOP_FUNCTION, NULL_FUNCTION } from '../../constants';

const toBodyChildren = (isExpanded, preserveChildrenOnCollapse, children) => {
  if (preserveChildrenOnCollapse) return children;

  return isExpanded ? children : null;
};

const Body = (props) => {
  const { isExpanded, preserveChildrenOnCollapse, className, children } = props;

  const displayClass = !isExpanded ? 'k-display-none' : '';
  const bodyChildren = toBodyChildren(
    isExpanded,
    preserveChildrenOnCollapse,
    children
  );

  return (
    <div className={`expandable-container-body ${displayClass} ${className}`}>
      {bodyChildren}
    </div>
  );
};

export const ExpandableContainer = (props) => {
  const {
    dataElementName = '',
    Header = NULL_FUNCTION,
    Controller,
    onChange = NOOP_FUNCTION,
    isExpanded = false,
    headerProps = EMPTY_OBJECT,
    preserveChildrenOnCollapse = false,
    children,
    className = '',
    headerClassName = '',
    bodyClassName = '',
    controllerWrapperClassName,
    style = EMPTY_OBJECT,
  } = props;

  const _onChangeCallback = useCallback(
    () => onChange(!isExpanded),
    [isExpanded, onChange]
  );

  const _RealizedControllerComponent = Controller ? (
    <button
      data-element-name="expandableContainer__expand"
      className={`expandable-container__controller ${controllerWrapperClassName}`}
      onClick={_onChangeCallback}
      type="button"
    >
      {isValidElement(Controller) ? Controller : <Controller {...props} />}
    </button>
  ) : null;

  return (
    <div
      data-element-name={
        dataElementName !== ''
          ? `${dataElementName}__expandableContainer`
          : `expandableContainer`
      }
      data-ejs-component-name="ExpandableContainer"
      data-component-name="ExpandableContainer"
      className={`expandable-container ${
        isExpanded ? 'expanded' : 'expandable-container--collapsed'
      } ${className}`}
      style={style}
    >
      <div
        className={`expandable-container-header ${headerClassName}`}
        onClick={!Controller ? _onChangeCallback : NOOP_FUNCTION}
      >
        <Header
          dataElementName={dataElementName}
          {...headerProps}
          Controller={_RealizedControllerComponent}
        />
      </div>
      <Body
        isExpanded={isExpanded}
        preserveChildrenOnCollapse={preserveChildrenOnCollapse}
        className={bodyClassName}
      >
        {children}
      </Body>
    </div>
  );
};

ExpandableContainer.propTypes = {
  Header: PropTypes.func,
  Controller: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  headerProps: PropTypes.object,
  onChange: PropTypes.func,
  isExpanded: PropTypes.bool,
  preserveChildrenOnCollapse: PropTypes.bool,
  dataElementName: PropTypes.string,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  style: PropTypes.object,
};

export default ExpandableContainer;
