import { castSchema } from '../../schema/schemaCaster';
import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import ServiceBookingFullDetails from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails.json';
import { RESPONSE_GET_APPOINTMENT_DETAILS } from './actions';

export default [
  when(isType(RESPONSE_GET_APPOINTMENT_DETAILS))(
    toActionValueReducer('appointment', castSchema(ServiceBookingFullDetails))
  ),
];
