import { isNil } from '../../../../fp/pred';
import { combineLatest } from 'rxjs';
import { filter, map, mergeMap, pluck, withLatestFrom } from 'rxjs/operators';
import { browse } from 'services/call-logs/xe-call-logs-svc.js';
import { RESPONSE_CALL_LOG_HISTORY } from './actions';

export const toCallLogHistoryEpic$ = (
  action$,
  state$,
  { ipid$, ivid$, menuNode$ }
) => {
  return combineLatest([ipid$, ivid$]).pipe(
    filter(([ipid, ivid]) => !isNil(ipid) || !isNil(ivid)),
    withLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap((params) => {
      const [combineLatestResult, toRequest$] = params;
      const [ipid, ivid] = combineLatestResult;
      return browse(
        { IPID: ipid, IVID: ivid },
        {},
        toRequest$({ fullRequest: true })
      );
    }),
    pluck('results'),
    map((response) => {
      return {
        type: RESPONSE_CALL_LOG_HISTORY,
        value: response,
      };
    })
  );
};

export default [toCallLogHistoryEpic$];
