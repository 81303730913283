import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import { RESPONSE_ASSIGN_CANDIDATES } from './actions';

export default [
  when(isType(RESPONSE_ASSIGN_CANDIDATES))(
    toActionValueReducer('assignmentOptions')
  ),
];
