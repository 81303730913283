import { schemaPluck } from '../../../schema/schemaTypeBuilder';
import { pluck } from '../../../fp/object';
import { isNil, isObjectLike } from '../../../fp/pred';
import { XePatientSearchWidget } from '../../../widgets';
import { AttachmentButton, DropDownList, Label, Panel } from '../../';
import { Flexbox, GridLayout, IconButton, Button } from '../../../components';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { SEARCH } from '../../../icons';
import XePatientSearchSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch.json';
import { formatCommonName } from '../../../utils';
import { useXeRefData } from '../../../contexts/XeRefDataContext';
import { EMPTY_OBJECT } from '../../../constants';

export const FileSummaryHeader = (props) => {
  const {
    docTypeId,
    scanDocId,
    statusCode,
    data = EMPTY_OBJECT,
    hideAdd,
    onBrowse,
    onAddFile,
    onShowUpdate,
    ipid,
    ipidData,
  } = props;
  const labels = useXeLabels();

  const { XeScanDocTypeCategory, XeResultStatusCode } = useXeRefData();
  const { DocTypeID, ScanDocID, XeScanDocSet = [] } = data;

  const docTypeIdString =
    typeof DocTypeID === 'string' ? DocTypeID : pluck('DocTypeID')(DocTypeID);

  const docTypeIdObject = XeScanDocTypeCategory.find(
    ({ id }) => id === docTypeIdString
  ) || {
    item: {
      IsIPIDAllowed: false,
      IsIPIDRequired: false,
      IsObjectLink: false,
      IsRootType: false,
    },
  };

  const {
    item: { IsIPIDRequired, IsIPIDAllowed, IsObjectLink, IsRootType },
  } = docTypeIdObject;

  const showMemberWidget = (() => {
    if (!isNil(ipid)) return false;
    const scanDocIdCase = IsRootType && !IsObjectLink && IsIPIDAllowed;
    const noScanDocIdCase = IsIPIDRequired || IsIPIDAllowed;
    return ScanDocID ? scanDocIdCase : noScanDocIdCase;
  })();

  const LinkElement = ScanDocID ? (
    <Label descriptor={labels.Type} descriptorClassName="align-self-center">
      {docTypeIdObject.text}
    </Label>
  ) : (
    <DropDownList
      data={[{}, ...XeScanDocTypeCategory]}
      dataPath="DocTypeID"
      descriptor={labels.Type}
      required={true}
      labelFn={pluck('text')}
      valueFn={pluck('id')}
      className="file-details-panel__grid-content"
    />
  );

  return (
    <Panel className="flex-shrink-0">
      <Flexbox alignItems="center" justifyContent="space-between">
        <GridLayout
          autoFlow="column"
          alignItems="center"
          style={{ gridAutoColumns: 'max-content' }}
        >
          {ScanDocID && (
            <Label
              dataElementName="fileSummary__id"
              descriptor={labels.ID}
              descriptorClassName="align-self-center"
            >
              {ScanDocID}
            </Label>
          )}
          {docTypeId ? null : LinkElement}
          {statusCode ? (
            <Label
              dataElementName="fileSummary__status"
              descriptor={labels.Status}
              descriptorClassName="align-self-center"
            >
              {
                (
                  XeResultStatusCode.find(
                    ({ id: code } = {}) =>
                      code ===
                      (isObjectLike(statusCode)
                        ? statusCode.StatusCode
                        : statusCode)
                  ) || {}
                ).text
              }
            </Label>
          ) : (
            <DropDownList
              dataElementName="fileSummary__status"
              data={[{}, ...XeResultStatusCode]}
              dataPath="StatusCode"
              descriptor={labels.Status}
              labelFn={pluck('text')}
              valueFn={pluck('id')}
            />
          )}
          {showMemberWidget ? (
            <XePatientSearchWidget
              dataElementName="fileSummary__patient"
              descriptor={labels.Patient}
              dataPath="IPID"
              valueFn={schemaPluck(XePatientSearchSchema, 'IPID')}
              required={IsIPIDRequired}
            />
          ) : !isNil(ipid) ? (
            <Label
              dataElementName="fileSummary__patient"
              descriptor={labels.Patient}
              descriptorClassName="align-self-center"
            >
              {formatCommonName(ipidData, 'F, G')}
            </Label>
          ) : null}
        </GridLayout>
        {hideAdd ? (
          <Button
            dataElementName="fileSummary__update"
            onClick={onShowUpdate}
            disabled={!XeScanDocSet.filter(({ Active }) => Active).length}
          >
            {labels.UpdateInSet}
          </Button>
        ) : (
          <Flexbox>
            {scanDocId && (
              <IconButton
                dataElementName="fileSummary__browse"
                description={labels.BrowseExisting}
                icon={SEARCH}
                onClick={onBrowse}
                tooltip={false}
                look="flat"
              />
            )}
            <AttachmentButton
              dataElementName="fileSummary__upload"
              onChange={(files) => {
                onAddFile(files);
              }}
              multiple={false}
            >
              {labels.UploadFile}
            </AttachmentButton>
          </Flexbox>
        )}
      </Flexbox>
    </Panel>
  );
};
