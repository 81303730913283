import { isNil } from '../../fp/pred';
import { getIpid } from '../../utils/pluckers';

export const toClinicalDocumentReportParams = (data = {}) => {
  const {
    ReportID,
    VisitAssessmentID,
    VersionID,
    XeVisitAssessmentVersion = [],
    Version: dataVersion,
    IPID: propsIpid = {},
  } = data;

  const IPID = getIpid(propsIpid);

  //From the clinicalContactsButtons we have a different shape that only has the version and an array of all the versions
  //So we will use that data as a fallback if VersionID doesn't exit on the data
  const possibleVersionData =
    XeVisitAssessmentVersion.find(({ Version }) => Version === dataVersion) ||
    {};
  const VersionIDWithFallback = VersionID || possibleVersionData.VersionID;

  const reportParams = [
    {
      Name: 'IPID',
      Value: IPID,
    },
    {
      Name: 'VisitAssessmentID',
      Value: VisitAssessmentID,
    },
    {
      Name: 'VersionID',
      Value: VersionIDWithFallback,
    },
  ]
    .filter(({ Value }) => {
      return !isNil(Value);
    })
    .map((o) => {
      return {
        ...o,
        Value: `${o.Value}`,
      };
    });

  return {
    OutputType: 'pdf',
    Report: [
      {
        Parameter: reportParams,
        ReportID,
      },
    ],
    ReturnResults: true,
  };
};
