import { combineEpics } from 'redux-observable';
import { useReducer$ } from '../../../../hooks/useReducer$';
import { useMenuNode } from '../../../../contexts/XeMenuNodeContext';
import { useSchemaDispatch } from '../../../../schema/SchemaReducer';
import { UPSERT } from '../../../../schema/JSONSchemaReducer';
import { pluck } from '../../../../fp/object';
import { localeFormat } from '../../../../format/luxonToDisplayString';
import { useCallback, useContext, useState } from 'react';
import { CellRendererContext } from '../../../../components/Grid/contexts/CellRendererContext';
import {
  DropDownList,
  Flexbox,
  Grid,
  GridLayout,
  IconButton,
  UIControlLabel,
  ViewHeader,
  Checkbox,
  TextInput,
  OverflowContainer,
} from '../../../';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { useXeRefData } from '../../../../contexts/XeRefDataContext';
import {
  TODAY,
  PAST_7D,
  PAST_1M,
  PAST_6M,
  ALL,
} from '../../../../dates/dateOptions';
import { toDisplayDateFromISOString } from '../../../../g11n/displayDates';
import { ADD, EXPORT_DATA } from '../../../../icons';
import { XeDateRangeWidget } from '../../../../widgets/XeDateRangeWidget';
import { Panel } from '../../../Panel';
import {
  REQUEST_DOWNLOAD_ITEM,
  REQUEST_SCAN_DOCS,
  RESPONSE_SCAN_DOCS,
} from './actions';
import epics from './epics';
import { of } from 'rxjs';
import { EMPTY_OBJECT } from '../../../../constants';

const reducer = (state, action) => {
  switch (action.type) {
    case REQUEST_SCAN_DOCS: {
      return {
        ...state,
        scanDocs: null,
      };
    }
    case RESPONSE_SCAN_DOCS: {
      const { value = [] } = action;
      return {
        ...state,
        scanDocs: value.filter(({ Active } = {}) => Active),
      };
    }
    default: {
      return state;
    }
  }
};

const epic = combineEpics(...epics);

const RowActionsCell = ({ data = EMPTY_OBJECT }) => {
  const {
    onAdd,
    onDownload,
    scanDocSet = [],
    categories = [],
  } = useContext(CellRendererContext);
  const {
    // eslint-disable-next-line no-unused-vars
    ItemID,
    CategoryID = {},
    DocumentTypeID = {},
    ...rest
  } = data;
  const labels = useXeLabels();
  const schemaDispatch = useSchemaDispatch();

  const isValidCategory = !!categories.find(
    ({ CategoryID: id } = {}) => id === CategoryID.DocTypeID
  );

  return (
    <Flexbox alignItems="center">
      <IconButton
        dataElementName="attachmentGrid__add"
        description={labels.Add}
        className="file-set-action icon-button"
        icon={ADD}
        onClick={() => {
          schemaDispatch({
            type: UPSERT,
            path: 'XeScanDocSet',
            value: [
              ...scanDocSet,
              isValidCategory ? { ...rest, CategoryID, DocumentTypeID } : rest,
            ],
          });
          onAdd();
        }}
      />
      <IconButton
        dataElementName="attachmentGrid__download"
        description={labels.Download}
        className="file-set-action icon-button"
        icon={EXPORT_DATA}
        onClick={() => onDownload(data)}
      />
    </Flexbox>
  );
};

const MINI_RANGE_OPTIONS = [TODAY, PAST_7D, PAST_1M, PAST_6M, ALL];
const COLUMN_DEFS = [
  {
    headerLabelKey: 'Type',
    field: 'DocumentTypeID.Name',
  },
  {
    headerLabelKey: 'Status',
    field: 'StatusCode.Description',
  },
  {
    headerLabelKey: 'Description',
    field: 'Description',
  },
  {
    headerLabelKey: 'Uploaded',
    field: 'UploadDate',
    valueFormatter: ({ value }) => {
      return toDisplayDateFromISOString(value, localeFormat.LONG);
    },
  },
  {
    headerName: '',
    width: 128,
    resizable: false,
    sortable: false,
    enableRowGroup: false,
    suppressMovable: true,
    cellRenderer: RowActionsCell,
    pinned: 'right',
  },
];

const toDefaultFilters = (ipid) => ({
  documentTypeId: '',
  selectedDateRange: PAST_1M,
  statusCode: '',
  ipid,
  description: '',
});

export const AttachmentGrid = (props) => {
  const { onAdd, ipid, categories, scanDocSet } = props;

  const menuNode = useMenuNode();

  const epicWithDeps = useCallback(
    (action$, state$) => {
      return epic(action$, state$, { menuNode$: of(menuNode) });
    },
    [menuNode]
  );

  const [state, dispatch] = useReducer$(reducer, epicWithDeps);
  const labels = useXeLabels();

  const [filters, updateFilters] = useState(toDefaultFilters(ipid));

  const scanDocs = pluck('scanDocs')(state) || [];

  const { XeDocumentType, XeResultStatusCode } = useXeRefData();

  const onFilterChange = (key, value) => {
    return updateFilters({
      ...filters,
      [key]: value,
    });
  };

  const onDownload = (value) =>
    dispatch({ type: REQUEST_DOWNLOAD_ITEM, value });

  return (
    <Panel className="flex-1">
      <ViewHeader
        dataElementName="attachmentGrid"
        actions={{
          search: EMPTY_OBJECT,
          clear: EMPTY_OBJECT,
        }}
        onAction={({ type }) => {
          if (type === 'search') {
            dispatch({
              type: REQUEST_SCAN_DOCS,
              value: filters,
            });
          }

          if (type === 'clear') {
            updateFilters(toDefaultFilters(ipid));
          }
        }}
      >
        <OverflowContainer className="flex-1">
          <GridLayout autoFlow="column">
            <Flexbox direction="column">
              <TextInput
                dataElementName="attachmentGrid__description"
                descriptor={labels.Description}
                value={filters.description}
                onChange={(value) => onFilterChange('description', value)}
              />
            </Flexbox>
            <Flexbox direction="column" alignItems="center">
              <UIControlLabel dataElementName="attachmentGrid__patientAttachmentsLabel">
                {labels.PatientAttachments}
              </UIControlLabel>
              <Checkbox
                dataElementName="attachmentGrid__patientAttachments"
                checked={!!filters.ipid}
                onChange={(value) =>
                  onFilterChange('ipid', value ? ipid : undefined)
                }
              />
            </Flexbox>
            <Flexbox direction="column">
              <DropDownList
                dataElementName="attachmentGrid__documentType"
                data={[{}, ...XeDocumentType]}
                valueFn={pluck('id')}
                labelFn={pluck('text')}
                descriptor={labels.DocumentType}
                onChange={(value) => {
                  onFilterChange('documentTypeId', value);
                }}
                value={pluck('documentTypeId')(filters)}
              />
            </Flexbox>
            <Flexbox direction="column">
              <DropDownList
                dataElementName="attachmentGrid__statusCode"
                data={[{}, ...XeResultStatusCode]}
                valueFn={pluck('id')}
                labelFn={pluck('text')}
                descriptor={labels.Status}
                onChange={(value) => {
                  onFilterChange('statusCode', value);
                }}
                value={pluck('statusCode')(filters)}
              />
            </Flexbox>
            <Flexbox direction="column">
              <UIControlLabel dataElementName="attachmentGrid__uploadedLabel">
                {labels.Uploaded}
              </UIControlLabel>
              <XeDateRangeWidget
                dataElementName="attachmentGrid__dateRange"
                dateKeys={MINI_RANGE_OPTIONS}
                onChange={(value) => onFilterChange('selectedDateRange', value)}
                value={filters.selectedDateRange}
              />
            </Flexbox>
          </GridLayout>
        </OverflowContainer>
      </ViewHeader>
      <Grid
        dataElementName="attachment__grid"
        data={scanDocs}
        columnDefs={COLUMN_DEFS}
        condensed
        cellRendererContext={{
          onAdd,
          onDownload,
          scanDocSet,
          categories,
        }}
      />
    </Panel>
  );
};
