import { localeFormat } from '../../../format/luxonToDisplayString';
import { schemaPick } from '../../../schema/schemaTypeBuilder';
import XeVisitAssessmentListSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentList.json';
import { ClinicalDocumentButtons } from '../../../components/ClinicalDocumentButtons';
import { Flexbox, GridLayout, Label } from '../../../components';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { useXeRights } from '../../../contexts/XeUserRightsContext';
import { toDisplayDateFromISOString } from '../../../g11n/displayDates';
import { toFormattedDuration } from '../../../g11n/displayDuration';
import { formatStaffName } from '../../../utils';
import { toDurationFromISODateStrings } from '../../../utils/dates';
import { ActivityAssets } from '../constants';
import AttachmentDetailsRender from './components/AttachmentDetailsRender';
import TaskRender from './components/TaskRender';
import './styles.css';
import { useMenuNode } from '../../../contexts/XeMenuNodeContext';
import { CallLogDetailsRender } from './components/CallLogDetailsRender';
import { CorrespondenceDetailsRender } from './components/CorrespondenceDetailRender';

const NoteDetailsRender = (props) => {
  const { data } = props;
  const { Description } = data;

  return (
    <Flexbox
      wrap
      alignItems="center"
      className="padding-all-small note-details-render"
    >
      {Description}
    </Flexbox>
  );
};

const AuthDetailsRender = (props) => {
  const { data } = props;
  const {
    Description: Transition,
    ActivityName: Queue,
    ActivityDateStart: Started,
    ActivityDateEnd: Ended,
    ProviderID: Owner,
  } = data;

  const labels = useXeLabels();

  const startedDateString = toDisplayDateFromISOString(
    Started,
    localeFormat.LONG
  );
  const endedDateString = toDisplayDateFromISOString(Ended, localeFormat.LONG);

  return (
    <div className="display-grid display-grid--basic-form">
      <Label descriptor={labels.Transition}>{Transition}</Label>
      <Label descriptor={labels.Queue}>{Queue}</Label>
      {startedDateString && (
        <Label descriptor={labels.Started}>{startedDateString}</Label>
      )}
      {endedDateString && (
        <Label descriptor={labels.Ended}>{endedDateString}</Label>
      )}
      {Started && Ended && (
        <Label descriptor={labels.Time}>
          {toFormattedDuration(toDurationFromISODateStrings(Started, Ended))}
        </Label>
      )}
      <Label descriptor={labels.Owner}>{formatStaffName(Owner)}</Label>
    </div>
  );
};

const ClinicalDocumentRenderer = (props) => {
  const { data, onRefresh, Header, onMarkInError } = props;

  const {
    EnteredBy = {},
    SignedBy = {},
    EnteredDate,
    SignedDate,
    ServiceDate,
    ServiceEndDate,
    IPID,
    RightsBase,
  } = schemaPick(
    XeVisitAssessmentListSchema,
    'EnteredBy',
    'SignedBy',
    'EnteredDate',
    'SignedDate',
    'ServiceDate',
    'ServiceEndDate',
    'IPID',
    'RightsBase'
  )(data);

  const { [`MARKINERROR_${RightsBase}`]: hasMarkInErrorRight } = useXeRights();

  const labels = useXeLabels();
  const menuNode = useMenuNode();

  return (
    <div className="padding-all-medium">
      <GridLayout templateColumns="auto 1fr">
        <Label descriptor={labels.EnteredBy}>
          {`${formatStaffName(EnteredBy)} on ${toDisplayDateFromISOString(
            EnteredDate,
            localeFormat.LONG
          )}`}
        </Label>
        {SignedDate && SignedBy && (
          <Label descriptor={labels.SignedBy}>
            {`${formatStaffName(SignedBy)} on ${toDisplayDateFromISOString(
              SignedDate,
              localeFormat.LONG
            )}`}
          </Label>
        )}
        {ServiceEndDate && (
          <Label descriptor={labels.DateRange}>
            {`${toDisplayDateFromISOString(
              ServiceDate,
              localeFormat.LONG
            )} - ${toDisplayDateFromISOString(
              ServiceEndDate,
              localeFormat.LONG
            )}`}
          </Label>
        )}
        {!ServiceEndDate && ServiceDate && (
          <Label descriptor={labels.ServiceDate}>
            {toDisplayDateFromISOString(ServiceDate, localeFormat.LONG)}
          </Label>
        )}
      </GridLayout>
      <ClinicalDocumentButtons
        labels={labels}
        menuNode={menuNode}
        ipid={IPID}
        data={data}
        onSave={onRefresh}
        onMarkInError={onMarkInError}
        PatientHeaderElement={Header}
        showMarkInError={hasMarkInErrorRight}
      />
    </div>
  );
};

export const AssetRenderForType = {
  [ActivityAssets.XeCallLog]: CallLogDetailsRender,
  [ActivityAssets.XeScanDocSet]: AttachmentDetailsRender,
  [ActivityAssets.XeVisitCorrespRecip]: CorrespondenceDetailsRender,
  [ActivityAssets.XeVisitNote]: NoteDetailsRender,
  [ActivityAssets.XeHistoryWorkView]: AuthDetailsRender,
  [ActivityAssets.XeWaitList]: TaskRender,
  [ActivityAssets.XeVisitAssessment]: ClinicalDocumentRenderer,
};

/**
 * @typedef ActivityAssetRenderProps
 * @property {T} data
 * @property {import('../').ActivityAsset} type
 * @template T
 */

/**
 * @param {ActivityAssetRenderProps<T>} props
 * @template T
 */
export const ActivityAssetRender = (props) => {
  const { data, type, worklistDetails, ...rest } = props;
  if (!data) return null;

  const Render = AssetRenderForType[type];

  return Render ? (
    <Render data={data} worklistDetails={worklistDetails} {...rest} />
  ) : null;
};
