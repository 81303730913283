import { useEffect, useState } from 'react';
import {
  Flexbox,
  DropDownList,
  IconButton,
  Button,
  TelephoneInput,
} from '../../../components';
import { EMPTY_OBJECT } from '../../../constants';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { useXeMutation } from '../../../data/useXeMutation';
import phoneNumberFormatter from '../../../format/phoneNumberFormatter';
import { pluck } from '../../../fp/object';
import { EDIT } from '../../../icons';
import { useSchemaSelector } from '../../../schema/SchemaReducer';
import { QuickCallButtonWithSideEffects } from './QuickCallButtonWithSideEffects';
import { update as updateProviderRecipientPhoneNumber } from 'services/staff-address/xe-staff-address-svc';
import { identity } from '../../../fp/fp';
import { useXeQuery } from '../../../data/useXeQuery';
import { getPhoneContacts } from 'services/patients/xe-patients-svc';
import { SET_PHONE_CONTACTS_AFTER_EDIT } from '../actions';

/**
 * @typedef {import('services/generated/types').StaffAddressRequest} StaffAddressRequest
 * @typedef {import('services/generated/types').CallLogContact} CallLogContact
 * @typedef {import('services/generated/types').CallLogContact$XePhone} CallLogContact$XePhone
 */

/**
 *
 * @param {CallLogContact$XePhone} phoneObject
 * @returns
 */
const toFormattedPhoneLabel = (phoneObject = EMPTY_OBJECT) => {
  const { Phone, Label = '', IDName } = phoneObject;

  const formatedIDName = IDName ? ` (${IDName})` : '';
  const formattedPhoneLabel = Phone
    ? `${phoneNumberFormatter(Phone)} ${Label}${formatedIDName}`
    : '';

  return formattedPhoneLabel;
};

/**
 *
 * @param {CallLogContact$XePhone} phoneObject
 * @param {Number} phoneNumberToAdd
 * @returns
 */
const toStaffAddressRequest = (phoneObject, phoneNumberToAdd) => {
  const { Label, ContactInfoTypeID } = phoneObject;
  const isMobile = Label === 'Mobile';

  return {
    ...(isMobile
      ? { MobilePhone: phoneNumberToAdd }
      : { Phone: phoneNumberToAdd }),
    ...(ContactInfoTypeID ? { ContactInfoTypeID } : EMPTY_OBJECT),
  };
};

/**
 *
 * @param {Object} props
 * @param {Boolean} props.isVisible
 * @param {Array.<CallLogContact$XePhone>} props.callToPhoneOptions
 * @param {Boolean} props.readOnly
 * @param {Boolean} props.shouldEnableCallLinkRef
 * @param {CallLogContact} props.callWith
 * @param {Function} props.onSave
 * @param {Function} props.onEdit
 * @param {Function} props.onCancel
 * @returns
 */
export const CallToPhoneWrapper = (props) => {
  const {
    isVisible,
    callToPhoneOptions,
    readOnly,
    shouldEnableCallLinkRef,
    callWith,
    setCallWith,
    onSave,
    onEdit,
    onCancel,
    ipid,
    ivid,
    dispatch,
  } = props;

  const [callToPhoneToEdit] = useSchemaSelector((instance = EMPTY_OBJECT) => {
    const { CallToPhone: callToPhoneToEdit } = instance;
    return [callToPhoneToEdit];
  });

  const [phoneToEdit, setPhoneToEdit] = useState();
  const [updatedPhoneNumber, setUpdatedPhoneNumber] =
    useState(callToPhoneToEdit);
  const [isValid, setIsValid] = useState();
  const [hasEditedPhone, setHasEditedPhone] = useState();

  const selectedCallToPhoneOption = callToPhoneOptions.find(
    (callToPhoneOption) => callToPhoneOption?.Phone === callToPhoneToEdit
  );
  const isLocalCopy = selectedCallToPhoneOption?.ContactInfoTypeID !== 'MAIN';

  /**
   *
   * @param {Object} data
   * @param {StaffAddressRequest} data.staffAddressRequestBody - request body used to update phone number
   * @param {Object} data.headerParamObject - header body use
   * @returns Object - to be used by the mutation call
   */
  const editCallToPhoneFn = (data) => {
    const { staffAddressRequestBody, headerParamObject = EMPTY_OBJECT } = data;
    return updateProviderRecipientPhoneNumber(
      staffAddressRequestBody,
      headerParamObject,
      identity
    );
  };

  const { data, isFetching, refetch } = useXeQuery(
    getPhoneContacts(
      {
        ipid,
        ivid,
      },
      identity
    ),
    {
      enabled: !!ipid,
    }
  );

  useEffect(() => {
    if (hasEditedPhone && !phoneToEdit && !isFetching && !!data) {
      dispatch({
        type: SET_PHONE_CONTACTS_AFTER_EDIT,
        value: data,
      });
      const callWithToSet = data.find((callWithOption) => {
        return callWith?.CallerID === callWithOption?.CallerID;
      });
      setCallWith(callWithToSet);
      setHasEditedPhone();
    }
  }, [
    data,
    dispatch,
    isFetching,
    callWith,
    setCallWith,
    phoneToEdit,
    hasEditedPhone,
  ]);

  const { mutate: saveInlineCallToPhoneEdit } = useXeMutation(
    editCallToPhoneFn,
    {
      onSuccess: () => {
        refetch();
        setUpdatedPhoneNumber();
        setPhoneToEdit(undefined);
        onSave();
      },
    }
  );

  const labels = useXeLabels();

  if (!isVisible) return null;

  const isProvider = callWith?.CallContactType === 'CLINICAL';

  return (
    <Flexbox>
      {!!phoneToEdit ? (
        <TelephoneInput
          descriptor={labels.Phone}
          onChange={setUpdatedPhoneNumber}
          required={true}
          value={updatedPhoneNumber}
          onValidation={setIsValid}
        />
      ) : (
        <DropDownList
          dataElementName="phone"
          descriptor={labels.Phone}
          data={callToPhoneOptions}
          valueFn={pluck('Phone')}
          labelFn={toFormattedPhoneLabel}
          dataPath="CallToPhone"
          disabled={readOnly}
          required={true}
        />
      )}
      {phoneToEdit ? (
        <Flexbox>
          <Button
            look="outline"
            onClick={() => {
              setPhoneToEdit(undefined);
              onCancel();
            }}
            className="margin-left-small"
          >
            {labels.Cancel}
          </Button>
          <Button
            look="outline"
            onClick={() => {
              const staffAddressRequestBody = toStaffAddressRequest(
                phoneToEdit,
                updatedPhoneNumber
              );

              const headerParamObject = {
                staffId: callWith?.CallerID,
                addressId: phoneToEdit?.AddressID,
              };

              saveInlineCallToPhoneEdit({
                staffAddressRequestBody,
                headerParamObject,
              });
              setHasEditedPhone(true);
            }}
            className="margin-left-small"
            disabled={!isValid}
          >
            {labels.Save}
          </Button>
        </Flexbox>
      ) : (
        <Flexbox>
          {shouldEnableCallLinkRef ? (
            <QuickCallButtonWithSideEffects dataPath="CallToPhone" />
          ) : null}
          {!readOnly && isProvider && callToPhoneToEdit && isLocalCopy ? (
            <IconButton
              dataElementName="edit"
              icon={EDIT}
              tooltip={true}
              description={labels.Edit}
              onClick={(ev) => {
                ev.stopPropagation();
                const callLogPhoneToEdit = callWith?.XePhone.find(
                  (phoneObject) => {
                    const { Phone } = phoneObject;
                    return Phone === callToPhoneToEdit;
                  }
                );
                // set phone object for usage when calling mutation
                setPhoneToEdit(callLogPhoneToEdit);

                // initialize phone input with callToPhoneToEdit value
                setUpdatedPhoneNumber(callToPhoneToEdit);
                onEdit();
              }}
            />
          ) : null}
        </Flexbox>
      )}
    </Flexbox>
  );
};

export default CallToPhoneWrapper;
