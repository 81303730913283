import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import {
  POLL_FOR_DOCUMENT_STATE,
  RECOVER_STALLED_DOCUMENT,
  STOP_POLLING_FOR_DOCUMENT_STATE,
} from './actions';
import {
  PROCESSING_STATE_COMPLETED,
  PROCESSING_STATE_PENDING,
  PROCESSING_STATE_STALLED,
  IS_QUICK_SAVE_COMPLETED,
} from './constants';

export const updateFinalProcessingStateReducer = (state, action) => {
  const { value: lastPoll = [] } = action;
  const isDocumentStalled = lastPoll.some(
    ({ IsQuickSave } = {}) => IsQuickSave !== IS_QUICK_SAVE_COMPLETED
  );
  return {
    ...state,
    processingState: isDocumentStalled
      ? PROCESSING_STATE_STALLED
      : PROCESSING_STATE_COMPLETED,
  };
};

export default [
  when(isType(POLL_FOR_DOCUMENT_STATE, RECOVER_STALLED_DOCUMENT))(
    toActionValueReducer('processingState', () => PROCESSING_STATE_PENDING),
    toActionValueReducer('currentDocument')
  ),
  when(isType(STOP_POLLING_FOR_DOCUMENT_STATE))(
    updateFinalProcessingStateReducer
  ),
];
