import { Label } from '../../../../components';
import PropTypes from 'prop-types';

import './styles.css';

const VALID_TYPES = ['label', 'value'];

const baseClassName = 'clindoc__header-label';

export const HeaderLabel = (props) => {
  const {
    dataElementName = '',
    className = '',
    type = 'label',
    children = null,
  } = props;

  const valueClassName = type === 'value' ? `${baseClassName}--value` : '';

  return (
    <Label
      dataElementName={dataElementName}
      className={`${className} ${baseClassName} ${valueClassName}`}
    >
      {children}
    </Label>
  );
};

HeaderLabel.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(VALID_TYPES),
  children: PropTypes.node,
  dataElementName: PropTypes.string,
};
