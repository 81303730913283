import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import DashboardDetailSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardDetail.json';
import XeSmartBookInstanceSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json';
import { Flexbox, Grid, ViewHeader } from '../';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { useMenuNode } from '../../contexts/XeMenuNodeContext';
import { useXeRefData } from '../../contexts/XeRefDataContext';
import { useXeRights } from '../../contexts/XeUserRightsContext';
import { downloadFile } from '../../download/downloadFile';
import { castFunction } from '../../fp/fp';
import { pluck } from '../../fp/object';
import { isNil } from '../../fp/pred';
import { usePathNavigate } from '../../hooks/usePathNavigate';
import { useStateFromValue } from '../../hooks/useStateFromValue';
import { toColumnDefs, toRowData } from '../../utils/toRowDataAndColumnDefs';
import { dashboardExportParams } from '../Grid/utils';
import { OverflowContainer } from '../OverflowContainer';
import { SmartBook } from '../SmartBook';
import DashboardHeader from './components/DashboardHeader';
import { GlobalActions } from './components/GlobalActions';
import { AvailableGuidelines } from '../../../../xnetjs/src/components/AvailableGuidelines';

const hasFilters = (smartBook) => {
  if (!smartBook) return false;
  const instance = pluck('XeSmartBookInstance')(smartBook) || [];
  if (!instance.some((x) => x.IsVisible)) return false;
  const instanceAttrType = pluck('AttrType')(smartBook);

  return (
    !!instanceAttrType || !!pluck('XeSmartBookInstance', 'length')(smartBook)
  );
};

export const Dashboard = (props) => {
  const {
    condensed,
    showHeader,
    data = EMPTY_ARRAY,
    globalAction = '',
    components = EMPTY_OBJECT,
    smartBook,
    SmartBookSchema = DashboardDetailSchema,
    SmartBookInstanceSchema = XeSmartBookInstanceSchema,
    name = '',
    dataElementName = '',
    description = '',
    timestamp,
    context = EMPTY_OBJECT,
    actions = EMPTY_OBJECT,
    clearEnabled: propsClearEnabled,
    onSearch,
    onFiltersChange,
    onClear,
    onAction,
    columnDefs: providedColumnDefs,
    showGuidelines = false,
    hasSearched,
    isLoading,
    gridModel = EMPTY_OBJECT,
    pagedRowCount,
  } = props;

  const { rowModelType = 'clientSide' } = gridModel;

  const actionsProxy = new Proxy(actions, {
    get: (target, prop) => {
      if (prop in target) {
        return target[prop];
      }

      return {};
    },
  });

  const [gridRef, setGridRef] = useState({});

  const refData = useXeRefData();
  const labels = useXeLabels();
  const rights = useXeRights();
  const menuNode = useMenuNode();
  const navigate = usePathNavigate();

  const [initialSmartBookFilters, setInitialSmartBookFilters] =
    useStateFromValue(smartBook);
  const [currentSmartBookFilters, setCurrentSmartBookFilters] =
    useStateFromValue(smartBook);

  const [isSmartBookValid, updateIsSmartBookValidTo] = useState(true);
  /**
   * Dashboards have been standalone for the most part, but XeWorklist has a feature
   * where the last searched filters should persist when navigating.
   * If the clear button is uncontrolled (no `clearEnabled` from props), then the
   * Dashboard will handle the button's state on its own
   */
  const [internalClearEnabled, setInternalClearEnabled] = useState(
    isNil(propsClearEnabled) ? false : propsClearEnabled
  );
  const [isSearchTouched, updateSearchTouched] = useState(false);

  const _onSearch = useCallback(() => {
    castFunction(onSearch)(currentSmartBookFilters);
  }, [currentSmartBookFilters, onSearch]);

  const hasSmartBookFilters = hasFilters(smartBook);

  const columnDefs = providedColumnDefs
    ? providedColumnDefs
    : toColumnDefs([...data], {
        globalAction,
      });

  const isClearEnabled = isNil(propsClearEnabled)
    ? internalClearEnabled
    : propsClearEnabled;

  const isExportEnabled =
    rowModelType == 'clientSide' ? data.length : pagedRowCount > 0;

  return (
    <Flexbox
      direction="column"
      className="stretch flex-1"
      data-component-name="Dashboard"
      dataElementName={dataElementName}
    >
      <ViewHeader
        dataElementName={dataElementName}
        condensed={condensed}
        actions={{
          ...actionsProxy,
          search: {
            hidden: !smartBook,
            ...actionsProxy.search,
          },
          clear: {
            hidden: !hasSmartBookFilters,
            disabled: !isClearEnabled,
            ...actionsProxy.clear,
          },
          export: {
            disabled: !isExportEnabled,
            ...actionsProxy.export,
          },
        }}
        onAction={(ev) => {
          const { type } = ev;

          castFunction(onAction)(ev);

          switch (type) {
            case 'clear': {
              updateSearchTouched(false);
              //There is a slight timing issue where we don't fire onChange until the instance changes again, so if you search right after
              //clear you have the old state this solves that issue
              const nextFilters = { ...initialSmartBookFilters };
              setCurrentSmartBookFilters(nextFilters);
              setInitialSmartBookFilters(nextFilters);
              return castFunction(onClear)();
            }
            case 'export': {
              if (!gridRef) return;
              const csvBlob = new Blob(
                [gridRef.api.getDataAsCsv(dashboardExportParams)],
                {
                  type: 'text/csv',
                }
              );
              return downloadFile(csvBlob, `${name}.csv`);
            }
            case 'search': {
              if (!isSearchTouched) {
                updateSearchTouched(true);
              }
              if (!isSmartBookValid) return;
              return _onSearch();
            }
          }
        }}
      >
        {hasSmartBookFilters && (
          <OverflowContainer>
            <SmartBook
              dataElementName="dashboard__smartBook"
              instance={initialSmartBookFilters}
              onChange={({ instance, valid, changed }) => {
                if (changed) {
                  castFunction(onFiltersChange)({
                    value: instance,
                  });
                }
                setCurrentSmartBookFilters(instance);
                updateIsSmartBookValidTo(valid);

                if (isNil(propsClearEnabled)) {
                  setInternalClearEnabled(changed);
                }
              }}
              SmartBookSchema={SmartBookSchema}
              SmartBookInstanceSchema={SmartBookInstanceSchema}
              validityStyles={isSearchTouched}
            />
          </OverflowContainer>
        )}
      </ViewHeader>
      <Flexbox
        direction="column"
        className="flex-1"
        dataElementName="gridColumn"
      >
        <DashboardHeader
          showHeader={showHeader}
          Name={name}
          Description={description}
        >
          <GlobalActions
            data={globalAction}
            labels={labels}
            refData={refData}
            onSearch={_onSearch}
            rights={rights}
            gridApi={gridRef.api}
            dashboardDetails={smartBook}
            components={components}
          />
        </DashboardHeader>
        <Grid
          dataElementName="dashboard__grid"
          className="flex-1"
          condensed={condensed}
          columnDefs={columnDefs}
          data={toRowData(data)}
          gridModel={gridModel}
          pagedRowCount={pagedRowCount}
          rowSelection="multiple"
          context={{
            ...context,
            components,
            PatientNavigation: {
              toPatientRouteAction: (ipid) => {
                return navigate(`~/DefaultSearchFeature/${ipid}`);
              },
            },
          }}
          cellRendererContext={{
            labels,
            menuNode,
            refData,
            rights,
            onSearch: _onSearch,
          }}
          timestamp={timestamp}
          onGridReady={(ev) => {
            setGridRef(ev);
          }}
          autoSizeRowsOnUpdate={false}
          FooterComponent={showGuidelines ? <AvailableGuidelines /> : null}
          hasSearched={hasSearched}
          isLoading={isLoading}
        />
      </Flexbox>
    </Flexbox>
  );
};

export default Dashboard;

Dashboard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  globalAction: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
