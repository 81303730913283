import { ofType } from '../../../../../../frp/operators/ofType';
import { combineWithLatestFrom } from '../../../../../../frp/operators/combineWithLatestFrom';
import { useReducer$ } from '../../../../../../hooks/useReducer$';
import { castSchema } from '../../../../../../schema/schemaCaster';
import { useCallback } from 'react';
import { map, mergeMap, pluck } from 'rxjs/operators';
import { getPatient } from 'services/patients/xe-patients-svc';
import FactXeCallLogSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog.json';
import { PHONE_OUTLINE } from '../../../../../../icons';
import AddCallLogPopup from '../../../../../AddCallLogPopup';
import { IconButton } from '../../../../../IconButton';
import { of } from 'rxjs';
import { EMPTY_OBJECT } from '../../../../../../constants';

const toGetPatientDetailsEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType('getPatientDetails'),
    pluck('value'),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([ipid, toRequest$]) =>
      getPatient({ ipid }, toRequest$({ fullRequest: true }))
    ),
    pluck('results'),
    map(([value]) => ({
      type: 'responseGetPatient',
      value,
    }))
  );
};

const reducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case 'responseGetPatient': {
      const { value } = action;
      return {
        ...state,
        patientDetails: value,
      };
    }
    case 'clearPatientDetails': {
      return {
        ...state,
        patientDetails: null,
      };
    }
  }
};

export const CallLogAction = (props) => {
  const { actionData = EMPTY_OBJECT, menuNode } = props;

  const { IPID } = actionData;

  const epicWithDeps = useCallback(
    (action$, state$) => {
      return toGetPatientDetailsEpic$(action$, state$, {
        menuNode$: of(menuNode),
      });
    },
    [menuNode]
  );

  const [state = {}, dispatch] = useReducer$(reducer, epicWithDeps);
  const { patientDetails } = state;
  const initialCallLog = castSchema(FactXeCallLogSchema)({
    ...actionData,
    IPID: patientDetails,
  });

  return (
    <>
      <IconButton
        icon={PHONE_OUTLINE}
        onClick={() => {
          dispatch({
            type: 'getPatientDetails',
            value: IPID,
          });
        }}
      />
      {patientDetails ? (
        <AddCallLogPopup
          initialCallLog={initialCallLog}
          patient={patientDetails}
          onSave={() => {
            dispatch({
              type: 'clearPatientDetails',
            });
          }}
          onClose={() => {
            dispatch({
              type: 'clearPatientDetails',
            });
          }}
          showHistory
        />
      ) : null}
    </>
  );
};

export default CallLogAction;
