import { useState } from 'react';
import { pluck as pluckFp } from '../../../fp/object';
import {
  GridLayout,
  Flexbox,
  PDFViewer,
  PDFRangePanel,
  OverflowContainer,
} from '../../';
import { FileDetailsPanel } from './FileDetailsPanel';
import { toCategoryIDDocTypeID, toDocumentTypeID } from '../utils';
import { REQUEST_DOWNLOAD_ITEM } from '../actions';
import { EMPTY_ARRAY, EMPTY_OBJECT, PDF_FILE_TYPE } from '../../../constants';
import sortBy from 'lodash/sortBy';

const toFilledInterval = (low, high) => {
  return Array.from({ length: high - low + 1 }, (_, i) => low + i);
};

// TODO: in the future, this should also use SchemaReducer, but was running into complications (JCM / SYNUI-5139)
export const UpdateSet = ({
  dispatch,
  attachment = EMPTY_OBJECT,
  itemId,
  categories = EMPTY_ARRAY,
  pdfFileData,
  onChange,
}) => {
  const { XeScanDocSet = [] } = attachment;
  const file = XeScanDocSet.find(({ ItemID: id } = {}) => id === itemId);

  const { type = '' } = pdfFileData || {};
  const isPdf = type.includes(PDF_FILE_TYPE);

  const [fileData, updateFileData] = useState(file);
  const [splitData, updateSplitData] = useState([]);
  const [pdfData, setPDFData] = useState();
  const [disabledPages, setDisabledPages] = useState([]);

  const onSplitChanged = (value) => {
    updateSplitData(value);
    onChange({ fileData, splitData: value });
  };

  const onItemsAdded = (...newItems) => {
    setDisabledPages(
      newItems.reduce(
        (acc, { startPage, endPage }) => [
          ...acc,
          ...toFilledInterval(startPage, endPage).filter(
            (i) => !acc.includes(i)
          ),
        ],
        disabledPages
      )
    );

    const newData = [
      ...splitData,
      ...newItems.map((item = {}) => {
        const { startPage, endPage } = item;
        return {
          StartPage: startPage,
          EndPage: endPage,
          DocumentTypeID: toDocumentTypeID(toDocumentTypeID(fileData)),
          CategoryID: toCategoryIDDocTypeID(fileData),
        };
      }),
    ];

    const sortedData = sortBy(newData, pluckFp('StartPage'));

    return onSplitChanged(sortedData);
  };

  const onItemChanged = (newItem, index) => {
    onSplitChanged([
      ...splitData.slice(0, index),
      newItem,
      ...splitData.slice(index + 1),
    ]);
  };

  const onItemRemoved = (index) => {
    const removedSplitData = splitData.find((_, i) => i === index);
    onSplitChanged(splitData.filter((_, i) => i !== index));

    const { StartPage, EndPage } = removedSplitData;
    const pagesToReEnable = toFilledInterval(StartPage, EndPage);
    setDisabledPages(disabledPages.filter((e) => !pagesToReEnable.includes(e)));
  };

  return (
    <GridLayout
      templateColumns={isPdf ? '1fr 1fr' : '1fr auto'}
      className="flex-1"
    >
      <OverflowContainer>
        {file ? (
          <FileDetailsPanel
            categories={categories}
            onChange={(value) => {
              updateFileData(value);
              onChange({ fileData: value, splitData });
            }}
            onDownload={(value) =>
              dispatch({ type: REQUEST_DOWNLOAD_ITEM, value })
            }
            file={fileData}
          />
        ) : null}
        {isPdf && (
          <Flexbox direction="column">
            <div className="edit-file-popup__pdf-page-ranges overflow-auto">
              {splitData.length > 0 &&
                splitData.map((split, index) => {
                  const startPage = pluckFp('StartPage')(split);
                  return (
                    <PDFRangePanel
                      key={startPage}
                      onRemove={() => onItemRemoved(index)}
                      onChange={(value) => onItemChanged(value, index)}
                      currentRange={split}
                      pdfData={pdfData}
                      rangeReadOnly={true}
                    />
                  );
                })}
            </div>
          </Flexbox>
        )}
      </OverflowContainer>
      {isPdf && (
        <PDFViewer
          file={pdfFileData}
          onSplit={onItemsAdded}
          onLoadSuccess={setPDFData}
          disabledPages={disabledPages}
          enableSelection
        />
      )}
    </GridLayout>
  );
};

export default UpdateSet;
