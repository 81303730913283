import { useSchema } from '../../schema/SchemaReducer';
import { toUIValid } from '../../schema/UIValidationProjection';
import { DateTimePicker as KendoDateTimePicker } from '@progress/kendo-react-dateinputs';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import {
  DateTimeFormatNamePropType,
  toFormatterAndParserByType,
  toParseWithToken,
} from '../../g11n/ISODates';
import { toDateSchemaNodeFormat } from '../../utils/schema';
import { Flexbox } from '../Flexbox';
import { UIControlLabel } from '../Label';
import { NOOP_FUNCTION } from '../../constants';

const defaultFormatPlaceholder = {
  year: 'YYYY',
  month: 'MM',
  day: 'DD',
  hour: 'hh',
  minute: 'mm',
};

export const DateTimePicker = (props) => {
  const {
    dataElementName = '',
    dataPath,
    value: propValue,
    min,
    max,
    minTime,
    maxTime,
    format: propsFormat = 'date-time',
    validityStyles: propsValidityStyles,
    descriptor,
    descriptorClassName,
    required: propsRequired,
    onChange = NOOP_FUNCTION,
    children,
    onValidation = NOOP_FUNCTION,
    ...passThroughProps
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const {
    onValueChange = onChange,
    value = propValue,
    touched = false,
    required = false,
    validityMessage = '',
    onTouched = NOOP_FUNCTION,
    onValidationChange = onValidation,
    schemaNode,
  } = useSchema(dataPath);

  const dateTimePickerRef = useRef();

  const dateFormat = schemaNode
    ? toDateSchemaNodeFormat(schemaNode)
    : propsFormat;

  const validityStyles = schemaNode ? touched : propsValidityStyles;

  const { parser, formatter } = toFormatterAndParserByType(dateFormat);

  const valueAsDate = (() => {
    if (!value) return null;
    return parser(value);
  })();

  const valid = !validityMessage ? undefined : false;

  return (
    <>
      <UIControlLabel
        dataElementName={
          !!dataElementName
            ? `${dataElementName}__label`
            : 'dateTimePickerLabel'
        }
        className={descriptorClassName}
        required={required || propsRequired}
      >
        {descriptor}
      </UIControlLabel>

      <Flexbox alignItems="center">
        <KendoDateTimePicker
          {...passThroughProps}
          ref={(ref) => {
            if (ref && dateTimePickerRef.current !== ref) {
              ref.element.setAttribute('data-component-name', 'DateTimePicker');
              ref.element.setAttribute('data-element-name', dataElementName);
            }
            dateTimePickerRef.current = ref;
          }}
          value={valueAsDate}
          required={required}
          formatPlaceholder={defaultFormatPlaceholder}
          onChange={({ target: { value, validity } = {} }) => {
            //Due to how moment works, passing it a null will result in a date at timestamp 0.
            //We need the actual value of null to be passed through to update dates in the database. This ensures
            //null is always passed back when an incomplete or fully deleted date is entered.
            const realizedValue = value !== null ? formatter(value) : value;
            const { valueMissing, valid, ...rest } = validity;
            //FIXME: in Kendo, valueMissing does not follow the html specification when the constraint
            // should apply.  Remove when Kendo fixes this for their datepickers. (AZ)
            onValueChange(realizedValue);
            onValidationChange(
              toUIValid({
                ...rest,
                valid,
                valueMissing: required ? valueMissing : false,
              })
            );
          }}
          min={toParseWithToken(parser)(min)}
          max={toParseWithToken(parser)(max)}
          minTime={toParseWithToken(parser)(minTime)}
          maxTime={toParseWithToken(parser)(maxTime)}
          onBlur={onTouched}
          valid={valid}
          validityStyles={validityStyles}
        />
        {children}
      </Flexbox>
    </>
  );
};

export default DateTimePicker;

DateTimePicker.propTypes = {
  dataElementName: PropTypes.string,
  dataPath: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  autoComplete: PropTypes.string,
  format: DateTimeFormatNamePropType,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
};
