import { DOWNLOAD_FILE } from '../../../../../../icons';
import { IconButton } from '../../../../../IconButton';
import { isNil } from '../../../../../../fp/pred';
import { useRequestDownloadFile } from '../../../../../../hooks/useRequestDownloadFile';
import { EMPTY_OBJECT } from '../../../../../../constants';

export const DownloadFileAction = (props) => {
  const { actionData = EMPTY_OBJECT, rowData = EMPTY_OBJECT, labels } = props;

  const { FileID } = actionData;
  const { FileName: { DisplayResult: fileName } = EMPTY_OBJECT } = rowData;

  const requestDownloadFile = useRequestDownloadFile();

  return !isNil(FileID) ? (
    <IconButton
      description={labels.Download}
      icon={DOWNLOAD_FILE}
      onClick={() => {
        requestDownloadFile(FileID, fileName);
      }}
    />
  ) : null;
};
