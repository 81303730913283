export const SHOULD_ADD_CALL_LOG = 'shouldAddCallLog';
export const SHOULD_CANCEL_CALL_LOG = 'shouldCancelCallLog';
export const SHOULD_UPDATE_CALL_LOG = 'shouldUpdateCallLog';

export const SHOULD_SHOW_ADD_PATIENT_CONTACT = 'shouldShowAddPatientContact';
export const SHOULD_ADD_PATIENT_CONTACT = 'shouldAddPatientContact';
export const SHOULD_CANCEL_ADD_PATIENT_CONTACT =
  'shouldCancelAddPatientContact';

export const SHOULD_UPDATE_CONTACT = 'shouldUpdateContact';
export const SHOULD_ADD_PERSONAL_CONTACT = 'shouldAddPersonalContact';
export const SHOULD_ADD_CLINICAL_CONTACT = 'shouldAddClinicalContact';
export const ON_VIEW_CHANGE = 'onViewChange';

export const SHOULD_UPDATE_CLINICAL_CONTACT_TYPE =
  'shouldUpdateClinicalContactType';

export const RESPONSE_PATIENT_VISITS = 'response/patient-visits';
export const RESPONSE_ADD_CALL_LOG = 'response/add-call-log';
export const RESPONSE_CALL_LOG_TYPES = 'response/call-log-types';
export const RESPONSE_CALL_LOG_DETAIL = 'response/call-log-details';
export const RESPONSE_ADD_PERSONAL_CONTACT = 'response/addPersonalContact';
export const RESPONSE_ADD_CLINICAL_CONTACT = 'response/addClinicalContact';
export const RESPONSE_PHONE_CONTACTS = 'response/phoneContacts';

export const SET_PHONE_CONTACTS_AFTER_EDIT = 'setPhoneContactsAfterEdit';

export const SHOULD_REFRESH_PHONE_CONTACTS = 'shouldRefreshPhoneContacts';
