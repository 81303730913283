import window from '../../globals/window';
import { DateRangePicker as KendoDateRangePicker } from '@progress/kendo-react-dateinputs';
import { Popup } from '@progress/kendo-react-popup';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import CustomCalendar from '../CustomCalendar';
import {
  DateTimeFormatNamePropType,
  toDateFromISOString,
  toFormatterAndParserByType,
} from '../../g11n/ISODates';
import './styles.css';
import { CUSTOM_KEY } from './constants';
import { toIsKendoComponentValid } from '../utils';
import { NOOP_FUNCTION } from '../../constants';

const toKendoDateRangePickerValue = (value) => {
  if (!value) return null;
  const { start = null, end = null } = value;
  if (!start || !end) return null;
  return {
    start: toDateFromISOString(start),
    end: toDateFromISOString(end),
  };
};

export const isMouseTargetIn = (element, ev) =>
  element ? element.contains(ev.target) : false;

export const CustomDateRangePickerPopup = (props) => {
  const {
    anchor,
    showPopup,
    onChange = NOOP_FUNCTION,
    onClose = NOOP_FUNCTION,
    format = 'date-time',
    min,
    max,
    value,
    okLabel = 'OK',
  } = props;
  const popupRef = useRef();
  const calendarRef = useRef();
  const calendarOpenRef = useRef(false);
  const [isStartValid, setIsStartValid] = useState(false);
  const [isEndValid, setIsEndValid] = useState(false);
  const [selectedRange, setSelectedRange] = useState(
    toKendoDateRangePickerValue(value)
  );
  const { formatter } = toFormatterAndParserByType(format);
  const onSubmit = useCallback(() => {
    const { start = null, end = null } = selectedRange || {};
    calendarOpenRef.current = false;
    setSelectedRange(null);
    if (!start || !end || !isStartValid || !isEndValid) {
      return onClose();
    }
    onChange({
      key: CUSTOM_KEY,
      range: {
        from: () => formatter(DateTime.fromJSDate(start).startOf('day')),
        to: () => formatter(DateTime.fromJSDate(end).endOf('day')),
      },
    });
  }, [formatter, onClose, onChange, selectedRange, isStartValid, isEndValid]);

  useEffect(() => {
    const mouseDownHandler = (ev) => {
      if (!calendarOpenRef.current) return;
      const isCalendarClick = isMouseTargetIn(calendarRef.current, ev);
      const isPopupClick = isMouseTargetIn(popupRef.current._popup, ev);
      if (!isPopupClick && !isCalendarClick) {
        onSubmit();
      }
    };
    window.addEventListener('mousedown', mouseDownHandler);
    return () => {
      window.removeEventListener('mousedown', mouseDownHandler);
    };
  }, [onSubmit]);

  return (
    <Popup
      anchor={anchor}
      show={showPopup}
      ref={popupRef}
      onOpen={() => {
        calendarOpenRef.current = true;
        setSelectedRange(toKendoDateRangePickerValue(value));
      }}
    >
      <KendoDateRangePicker
        calendar={CustomCalendar}
        calendarSettings={{
          ref: calendarRef,
          okLabel: okLabel,
          onSubmit,
          okDisabled: false,
        }}
        onChange={({ value, target: { _startDateInput, _endDateInput } }) => {
          setIsStartValid(toIsKendoComponentValid(_startDateInput.current));
          setIsEndValid(toIsKendoComponentValid(_endDateInput.current));
          setSelectedRange(value);
        }}
        value={selectedRange}
        // Min and max only used as UI boundaries so enforcing those boundaries with luxon rather than using parser/formatter(MSS).
        min={min ? DateTime.fromISO(min).startOf('day').toJSDate() : undefined}
        max={max ? DateTime.fromISO(max).endOf('day').toJSDate() : undefined}
      />
    </Popup>
  );
};

export default CustomDateRangePickerPopup;

CustomDateRangePickerPopup.propTypes = {
  onChange: PropTypes.func,
  showPopup: PropTypes.bool,
  format: DateTimeFormatNamePropType,
  okLabel: PropTypes.string,
};
