export const ActivityAssets = {
  XeAuth: 'XeAuth',
  XePatientIssue: 'XePatientIssue',
  XeCallLog: 'XeCallLog',
  XeScanDocSet: 'XeScanDocSet',
  XeVisitCorrespRecip: 'XeVisitCorrespRecip',
  XeVisitAssessment: 'XeVisitAssessment',
  XeVisitNote: 'XeVisitNote',
  XeWaitList: 'XeWaitList',
  XeWorkflow: 'XeWorkflow',
  XeOrderBase: 'XeOrderBase',
  XeHistoryWorkView: 'XeHistoryWorkView',
  XeVisitCaseManagementProgram: 'XeVisitCaseManagementProgram',
  XePatientIssueTask: 'XePatientIssueTask',
};
