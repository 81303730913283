import { ofType } from '../../frp/operators/ofType';
import { map, mergeMap, pluck, withLatestFrom } from 'rxjs/operators';
import {
  SHOULD_SAVE_APPOINTMENT,
  RESPONSE_SAVE_APPOINTMENT,
  RESPONSE_GET_APPOINTMENT_DETAILS,
} from './actions';
import {
  update,
  getDetails,
} from 'services/service-bookings/xe-service-bookings-svc';
import { neverCache } from '../../service/serviceCache';

const toGetAppointmentDetailsEpic$ = (
  action$,
  state$,
  { menuNode$, serviceBookingId$ }
) =>
  serviceBookingId$.pipe(
    withLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn(neverCache)())
      )
    ),
    mergeMap(([serviceBookingId, toRequest$]) =>
      getDetails({ serviceBookingId }, toRequest$({ fullRequest: true }))
    ),
    pluck('results'),
    map((value) => ({
      type: RESPONSE_GET_APPOINTMENT_DETAILS,
      value,
    }))
  );

const toSaveAppointmentRequestEpic$ = (action$, state$, { menuNode$ }) =>
  action$.pipe(
    ofType(SHOULD_SAVE_APPOINTMENT),
    withLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([action, toRequest$]) => {
      const { value, navigate } = action;
      return update(value, {}, toRequest$({ fullRequest: true })).pipe(
        pluck('results'),
        map((response) => {
          return [response, navigate];
        })
      );
    }),
    map(([response, navigate]) => {
      return {
        type: RESPONSE_SAVE_APPOINTMENT,
        value: response,
        navigate,
      };
    })
  );

export default [toSaveAppointmentRequestEpic$, toGetAppointmentDetailsEpic$];
