import { useSchema } from '../../schema/SchemaReducer';
import { toUIValid } from '../../schema/UIValidationProjection';
import { identity } from '../../fp/fp';
import { isObjectLike } from '../../fp/pred';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useMemo, useState, forwardRef, useRef } from 'react';
import ToolTip from '../ToolTip';
import { isTrue } from '../../utils';
import { UIControlLabel } from '../Label';
import './styles.css';
import { EMPTY_ARRAY, NOOP_FUNCTION } from '../../constants';

const shouldShowItem = (item, index, initialSelectedIndex) => {
  if (!isObjectLike(item) || !('Active' in item) || isTrue(item.Active))
    return true;
  return initialSelectedIndex === index;
};

const ButtonBarItem = forwardRef((props, ref) => {
  const {
    children,
    dataElementName,
    value,
    selected,
    disabled,
    onSelect,
    onValidation,
  } = props;
  const refFn = ref;
  return (
    <Button
      data-element-name={dataElementName}
      togglable
      type="button"
      onClick={({ target: { validity } }) => {
        onSelect(value);
        onValidation(toUIValid(validity));
      }}
      selected={selected}
      disabled={disabled}
      ref={(buttonRef) => {
        if (buttonRef) {
          return refFn(buttonRef.element);
        }
      }}
    >
      {children}
    </Button>
  );
});

const defaultLabelFn = (value) =>
  typeof value === 'string' ? value : JSON.stringify(value);

export const ButtonBar = (props) => {
  const {
    dataElementName = '',
    data = EMPTY_ARRAY,
    dataPath,
    disabledIndices = EMPTY_ARRAY,
    valueFn = identity,
    labelFn = defaultLabelFn,
    tooltipFn = NOOP_FUNCTION,
    keyFn,
    selectedItem,
    onSelect = NOOP_FUNCTION,
    descriptor,
    descriptorClassName,
    required: propsRequired,
    onValidation = NOOP_FUNCTION,
    className = '',
  } = props;

  if (!!onSelect && !(onSelect == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onSelect callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const _buttonBarRef = useRef();

  const toKey = keyFn || valueFn;

  const {
    onValueChange = onSelect,
    value,
    required = false,
    onValidationChange = onValidation,
  } = useSchema(dataPath);

  const currentValue = dataPath ? value : toKey(selectedItem);

  const selectedIndex = useMemo(
    () => data.findIndex((item) => isEqual(toKey(item), currentValue)),
    [data, toKey, currentValue]
  );

  const [initialSelectedIndex] = useState(selectedIndex);

  return (
    <>
      {descriptor && (
        <UIControlLabel
          dataElementName={
            dataElementName !== '' ? `${dataElementName}__label` : ''
          }
          className={descriptorClassName}
          required={required || propsRequired}
        >
          {descriptor}
        </UIControlLabel>
      )}
      <ButtonGroup
        ref={(ref) => {
          if (ref && _buttonBarRef.current !== ref) {
            ref._element.setAttribute('data-component-name', 'ButtonBar');
            ref._element.setAttribute('data-element-name', dataElementName);
          }
          _buttonBarRef.current = ref;
        }}
        className={`button-bar ${className}`}
        required={required}
      >
        {data.map((item, index) => {
          if (!shouldShowItem(item, index, initialSelectedIndex)) return null;
          const label = labelFn(item);

          return (
            <ToolTip key={toKey(item)} value={tooltipFn(item)}>
              <ButtonBarItem
                dataElementName={label}
                value={valueFn(item)}
                tooltip={tooltipFn(item)}
                key={toKey(item)}
                selected={index === selectedIndex}
                disabled={disabledIndices.some((i) => i === index)}
                onSelect={onValueChange}
                onValidation={onValidationChange}
              >
                {label}
              </ButtonBarItem>
            </ToolTip>
          );
        })}
      </ButtonGroup>
    </>
  );
};

ButtonBar.propTypes = {
  data: PropTypes.array.isRequired,
  valueFn: PropTypes.func,
  labelFn: PropTypes.func,
  tooltipFn: PropTypes.func,
  keyFn: PropTypes.func,
  disabledIndices: PropTypes.arrayOf(PropTypes.number),
  descriptor: PropTypes.string,
  descriptorClassName: PropTypes.string,
  required: PropTypes.bool,
  onValidation: PropTypes.func,
  dataElementName: PropTypes.string,
};

export default ButtonBar;
