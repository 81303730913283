import { QuickCallButton } from '../../QuickCallButton';
import { useCallback } from 'react';
import { useSchemaDispatch } from '../../../schema/SchemaReducer';
import { UPSERT } from '../../../schema/JSONSchemaReducer';
import {
  toFormatterAndParserObject,
  toNowDate,
  XS_DATE_TIME_FORMAT,
} from '../../../g11n/ISODates';
const { formatter } = toFormatterAndParserObject(XS_DATE_TIME_FORMAT);

export const QuickCallButtonWithSideEffects = (props) => {
  const { dataPath } = props;

  const schemaDispatch = useSchemaDispatch();

  const onClick = useCallback(() => {
    schemaDispatch({
      type: UPSERT,
      path: 'StartDateTime',
      //Set time to when button is clicked
      value: formatter(toNowDate()),
    });
  }, [schemaDispatch]);
  return <QuickCallButton dataPath={dataPath} onClick={onClick} />;
};
