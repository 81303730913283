import { pluck } from '../../fp/object';
import { Checkbox, Icon } from '../';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { useXeRights } from '../../contexts/XeUserRightsContext';
import { CALENDAR_APPT } from '../../icons';
import { formatCommonName } from '../../utils';
import { EMPTY_OBJECT } from '../../constants';
import { useEnterprise } from '../../contexts/XeEnterpriseContext';

export const CalendarUserBar = (props) => {
  const {
    user = EMPTY_OBJECT,
    appointment = EMPTY_OBJECT,
    shouldAccept = false,
  } = props;

  const {
    SchedEventID,
    IsProviderAccepted,
    ProviderID: { ResourceID } = {},
  } = appointment;

  const labels = useXeLabels();
  const { ACCEPT_BOOKING_ALL } = useXeRights();
  const { userData } = useEnterprise();
  const userResourceID = pluck('XeResource', 'ResourceID')(userData);

  const showCheckBox =
    SchedEventID &&
    ((!IsProviderAccepted && userResourceID === ResourceID) ||
      ACCEPT_BOOKING_ALL);

  return (
    <div
      data-component-name="CalendarUserBar"
      className={`calendar-user-bar flex-container align-items-center${
        SchedEventID && !(IsProviderAccepted || shouldAccept)
          ? ' not-accepted'
          : ''
      }`}
    >
      <Icon icon={CALENDAR_APPT} className="calendar-user-bar-icon" />
      <span>
        {labels.CalendarOf}
        <span className="bold"> {formatCommonName(user, 'F, G')}</span>
      </span>
      <div className="bold">
        {IsProviderAccepted ? null : (
          <span className="accept-appt-message dark-red">
            {shouldAccept
              ? labels.WillRecordAcceptanceOnSave
              : labels.HasNotAcceptedThisAppointment}
          </span>
        )}
        {showCheckBox ? (
          <Checkbox
            dataElementName="calendarUserBar__isProviderAccepted"
            label={labels.Accepted}
            dataPath="IsProviderAccepted"
            disabled={IsProviderAccepted}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CalendarUserBar;
