import { useSchema } from '../../schema/SchemaReducer';
import { isString } from '../../fp/pred';
import { identity } from '../../fp/fp';
import { pluck } from '../../fp/object';
import { ButtonGroup as ButtonGroupKendo } from '@progress/kendo-react-buttons';
import PropTypes from 'prop-types';
import { useActiveList } from '../../hooks/useActiveList';
import { UIControlLabel } from '../Label';
import { NOOP_FUNCTION } from '../../constants';
import { cloneElement, forwardRef } from 'react';
import './styles.css';
import ToolTip from '../ToolTip';
import { Button } from '../Button';
import IconButton from '../IconButton';

/**
 * @typedef {any} ComponentProps
 *
 * @typedef Props
 * @property {import('react').ComponentType<any>} [component=Button] Component to use as each child of the ButtonGroup. Defaults to `Button`
 * @property {(datem: any) => ComponentProps} [componentPropsSelector] Selector for deriving extra props from each datem to pass into `component`
 */

const defaultRenderItemFn = ({ element }) => {
  return element;
};

const ButtonGroupItem = forwardRef((props, ref) => {
  return (
    <ToolTip>
      {!!props.icon ? (
        <IconButton ref={ref} togglable={true} {...props} />
      ) : (
        <Button ref={ref} togglable={true} {...props} />
      )}
    </ToolTip>
  );
});

/**
 * @param {Props} props
 * @returns
 */
export const ButtonGroup = (props) => {
  const {
    dataElementName = '',
    data,
    dataPath,
    disabled,
    valueFn = identity,
    labelFn = identity,
    renderItem = defaultRenderItemFn,
    value: propsValue,
    dataItemKey,
    onChange = NOOP_FUNCTION,
    onValidation = NOOP_FUNCTION,
    className = '',
    descriptorClassName,
    descriptor,
    required: propsRequired,
    comparator,
    look = 'default',
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const activeList = useActiveList(data);
  const {
    value = propsValue,
    onValueChange = onChange,
    onValidationChange = onValidation,
    required = false,
  } = useSchema(dataPath);

  return (
    <>
      <UIControlLabel
        dataElementName={
          dataElementName !== '' ? `${dataElementName}__label` : ''
        }
        className={descriptorClassName}
        required={required || propsRequired}
      >
        {descriptor}
      </UIControlLabel>
      <ButtonGroupKendo disabled={disabled} className={className}>
        {activeList.map((item, index) => {
          const valueFnResult = valueFn(item);
          const isSelected = (() => {
            if (comparator) {
              return comparator(value, item);
            }
            if (dataItemKey) {
              return (
                pluck(dataItemKey)(valueFnResult) == pluck(dataItemKey)(value)
              );
            }
            return valueFnResult == value;
          })();

          const labelFnResult = labelFn(item, index);
          const buttonLabel = isString(labelFnResult) ? labelFnResult : '';
          const itemName =
            item.name && item.name !== '' ? item.name : buttonLabel;

          const element = (
            <ButtonGroupItem
              onClick={() => {
                onValueChange(valueFnResult);
                onValidationChange(true);
              }}
              look={look}
              dataElementName={itemName}
              selected={isSelected}
              togglable={true}
            >
              {buttonLabel}
            </ButtonGroupItem>
          );

          const RenderItem = renderItem({
            item,
            index,
            element,
            onValueChange,
            onValidationChange,
          });

          return cloneElement(RenderItem, {
            key: `button-group-item@${index}`,
          });
        })}
      </ButtonGroupKendo>
    </>
  );
};

ButtonGroup.propTypes = {
  data: PropTypes.array.isRequired,
  valueFn: PropTypes.func,
  labelFn: PropTypes.func,
  dataPath: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  dataItemKey: PropTypes.string,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  look: PropTypes.string,
  className: PropTypes.string,
  descriptorClassName: PropTypes.string,
  descriptor: PropTypes.string,
  required: PropTypes.bool,
  dataElementName: PropTypes.string,
};

export default ButtonGroup;
