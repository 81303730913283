import { Card } from '@progress/kendo-react-layout';
import PropTypes from 'prop-types';
import { EMPTY_ARRAY } from '../../constants';
import withClassNameModifiers from '../../utils/withClassNameModifiers';
import './styles.css';

/**
 * @typedef CallbackObject
 * @property {T} item
 * @property {Number} index
 * @template T
 */

/**
 * @typedef CardGroupProps
 * @property {Array<T>} data
 * @property {React.FunctionComponent<CallbackObject<T>>} renderItem
 * @property {'vertical'|'horizontal'=} direction
 * @property {({ item, index }: CallbackObject<T>) => void=} onCardOpened
 * @property {({ item, index }: CallbackObject<T>) => void=} onCardClosed
 * @property {({ item, index }: CallbackObject<T>) => React.ReactText=} keyFn
 * @template T
 */

const defaultKeyFn = ({ index }) => index;

/**
 * @typedef {React.FunctionComponent<CardGroupProps<T>>} CardGroup
 * @example
 * <CardGroup
 *   data={[1, 2, 3]}
 *   renderItem={({ item }) => <span>{item}</span>}
 * />
 * @example
 * <CardGroup
 *   data={[{ value: 1, key: 'item1' }, { value: 2, key: 'item2' }]}
 *   keyFn={({ item }) => item.key}
 *   renderItem={({ item }) => <span>{item.value}</span>}
 * />
 * @template T
 */

/**
 * @type {CardGroup}
 */
export const CardGroup = (props) => {
  const {
    className,
    data = EMPTY_ARRAY,
    direction = 'vertical',
    keyFn = defaultKeyFn,
    renderItem,
  } = props;

  const cardGroupClass =
    direction === 'vertical' ? 'k-card-list' : 'k-card-deck';

  const baseClassName = `${withClassNameModifiers('xjs-card-group', {
    vertical: direction === 'vertical',
    horizontal: direction === 'horizontal',
  })} ${cardGroupClass}`;

  return (
    <div
      className={className ? `${baseClassName} ${className}` : baseClassName}
    >
      {data.map((item, index) => {
        return (
          <Card key={keyFn({ item, index })} className="xjs-card-group__card">
            {renderItem({ item, index })}
          </Card>
        );
      })}
    </div>
  );
};

CardGroup.propTypes = {
  data: PropTypes.array,
  renderItem: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  keyFn: PropTypes.func,
  className: PropTypes.string,
};
