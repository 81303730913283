import { cloneElement } from 'react';
import { combineEpics } from 'redux-observable';
import { combinePredicatedReducers } from '../../connection/toConnectionDef';
import { useReducer$ } from '../../hooks/useReducer$';
import { useRef$ } from '../../hooks/useRef$';
import { useMenuNode } from '../../contexts/XeMenuNodeContext';
import { useEffect$ } from '../../hooks/useEffect$';
import { pluck } from '../../fp/object';
import { isNil } from '../../fp/pred';
import { useScopedSelector } from '../../hooks/scopedReducer';
import { ofType } from '../../frp/operators/ofType';
import { useCallback, useState } from 'react';
import { pluck as pluckOperator, tap } from 'rxjs/operators';
import { Flexbox } from '../Flexbox';
import { ExpandableContainer } from '../../components';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { ATTACH_FILE, CHEVRON_LEFT, CHEVRON_RIGHT, PRINT } from '../../icons';
import { Button } from '../Button';
import { GridLayout } from '../GridLayout';
import { Icon } from '../Icon';
import IconButton from '../IconButton';
import { Label } from '../Label';
import { Popup, toDefaultPopupFooter } from '../Popup';
import { Window } from '../Popup/Window';
import { Footer } from '../Popup/components/Footer';
import { SmartBook } from '../SmartBook';
import TabStrip, { TabStripTab } from '../TabStrip';
import UploadFilesPopup from '../UploadFilesPopup';
import {
  DID_FINISH_PROCESSING_DOCUMENT_SET,
  DID_SAVE_DOCUMENT_SCAN_DOC,
  PROCESS_DOCUMENT_SET,
  SAVE_DOCUMENT_SET,
  SET_SHOW_VALIDITY_STYLES,
  SHOULD_PRINT,
  SHOULD_UNLOCK_DOCUMENT,
  SWITCH_ACTIVE_DOCUMENT,
  UPDATE_SMARTBOOK_INSTANCE,
} from './actions';
import { SaveSelectionPopup } from './components/SaveSelectionPopup';
import { IS_QUICK_SAVE_DRAFT } from './constants';
import epics from './epics';
import reducers from './reducers';
import './styles.css';
import { getVisitAssessmentID } from '../../utils/pluckers';
import { toAvailableActionsForDocument } from '../ClinicalDocumentsGrid/utils';
import { of } from 'rxjs';
import { EMPTY_OBJECT } from '../../constants';
import { useEnterprise } from '../../contexts/XeEnterpriseContext';

const CAN_CREATE = 'CREATE_VISITDOC';
const CAN_VIEW = 'VIEW_VISITDOC';
const CAN_EDIT = 'EDIT_VISITDOC';
const CAN_SIGN = 'SIGN_VISITDOC';
export const CAN_SIGN_INVALID = 'SIGN_VISITDOC_INVALID';
const SIGNED_STATUS = 'SIGNED';

const CLINICAL_DOCUMENT_RIGHTS = [CAN_CREATE, CAN_VIEW, CAN_EDIT, CAN_SIGN];

const epic = combineEpics(...epics);
const reducer = combinePredicatedReducers(...reducers);

const FooterButton = (props) => {
  const { children, ...buttonProps } = props;
  return (
    <Button
      className="default-popup-footer-button"
      look="outline"
      primary
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

const ViewerFooter = (props) => {
  const {
    data = EMPTY_OBJECT,
    editEnabled = true,
    canEdit,
    canSave,
    canSign,
    availableActions,
    readOnly,
    onEdit,
    onSaveAndClose,
    onClose,
    onSign,
  } = props;

  const labels = useXeLabels();

  const { IsSignOnly, IsVersionable, LockDateTime } = data;

  const canUserEdit =
    availableActions.includes(CAN_EDIT) && IsVersionable && !LockDateTime;
  const canUserSign = availableActions.includes(CAN_SIGN) && !LockDateTime;

  const EditButton = () => {
    if (editEnabled && (canUserSign || canUserEdit)) {
      return (
        <FooterButton
          dataElementName="edit"
          onClick={() => onEdit(data)}
          look="flat"
        >
          {labels.Edit}
        </FooterButton>
      );
    }

    return null;
  };

  const SaveSignButtonGroup = () => {
    if (!canEdit) return null;
    return (
      <>
        {(canUserSign || canUserEdit) && !IsSignOnly && (
          <FooterButton
            dataElementName="saveAndClose"
            onClick={onSaveAndClose}
            disabled={!canSave}
          >
            {labels.SaveAndClose}
          </FooterButton>
        )}
        {canUserSign && (
          <FooterButton
            dataElementName="sign"
            onClick={onSign}
            disabled={!canSign}
          >
            {labels.Sign}
          </FooterButton>
        )}
      </>
    );
  };

  return (
    <Flexbox
      justifyContent="space-between"
      className="clin-docs-footer padding-top-medium flex-1"
    >
      <div />
      <div className="clin-docs-footer__group">
        <FooterButton dataElementName="close" onClick={onClose}>
          {labels.Close}
        </FooterButton>
        {readOnly ? <EditButton /> : <SaveSignButtonGroup />}
      </div>
    </Flexbox>
  );
};

export const ClinicalDocumentViewer = (props) => {
  const {
    assessmentId,
    PatientHeaderElement,
    SocialPanelElement,
    SmartBookSchema,
    SmartBookInstanceSchema,
    showSocialPanelOnLoad,
    data,
    ipid,
    ivid,
    enterpriseId = null,
    onSave,
    onClose,
    // TOOD: (SYNUI-5133) I'm going to need to revisit this since there's always the chance
    // that you open multiple documents, but you only want to externally force a subset to
    // readOnly
    readOnly: propsReadOnly,
  } = props;

  const { userData } = useEnterprise();

  const labels = useXeLabels();

  const userData$ = useRef$(userData);
  const menuNode = useMenuNode();
  const data$ = useRef$(data);
  const assessmentId$ = useRef$(assessmentId);
  const ipid$ = useRef$(ipid);
  const enterpriseId$ = useRef$(enterpriseId);
  const ivid$ = useRef$(ivid);
  const epicWithDeps = useCallback(
    (action$, state$) => {
      return epic(action$, state$, {
        data$,
        ipid$,
        ivid$,
        menuNode$: of(menuNode),
        enterpriseId$,
        assessmentId$,
        userData$,
      });
    },
    [data$, ipid$, ivid$, menuNode, enterpriseId$, assessmentId$, userData$]
  );

  const rightId =
    useScopedSelector(pluck('contexts', 'enterprise', 'userData', 'RightID')) ||
    '';

  const [state = {}, dispatch, action$] = useReducer$(reducer, epicWithDeps);

  const [activeDocumentIndex, setActiveDocumentIndex] = useState(0);
  const [attachmentPopupData, setAttachmentPopupData] = useState(null);
  const [isSocialPanelExpanded, setIsSocialPanelExpanded] = useState(
    showSocialPanelOnLoad
  );
  const [showCannotPrintPopup, setShowCannotPrintPopup] = useState(false);
  const [saveSelectionData, setSaveSelectionData] = useState(null);

  const { documentSet = [], isProcessingDocuments = false } = state;

  const selectedDocumentData = documentSet[activeDocumentIndex];

  useEffect$(
    () =>
      action$.pipe(
        ofType(PROCESS_DOCUMENT_SET),
        pluckOperator('value'),
        tap(onSave)
      ),
    [action$, onSave]
  );

  useEffect$(
    () =>
      action$.pipe(
        ofType(DID_FINISH_PROCESSING_DOCUMENT_SET),
        pluckOperator('documentSet'),
        tap(onClose)
      ),
    [action$, onClose]
  );

  if (!selectedDocumentData || isProcessingDocuments) return null;

  const {
    data: selectedDocument,
    canEdit,
    readOnly,
    validityStyles,
  } = selectedDocumentData;

  const availableActions = toAvailableActionsForDocument(
    selectedDocument,
    rightId,
    CLINICAL_DOCUMENT_RIGHTS
  );

  const canSave = documentSet.some(
    ({ changed, fileSetChanged } = EMPTY_OBJECT) => {
      return changed || fileSetChanged;
    }
  );

  const canSign = documentSet.some(
    ({
      changed,
      data: { VisitAssessmentID, Status } = EMPTY_OBJECT,
      fileSetChanged,
      valid,
    } = EMPTY_OBJECT) =>
      (changed ||
        fileSetChanged ||
        (VisitAssessmentID && Status !== SIGNED_STATUS)) &&
      valid
  );

  return (
    <Window
      size="x-large"
      className="clinical-document-viewer"
      dataElementName="ClinicalDocumentViewer__popup"
    >
      <Flexbox direction="column" className="stretch-x">
        {PatientHeaderElement
          ? cloneElement(PatientHeaderElement, {
              activeDocument: selectedDocument,
            })
          : null}
        <Flexbox className="flex-1">
          {SocialPanelElement ? (
            <ExpandableContainer
              Header={() => (
                <Icon
                  dataElementName="clinicalDocumentViewer__expandSocialPanel"
                  icon={isSocialPanelExpanded ? CHEVRON_LEFT : CHEVRON_RIGHT}
                  defaultIconColor
                />
              )}
              onChange={() => setIsSocialPanelExpanded(!isSocialPanelExpanded)}
              isExpanded={isSocialPanelExpanded}
              preserveChildrenOnCollapse={true}
              // (SYNUI-5734) This `style` tag should serve as a note to move to the ExpandablePanel component
              // The ExpandableContainer has problematic styles built-in(JDM)
              style={{
                width: 'auto',
              }}
              className="clinical-document-viewer__collapsable-side"
              headerClassName="clinical-document-viewer__social-collapsible-header"
              bodyClassName="clindoc-template__collapsable-body flex-container"
            >
              {SocialPanelElement}
            </ExpandableContainer>
          ) : null}
          <GridLayout className="flex-1" templateRows="auto 1fr">
            <div className="flex-container">
              <TabStrip
                onSelect={({ selected }) => {
                  dispatch({
                    type: SWITCH_ACTIVE_DOCUMENT,
                    value: selected,
                  });
                  setActiveDocumentIndex(selected);
                }}
                selected={activeDocumentIndex}
              >
                {documentSet.map((params = EMPTY_OBJECT, index) => {
                  const { data, fetched, readOnly } = params;
                  const {
                    AssessmentID,
                    EnterpriseID,
                    IsScanDoc,
                    Name,
                    ScanDocID,
                    IPID,
                    IsQuickSave,
                  } = data;
                  return (
                    <TabStripTab
                      key={`${AssessmentID}_${
                        getVisitAssessmentID(data) || index
                      }`}
                      title={Name}
                      dataElementName={Name}
                      RightElement={
                        fetched ? (
                          <Flexbox alignItems="center">
                            <IconButton
                              dataElementName="clinicalDocumentViewer__print"
                              description={labels.Print}
                              onClick={() => {
                                if (
                                  isNil(getVisitAssessmentID(data)) ||
                                  IsQuickSave === IS_QUICK_SAVE_DRAFT
                                ) {
                                  return setShowCannotPrintPopup(true);
                                }
                                dispatch({ type: SHOULD_PRINT, value: data });
                              }}
                              icon={PRINT}
                            />
                            {!IsScanDoc && (
                              <IconButton
                                dataElementName="clinicalDocumentViewer__attachFile"
                                disabled={readOnly}
                                onClick={() => {
                                  setAttachmentPopupData({
                                    scanDocId: ScanDocID,
                                    comments: Name,
                                    enterpriseId:
                                      pluck('EnterpriseID')(EnterpriseID),
                                    ipid: pluck('IPID')(IPID),
                                    // Per SYNUI-3760:
                                    // > Pass ASSESSMENT as the ScanDocTypeID  (There is no where to get this from, so it can be hard-coded)
                                    docTypeId: 'ASSESSMENT',
                                  });
                                }}
                                icon={ATTACH_FILE}
                              />
                            )}
                          </Flexbox>
                        ) : null
                      }
                    />
                  );
                })}
              </TabStrip>
              {attachmentPopupData ? (
                // TODO: Due to some issues, the upload files popup is being reverted for now (JDM)
                // Need to discuss full requirements and expectations to figure out how we can merge
                // the two ideas between the popups
                // <AttachmentPopup
                //   onClose={() => {
                //     setAttachmentPopupData(null);
                //   }}
                //   onSave={(response) => {
                //     setAttachmentPopupData(null);
                //     return dispatch({
                //       type: DID_SAVE_DOCUMENT_SCAN_DOC,
                //       value: {
                //         ...selectedDocument,
                //         ScanDocID: pluck('ScanDocID')(response),
                //       },
                //     });
                //   }}
                //   scanDocId={pluck('scanDocId')(attachmentPopupData)}
                //   comments={pluck('comments')(attachmentPopupData)}
                //   enterpriseId={pluck('enterpriseId')(attachmentPopupData)}
                //   ipid={pluck('ipid')(attachmentPopupData)}
                //   docTypeId={pluck('docTypeId')(attachmentPopupData)}
                // />
                <UploadFilesPopup
                  onCancel={() => setAttachmentPopupData(null)}
                  onSave={({
                    value: response,
                    changed: fileSetChanged,
                  } = {}) => {
                    const { ScanDocID } = response;

                    setAttachmentPopupData(null);

                    return dispatch({
                      type: DID_SAVE_DOCUMENT_SCAN_DOC,
                      value: {
                        ...selectedDocument,
                        ScanDocID,
                      },
                      index: activeDocumentIndex,
                      fileSetChanged,
                    });
                  }}
                  fileSetEnterpriseId={pluck('enterpriseId')(
                    attachmentPopupData
                  )}
                  fileSetIpid={pluck('ipid')(attachmentPopupData)}
                  scanDocId={pluck('scanDocId')(attachmentPopupData)}
                  defaultFileSetName={pluck('comments')(attachmentPopupData)}
                  docTypeId={pluck('docTypeId')(attachmentPopupData)}
                />
              ) : null}
            </div>
            {documentSet.map((params = EMPTY_OBJECT, index) => {
              const { data = EMPTY_OBJECT } = params;
              const { AssessmentID, Name, IPID, EnterpriseID } = data;
              const hiddenStyle =
                activeDocumentIndex !== index ? { display: 'none' } : {};
              return data ? (
                <SmartBook
                  style={hiddenStyle}
                  SmartBookSchema={SmartBookSchema}
                  SmartBookInstanceSchema={SmartBookInstanceSchema}
                  key={getVisitAssessmentID(data) || `${AssessmentID}_${Name}`}
                  readOnly={readOnly || !canEdit}
                  instance={data}
                  validityStyles={validityStyles}
                  ipid={IPID}
                  enterpriseId={pluck('EnterpriseID')(EnterpriseID)}
                  onChange={({ instance, valid, changed }) => {
                    dispatch({
                      type: UPDATE_SMARTBOOK_INSTANCE,
                      value: instance,
                      changed,
                      valid,
                      index: activeDocumentIndex,
                    });
                  }}
                />
              ) : null;
            })}
          </GridLayout>
        </Flexbox>
      </Flexbox>
      {showCannotPrintPopup ? (
        <Popup
          size="small"
          FooterComponent={toDefaultPopupFooter({
            onClose: () => setShowCannotPrintPopup(false),
          })}
        >
          <Label wrapText>{labels.PrintDenied}</Label>
        </Popup>
      ) : null}
      {saveSelectionData ? (
        <SaveSelectionPopup
          documentSet={saveSelectionData.documentSet}
          validate={saveSelectionData.validate}
          onClose={() => setSaveSelectionData(null)}
          onSave={({ documentSet, isSign }) => {
            dispatch({
              type: SAVE_DOCUMENT_SET,
              value: documentSet,
              isSign,
            });
          }}
          canSignInvalidDocument={availableActions.includes(CAN_SIGN_INVALID)}
        />
      ) : null}
      <Footer>
        <ViewerFooter
          data={selectedDocument}
          availableActions={availableActions}
          editEnabled={!propsReadOnly}
          canEdit={!propsReadOnly && canEdit}
          canSave={canSave}
          canSign={canSign}
          readOnly={propsReadOnly || readOnly}
          onClose={onClose}
          onEdit={() => {
            dispatch({
              type: SHOULD_UNLOCK_DOCUMENT,
              index: activeDocumentIndex,
              readOnly: false,
            });
          }}
          onSign={() => {
            dispatch({
              type: SET_SHOW_VALIDITY_STYLES,
              index: activeDocumentIndex,
            });
            setSaveSelectionData({
              documentSet,
              validate: true,
            });
          }}
          onSaveAndClose={() => {
            dispatch({
              type: SET_SHOW_VALIDITY_STYLES,
              index: activeDocumentIndex,
            });
            setSaveSelectionData({
              documentSet,
              validate: false,
            });
          }}
        />
      </Footer>
    </Window>
  );
};
