import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import {
  localeFormat,
  luxonToDisplayString,
} from '../../format/luxonToDisplayString';
import { Label } from '../../components/Label';

// TODO: Many parent components and features have to go out of their way to use millisecond timestamps to make this component work
// We should probably refactor those places to eliminate the use of date timestamps and pass around dates as ISO strings or Date objects instead
export const GridFooter = (props) => {
  const { count, timestamp, className = '', dataElementName = '' } = props;
  const time = luxonToDisplayString(
    DateTime.fromMillis(timestamp),
    localeFormat.TIME
  );
  return (
    <div
      className={`grid-footer ${className}`}
      data-element-name={dataElementName}
    >
      <Label
        dataElementName="rowCount"
        className="margin-bottom-small"
      >{`Count: ${count} Time: ${time}`}</Label>
    </div>
  );
};

GridFooter.propTypes = {
  count: PropTypes.number,
  timestamp: PropTypes.number,
  className: PropTypes.string,
};

export default GridFooter;
