import { combineEpics } from 'redux-observable';
import {
  combinePredicatedReducers,
  combineViews,
} from '../../connection/toConnectionDef';
import { when } from '../../predication/when';
import { defaultFeatureContextSet } from '../../contexts';

import { toXeConnectedComponent } from '../../compositions/XeConnectedComponent';
import { useXeAuthentication } from '../../contexts/XeAuthenticationContext';
import InlineView from './views/InlineView';
import epics from './epics';
import reducers from './reducers';

const identifier = 'AvailableGuidelines';

const contextSet = {
  authentication: useXeAuthentication,
  ...defaultFeatureContextSet,
};

export const views = [when(() => true)(InlineView)];

export const AvailableGuidelines = toXeConnectedComponent(
  identifier,
  combinePredicatedReducers(...reducers),
  combineEpics(...epics),
  combineViews(...views),
  undefined,
  contextSet
);
