import { combineEpics } from 'redux-observable';
import { combinePredicatedReducers } from '../../../../connection/toConnectionDef';
import { useReducer$ } from '../../../../hooks/useReducer$';
import { useMenuNode } from '../../../../contexts/XeMenuNodeContext';
import { useRef$ } from '../../../../hooks/useRef$';
import { pluck } from '../../../../fp/object';
import { useCallback } from 'react';
import { Flexbox, Label } from '../../../';
import { ActivityAssetCard } from '../../../ActivityAssetCard';
import { ActivityAssetRender } from '../../../ActivityAssetCard/ActivityAssetRender';
import { ActivityAssets } from '../../../ActivityAssetCard/constants';
import { CardGroup } from '../../../CardGroup';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import epics from './epics';
import reducers from './reducers';
import { of } from 'rxjs';

const epic = combineEpics(...epics);
const reducer = combinePredicatedReducers(...reducers);

export const CallLogHistory = (props) => {
  const { AssetXeCallLog, AddCallLogRequest } = props;

  const labels = useXeLabels();

  const ipid$ = useRef$(
    pluck('IPID', 'IPID')(AssetXeCallLog) || pluck('IPID')(AddCallLogRequest)
  );
  const ivid$ = useRef$(pluck('IVID')(AddCallLogRequest));
  const menuNode = useMenuNode();

  const epicWithDeps = useCallback(
    (action$, state$) => {
      return epic(action$, state$, {
        ipid$,
        ivid$,
        menuNode$: of(menuNode),
      });
    },
    [ipid$, ivid$, menuNode]
  );

  const [state = {}] = useReducer$(reducer, epicWithDeps);

  const { callLogHistory = [] } = state;

  if (!callLogHistory.length) {
    return (
      <Flexbox className="stretch" alignItems="center" justifyContent="center">
        <Label className="bold">{labels.NoResults}</Label>
      </Flexbox>
    );
  }

  return (
    <CardGroup
      className="padding-horizontal-small"
      data={callLogHistory}
      renderItem={({ item }) => {
        const { StartDateTime, DerivedCallLogName, PlacerID, CallLogID } = item;
        return (
          <ActivityAssetCard
            key={CallLogID}
            item={{
              ActivityDate: StartDateTime,
              ActivityName: DerivedCallLogName,
              ProviderID: PlacerID,
            }}
          >
            <ActivityAssetRender
              type={ActivityAssets.XeCallLog}
              data={item}
              historyItem={item}
              // onEdit={(item) => /* Open another instance of the AddCallLogPopup? */}
            />
          </ActivityAssetCard>
        );
      }}
    />
  );
};
