import PropTypes from 'prop-types';
import { useRef, forwardRef, Children } from 'react';
import { FILE_UPLOAD } from '../../icons';
import './styles.css';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { ToolTip } from '../ToolTip';
import { NOOP_FUNCTION } from '../../constants';

const clearPreviousSelection = ({ target }) => (target.value = null);

const ToolTipWrapper = ({ description, children }) =>
  description ? <ToolTip value={description}>{children}</ToolTip> : children;

export const AttachmentButton = forwardRef(function AttachmentButton(
  props,
  refFn
) {
  const {
    dataElementName,
    onChange = NOOP_FUNCTION,
    icon,
    description = '',
    buttonProps,
    className = '',
    children,
    ...inputProps
  } = props;

  const fileInput = useRef(null);

  return (
    <div className={className}>
      <ToolTipWrapper description={description}>
        <Button
          dataElementName={dataElementName}
          onClick={() => {
            return fileInput.current && fileInput.current.click();
          }}
          {...buttonProps}
        >
          <Icon icon={icon || FILE_UPLOAD} />
          {Children.count(children) > 0 ? (
            <span className="margin-left-small">{children}</span>
          ) : null}
        </Button>
      </ToolTipWrapper>
      <input
        multiple
        data-element-name="file"
        {...inputProps}
        className="attachment_button__file-input"
        type="file"
        ref={(ref) => {
          fileInput.current = ref;
          if (refFn) {
            refFn(ref);
          }
        }}
        onClick={clearPreviousSelection}
        onChange={(ev) => {
          const fileListAsArray = Array.prototype.slice.call(
            ev.target.files,
            0
          );
          onChange(fileListAsArray);
        }}
      />
    </div>
  );
});

AttachmentButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
};

export default AttachmentButton;
