import { pluck } from '../../fp/object';
import { useState } from 'react';
import uniq from 'lodash/uniq';
import { ExpandableContainer, Flexbox, ToolTip } from '..';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { CHEVRON_DOWN, CHEVRON_RIGHT, WARNING } from '../../icons';
import { Button } from '../Button';
import { ClinicalDocumentViewer } from '../ClinicalDocumentViewer';
import { Icon } from '../Icon';
import { HeaderLabel, Label } from '../Label';
import { List } from '../List';
import { unionBy } from 'lodash/fp';
import { EMPTY_ARRAY, EMPTY_OBJECT, NULL_FUNCTION } from '../../constants';
import { Header } from '../../../../xnetjs/src/components/ClinDocs/Header/index';
import { useEffect } from 'react';
import { isEmpty } from '../../fp/pred';

/**
 * Produces a stable key for a catalog item
 * @param {*} item
 */
// const toCatalogItemKey = (item) => {
//   const { AssessmentID: { AssessmentID } = {}, CatalogID } = item;
//   return `${AssessmentID}_${CatalogID}`;
// };

const CatalogList = ({ item, onChange, disabled }) => {
  const { Name, XeAssessmentCatalogDerived } = item;
  const [expanded, setExpanded] = useState(true);

  return (
    <ExpandableContainer
      Header={({ Controller }) => (
        <Flexbox alignItems="center" className="padding-all-small">
          {Controller}
          <Label dataElementName="clinicalDocumentList__phoneCallType__label">
            {Name}
          </Label>
        </Flexbox>
      )}
      Controller={() => (
        <Icon defaultColor icon={expanded ? CHEVRON_DOWN : CHEVRON_RIGHT} />
      )}
      isExpanded={expanded}
      onChange={setExpanded}
    >
      <div className="margin-left-medium">
        <List
          data={XeAssessmentCatalogDerived}
          renderItem={({ item }) => {
            const {
              AssessmentID: assessmentIdObject = { Name: '', AssessmentID },
            } = item;
            const { Name, AssessmentID } = assessmentIdObject;
            return (
              <div key={`${Name}_${AssessmentID}`}>
                <Button
                  dataElementName="clinicalDocumentList__assessmentId"
                  onClick={() => onChange(item)}
                  className="padding-vertical-small"
                  style={{ textAlign: 'initial' }}
                  disabled={disabled}
                >
                  <Label wrapText={true}>{Name}</Label>
                </Button>
              </div>
            );
            // TODO: (SYNUI-4407) For now, single selection is fine according to Mark (JDM)
            // return (
            //   <Flexbox
            //     justifyContent="space-between"
            //     alignItems="center"
            //     className="margin-vertical-small"
            //   >
            //     <Label>{Name}</Label>
            //     <Checkbox
            //       checked={!!checkedItemsMap[toCatalogItemKey(item)]}
            //       onClick={(ev) => {
            //         const {
            //           target: { checked },
            //         } = ev;
            //         if (checked) {
            //           return onAddItem(item);
            //         }
            //         return onRemoveItem(item);
            //       }}
            //     />
            //   </Flexbox>
            // );
          }}
        />
      </div>
    </ExpandableContainer>
  );
};

const toGroupedByAssessmentType = ([callLogType]) => {
  const { Name } = callLogType;
  const rawCatalog = callLogType?.XeAssessmentCatalogDerived;

  const toHeaderName = (catalog) => {
    return catalog?.AssessmentID?.AssessmentSubTypeID?.Name || Name;
  };
  const assessmentNames = uniq(rawCatalog.map(toHeaderName));

  return assessmentNames.reduce((acc, Name) => {
    return [
      ...acc,
      {
        Name,
        XeAssessmentCatalogDerived: rawCatalog.filter((catalogItem) => {
          return Name === toHeaderName(catalogItem);
        }),
      },
    ];
  }, []);
};

export const ClinicalDocumentList = (props) => {
  const {
    data = EMPTY_ARRAY,
    ipid,
    ivid,
    onChange = NULL_FUNCTION,
    disabled = false,
    disabledDescription,
    PatientHeaderComponent = Header,
    patient,
    signedOutreachItems = EMPTY_ARRAY,
  } = props;
  const labels = useXeLabels();

  const [checkedDocuments, setCheckedDocuments] = useState(null);
  const [savedDocuments, updateSavedDocuments] = useState(EMPTY_ARRAY);

  useEffect(() => {
    if (!isEmpty(signedOutreachItems) && isEmpty(savedDocuments)) {
      updateSavedDocuments(signedOutreachItems);
    }
  }, [signedOutreachItems, updateSavedDocuments, savedDocuments]);

  return (
    <Flexbox className="clinical-document-list" direction="column">
      <Flexbox alignItems="center" className="flex-0">
        <HeaderLabel dataElementName="clinicalDocumentList__outReachItem__label">
          {labels.OutreachItems}
        </HeaderLabel>
        {disabled ? (
          <ToolTip value={disabledDescription}>
            <Icon icon={WARNING} />
          </ToolTip>
        ) : null}
      </Flexbox>
      <List
        data={toGroupedByAssessmentType(data)}
        renderItem={({ item = EMPTY_OBJECT }) => {
          const { CatalogID, Name } = item;
          return (
            <CatalogList
              key={`${CatalogID}_${Name}`}
              item={item}
              onChange={(item) => {
                setCheckedDocuments([pluck('AssessmentID')(item)]);
              }}
              disabled={disabled}
            />
          );
        }}
      />
      <div className="horizontal-separator" />
      <HeaderLabel dataElementName="clinicalDocumentList__currentOutreachItems__label">
        {labels.CurrentOutreachItems}
      </HeaderLabel>
      <List
        data={savedDocuments}
        renderItem={({ item }) => {
          const { Name = '', AssessmentID } = item;
          return (
            <div key={`${Name}_${AssessmentID}`}>
              <Button
                onClick={() => setCheckedDocuments([item])}
                className="padding-vertical-small"
                disabled={disabled}
              >
                <Label>{Name}</Label>
              </Button>
            </div>
          );
        }}
      />
      {checkedDocuments ? (
        <ClinicalDocumentViewer
          ipid={ipid}
          ivid={ivid}
          data={checkedDocuments}
          PatientHeaderElement={
            <PatientHeaderComponent
              patient={patient}
              activeDocument={checkedDocuments}
            />
          }
          onClose={() => {
            return setCheckedDocuments(null);
          }}
          onSave={(documentSet = []) => {
            //If the document set has any documents with the same AssessmentID we overwrite the saved document
            const nextSavedDocuments = unionBy(
              pluck('AssessmentID'),
              documentSet,
              savedDocuments
            );
            onChange(nextSavedDocuments);
            return updateSavedDocuments(nextSavedDocuments);
          }}
        />
      ) : null}
    </Flexbox>
  );
};

export default ClinicalDocumentList;
