import {
  map,
  mergeMap,
  pluck,
  filter,
  tap,
  ignoreElements,
  switchMap,
} from 'rxjs/operators';
import { FEATURE_START } from '../../hooks/scopedReducer';
import { ofType } from '../../frp/operators/ofType';
import { combineWithLatestFrom } from '../../frp/operators/combineWithLatestFrom';
import { ofChangedPropWhenExists } from '../../frp/operators/ofChangedPropWhenExists';
import { toMenuNodeRequestFn } from '../../operators/toMenuNodeRequestFn';
import {
  openUrlInNewTab,
  openFileInNewTab,
  resolveServerPath,
} from '../../utils/navigation';
import { getFile } from 'services/file-storages/xe-file-storages-svc';
import {
  AVAILABLE_GUIDELINES_RESPONSE,
  SHOULD_OPEN_MILLIMAN,
  SHOULD_OPEN_DOCUMENT,
} from './actions';
import { queryGuidelines } from 'services/visit-guidelines/xe-visit-guidelines-svc';
import { create } from 'services/visit-guidelines/xe-visit-guidelines-svc';
import { from } from 'rxjs';

const toMillimanView$ = (action$, state$, dependencies) => {
  const { environment: { authProvider } = {} } = dependencies;

  return action$.pipe(
    ofType(SHOULD_OPEN_MILLIMAN),
    pluck('value'),
    combineWithLatestFrom(
      state$.pipe(
        ofChangedPropWhenExists('contexts', 'authentication', 'authClient')
      ),
      state$.pipe(toMenuNodeRequestFn())
    ),
    mergeMap(([{ pathRoot } = {}, authClient = {}, toRequest]) => {
      return create({ ServerUrl: pathRoot }, {}, toRequest()).pipe(
        switchMap((response) => {
          return from(authClient.getTokenSilently()).pipe(
            map((accessToken) => {
              const authorization =
                authProvider === 'xnet'
                  ? `XNet ${accessToken}`
                  : `Bearer ${accessToken}`;

              return {
                response,
                pathRoot,
                authorization,
              };
            })
          );
        })
      );
    }),
    switchMap(
      ({ pathRoot = '/Synergy', response = {}, authorization = '' } = {}) => {
        const { GuidelinePath = '', Key = '' } = response;

        //Per mark, we can ignore the Synergy portion of this as it can't be changed for compatibility
        const relativeGuidelinesPath = GuidelinePath.replace('Synergy/', '');
        const baseUrl = resolveServerPath(pathRoot, relativeGuidelinesPath);

        const url = `${baseUrl}?authorization=${encodeURIComponent(
          Key
        )}@${encodeURIComponent(authorization)}`;

        return openUrlInNewTab(url, 'Milliman');
      }
    ),
    //map() or tap ... if need to do any sort of action dispatch on milliman close, it goes here
    ignoreElements()
  );
};

const shouldDownloadDocumentByFileID$ = (action$, state$) => {
  return action$.pipe(
    ofType(SHOULD_OPEN_DOCUMENT),
    pluck('value', 'guideline'),
    filter(({ FileID }) => !!FileID),
    combineWithLatestFrom(state$.pipe(toMenuNodeRequestFn())),
    mergeMap(([{ FileID, Name }, toRequest$]) => {
      return getFile({ fileId: FileID }, toRequest$({})).pipe(
        tap((blob) => {
          if (blob) {
            openFileInNewTab(blob, Name);
          }
        }),
        ignoreElements()
      );
    })
  );
};

const shouldDownloadDocumentByURL$ = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(SHOULD_OPEN_DOCUMENT),
    pluck('value'),
    filter(({ guideline: { URL } } = {}) => !!URL),
    tap(({ pathRoot = '/Synergy', guideline: { URL, Name } } = {}) => {
      openUrlInNewTab(resolveServerPath(pathRoot, URL), Name);
    }),
    ignoreElements()
  );
};

const getAvailableGuidelines$ = (action$, state$) => {
  return action$.pipe(
    ofType(FEATURE_START),
    combineWithLatestFrom(state$.pipe(toMenuNodeRequestFn())),
    mergeMap(([, toRequest$]) => {
      return queryGuidelines({}, toRequest$());
    }),
    map(({ XeClinicalLinks: linksList = [] }) => {
      return {
        type: AVAILABLE_GUIDELINES_RESPONSE,
        value: linksList,
      };
    })
  );
};

//AvailableGuidelines
export default [
  toMillimanView$,
  getAvailableGuidelines$,
  shouldDownloadDocumentByFileID$,
  shouldDownloadDocumentByURL$,
];
