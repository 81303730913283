import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import { AVAILABLE_GUIDELINES_RESPONSE, CLOSE_GUIDELINE } from './actions';

const toDefaultState = () => ({ availableGuidelines: [] });

export default [
  when(isType(AVAILABLE_GUIDELINES_RESPONSE))(
    toActionValueReducer('availableGuidelines')
  ),
  when(isType(CLOSE_GUIDELINE))(
    toActionValueReducer('loadedGuideline', () => undefined)
  ),
  when(state => state === undefined)(toDefaultState),
];
