import { toUIValid } from '../../schema/UIValidationProjection';
import { useSchema } from '../../schema/SchemaReducer';
import { isNil } from '../../fp/pred';
import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import uniqid from 'uniqid';
import { Label } from '../Label';
import './styles.css';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';

const toIsChecked = (schemaValue, checkboxValue, defaultValue) => {
  if (schemaValue !== undefined && checkboxValue !== undefined)
    return schemaValue === checkboxValue;
  else if (schemaValue !== undefined) return schemaValue;
  else return defaultValue;
};

const defaultValueFn = (checked, value) => {
  if (!isNil(value)) {
    return checked ? value : undefined;
  }
  return checked;
};

export const Checkbox = forwardRef((props, ref) => {
  const [id] = useState(uniqid());
  const {
    dataElementName = '',
    dataPath,
    label,
    className,
    onClick,
    onChange = NOOP_FUNCTION,
    checked = false,
    value: checkboxValue,
    labelClassName,
    wrapperClassName,
    indeterminate = false,
    onValidation = NOOP_FUNCTION,
    valueFn = defaultValueFn,
    style = EMPTY_OBJECT,
    wrapText = false,
    ...passThroughProps
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const {
    onValueChange = onChange,
    value: schemaValue,
    schemaNode = {},
    onValidationChange = onValidation,
    //required,
  } = useSchema(dataPath);

  const { defaultValue } = schemaNode;
  const controlledChecked =
    toIsChecked(schemaValue, checkboxValue, defaultValue) || checked;

  return (
    <div
      data-component-name="Checkbox"
      ref={ref}
      style={{ display: 'inline-block', ...style }}
      className={wrapperClassName}
      data-element-name={dataElementName}
    >
      <input
        id={id}
        data-indeterminate={indeterminate}
        type="checkbox"
        data-element-name="checkboxInput"
        onChange={({ target: element }) => {
          const { checked, value, validity } = element;
          const schemaUpdateValue = valueFn(
            checked,
            isNil(checkboxValue) ? checkboxValue : value
          );

          if (isNil(checked)) {
            onValueChange(undefined);
            onValidationChange(toUIValid(validity));
            return;
          }

          onValueChange(schemaUpdateValue);
          onValidationChange(toUIValid(validity));
        }}
        onClick={onClick}
        checked={controlledChecked}
        className="k-checkbox k-checkbox-legacy"
        value={checkboxValue}
        {...passThroughProps}
        ref={(ref) => {
          if (ref) {
            ref.indeterminate = indeterminate;
          }
        }}
      />
      <label
        data-element-name="checkboxLabel"
        className={
          className ? `${className} k-checkbox-label` : 'k-checkbox-label'
        }
        htmlFor={id}
      >
        <Label
          wrapText={wrapText}
          dataElementName={
            dataElementName !== '' ? dataElementName + '__label' : ''
          }
          className={labelClassName}
        >
          {label}
        </Label>
      </label>
    </div>
  );
});

export default Checkbox;

Checkbox.propTypes = {
  dataElementName: PropTypes.string,
  dataPath: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  valueFn: PropTypes.func,
};
