import { combineEpics } from 'redux-observable';
import { useReducer$ } from '../../hooks/useReducer$';
import { useMenuNode } from '../../contexts/XeMenuNodeContext';
import { useEffect$ } from '../../hooks/useEffect$';
import { identity } from '../../fp/fp';
import { pluck as pluckFp } from '../../fp/object';
import { ofType } from '../../frp/operators/ofType';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { pluck, tap, map } from 'rxjs/operators';
import { Popup, Flexbox, toDefaultPopupFooter } from '../';
import { useXeLabels } from '../../contexts/XeLabelContext';
import epics from './epics';
import { SHOULD_SAVE_TASK, RESPONSE_SAVE_TASK } from './actions';
import DefaultView from './views/DefaultView';
import './styles.css';
import { of } from 'rxjs';
import { usePathNavigate } from '../../hooks/usePathNavigate';
import NewTaskRequest from 'services/schemas/com.thrasys.xnet.erp.assets.AssetXeWaitList.json';
import UpdateTaskRequest from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListRequest.json';
import { schemaPluck } from '../../schema/schemaTypeBuilder';
import { useXeRefData } from '../../contexts/XeRefDataContext';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { isEmpty } from '../../fp/pred';
import { toFilteredByProgramBits } from '../../utils/bitFiltering';
import useWorklistTemplateWrapperContext from '../WorklistUITemplateWrapper/hooks/useWorklistTemplateWrapperContext';

const epic = combineEpics(...epics);

const toTaskCategories = (category = 'AUTH', categoryTree = []) => {
  return categoryTree.reduce((acc, curr) => {
    if (!isEmpty(acc)) return acc;
    const { item: { CategoryID, XeWaitListSubCategory } = EMPTY_OBJECT } = curr;
    if (CategoryID === category) {
      return XeWaitListSubCategory;
    }
    return acc;
  }, []);
};

export const AddTask = (props) => {
  const {
    dataElementName,
    onClose = NOOP_FUNCTION,
    onSave = NOOP_FUNCTION,
    onChange = NOOP_FUNCTION,
    value: initialValue = EMPTY_OBJECT,
    category = 'TASKCM',
    embedded = false,
    filterBits,
  } = props;

  const labels = useXeLabels();

  const menuNode = useMenuNode();

  const {
    XeAppUserNotificationPriority,
    WaitListStatus,
    XeWaitListCategoryTree = [],
  } = useXeRefData();
  const refData = {
    XeAppUserNotificationPriority: XeAppUserNotificationPriority.map(
      pluckFp('item')
    ),
    WaitListStatus: WaitListStatus.map(pluckFp('item')),
    XeWaitListCategoryTree: XeWaitListCategoryTree.map(pluckFp('item')),
  };

  const epicWithDeps = useCallback(
    (action$, state$) => epic(action$, state$, { menuNode$: of(menuNode) }),
    [menuNode]
  );
  const [, dispatch, action$] = useReducer$(identity, epicWithDeps);

  const [viewAllCategories, onSetViewAllCategories] = useState(false);

  const tempCategory =
    pluckFp('WaitListSubCategoryID', 'CategoryID')(initialValue) || category;
  const selectedCategory =
    'TASK' === tempCategory ? `${tempCategory}ISSUE` : tempCategory;

  const taskCategories = toTaskCategories(
    selectedCategory ?? category,
    XeWaitListCategoryTree
  );

  const { worklistTypeBit } = useWorklistTemplateWrapperContext();
  const finalizedFilterBits = filterBits ?? worklistTypeBit;

  const bitFilteredTaskCategories =
    finalizedFilterBits !== undefined && !viewAllCategories
      ? toFilteredByProgramBits(taskCategories, finalizedFilterBits)
      : taskCategories;

  const { WaitListID } = initialValue;
  const taskSchema = WaitListID ? UpdateTaskRequest : NewTaskRequest;

  useEffect$(
    () =>
      action$.pipe(
        ofType(RESPONSE_SAVE_TASK),
        pluck('value'),
        // TODO: this is currently handling some inconsistencies between the task endpoints & schemas
        // Long-term, it seems like these could be brought closer together, but with the holidays leaving this be for now
        // JCM (SYNUI-5622)
        map((o = {}) => {
          return {
            ...o,
            WaitListSubCategoryID: taskCategories.find(
              ({ WaitListSubCategoryID }) =>
                WaitListSubCategoryID ===
                schemaPluck(taskSchema, 'WaitListSubCategoryID')(o)
            ),
          };
        }),
        tap(onSave)
      ),
    [action$, onSave, taskCategories]
  );

  const [task, setTask] = useState({});
  const [canSaveTask, setCanSaveTask] = useState(false);

  const navigate = usePathNavigate();
  if (
    !XeWaitListCategoryTree.length ||
    !WaitListStatus.length ||
    !XeAppUserNotificationPriority.length
  )
    return null;

  // TODO: this is currently to handle the AppointmentBrowser use case, that has it's own popup
  // that used to toggle between Add Appointment and Add Task. Not sure what the current plan is for that
  // moving forward (JCM / SYNUI-5514)
  if (embedded) {
    return (
      <DefaultView
        dataElementName={dataElementName}
        refData={refData}
        taskCategories={bitFilteredTaskCategories}
        task={task}
        category={category}
        initialValue={initialValue}
        viewAllCategories={viewAllCategories}
        onChange={(value = {}) => {
          onChange(value);
          setTask(value.instance);
        }}
        onViewChart={(value) => {
          navigate(`~/DefaultSearchFeature/${value}`), onClose();
        }}
        onSetViewAllCategories={onSetViewAllCategories}
      />
    );
  }

  return (
    <Popup
      title={WaitListID ? labels.EditPatientTask : labels.AddPatientTask}
      FooterComponent={toDefaultPopupFooter({
        disableConfirm: !canSaveTask,
        onClose,
        onConfirm: () => dispatch({ type: SHOULD_SAVE_TASK, value: task }),
      })}
      className="add-appointment-task-popup"
      size="auto"
    >
      <Flexbox
        direction="column"
        alignItems="center"
        className="flex-1 add-appointment-task-grid"
      >
        <DefaultView
          dataElementName={dataElementName}
          refData={refData}
          task={task}
          category={category}
          taskCategories={bitFilteredTaskCategories}
          initialValue={initialValue}
          viewAllCategories={viewAllCategories}
          onChange={(value = {}) => {
            const { valid, changed, instance } = value;
            onChange(value);
            setTask(instance);
            setCanSaveTask(valid && changed);
          }}
          onViewChart={(value) => {
            navigate(`~/DefaultSearchFeature/${value}`);
            onClose();
          }}
          onSetViewAllCategories={onSetViewAllCategories}
        />
      </Flexbox>
    </Popup>
  );
};

AddTask.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  embedded: PropTypes.bool,
  category: PropTypes.string,
};

export default AddTask;
