export const CREATE_OR_FIND_NEW_DOCUMENT =
  'clinicalDocumentViewer/createOrFind';
export const RESPONSE_CREATE_DOCUMENT = 'clinicalDocumentViewer/createResponse';
export const OPEN_EXISTING_DOCUMENT = 'clinicalDocumentViewer/open';
export const RESPONSE_OPEN_EXISTING_DOCUMENT =
  'clinicalDocumentViewer/openResponse';
export const SET_SHOW_VALIDITY_STYLES =
  'clinicalDocumentViewer/setShowValidityStyles';
export const SAVE_DOCUMENT_SET = 'clinicalDocumentViewer/saveDocumentSet';
export const PROCESS_DOCUMENT_SET = 'clinicalDocumentViewer/processDocumentSet';
export const DID_FINISH_PROCESSING_DOCUMENT_SET =
  'clinicalDocumentViewer/didProcessDocumentSet';
export const SWITCH_ACTIVE_DOCUMENT =
  'clinicalDocumentViewer/switchActiveDocument';
export const INITIALIZE_UNOPENED_SET_ITEMS =
  'clinicalDocumentViewer/initializeUnopenedSetItems';

/* Legacy stuff that's half ported ahead */
export const SHOULD_UNLOCK_DOCUMENT = 'SHOULD_UNLOCK_DOCUMENT';
export const UPDATE_SMARTBOOK_INSTANCE = 'UPDATE_SMARTBOOK_INSTANCE';
export const DID_SAVE_DOCUMENT_SCAN_DOC = 'DID_SAVE_DOCUMENT_SCAN_DOC';
export const SHOULD_PRINT = 'SHOULD_PRINT';
