import { useContext, useState } from 'react';
import { add } from 'services/patient-visit-providers/xe-patient-visit-providers-svc';
import { IconButton } from '../../../../..';
import { NOOP_FUNCTION } from '../../../../../../constants';
import { useXeLabels } from '../../../../../../contexts/XeLabelContext';
import { useXeMutation } from '../../../../../../data/useXeMutation';
import { identity } from '../../../../../../fp/fp';
import { PERSON_ARROW_RIGHT } from '../../../../../../icons';
import { XeStaffSearchWidget } from '../../../../../../widgets';
import { Popup, toDefaultPopupFooter } from '../../../../../Popup';
import { CellRendererContext } from '../../../../contexts/CellRendererContext';

const AssignProviderPopup = (props) => {
  const { onClose, actionData } = props;
  const labels = useXeLabels();
  const [selectedStaff, setSelectedStaff] = useState();
  const { onSearch = NOOP_FUNCTION } = useContext(CellRendererContext);

  const { mutate: addMutation } = useXeMutation((data) =>
    add(data, { xxside: undefined }, identity)
  );

  const { IVID, ProviderTypeID, VisitProviderID } = actionData;
  return (
    <Popup
      size="small"
      FooterComponent={toDefaultPopupFooter({
        closeLabelKey: 'No',
        confirmLabelKey: 'Yes',
        onClose: onClose,
        onConfirm: () => {
          addMutation(
            {
              IVID,
              ProviderTypeID,
              VisitProviderID,
              StaffID: selectedStaff.StaffID,
              StaffID_EnterpriseID: selectedStaff.EnterpriseID.EnterpriseID,
            },
            {
              onSuccess: () => {
                onClose();
                onSearch();
              },
            }
          );
        },
        disableConfirm: !selectedStaff,
      })}
      title={labels.Assign}
    >
      <XeStaffSearchWidget
        descriptor={labels.Assign}
        onChange={(staff) => setSelectedStaff(staff)}
        value={selectedStaff}
      />
    </Popup>
  );
};

export const AssignProvider = (props) => {
  const { actionData, menuNode } = props;

  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <IconButton
        icon={PERSON_ARROW_RIGHT}
        onClick={() => setShowPopup(true)}
      />
      {showPopup && (
        <AssignProviderPopup
          onClose={() => setShowPopup(false)}
          actionData={actionData}
          menuNode={menuNode}
        />
      )}
    </>
  );
};
