import { ofType } from '../../frp/operators/ofType';
import { exists } from '../../frp/operators/exists';
import { combineWithLatestFrom } from '../../frp/operators/combineWithLatestFrom';
import { castSchema } from '../../schema/schemaCaster';
import { pluck as pluckFp } from '../../fp/object';
import { isNil, isObjectLike } from '../../fp/pred';
import { downloadFile } from '../../download/downloadFile';
import { of } from 'rxjs';
import {
  map,
  mergeMap,
  pluck,
  startWith,
  withLatestFrom,
  tap,
  ignoreElements,
  distinctUntilChanged,
} from 'rxjs/operators';
import { getPatient } from 'services/patients/xe-patients-svc';
import { getFile } from 'services/file-storages/xe-file-storages-svc';
import {
  getDetails,
  splitItemPDF,
  updateSetItem,
} from 'services/scan-doc-sets/xe-scan-doc-sets-svc.js';
import {
  getScanDoc,
  withUpdatedScanDoc,
  withUploadedFiles,
} from '../../files/operators';
import { toNowDate, toXsDateTimeString } from '../../g11n/ISODates';
import { neverCache } from '../../service/serviceCache';
import {
  RESPONSE_FETCH_SCAN_DOC,
  PERFORM_FILE_ACTION,
  RESPONSE_ADD_FILE,
  RESPONSE_FETCH_FILE,
  RESPONSE_UPDATE_FILE,
  SET_ITEM_DATA,
  SHOULD_ADD_FILE,
  SHOULD_SPLIT_PDF,
  SHOULD_UPDATE_FILE,
  UPLOAD_ATTACHMENT,
  RESPONSE_SPLIT_FILE,
  UPDATE_UPLOADED_ATTACHMENT,
  REQUEST_DOWNLOAD_ITEM,
  REQUEST_FILE,
  REQUEST_REMOVE_FILE,
  RESPONSE_REMOVE_FILE,
  SHOULD_UPDATE_IPID_DATA,
} from './actions';
import XeScanDocSetSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json';
import XeScanDocSetItemSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$XeScanDocSet.json';

/**
 * Fetches the details for a document set item
 * @type {import('redux-observable').Epic}
 */
const toFetchFileDataEpic$ = (
  action$,
  state$,
  { itemId$, menuNode$, comments$, scanDocId$, docTypeId$ }
) => {
  return itemId$.pipe(
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn(neverCache)())
      )
    ),
    mergeMap(([itemId, toRequest$]) => {
      if (!itemId) return of({ results: null });
      return getDetails({ itemId }, toRequest$({ fullRequest: true }));
    }),
    pluck('results'),
    withLatestFrom(comments$, scanDocId$, docTypeId$),
    map(([itemDetails, comments, scanDocId, docTypeId]) => {
      if (!itemDetails) {
        return {
          type: SET_ITEM_DATA,
          value: {
            Comments: comments,
            DocTypeID: docTypeId,
            ScanDocID: {
              ScanDocID: scanDocId,
            },
          },
        };
      }

      return {
        type: SET_ITEM_DATA,
        value: itemDetails,
      };
    })
  );
};

/**
 * Fetches the details of the file in the set
 * @type {import('redux-observable').Epic}
 */
const toFetchFileEpic$ = (action$, state$, { fileId$, menuNode$ }) => {
  return action$.pipe(
    ofType(REQUEST_FILE),
    combineWithLatestFrom(
      fileId$.pipe(exists()),
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([, fileId, toRequest$]) => {
      return getFile({ fileId: fileId }, toRequest$({ fullRequest: true }));
    }),
    pluck('blob'),
    map((blob) => {
      return {
        type: RESPONSE_FETCH_FILE,
        value: blob,
      };
    })
  );
};

/**
 * Requests scan doc data for a given scan doc id
 * @type {import('redux-observable').Epic}
 */
const toFetchScanDocEpic$ = (action$, state$, { menuNode$, scanDocId$ }) => {
  return action$.pipe(ofType(SET_ITEM_DATA), pluck('value')).pipe(
    mergeMap((value) => {
      const fallbackScanDocData = castSchema(XeScanDocSetSchema)(value);

      const scanDocId = pluckFp('ScanDocID')(fallbackScanDocData);
      if (!scanDocId) {
        return of({
          type: RESPONSE_FETCH_SCAN_DOC,
          value: fallbackScanDocData,
        });
      }

      return of(scanDocId).pipe(
        getScanDoc((scanDocId) => scanDocId, menuNode$),
        pluck('results'),
        map(([response]) => {
          return {
            type: RESPONSE_FETCH_SCAN_DOC,
            value: response || fallbackScanDocData,
          };
        })
      );
    })
  );
};

const toFileID = (FileID) => (isObjectLike(FileID) ? FileID.FileID : FileID);
const toDownloadFileEpic$ = (action$, state$, { menuNode$ }) =>
  action$.pipe(
    ofType(REQUEST_DOWNLOAD_ITEM),
    pluck('value'),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([{ FileName, FileID } = {}, toRequest$]) => {
      return getFile(
        { fileId: toFileID(FileID) },
        toRequest$({ fullRequest: true })
      ).pipe(
        tap(({ blob }) => {
          return downloadFile(blob, FileName);
        }),
        ignoreElements()
      );
    })
  );

/**
 * Uploads a file from the file system and dispatches and action to update the item data in state
 * @type {import('redux-observable').Epic}
 */
const toUploadAttachmentEpic$ = (
  action$,
  state$,
  { menuNode$, enterpriseContext$ }
) => {
  return action$.pipe(
    ofType(UPLOAD_ATTACHMENT),
    pluck('value'),
    withUploadedFiles((files) => files, menuNode$),
    withLatestFrom(enterpriseContext$),
    map(([[files = [], response = []], enterprise]) => {
      /**
       * @type {File}
       */
      const requestedFile = files[0] || {};
      const requestedFileResponse = response[0] || {};

      const fileName = requestedFile.name;
      return {
        type: UPDATE_UPLOADED_ATTACHMENT,
        value: castSchema(XeScanDocSetItemSchema)({
          Active: true,
          FileSize: requestedFile.size,
          FileName: fileName,
          Description: fileName,
          UploadDate: toXsDateTimeString(toNowDate()),
          UploadUser: pluckFp('userData', 'XeResource')(enterprise) || {},
          ...requestedFileResponse,
        }),
      };
    })
  );
};

/**
 * Determines whether to dispatch an add or update action
 * @type {import('redux-observable').Epic}
 */
const toPerformFileActionEpic$ = (action$, state$, { itemId$ }) => {
  return action$.pipe(
    ofType(PERFORM_FILE_ACTION),
    pluck('value'),
    withLatestFrom(itemId$.pipe(startWith(undefined))),
    map(([value, itemId]) => {
      if (!itemId) {
        return {
          type: SHOULD_ADD_FILE,
          value,
        };
      }

      return {
        type: SHOULD_UPDATE_FILE,
        value,
      };
    })
  );
};

/**
 * Creates a new scan doc and adds the current file to the set
 * @type {import('redux-observable').Epic}
 */
const toAddFileEpic$ = (
  action$,
  state$,
  { menuNode$, scanDocId$, ipid$, enterpriseId$ }
) => {
  return action$.pipe(
    ofType(SHOULD_ADD_FILE),
    pluck('value'),
    withLatestFrom(
      ipid$.pipe(startWith(undefined)),
      scanDocId$.pipe(startWith(undefined)),
      enterpriseId$.pipe(startWith(undefined)),
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    withUpdatedScanDoc(
      ([attachment = {}, ipid, scanDocId]) => {
        const { IPID, ScanDocID } = attachment;
        // TODO: most of this seems like it could be passed in as the initial instance (JCM)
        return {
          ...attachment,
          Active: true,
          ScanDocID: scanDocId || ScanDocID,
          IPID: ipid ? parseInt(ipid) : IPID,
        };
      },
      menuNode$,
      { enterpriseId$ }
    ),
    map(([, response]) => {
      return {
        type: RESPONSE_ADD_FILE,
        value: response,
      };
    })
  );
};

/**
 * Updates a file in a set as well as the parent scan doc set
 * @type {import('redux-observable').Epic}
 */
const toUpdateFileEpic$ = (action$, state$, { menuNode$, enterpriseId$ }) => {
  return action$.pipe(
    ofType(SHOULD_UPDATE_FILE),
    pluck('value'),
    withUpdatedScanDoc((attachment = {}) => attachment, menuNode$, {
      enterpriseId$,
    }),
    map(([, response]) => {
      return {
        type: RESPONSE_UPDATE_FILE,
        value: response,
      };
    })
  );
};

/**
 * When applicable, sends a request to split a PDF
 * @type {import('redux-observable').Epic}
 */
const toSplitFileEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(SHOULD_SPLIT_PDF),
    pluck('value'),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([value, toRequest$]) => {
      const { fileData = {}, splitData = [], attachment = {} } = value;
      const { FileID: { FileID } = {}, ItemID, StatusCode = '' } = fileData;

      const { DocTypeID, StatusCode: ParentStatusCode } = attachment;

      const XeScanDocSet = splitData.map((split) => {
        /*eslint-disable no-unused-vars*/
        const { valid, ...rest } = split;

        return {
          ...rest,
          XeScanDoc: {
            DocTypeID: pluckFp('DocTypeID')(DocTypeID),
          },
        };
      });

      return splitItemPDF(
        {
          // Description, // The service is currently rejecting this
          FileID,
          ParentStatusCode,
          StatusCode,
          XeScanDocSet,
        },
        { itemId: ItemID },
        toRequest$({ fullRequest: true })
      ).pipe(pluck('results'));
    }),
    // TODO: Is the split response worth anything here? (JDM)
    map((response) => {
      return {
        type: RESPONSE_SPLIT_FILE,
        value: response,
      };
    })
  );
};

const toRemoveFileEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(REQUEST_REMOVE_FILE),
    pluck('value'),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([value = {}, toRequest$]) => {
      const { ItemID } = value;
      return updateSetItem(
        value,
        { itemId: ItemID },
        toRequest$({ fullRequest: true })
      ).pipe(pluck('results'));
    }),
    map((value) => {
      return {
        type: RESPONSE_REMOVE_FILE,
        value,
      };
    })
  );
};

/**
 * Fetches full IPID information when the ipid is provided
 * @type {import('redux-observable').Epic}
 *
 */

const toIPIDDataEpic$ = (action$, state$, { ipid$, menuNode$ }) => {
  return ipid$.pipe(
    distinctUntilChanged(),
    withLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([ipid, toRequest$]) => {
      if (isNil(ipid)) {
        return of({ results: [] });
      }
      return getPatient(
        {
          ipid,
        },
        toRequest$({ fullRequest: true })
      );
    }),
    pluck('results'),
    map((results = []) => ({
      type: SHOULD_UPDATE_IPID_DATA,
      value: results[0],
    }))
  );
};

export default [
  toFetchFileDataEpic$,
  toFetchFileEpic$,
  toUpdateFileEpic$,
  toSplitFileEpic$,
  toAddFileEpic$,
  toUploadAttachmentEpic$,
  toPerformFileActionEpic$,
  toFetchScanDocEpic$,
  toDownloadFileEpic$,
  toRemoveFileEpic$,
  toIPIDDataEpic$,
];
