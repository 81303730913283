import { Children } from 'react';
import PropTypes from 'prop-types';

import './styles.css';
import { EMPTY_ARRAY } from '../../constants';

// The basic pattern is to provide inputs as children to the FilterBar component.
// Widgets should be wrapped in a fragment, which will wrap adjacent label + widget in a div via the map function below.
// The toolbar buttons are replaced by wrapping the Filterbar in a ViewHeader component, which will handle the buttons.
// See XeClaimBrowserFilterBar, XeAttachmentsFilterBar, and XeClindocBrowseFilterBar for reference. (MM) [SYNUI-4786]
export const FilterBar = (props) => {
  const { children = EMPTY_ARRAY, className = '' } = props;
  return (
    <div
      className={`xjs-filter-bar flex-container align-items-center flex-wrap input-form ${className}`}
      data-component-name="FilterBar"
    >
      {Children.map(children, (child) => (
        <div className="flex-container xjs-filter-bar__control">{child}</div>
      ))}
    </div>
  );
};

FilterBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};
