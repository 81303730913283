export const XE_PATIENT_PROVIDER_ACTION_KEY = 'XePatientProvider';
export const XE_PROVIDER_ASSIGNMENT_ACTION_KEY = 'XePatientVisitProvider';
export const XE_VISIT_ASSESSMENT_ACTION_KEY = 'XeVisitAssessment';
export const XE_CALL_LOG_ACTION_KEY = 'XeCallLog';
export const XE_WORK_GRAB_ACTION_KEY = 'XeWork';
export const XE_AUTH_DETAILS_ACTION_KEY = 'XeAuth';
export const XE_VISIT_CASE_MANAGEMENT_ACTION_KEY =
  'XeVisitCaseManagementProgram';
export const XE_INVOICE_ACTION_KEY = 'InvoiceData';
export const XE_AUTH_PROVIDER_DETAILS_ACTION_KEY = 'XeAuthProvider';
export const XE_PATIENT_ISSUE_DETAILS_ACTION_KEY = 'XePatientIssue';
export const XE_SERVICE_BOOKING_ACTION_KEY = 'XeServiceBooking';
export const XE_SCAN_DOC_SET_ACTION_KEY = 'XeScanDocSet';
export const XE_ENCOUNTER_FORM_ACTION_KEY = 'XeEncForm';
export const XE_CLEARING_HOUSE = 'XeClearingHouse';
export const XE_CLAIM_BATCH = 'XeClaimBatch';

export const ADD_PATIENT_PROVIDER = 'add';
