import PropTypes from 'prop-types';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Label } from '../Label';
import { ToolTip } from '../ToolTip';
import './styles.css';
import document from '../../globals/document';
import { isString } from '../../fp/pred';

const defaultFormatter = (data) =>
  isString(data) ? data : JSON.stringify(data);

//TODO: Move these functions to src/browser/browser.js once improv/SYNUI-781 gets merged
const createComponentFn =
  (document) =>
  (...args) =>
    document.createElement(...args);
const createComponent = createComponentFn(document);
const getElementByTagNameFn =
  (document) =>
  (...args) =>
    document.getElementsByTagName(...args);
const getElementsByTagName = getElementByTagNameFn(document);
const execCommandFn =
  (document) =>
  (...args) =>
    document.execCommand(...args);
const execCommand = execCommandFn(document);

const copyStringToClipboard = (string) => {
  //Creating a textarea element to put our text
  const element = createComponent('textarea');
  //Setting its textContent to what we want to copy
  element.textContent = string;
  //Getting the body tag
  const body = getElementsByTagName('body')[0];
  //Appending our element with the text to copy to the body
  body.appendChild(element);
  //selecting the the text in our element
  element.select();
  //Copying what we have selected
  execCommand('copy');
  //Remove the element that was used just for the copy
  body.removeChild(element);
};

export const CopyToClipboardButton = ({
  data,
  dataElementName = '',
  labelFn = defaultFormatter,
  buttonLabel = '',
  toolTipLabel = 'Click to copy',
  buttonClassName = 'traditional-button',
  iconClassName = 'traditional-icon',
  icon = 'clipboard-text',
  onClick = () => {},
}) => (
  <div
    data-component-name="CopyToClipboardButton"
    className="copy-data-button-container"
  >
    <Button
      dataElementName={dataElementName}
      onClick={(ev) => {
        copyStringToClipboard(labelFn(data));
        onClick(ev);
      }}
      className={`copy-to-clipboard ${buttonClassName}`}
    >
      <div className="label-image">
        <Label>{buttonLabel}</Label>
        <ToolTip value={toolTipLabel}>
          <Icon icon={icon} className={iconClassName} />
        </ToolTip>
      </div>
    </Button>
  </div>
);

CopyToClipboardButton.propTypes = {
  data: PropTypes.any.isRequired,
  labelFn: PropTypes.func,
  buttonLabel: PropTypes.string,
  toolTipLabel: PropTypes.string,
  buttonClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

export default CopyToClipboardButton;
