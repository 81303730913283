import { useXeLabels } from '../../../contexts/XeLabelContext';
import { Popup, toDefaultPopupFooter } from '../..';

export const AssignToSelfPopup = ({ onClose, onConfirm }) => {
  const labels = useXeLabels();
  return (
    <Popup
      size="small"
      FooterComponent={toDefaultPopupFooter({
        closeLabelKey: 'No',
        confirmLabelKey: 'Yes',
        onClose: onClose,
        onConfirm: onConfirm,
      })}
    >
      <div>{labels.GrabOverrideConfirm}</div>
    </Popup>
  );
};
