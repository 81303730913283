import { ofType } from '../../../../../../frp/operators/ofType';
import { identity } from '../../../../../../fp/fp';
import { combineEpics } from 'redux-observable';
import { useReducer$ } from '../../../../../../hooks/useReducer$';
import { useEffect$ } from '../../../../../../hooks/useEffect$';
import { DropDownButton } from '@progress/kendo-react-buttons';
import { useCallback } from 'react';
import { ADD, REMOVE } from '../../../../../../icons';
import { IconButton } from '../../../../../IconButton';
import './styles.css';
import epics from './epics';
import { ROW_ACTION_ADD, ROW_ACTION_UPDATE } from './constants';
import {
  DID_ADD_PATIENT_PROVIDER,
  DID_UPDATE_PATIENT_PROVIDER,
  SHOULD_ADD_PATIENT_PROVIDER,
  SHOULD_UPDATE_PATIENT_PROVIDER,
} from './actions';
import { tap } from 'rxjs/operators';
import { useActiveList } from '../../../../../../hooks/useActiveList';
import { of } from 'rxjs';

const toXePatientProviderActionIconName = ({ Action }) => {
  if (Action === ROW_ACTION_UPDATE) return REMOVE;
  else if (Action === ROW_ACTION_ADD) return ADD;
};

const epic = combineEpics(...epics);

export const PatientProvider = (props) => {
  const {
    actionData,
    updateActionCellData,
    menuNode,
    labels,
    rights,
    refData,
  } = props;

  const { CREATE_CLINICAL_PATIENT_DATA_CAREPROVIDER } = rights;
  const { XePatientProviderType } = refData;
  const activeXePatientProviderType = useActiveList(XePatientProviderType);

  const epicWithDeps = useCallback(
    (action$, state$) => {
      return epic(action$, state$, {
        menuNode$: of(menuNode),
      });
    },
    [menuNode]
  );

  const [, dispatch, action$] = useReducer$(identity, epicWithDeps);

  useEffect$(() => {
    return action$.pipe(
      ofType(DID_ADD_PATIENT_PROVIDER, DID_UPDATE_PATIENT_PROVIDER),
      tap(() => {
        updateActionCellData({
          ...actionData,
          Valid: false,
        });
      })
    );
  }, [action$, updateActionCellData]);

  if (!CREATE_CLINICAL_PATIENT_DATA_CAREPROVIDER) return null;

  const iconName = toXePatientProviderActionIconName(actionData);

  const { Action, Valid } = actionData;

  // After doing an action, we make the action invalid
  // If an action is not valid, we should not show it
  if (!Valid) return null;

  // When doing the Remove action
  if (Action === ROW_ACTION_UPDATE) {
    return (
      <IconButton
        icon={iconName}
        tooltip
        description={labels.Remove}
        onClick={() => {
          const { Active, ProviderID } = actionData;

          /**
           * @type {import('services/generated/types').PatientProviderUpdateRequest}
           */
          const dispatchValue = {
            Active,
            ProviderID,
          };

          return dispatch({
            type: SHOULD_UPDATE_PATIENT_PROVIDER,
            value: dispatchValue,
          });
        }}
      />
    );
  }

  const { IPID, StaffID } = actionData;

  return (
    <DropDownButton
      iconClass={`mdi mdi-${iconName} patient-provider-action-renderer__provider-type-dropdown`}
      items={activeXePatientProviderType}
      textField="text"
      popupSettings={{
        popupClass: 'k-column-list',
      }}
      onItemClick={({ item }) => {
        const { id: ProviderTypeID } = item;

        /**
         * @type {import('services/generated/types').PatientProviderAddRequest}
         */
        const dispatchValue = {
          ProviderTypeID,
          IPID,
          StaffID,
        };

        return dispatch({
          type: SHOULD_ADD_PATIENT_PROVIDER,
          value: dispatchValue,
        });
      }}
    />
  );
};

export default PatientProvider;
