import { useState } from 'react';
import { add as addVisitProvider } from 'services/patient-visit-providers/xe-patient-visit-providers-svc';
import { useXeMutation } from '../../../../../data/useXeMutation';
import { set } from '../../../../../fp/object';
import { PERFORM_ACTION } from '../../../../../icons';
import { XeStaffSearchWidget } from '../../../../../widgets';
import { Flexbox } from '../../../../Flexbox';
import IconButton from '../../../../IconButton';
import {
  ADD_PATIENT_PROVIDER,
  XE_PROVIDER_ASSIGNMENT_ACTION_KEY,
} from '../../../constants';
import { identity } from '../../../../../fp/fp';

export const GlobalActionVisitProvider = (props) => {
  const { labels, onSearch, gridApi } = props;

  const [selectedStaff, setSelectedStaff] = useState();

  const { mutate: addVisitProviderMutation } = useXeMutation((data) =>
    addVisitProvider(data, { xxsid: undefined }, identity)
  );

  // TODO: Determine rights
  // if (!CREATE_CLINICAL_PATIENT_DATA_CAREPROVIDER) {
  //   return null;
  // }

  return (
    <Flexbox direction="row">
      <XeStaffSearchWidget
        descriptor={labels.Assign}
        onChange={(staff) => setSelectedStaff(staff)}
        value={selectedStaff}
      />
      <IconButton
        dataElementName="performAction"
        icon={PERFORM_ACTION}
        disabled={!selectedStaff}
        tooltip
        description={labels.Submit}
        onClick={() => {
          /**
           * @type {GridRef}
           */
          const selectedRows = gridApi.getSelectedRows();

          // From docs:
          // "If you make multiple requests, onSuccess will fire only after the latest call you've made."
          // This seems like a lot... Is there a better way to handle parallel mutations?
          // I'd rather a success callback _per_ request, but from the docs, that doesn't seem
          // immediately possible (JDM)
          const onSuccessCallback = () => {
            const currentSelectedNodes = gridApi.getSelectedNodes();
            currentSelectedNodes.forEach((rowNode) => {
              const { data } = rowNode;
              rowNode.updateData(
                set(
                  `ActionList.${XE_PROVIDER_ASSIGNMENT_ACTION_KEY}.Valid`,
                  false,
                  data
                )
              );

              rowNode.setSelected(false);
            });
            onSearch();
          };

          selectedRows.forEach((row) => {
            const {
              row: { ActionList },
            } = row;
            const { Action, IVID, ProviderTypeID, VisitProviderID } = {
              ...(ActionList[XE_PROVIDER_ASSIGNMENT_ACTION_KEY] || {}),
            };
            if (Action === ADD_PATIENT_PROVIDER) {
              return addVisitProviderMutation(
                {
                  IVID,
                  ProviderTypeID,
                  VisitProviderID,
                  StaffID: selectedStaff.StaffID,
                  StaffID_EnterpriseID: selectedStaff.EnterpriseID.EnterpriseID,
                },
                {
                  onSuccess: onSuccessCallback,
                }
              );
            }
          });
        }}
      />
    </Flexbox>
  );
};
