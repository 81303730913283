import React, { useState } from 'react';
import AppointmentPopup from '../../../../../../components/AppointmentPopup';
import IconButton from '../../../../../../components/IconButton';
import { EDIT_APPOINTMENT } from '../../../../../../icons';

export const EditAppointmentAction = (props) => {
  const { actionData, labels } = props;

  const [showAppointment, setShowAppointment] = useState(false);

  return (
    <>
      <IconButton
        icon={EDIT_APPOINTMENT}
        description={labels.Edit}
        onClick={() => setShowAppointment(true)}
      />
      {showAppointment && (
        <AppointmentPopup
          data={actionData}
          onSave={() => setShowAppointment(false)}
          onClose={() => setShowAppointment(false)}
        />
      )}
    </>
  );
};

export default EditAppointmentAction;
