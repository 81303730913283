import EncounterFormPopupView from '../../../../../EncounterFormPopup';
import { useContext, useState } from 'react';
import {
  IconButton,
  Popup,
  toDefaultPopupFooter,
  Label,
} from '../../../../../../components';
import { EDIT, REMOVE } from '../../../../../../icons';
import { markEncounterInError } from 'services/enc-forms/xe-enc-forms-svc';
import { useXeMutation } from '../../../../../../data/useXeMutation';
import { identity } from '../../../../../../fp/fp';
import { CellRendererContext } from '../../../../contexts/CellRendererContext';
import { NOOP_FUNCTION } from '../../../../../../constants';

export const EditEncounterFormAction = (props) => {
  const { actionData, labels } = props;

  const { EncFormID, IsEdit, IsMarkInError } = actionData;

  const [showEditEncounterFormPopup, setShowEditEncounterFormPopup] =
    useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const { onSearch = NOOP_FUNCTION } = useContext(CellRendererContext);

  const { mutate: markEncounterInErrorMutation } = useXeMutation((data) =>
    markEncounterInError(data, identity)
  );

  return (
    <>
      {IsEdit ? (
        <IconButton
          icon={EDIT}
          description={labels.Edit}
          onClick={() => setShowEditEncounterFormPopup(true)}
        />
      ) : null}
      {IsMarkInError ? (
        <IconButton
          icon={REMOVE}
          description={labels.Remove}
          onClick={() => setShowConfirmationDialog(true)}
        />
      ) : null}
      {showEditEncounterFormPopup ? (
        <EncounterFormPopupView
          onClose={() => {
            setShowEditEncounterFormPopup(false);
          }}
          onSaveClose={() => {
            setShowEditEncounterFormPopup(false);
            onSearch();
          }}
          EncFormID={EncFormID}
        />
      ) : null}
      {showConfirmationDialog ? (
        <Popup
          FooterComponent={toDefaultPopupFooter({
            onClose: () => {
              setShowConfirmationDialog(false);
            },
            confirmLabelKey: 'Confirm',
            onConfirm: () => {
              markEncounterInErrorMutation(
                { encFormId: EncFormID },
                {
                  onSuccess: () => {
                    setShowConfirmationDialog(false);
                    onSearch();
                  },
                }
              );
            },
          })}
          size="small"
          title="Confirm"
        >
          <Label>Are you sure you want to remove this encounter?</Label>
        </Popup>
      ) : null}
    </>
  );
};

export default EditEncounterFormAction;
