import { USER_RIGHTS_FOR_CLINDOCS } from './constants';

export const toHasRightPred = (rightsBase, userRights = []) => (
  rightToVerify
) => {
  //Per SYNUI-5651, an undefined Right's base means the document is uncontrolled
  return !rightsBase || userRights.includes(`${rightToVerify}_${rightsBase}`);
};

export const toAvailableActionsForDocument = (
  clindoc = { RightsBase: undefined },
  RightID = '',
  actions = USER_RIGHTS_FOR_CLINDOCS
) => {
  const { RightsBase } = clindoc;
  const hasRight = toHasRightPred(RightsBase, RightID.split('|'));
  return actions.filter(hasRight);
};
