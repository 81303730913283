import { pluck } from '../../fp/object';
import { schemaGet } from '../../schema/schemaTypeBuilder';
import StaffSearchByTypeRequestSchema from 'services/schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSearchByTypeRequest.json';
import { ButtonBar, DropDownList, Flexbox } from '../';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { useXeRefData } from '../../contexts/XeRefDataContext';
import useContactCategoryOptions from '../../hooks/useContactCategoryOptions';
import { XeStaffSearchWidget } from '../../widgets';
import { useState } from 'react';
import { castFunction } from '../../fp/fp';
import { isEmpty } from '../../fp/pred';

export const INDIVIDUAL = 'INDIVIDUAL';
export const ORGANIZATION = 'ORGANIZATION';

export const AssignClinicalContact = (props) => {
  const { onRoleChange, onChange, onProgramChange } = props;
  const labels = useXeLabels();

  const contactCategoryOptions = useContactCategoryOptions();

  const { XePatientProviderType = [], ProviderCareType = [] } = useXeRefData();

  const [selectedContactCategory, updateSelectedContactCategory] = useState(
    contactCategoryOptions.find((item = {}) => item.nameKey === 'Individual')
  );
  const [selectedContact, updateSelectedContact] = useState();
  const [selectedContactType, updateSelectedContactType] = useState();
  const [programId, updateProgramId] = useState();

  const resourceTypeId =
    selectedContactCategory.nameKey === 'Individual'
      ? INDIVIDUAL
      : ORGANIZATION;

  const resourceIDFilter = ({ item }) => {
    const isResourceTypeIndividual = resourceTypeId === INDIVIDUAL;
    const _IsOrganization = pluck(
      'DefaultResourceTypeID',
      'IsOrganization'
    )(item);
    return isResourceTypeIndividual
      ? _IsOrganization === false
      : _IsOrganization === true;
  };

  return (
    <Flexbox direction="column" alignItems="start" justifyContent="center">
      <ButtonBar
        className="margin-auto"
        selectedItem={selectedContactCategory}
        data={contactCategoryOptions}
        labelFn={({ nameKey }) => labels[nameKey]}
        keyFn={({ value }) => value}
        onSelect={(item) => {
          updateSelectedContactCategory(item);
          updateSelectedContactType(undefined);
          updateProgramId(undefined);
          castFunction(onProgramChange)(undefined);
          castFunction(onRoleChange)(undefined);
          onChange(undefined);
          updateSelectedContact(undefined);
        }}
      />
      <br />
      <XeStaffSearchWidget
        className="margin-bottom-small"
        descriptor={labels.Provider}
        dataElementName="provider"
        value={schemaGet(
          StaffSearchByTypeRequestSchema,
          'StaffID',
          selectedContact
        )}
        onChange={(value) => {
          onChange(value);
          updateSelectedContact(value);
        }}
        initialFilters={{
          isUser: true,
          resourceTypeId,
        }}
        canAdd={false}
      />
      <DropDownList
        className="margin-bottom-small"
        descriptor={labels.Role}
        dataElementName="role"
        value={selectedContactType}
        valueFn={pluck('item')}
        labelFn={pluck('text')}
        onChange={(value) => {
          updateSelectedContactType(value);
          return castFunction(onRoleChange)(value);
        }}
        data={XePatientProviderType.filter(resourceIDFilter)}
      />
      {!isEmpty(ProviderCareType) && (
        <DropDownList
          descriptor={labels.CareType}
          dataElementName="program"
          data={ProviderCareType}
          valueFn={pluck('item')}
          labelFn={pluck('text')}
          value={programId}
          onChange={(value) => {
            updateProgramId(value);
            return castFunction(onProgramChange)(value);
          }}
        />
      )}
    </Flexbox>
  );
};
