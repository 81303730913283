import { useEffect, useRef, useState } from 'react';
import { Flexbox, Icon, Label, IconButton } from '../../components';
import { formatStaffName } from '../../utils';
import { castFunction } from '../../fp/fp';
import { localeFormat } from '../../format/luxonToDisplayString';
import { toDisplayDateFromISOString } from '../../g11n/displayDates';
import withClassNameModifiers from '../../utils/withClassNameModifiers';
import {
  CHEVRON_DOWN,
  CHEVRON_RIGHT,
  ACTIVITY_ASSET_CALL,
  ACTIVITY_ASSET_UPLOAD,
  ACTIVITY_ASSET_TASKS,
  ACTIVITY_ASSET_VISIT_NOTE,
  ACTIVITY_ASSET_AUTH_MENU,
  ACTIVITY_ASSET_CALL_FAIL,
  ACTIVITY_ASSET_MAIL,
  ACTIVITY_ASSET_DEFAULT,
  ACTIVITY_ASSET_WAITLIST,
} from '../../icons';
import { AssetRenderForType } from './ActivityAssetRender';
import './styles.css';
import { isNil } from '../../fp/pred';

const activityIconMap = new Proxy(
  {
    xe_call_light: ACTIVITY_ASSET_CALL,
    xe_call_message: ACTIVITY_ASSET_CALL,
    xe_call_fail: ACTIVITY_ASSET_CALL_FAIL,
    xe_mail: ACTIVITY_ASSET_MAIL,
    xe_upload: ACTIVITY_ASSET_UPLOAD,
    xe_auth_menu: ACTIVITY_ASSET_AUTH_MENU,
    xe_tasks: ACTIVITY_ASSET_TASKS,
    xe_visit_note: ACTIVITY_ASSET_VISIT_NOTE,
    xe_waitlist: ACTIVITY_ASSET_WAITLIST,
  },
  {
    get: (target, prop) => {
      if (prop in target) return target[prop];
      return ACTIVITY_ASSET_DEFAULT;
    },
  }
);

/**
 * @name toFormatStaffName
 * @param {Number} providerID
 * @returns {String}
 */
const toFormatStaffName = (providerID) => {
  if (!providerID) return '';

  const fullStaffName = formatStaffName(providerID);

  if (!fullStaffName) {
    return '';
  }

  return ` – ${fullStaffName}`;
};

/**
 * @typedef {'XeWorkflow'|'XePatientIssue'|'XeCallLog'|'XeScanDocSet'|'XeVisitCorrespRecip'|'XeVisitAssessment'} ActivityAsset
 */

/**
 * @typedef HistoryItem
 * @property {ActivityAsset} ActivityAsset
 * @property {String} ActivityDate
 * @property {String} ActivityName
 * @property {String} Description
 * @property {Number} IPID
 * @property {Number} IVID
 * @property {'y'|'n'|'p'} IsActiveStatus
 * @property {String} Label
 * @property {String} PrimaryKey
 * @property {{ FamilyName: String, GivenName: String, NameTitleID: Object, StaffID: Number }} ProviderID
 */

/**
 * @param {HistoryItem} historyItem
 */
const toCardIconName = (historyItem) => {
  if (!historyItem) return 'xe_tasks';

  const { ActivityAsset, ActivityName, IsActiveStatus } = historyItem;

  switch (ActivityAsset) {
    case 'XeCallLog': {
      if (IsActiveStatus === 'y') return 'xe_call_light';
      if (IsActiveStatus === 'p') return 'xe_call_message';
      if (IsActiveStatus === 'n') return 'xe_call_fail';
      break;
    }
    case 'XeVisitAssessment':
      return 'xe_visit_doc';
    case 'XeVisitCorrespRecip':
      return 'xe_mail';
    case 'XeScanDocSet':
      return 'xe_upload';
    case 'XePatientVisit':
      return 'xe_visit';
    case 'XeVisitActiveServices':
      return 'xe_med_rec';
    case 'XeVisitCaseManagement': {
      if (ActivityName === 'Owner') return 'xe_provider_group';
      break;
    }
    case 'XeSmartBookInstance':
    case 'XeSmartBookInstanceHistory': {
      if (ActivityName === 'CarePlan') return 'xe_careplan';
      if (ActivityName === 'CarePlanGoal') return 'xe_careplan_goal';
      if (ActivityName === 'CarePlanIntervention')
        return 'xe_careplan_intervention';
      if (ActivityName === 'CarePlanItemAction') return 'xe_careplan_action';
      if (ActivityName === 'CarePlanItemNote') return 'xe_careplan_note';
      if (ActivityName === 'CarePlanBarrier') return 'xe_careplan_barrier';
      break;
    }
    case 'XeVisitNote': {
      return 'xe_visit_note';
    }
    case 'XeWaitList': {
      return 'xe_waitlist';
    }
    default:
      return 'xe_tasks';
  }
};

/**
 * @typedef ActivityAssetCardProps
 * @property {HistoryItem} item
 * @property {({ nativeEvent, isOpen }: { nativeEvent: Event, isOpen: Boolean }) => void=} onToggle
 * @property {Boolean} mini
 * @property {Object} details
 */

/**
 * @param {ActivityAssetCardProps} props
 */
export const ActivityAssetCard = (props) => {
  const { item, mini, onToggle, hasDetails, children } = props;
  const [isOpen, setIsOpen] = useState(false);

  const {
    ActivityDate,
    ActivityName,
    ActivityDateStart,
    ActivityDateEnd,
    Description,
    ProviderID,
  } = item;
  const hasDetailsRef = useRef(hasDetails);
  //We only want to show the expansion iconButton if we have valid children to show.
  const canExpand = !!AssetRenderForType[item.ActivityAsset];
  const openStateToggleIcon = isOpen ? CHEVRON_DOWN : CHEVRON_RIGHT;

  const expandOnClick = (ev) => {
    setIsOpen(!isOpen);
    castFunction(onToggle)({ nativeEvent: ev, isOpen: !isOpen });
  };

  const baseClassNameWithModifiers = withClassNameModifiers(
    'activity-asset-card__toggle',
    {
      hidden: !canExpand,
    }
  );

  const hasStartAndEnd = !isNil(ActivityDateStart) && !isNil(ActivityDateEnd);

  //Close the card if we had data, and it was cleared(AZ)
  useEffect(() => {
    if (!hasDetails && !!hasDetailsRef.current) {
      setIsOpen(false);
      castFunction(onToggle)({ isOpen: false });
    }
    hasDetailsRef.current = hasDetails;
  }, [hasDetails, onToggle]);

  return (
    <div
      data-component-name="ActivityAssetCard"
      className="display-grid activity-asset-card"
    >
      <IconButton
        dataElementName="openToggle"
        look="flat"
        icon={openStateToggleIcon}
        iconClassName="activity-asset-card__open-button"
        onClick={expandOnClick}
        className={baseClassNameWithModifiers}
      />
      <Flexbox
        className={withClassNameModifiers('activity-asset-card__header', {
          mini,
        })}
        alignItems="flex-start"
        direction="column"
      >
        <Flexbox alignItems="center" className="activity-asset-card__info">
          <Icon
            dataElementName="assetActivityCard__activityIcon"
            icon={activityIconMap[toCardIconName(item)]}
          />
          <Label
            dataElementName="assetActivityCard__activityDate"
            className="margin-horizontal-small"
          >
            {toDisplayDateFromISOString(ActivityDate, localeFormat.LONG)}
          </Label>
          <Label dataElementName="assetActivityCard__activityName">
            <span className="activity-asset-card__activity-name">
              {ActivityName}
            </span>
            {toFormatStaffName(ProviderID)}
          </Label>
        </Flexbox>
        <Label
          dataElementName="assetActivityCard__description"
          className="margin-horizontal-small"
        >
          {Description}
        </Label>
      </Flexbox>
      {isOpen && <div className="activity-asset-card__details">{children}</div>}
    </div>
  );
};

export default ActivityAssetCard;
