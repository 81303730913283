import { ofType } from '../../frp/operators/ofType';
import { combineWithLatestFrom } from '../../frp/operators/combineWithLatestFrom';
import { isNil } from '../../fp/pred';
import { pluck as pluckFp } from '../../fp/object';
import { castSchema } from '../../schema/schemaCaster';
import { merge, race, Subject, timer } from 'rxjs';
import {
  bufferCount,
  distinctUntilKeyChanged,
  filter,
  ignoreElements,
  map,
  mergeMap,
  pluck,
  startWith,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import FactXeVisitAssessmentSchema from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment.json';
import {
  getPollList,
  markInError,
} from 'services/visit-clindocs/xe-visit-clindocs-svc';
import {
  downloadFile,
  getFileData,
  getReportData,
} from '../../files/operators';
import { neverCache } from '../../service/serviceCache';
import { backgroundReducer } from '../../service/toRequestFns';
import { getVisitAssessmentID } from '../../utils/pluckers';
import {
  DID_MARK_DOCUMENT_IN_ERROR,
  DOWNLOAD_EXISTING_DOCUMENT,
  MARK_DOCUMENT_IN_ERROR,
  POLL_FOR_DOCUMENT_STATE,
  RECOVER_STALLED_DOCUMENT,
  RESPONSE_LATEST_POLL,
  STOP_POLLING_FOR_DOCUMENT_STATE,
} from './actions';
import {
  IS_QUICK_SAVE_COMPLETED,
  IS_QUICK_SAVE_DRAFT,
  IS_QUICK_SAVE_PROCESSING,
} from './constants';
import { toClinicalDocumentReportParams } from './utils';
import { HEADER_XE_ENTERPRISE_ID } from '../../service/constants';

const toPollForStateOnDataUpdateEpic$ = (_action$, _state$, { data$ }) => {
  return data$.pipe(
    filter((x) => !isNil(pluckFp('ModifiedTStamp')(x))),
    distinctUntilKeyChanged('ModifiedTStamp'),
    filter(
      ({ IsQuickSave }) =>
        IsQuickSave === IS_QUICK_SAVE_DRAFT ||
        IsQuickSave === IS_QUICK_SAVE_PROCESSING
    ),
    map((data) => {
      return {
        type: POLL_FOR_DOCUMENT_STATE,
        value: data,
      };
    })
  );
};

const toDownloadDocumentEpic$ = (action$, _state$, { menuNode$ }) => {
  return action$.pipe(
    ofType(DOWNLOAD_EXISTING_DOCUMENT),
    pluck('value'),
    getReportData(toClinicalDocumentReportParams, menuNode$),
    pluck('results'),
    getFileData(([response = {}]) => {
      const { Report: [report0 = {}] = [] } = response;
      const { FileID, Name } = report0;
      return { fileId: FileID, Name: Name };
    }, menuNode$),
    downloadFile(({ response, fileData }) => {
      const { Name } = fileData;
      return {
        blob: response.blob,
        fileName: Name,
      };
    }),
    ignoreElements()
  );
};

const POLL_INTERVAL = 3000;
const MAX_NUMBER_OF_POLLS = 4;

const hasProcessedAllDocuments = (stateList) => {
  return !stateList.some(
    ({ IsQuickSave } = {}) => IsQuickSave !== IS_QUICK_SAVE_COMPLETED
  );
};

/**
 * Begins polling for the processed state of the documents until a stop poll action is observed
 */
const toPollDocumentStateEpic$ = (
  action$,
  _state$,
  { enterpriseId$, menuNode$ }
) => {
  return action$.pipe(
    ofType(RECOVER_STALLED_DOCUMENT, POLL_FOR_DOCUMENT_STATE),
    pluck('value'),
    combineWithLatestFrom(
      enterpriseId$,
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn(backgroundReducer, neverCache)())
      )
    ),
    mergeMap(([data, enterpriseId, toRequest$]) => {
      const visitAssessmentId = getVisitAssessmentID(data);
      const stateList$ = new Subject();

      const additionalRequestConfig = enterpriseId
        ? { headers: { [HEADER_XE_ENTERPRISE_ID]: enterpriseId } }
        : {};

      const stopPolling$ = race(
        stateList$.pipe(
          bufferCount(MAX_NUMBER_OF_POLLS),
          map((buffer = []) => {
            return buffer[buffer.length - 1];
          })
        ),
        // Stop polling if there is nothing left to poll
        stateList$.pipe(filter(hasProcessedAllDocuments))
      ).pipe(
        map((lastPoll) => {
          return {
            type: STOP_POLLING_FOR_DOCUMENT_STATE,
            value: lastPoll,
          };
        })
      );

      const documentIdsToPoll$ = stateList$.pipe(
        map((stateList) =>
          stateList
            .filter(
              ({ IsQuickSave }) => IsQuickSave !== IS_QUICK_SAVE_COMPLETED
            )
            .map(({ VisitAssessmentID }) => VisitAssessmentID)
        ),
        startWith([visitAssessmentId])
      );

      return merge(
        timer(POLL_INTERVAL, POLL_INTERVAL).pipe(
          withLatestFrom(documentIdsToPoll$),
          takeUntil(stopPolling$),
          mergeMap(([, visitAssessmentIdArray]) => {
            return getPollList(
              { visitAssessmentId: visitAssessmentIdArray },
              toRequest$({ fullRequest: true, ...additionalRequestConfig })
            );
          }),
          pluck('results'),
          map((stateList) => {
            stateList$.next(stateList);
            return {
              type: RESPONSE_LATEST_POLL,
              value: stateList,
            };
          })
        ),
        stopPolling$
      );
    })
  );
};

const toMarkInErrorEpic$ = (action$, state$, { enterpriseId$, menuNode$ }) => {
  return action$.pipe(
    ofType(MARK_DOCUMENT_IN_ERROR),
    withLatestFrom(
      enterpriseId$,
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([payload, enterpriseIdFromProps, toRequest$]) => {
      const { value, reason } = payload;
      /**
       * @type {import('services/generated/types').FactXeVisitAssessment}
       */
      const {
        EnterpriseID: EnterpriseIDString,
        EnterpriseID: { EnterpriseID = EnterpriseIDString },
        VisitAssessmentID,
      } = castSchema(FactXeVisitAssessmentSchema)(value);

      /**
       * @type {import('services/generated/types').FactXeVisitAssessment}
       */
      const visitAssessmentWithErrorData = {
        VisitAssessmentID,
        ErrorDescription: reason,
        IsInError: true,
      };

      return markInError(
        visitAssessmentWithErrorData,
        { visitAssessmentId: VisitAssessmentID },
        toRequest$({
          fullRequest: true,
          headers: {
            [HEADER_XE_ENTERPRISE_ID]: enterpriseIdFromProps || EnterpriseID,
          },
        })
      );
    }),
    pluck('results'),
    map((response) => {
      return {
        type: DID_MARK_DOCUMENT_IN_ERROR,
        value: response,
      };
    })
  );
};

export default [
  toDownloadDocumentEpic$,
  toPollDocumentStateEpic$,
  toPollForStateOnDataUpdateEpic$,
  toMarkInErrorEpic$,
];
