import { Icon, Label } from '../';
import { EMPTY_OBJECT } from '../../constants';
import { useXeLabels } from '../../contexts/XeLabelContext';

const CaseAndProgram = ({
  visit: {
    DerivedProgramIVID,
    DerivedProgram,
    DerivedCaseVisitID,
    VisitID,
    VisitTypeID: { VisitTypeID } = EMPTY_OBJECT,
    XeAuth: { AuthNum } = EMPTY_OBJECT,
  } = EMPTY_OBJECT,
}) => {
  const labels = useXeLabels();

  if (VisitTypeID === 'AUTH') {
    return (
      <Label
        dataElementName="calendarVisitBar__case"
        descriptor={labels.Case}
        descriptorClassName="align-self-center"
      >
        {AuthNum}
      </Label>
    );
  }

  if (DerivedProgramIVID && DerivedProgram) {
    return (
      <>
        <Label descriptor={labels.Case} descriptorClassName="align-self-center">
          {DerivedProgramIVID}
        </Label>
        <Label
          descriptor={labels.Program}
          descriptorClassName="align-self-center"
        >
          {DerivedProgram}
        </Label>
      </>
    );
  }

  return (
    <Label descriptor={labels.Case} descriptorClassName="align-self-center">
      {DerivedCaseVisitID || VisitID}
    </Label>
  );
};

export const CalendarVisitBar = ({ visit = EMPTY_OBJECT }) => {
  const { VisitID } = visit;

  return VisitID ? (
    <div
      data-component-name="CalendarVisitBar"
      className="calendar-visit-bar flex-container align-items-center"
    >
      <Icon icon="clipboard-alert" className="padding-all-x-small" />
      <CaseAndProgram visit={visit} />
    </div>
  ) : null;
};

export default CalendarVisitBar;
