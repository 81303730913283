import { BatchClaimPopupView } from '../../../../../BatchClaimPopup';
import { useState } from 'react';
import { IconButton } from '../../../../../../components';
import { VIEW } from '../../../../../../icons';

export const EditBatchClaimAction = (props) => {
  const { actionData, labels } = props;

  const { ClaimBatchID, IsEdit } = actionData;

  const [showBatchClaimPopup, setShowBatchClaimPopup] = useState(false);

  return (
    <>
      {IsEdit ? (
        <IconButton
          icon={VIEW}
          description={labels.ViewDetails}
          onClick={() => setShowBatchClaimPopup(true)}
        />
      ) : null}
      {showBatchClaimPopup ? (
        <BatchClaimPopupView
          onClose={() => {
            setShowBatchClaimPopup(false);
          }}
          ClaimBatchID={ClaimBatchID}
        />
      ) : null}
    </>
  );
};

export default EditBatchClaimAction;
