import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import { getEnterpriseID, getVisitAssessmentID } from '../../utils/pluckers';
import {
  DID_FINISH_PROCESSING_DOCUMENT_SET,
  DID_SAVE_DOCUMENT_SCAN_DOC,
  INITIALIZE_UNOPENED_SET_ITEMS,
  PROCESS_DOCUMENT_SET,
  RESPONSE_CREATE_DOCUMENT,
  RESPONSE_OPEN_EXISTING_DOCUMENT,
  SET_SHOW_VALIDITY_STYLES,
  SHOULD_UNLOCK_DOCUMENT,
  UPDATE_SMARTBOOK_INSTANCE,
} from './actions';

const addFetchedDocumentToSetReducer = (state, action) => {
  const { documentSet = [] } = state;
  const { value = {}, enterpriseIdHeader } = action;
  const { Status } = value;
  const indexOfTemplateDocument = documentSet.findIndex((params = {}) => {
    const { data = {} } = params;
    const { AssessmentID, Name } = data;
    const { AssessmentID: fetchedAssessmentId, Name: fetchedName } = value;
    if (!getVisitAssessmentID(data)) {
      return AssessmentID === fetchedAssessmentId && Name === fetchedName;
    }
    return getVisitAssessmentID(data) === getVisitAssessmentID(value);
  });
  const indexToInsert =
    indexOfTemplateDocument === -1
      ? documentSet.length
      : indexOfTemplateDocument;

  return {
    ...state,
    documentSet: [
      ...documentSet.slice(0, indexToInsert),
      {
        data: value,
        readOnly: Status === 'SIGNED',
        canEdit: getEnterpriseID(value) === enterpriseIdHeader,
        fetched: true,
      },
      ...documentSet.slice(indexToInsert + 1),
    ],
  };
};

const addTemplateDocumentsToSetReducer = (state, action) => {
  const { documentSet = [] } = state;
  const { value } = action;
  return {
    ...state,
    documentSet: [
      ...documentSet,
      ...value.map((data) => {
        return {
          data,
          fetched: false,
        };
      }),
    ],
  };
};

const updateDocumentInSetReducer = (state, action) => {
  const { documentSet = [] } = state;
  const { value, index, ...changedProps } = action;
  const previousItem = documentSet[index] || {};
  return {
    ...state,
    documentSet: [
      ...documentSet.slice(0, index),
      { ...previousItem, data: value, ...changedProps },
      ...documentSet.slice(index + 1),
    ],
  };
};

const setShowValidityStylesForActiveDocumentReducer = (state, action) => {
  const { documentSet = [] } = state;
  const { index } = action;
  const previousItem = documentSet[index] || {};
  return {
    ...state,
    documentSet: [
      ...documentSet.slice(0, index),
      { ...previousItem, validityStyles: true },
      ...documentSet.slice(index + 1),
    ],
  };
};

const unlockActiveDocumentReducer = (state, action) => {
  const { documentSet = [] } = state;
  const { index } = action;
  const previousItem = documentSet[index] || {};
  return {
    ...state,
    documentSet: [
      ...documentSet.slice(0, index),
      { ...previousItem, readOnly: false },
      ...documentSet.slice(index + 1),
    ],
  };
};

export default [
  when((state) => state === undefined)(() => ({})),
  when(isType(RESPONSE_CREATE_DOCUMENT, RESPONSE_OPEN_EXISTING_DOCUMENT))(
    addFetchedDocumentToSetReducer
  ),
  when(isType(INITIALIZE_UNOPENED_SET_ITEMS))(addTemplateDocumentsToSetReducer),
  when(isType(UPDATE_SMARTBOOK_INSTANCE, DID_SAVE_DOCUMENT_SCAN_DOC))(
    updateDocumentInSetReducer
  ),
  when(isType(SET_SHOW_VALIDITY_STYLES))(
    setShowValidityStylesForActiveDocumentReducer
  ),
  when(isType(SHOULD_UNLOCK_DOCUMENT))(unlockActiveDocumentReducer),
  when(isType(PROCESS_DOCUMENT_SET))(
    toActionValueReducer('isProcessingDocuments', () => true)
  ),
  when(isType(DID_FINISH_PROCESSING_DOCUMENT_SET))(
    toActionValueReducer('isProcessingDocuments', () => false)
  ),
];
