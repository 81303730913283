import { localeFormat } from '../../format/luxonToDisplayString';
import { get } from '../../fp/object';
import { toDisplayDateFromISOString } from '../../g11n/displayDates';
import { formatCommonName } from '../../utils';

const dateCellValueGetter = (date, columnOptions = {}) => {
  const { showTime } = columnOptions;

  return showTime
    ? toDisplayDateFromISOString(date, localeFormat.LONG)
    : toDisplayDateFromISOString(date, localeFormat.MEDIUM);
};

const sortDates =
  (field) =>
  (a = '', b = '', nodeA, nodeB) => {
    const realizedNodeA = !!nodeA.allLeafChildren
      ? nodeA.allLeafChildren[0]
      : nodeA;
    const realizedNodeB = !!nodeB.allLeafChildren
      ? nodeB.allLeafChildren[0]
      : nodeB;

    const {
      data: { [field]: dateA = a },
    } = realizedNodeA;
    const {
      data: { [field]: dateB = b },
    } = realizedNodeB;

    return dateA.localeCompare(dateB);
  };

const toBaseColumnDefs = (columnOptions) => [
  {
    headerLabelKey: 'Name',
    colId: 'Name',
    field: 'Name',
  },
  {
    headerLabelKey: 'Type',
    colId: 'Type',
    field: 'AssessmentTypeID.Name',
  },
  {
    headerLabelKey: 'Member',
    colId: 'Member',
    valueGetter: ({ data: { IPID } = {} }) =>
      IPID && formatCommonName(IPID, 'F, G'),
  },
  {
    headerLabelKey: 'Modified',
    colId: 'Modified',
    comparator: sortDates('ModifiedTStamp'),
    valueGetter: (params) => {
      const { data: { ModifiedTStamp } = {} } = params;
      return (
        params &&
        ModifiedTStamp &&
        dateCellValueGetter(ModifiedTStamp, columnOptions?.Modified)
      );
    },
  },
  {
    headerLabelKey: 'Entered',
    colId: 'Entered',
    comparator: sortDates('EnteredDate'),
    valueGetter: (params) => {
      const { data: { EnteredDate } = {} } = params;
      return (
        params &&
        EnteredDate &&
        dateCellValueGetter(EnteredDate, columnOptions?.Entered)
      );
    },
  },
  {
    headerLabelKey: 'EnteredBy',
    colId: 'EnteredBy',
    valueGetter: ({ data: { EnteredBy } = {} }) =>
      EnteredBy && formatCommonName(EnteredBy, 'F, G'),
  },
  {
    headerLabelKey: get('SignedDate.headerLabelKey', columnOptions) || 'Signed',
    colId: 'SignedDate',
    comparator: sortDates('SignedDate'),
    valueGetter: (params) => {
      const { data: { SignedDate } = {} } = params;
      return (
        params &&
        SignedDate &&
        dateCellValueGetter(SignedDate, columnOptions?.SignedDate)
      );
    },
  },
  {
    headerLabelKey: 'SignedBy',
    colId: 'SignedBy',
    valueGetter: ({ data: { SignedBy } = {} }) =>
      SignedBy && formatCommonName(SignedBy, 'F, G'),
  },
  {
    headerLabelKey: 'Source',
    colId: 'Source',
    field: 'EnterpriseID.EnterpriseName',
    width: 196,
  },
  {
    headerLabelKey: 'Version',
    colId: 'Version',
    field: 'Version',
    cellRenderer: 'VersionControl',
  },
];

export const toFullViewColumnDefs = (columnOptions) => [
  ...toBaseColumnDefs(columnOptions),
  {
    field: 'ModifiedTStamp',
    colId: 'Actions',
    headerName: '',
    cellRenderer: 'ClinicalDocumentButtons',
    width: 112,
    minWidth: 112,
    resizable: false,
    pinned: 'right',
  },
];

export const toEmbeddedViewColumnDefs = (columnOptions) => [
  ...toBaseColumnDefs(columnOptions),
  {
    field: 'ModifiedTStamp',
    headerName: '',
    colId: 'Actions',
    cellRenderer: 'ClinicalDocumentButtons',
    width: 56,
    minWidth: 56,
    resizable: false,
    pinned: 'right',
  },
];

export const CAN_CREATE = 'CREATE_VISITDOC';
export const CAN_VIEW = 'VIEW_VISITDOC';
export const CAN_EDIT = 'EDIT_VISITDOC';
export const CAN_SIGN = 'SIGN_VISITDOC';
export const CAN_SIGN_INVALID = 'SIGN_VISITDOC_INVALID';

export const USER_RIGHTS_FOR_CLINDOCS = [
  CAN_CREATE,
  CAN_VIEW,
  CAN_EDIT,
  CAN_SIGN,
  CAN_SIGN_INVALID,
];
