import { useState } from 'react';
import {
  insert as addPatientProvider,
  update as removePatientProvider,
} from 'services/patient-providers/xe-patient-providers-svc';
import { useXeMutation } from '../../../../../data/useXeMutation';
import { pluck, set } from '../../../../../fp/object';
import { PERFORM_ACTION } from '../../../../../icons';
import { XeStaffSearchWidget } from '../../../../../widgets';
import { DropDownList } from '../../../../DropDownList';
import { Flexbox } from '../../../../Flexbox';
import IconButton from '../../../../IconButton';
import {
  ADD_PATIENT_PROVIDER,
  XE_PATIENT_PROVIDER_ACTION_KEY,
} from '../../../constants';
import { identity } from '../../../../../fp/fp';

export const GlobalActionPatientProvider = (props) => {
  const { labels, refData, rights, gridApi } = props;

  const { XePatientProviderType } = refData;
  const {
    CREATE_CLINICAL_PATIENT_DATA_CAREPROVIDER,
    BULK_CLINICAL_PATIENT_DATA_CAREPROVIDER,
  } = rights;

  const [selectedGlobalActionValue, setSelectedGlobalAction] = useState();
  const [selectedProviderAssignee, setSelectedProviderAssignee] = useState();

  const { mutate: addPatientProviderMutation } = useXeMutation((data) =>
    addPatientProvider(data, { xxsid: undefined }, identity)
  );
  const { mutate: removePatientProviderMutation } = useXeMutation((data) =>
    removePatientProvider(data, { xxsid: undefined }, identity)
  );

  if (!CREATE_CLINICAL_PATIENT_DATA_CAREPROVIDER) {
    return null;
  }

  return (
    <Flexbox alignItems="center">
      {!!BULK_CLINICAL_PATIENT_DATA_CAREPROVIDER && (
        <XeStaffSearchWidget
          onChange={setSelectedProviderAssignee}
          valueFn={pluck('StaffID')}
          descriptor={labels.Assignee}
        />
      )}
      <DropDownList
        dataElementName="providerType"
        data={XePatientProviderType}
        value={selectedGlobalActionValue}
        valueFn={pluck('id')}
        labelFn={pluck('text')}
        onChange={setSelectedGlobalAction}
        descriptor={labels.CareTeamRole}
      />
      <IconButton
        dataElementName="performAction"
        icon={PERFORM_ACTION}
        disabled={!selectedGlobalActionValue}
        tooltip
        description={labels.Submit}
        onClick={() => {
          /**
           * @type {GridRef}
           */
          const selectedRows = gridApi.getSelectedRows();

          // From docs:
          // "If you make multiple requests, onSuccess will fire only after the latest call you've made."
          // This seems like a lot... Is there a better way to handle parallel mutations?
          // I'd rather a success callback _per_ request, but from the docs, that doesn't seem
          // immediately possible (JDM)
          const onSuccessCallback = () => {
            const currentSelectedNodes = gridApi.getSelectedNodes();
            currentSelectedNodes.forEach((rowNode) => {
              const { data } = rowNode;
              rowNode.updateData(
                set(
                  `ActionList.${XE_PATIENT_PROVIDER_ACTION_KEY}.Valid`,
                  false,
                  data
                )
              );

              rowNode.setSelected(false);
            });
          };

          selectedRows.forEach((row) => {
            const {
              row: { ActionList },
            } = row;
            const { Action, Active, IPID, ProviderTypeID, StaffID } = {
              ...(ActionList[XE_PATIENT_PROVIDER_ACTION_KEY] || {}),
              ProviderTypeID: selectedGlobalActionValue,
            };

            if (Action === ADD_PATIENT_PROVIDER) {
              return addPatientProviderMutation(
                {
                  IPID,
                  ProviderTypeID,
                  StaffID: selectedProviderAssignee || StaffID,
                },
                {
                  onSuccess: onSuccessCallback,
                }
              );
            }

            return removePatientProviderMutation(
              {
                Active,
                ProviderTypeID,
              },
              {
                onSuccess: onSuccessCallback,
              }
            );
          });
        }}
      />
    </Flexbox>
  );
};
