import { isFunction, isObjectLike } from '../../fp/pred';
import { Button as ButtonKendo } from '@progress/kendo-react-buttons';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import withClassNameModifiers from '../../utils/withClassNameModifiers';
// import '../base-styles.css';
import './styles.css';

const BASE_CLASS_NAME = 'xe-button';

/**
 * @typedef XJSButtonProps
 * @property {import('@progress/kendo-react-buttons').ButtonProps["look"] | 'hyperlink'=} look
 */

/**
 * @typedef {Omit<import('@progress/kendo-react-buttons').ButtonProps, "look"> & import('react').ClassAttributes<HTMLButtonElement> & XJSButtonProps} ButtonProps
 */

/**
 * @type {import('react').ForwardRefRenderFunction<import('@progress/kendo-react-buttons').Button, ButtonProps>
 */
export const Button = forwardRef((props, ref) => {
  const {
    dataElementName = '',
    className = '',
    children,
    look = 'flat',
    ...passthroughProps
  } = props;

  const isHyperlinkStyle = look === 'hyperlink';

  const baseClassNameWithModifiers = withClassNameModifiers(BASE_CLASS_NAME, {
    hyperlink: isHyperlinkStyle,
  });

  return (
    <ButtonKendo
      data-element-name={dataElementName}
      data-component-name="Button"
      className={
        className
          ? `${baseClassNameWithModifiers} ${className}`
          : baseClassNameWithModifiers
      }
      type="button"
      {...passthroughProps}
      look={isHyperlinkStyle ? 'flat' : look}
      ref={(buttonRef) => {
        const element = isObjectLike(buttonRef) ? buttonRef.element : null;
        if (isFunction(ref)) {
          return ref(element);
        }
        if (isObjectLike(ref) && 'current' in ref) {
          return (ref.current = element);
        }
      }}
    >
      {children}
    </ButtonKendo>
  );
});

Button.propTypes = {
  dataElementName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  look: PropTypes.oneOf(['default', 'outline', 'flat', 'hyperlink']),
  primary: PropTypes.bool,
};
