import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../../../../../constants';
import { EDIT } from '../../../../../../icons';
import { IconButton } from '../../../../../IconButton';

export const InvoiceAction = (props) => {
  const { actionData = EMPTY_OBJECT } = props;

  const { IsValid } = actionData;

  // TODO: Add Edit Popup - MSS
  return IsValid ? null : <IconButton icon={EDIT} onClick={NOOP_FUNCTION} />;
};

export default InvoiceAction;
