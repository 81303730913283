import PropTypes from 'prop-types';
import { IconButton } from '../../../../components';
import { DEFAULT_SEARCH } from '../../../../icons';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../../../constants';

export const PatientNavigationCell = (props) => {
  const { data: { row = EMPTY_OBJECT } = EMPTY_OBJECT, context } = props;

  const {
    PatientNavigation: { toPatientRouteAction = NOOP_FUNCTION } = EMPTY_OBJECT,
  } = context;

  const { IPID } = row;

  if (!IPID) return '';

  return (
    <IconButton
      dataElementName="workList__defaultSearch"
      icon={DEFAULT_SEARCH}
      onClick={() => {
        toPatientRouteAction(IPID);
      }}
    />
  );
};

PatientNavigationCell.propTypes = {
  data: PropTypes.shape({
    IsDefaultEnterprise: PropTypes.bool,
  }),
};
