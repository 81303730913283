import { useState } from 'react';
import { IconButton } from '../../../../IconButton';
import { ADD } from '../../../../../icons';
import ClearingHouseRecordPopupView from '../../../../ClearingHouseRecordPopup';

export const GlobalActionXeClearingHouse = (props) => {
  const { labels, titleLabelKey, onSearch } = props;

  const [showClearingHousePopup, setShowClearingHousePopup] = useState();

  return (
    <>
      <IconButton
        dataElementName="add"
        icon={ADD}
        onClick={() => {
          setShowClearingHousePopup(true);
        }}
        description={labels.Add}
      />
      {showClearingHousePopup ? (
        <ClearingHouseRecordPopupView
          title={labels[titleLabelKey]}
          onClose={() => {
            setShowClearingHousePopup(false);
          }}
          onSaveClose={() => {
            setShowClearingHousePopup(false);
            onSearch();
          }}
        />
      ) : null}
    </>
  );
};

export default GlobalActionXeClearingHouse;
